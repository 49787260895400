import { Component, OnInit, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { StoreConfigService } from 'app/services/store-config.service';
import { Subscription } from 'rxjs';

declare const jQuery: any;

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.css']
})
export class PublicHeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  private previousBackGroundColour;
  showAnnouncement = false;
  routerSub : Subscription = new Subscription();

  constructor(
    public configSvc: StoreConfigService, 
    private elementRef: ElementRef,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    //Load announcement on login page when first load
   if(this.router.url.includes('login')){
      this.showAnnouncement = true;
    }

    //only display announcement when the route is /login (does not load for the first time)
    this.routerSub =
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        let data = this.activatedRoute.firstChild.snapshot.data;
        if(data.isLoginPage){
          this.showAnnouncement = true;
        }else{
          this.showAnnouncement = false;
        }
      }
    });
  }

  
  ngAfterViewInit() {
    
    const $ = jQuery;
    $(document).ready(function () {

      $('.menu-link').bigSlide({
        menu: '#menu',
        side: 'right',
        easyClose: 'true',
        afterOpen: () => {
          $('a.menu-link').hover().focus();
        },
        afterClose: () => {
          $('a.menu-link').blur();
        }
      });

      $('#menu a, #menu button').on('click touchend', function (e) {

        $('.menu-link').click(); // eMart fix

        e.preventDefault();                   // prevent default anchor behavior
        const goTo = this.getAttribute('href'); // store anchor href

        setTimeout(function () {
          window.location.href = goTo;
        }, 300);
      });

      $('#search-button').on('click touchend', function (e) {
        if ($('#search-input-container').hasClass('hdn')) {
          e.preventDefault();
          $('#search-input-container').removeClass('hdn')
          return false;
        }
      });

      $('#hide-search-input-container').on('click touchend', function (e) {
        e.preventDefault();
        $('#search-input-container').addClass('hdn')
        return false;
      });
    });

    //set the background colour to white after view init
    this.previousBackGroundColour = this.elementRef.nativeElement.ownerDocument.body.style.backgroundColor;
    this.elementRef.nativeElement.ownerDocument
        .body.style.backgroundColor = 'white';
  }

  //revert the background colour on destroy
  ngOnDestroy() {
    this.elementRef.nativeElement.ownerDocument
    .body.style.backgroundColor = this.previousBackGroundColour;
  }
}
