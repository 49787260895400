import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CreditsComponent } from '../layout/mainbody/credits/credits.component';
import { CurrentOrdersComponent } from '../layout/mainbody/current-orders/current-orders.component';
import { DashboardComponent } from '../layout/mainbody/dashboard/dashboard.component';
// import { ErrorpageComponent } from '../layout/mainbody/errorpage/errorpage.component';
import { FeedbackComponent } from '../layout/mainbody/feedback/feedback.component';
import { NameTagShoppingCartComponent } from '../layout/mainbody/name-tag-shopping-cart/name-tag-shopping-cart.component';
import { OrderConfirmationComponent } from '../layout/mainbody/order-confirmation/order-confirmation.component';
import { ProfileComponent } from '../layout/mainbody/profile/profile.component';
import { ShoppingCartComponent } from '../layout/mainbody/shopping-cart/shopping-cart.component';
import { TailorShoppingCartComponent } from '../layout/mainbody/tailor-shopping-cart/tailor-shopping-cart.component';
import { TransactionHistoryComponent } from '../layout/mainbody/transaction-history/transaction-history.component';
import { ViewNameTagsComponent } from '../layout/mainbody/view-name-tags/view-name-tags.component';
import { ViewProductsComponent } from '../layout/mainbody/view-products/view-products.component';
import { ViewTailoredUniformsComponent } from '../layout/mainbody/view-tailored-uniforms/view-tailored-uniforms.component';
import { CollectionPointListComponent } from './../layout/mainbody/collection-point-list/collection-point-list.component';
import { FaqComponent } from './../layout/mainbody/faq/faq.component';
import { ProductDetailComponent } from './../layout/mainbody/product-detail/product-detail.component';
import { TailorListComponent } from './../layout/mainbody/tailor-list/tailor-list.component';
import { CreditsGuard } from './resolvers/credits-guard';
import { CurrentOrdersGuard } from './resolvers/current-orders-guard';
import { DashboardGuard } from './resolvers/dashboard-guard';
import { FeedbackGuard } from './resolvers/feedback-guard';
import { NameTagShoppingCartGuard } from './resolvers/name-tag-shopping-cart-guard';
import { UserProfileGuard } from './resolvers/profile-guard';
import { ShoppingCartGuard } from './resolvers/shopping-cart-guard';
import { TailorShoppingCartGuard } from './resolvers/tailor-shopping-cart-guard';
import { TransactionHistorysGuard } from './resolvers/transaction-history-guard';
import { ViewNameTagProductsGuard, ViewProductsGuard, ViewUniformProductsGuard } from './resolvers/view-products-guard';
import { OrderDetailPrintComponent } from '../layout/mainbody/order-detail-print/order-detail-print.component';
import { OrderDetailPrintGuard } from './resolvers/order-detail-print.guard';
import { WaitlistGuard } from './resolvers/waitlist-guard';
import { WaitlistComponent } from '../layout/mainbody/waitlist/waitlist.component';
import { AuthGuard } from './canActivate/auth.guard';
import { LoginComponent } from 'app/public-module/login/login.component';
import { TermsOfUseComponent } from 'app/public-module/terms-of-use/terms-of-use.component';
import { PrivacyStatementComponent } from 'app/public-module/privacy-statement/privacy-statement.component';
import { FaqsComponent } from 'app/public-module/faqs/faqs.component';
import { AccessErrorComponent } from 'app/public-module/error/access-error/access-error.component';
import { GeneralErrorComponent } from 'app/public-module/error/general-error/general-error.component';
import { ContactUsComponent } from 'app/public-module/contact-us/contact-us.component';
import { PersonaSelectionComponent } from 'app/layout/mainbody/persona-selection/persona-selection.component';
import { LiveMaintenanceComponent } from 'app/public-module/live-maintenance/live-maintenance.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  { path: '', canActivate: [AuthGuard], children: [
    { path: 'dashboard', pathMatch: 'full', component: DashboardComponent, resolve: { guard: DashboardGuard } },
    { path: 'profile', pathMatch: 'full', component: ProfileComponent, resolve: { guard: UserProfileGuard } },
    { path: 'credits', pathMatch: 'full', component: CreditsComponent, resolve: { guard: CreditsGuard } },
    { path: 'current-orders', pathMatch: 'full', component: CurrentOrdersComponent, resolve: { guard: CurrentOrdersGuard } },
    { path: 'transaction-history', pathMatch: 'full', component: TransactionHistoryComponent, resolve: { guard: TransactionHistorysGuard } },
    { path: 'order-detail-print', pathMatch: 'full', component: OrderDetailPrintComponent, resolve: { guard: OrderDetailPrintGuard }},
    { path: 'view-products', pathMatch: 'full', redirectTo: 'view-products/All' },
    { path: 'view-products/All', pathMatch: 'full', component: ViewProductsComponent, resolve: { guard: ViewProductsGuard } },
    { path: 'view-products/:selectedCategory', pathMatch: 'full', component: ViewProductsComponent, resolve: { guard: ViewProductsGuard } },
    { path: 'product/:productId', pathMatch: 'full', component: ProductDetailComponent },
    { path: 'catalogue/products/:productId', pathMatch: 'full', component: ProductDetailComponent },
    { path: 'shopping-cart', pathMatch: 'full', component: ShoppingCartComponent, resolve: { guard: ShoppingCartGuard } },
    { path: 'waitlist', pathMatch: 'full', component: WaitlistComponent, resolve: { guard: WaitlistGuard } },
    { path: 'order-confirmation', pathMatch: 'full', component: OrderConfirmationComponent },
    { path: 'tailor-list', pathMatch: 'full', component: TailorListComponent },
    { path: 'collection-point-list', pathMatch: 'full', component: CollectionPointListComponent },
    { path: 'view-uniforms', pathMatch: 'full', component: ViewTailoredUniformsComponent, resolve: { guard: ViewUniformProductsGuard } },
    { path: 'tailored-uniform/:productId', pathMatch: 'full', component: ProductDetailComponent },
    { path: 'tailor-shopping-cart', pathMatch: 'full', component: TailorShoppingCartComponent, resolve: { guard: TailorShoppingCartGuard } },
    { path: 'view-name-tags', pathMatch: 'full', component: ViewNameTagsComponent, resolve: { guard: ViewNameTagProductsGuard } },
    { path: 'name-tag/:productId', pathMatch: 'full', component: ProductDetailComponent },
    {
      path: 'name-tag-shopping-cart', pathMatch: 'full', component: NameTagShoppingCartComponent,
      resolve: { guard: NameTagShoppingCartGuard }
    },
    // { path: 'faq', pathMatch: 'full', component: FaqComponent },
    { path: 'feedback', pathMatch: 'full', component: FeedbackComponent, resolve: { guard: FeedbackGuard } },
    { path: 'feedback/:productId', pathMatch: 'full', component: FeedbackComponent, resolve: { guard: FeedbackGuard } }
  ] },
  { path: 'department', pathMatch: 'full', component: PersonaSelectionComponent, data: {public: true} },
  { path: 'faq', pathMatch: 'full', component: FaqsComponent, data: {public: true} },
  { path: 'terms-of-use', pathMatch: 'full', component: TermsOfUseComponent, data: {public: true} },
  { path: 'privacy-statement', pathMatch: 'full', component: PrivacyStatementComponent, data: {public: true} },
  { path: 'contact-us', pathMatch: 'full', component: ContactUsComponent, data: {public: true} },
  { path: 'access-error', pathMatch: 'full', component: AccessErrorComponent, data: {public: true} },
  { path: 'general-error', pathMatch: 'full', component: GeneralErrorComponent, data: {public: true} },
  { path: 'maintenance', pathMatch: 'full', component: LiveMaintenanceComponent, data: {public: true} },


  { path: 'login', pathMatch: 'full', component: LoginComponent, data: {public: true, isLoginPage: true} },
  // { path: 'error', pathMatch: 'full', component: ErrorpageComponent, data: {public: true} },
  { path: 'unauthorized', pathMatch: 'full', redirectTo: 'error', data: {public: true} },
  { path: '**', redirectTo: 'dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class RoutesRoutingModule { }
