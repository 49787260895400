import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BroadcastService } from 'app/services/broadcast-service';
import { UserProfileService } from 'app/services/user-profile.service';
import { LoginAndSessionApi } from 'app/shared/backend-api/emart/api/LoginAndSessionApi';
import { Constants } from 'app/shared/global-constants/constants';
import * as bootstrap from 'bootstrap';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, throwError, flatMap, catchError, BehaviorSubject, Subject, interval, map, Subscription } from 'rxjs';
import { PreloaderService } from './preloader.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';

declare const jQuery: any;

@Injectable()
export class AuthService {

  // for other component to check login status
  public userType: BehaviorSubject<any>;
  public isAuthenticatedSubject: BehaviorSubject<any>;
  public isAuthenticated: Observable<boolean>;
  private sessionInfo = new BehaviorSubject<any>({sessionID : "", sessionTS : ""});
  public sessionDuration = 0;

  // prepare to be cleared timeout if user extends session
  public loginUrl = environment.loginPage;

  public countDownTimerSubject = new Subject<number>();
  private timeoutAt: Date;

  constructor(
    private userProfileService: UserProfileService, 
    private preloaderService: PreloaderService,
    private router: Router,
    private cookieService: CookieService,
    private broadCastService: BroadcastService,
    private sessionService: LoginAndSessionApi,
    private keepalive: Keepalive,
    private idle: Idle
    ) {
      // this.ngnspBaseUrl = !isNgnspEnv ? 'https://www.ns.sg' : '';
      this.userType = new BehaviorSubject<any>(this.cookieService.get('SPCP'));
      this.isAuthenticatedSubject = new BehaviorSubject<any>(false);
      this.isAuthenticated = this.isAuthenticatedSubject.asObservable();
      this.sessionDuration = environment.SESSION_DURATION_IN_MINUTES;
  }

  /**
   * Main function for the validation workflow.
   * @returns {Observable<any>}
   * @memberof AuthService
   */
  public doValidation(): Observable<any> {
    //To determine STORE or LOGISTICS user
    if (this.userType.getValue() == "cp"){
      window.location.href = environment.logisticsBaseUrl;
    }
    
    return this.userProfileService.forceLoadUserProfile()
      .pipe(
        flatMap(() => this.preloaderService.load()), // .flatMap(() => this.validateUserRoles());
        flatMap(() => this.validateUserAccess()),
        catchError(
        err => {
          this.router.navigate(['/general-error']);
          throw throwError(() => err);
        }
      ));
  }

  public closeValidation(): Observable<any> {
    return new Observable(obs => {
      obs.complete();
    })
  }

  private validateUserAccess(): Observable<boolean> {
    return new Observable(obs => {
      const profile = this.userProfileService.userProfile;
      if (!profile) {
        throw throwError(() => 'User profile is not found in eMart');
      } else {
        this.checkSessionCookie();

        console.log("validate user (isAuthenticated): "+this.isAuthenticatedSubject.getValue());
        
        if(this.isAuthenticatedSubject.getValue()) {
          // Set Idle timeout
          this.setIdleTimeout();
        } else {
          this.router.navigate(['/login']);
        }

        obs.next(true);
      }
    });
  }
  
  checkSessionCookie() {
    const sessionTSFromCookie = this.cookieService.get('StartTime');
    
    /*
      Check if current time equal or greater than the session start time, 
      else the session time is using old one, considered as old session
    */
    if(sessionTSFromCookie) {
      this.sessionInfo.next({
        sessionTS : sessionTSFromCookie
      });
      console.log("Session is valid.");
      this.isAuthenticatedSubject.next(true);
    }
    console.log("Login TS: "+ this.sessionInfo.getValue().sessionTS);
  }


  closeBigSlide() {
    if(jQuery('#menu').css('right') == '0px') {
      jQuery('.navbar-header .menu-link').click();
    }
  }

  extendSessionApi() {
    this.sessionService.extendSessionUsingPOST().subscribe(
      res => {
        if(res.status === 200) {
          this.sessionDuration = this.sessionDuration + environment.SESSION_DURATION_IN_MINUTES;

          // Inform other tabs the current session is extended successfully
          this.broadCastService.publish({
            type: Constants.BROADCAST_MESSAGES.EXTEND.type,
            payload: Constants.BROADCAST_MESSAGES.EXTEND.payload
          });
        }
        console.log(res);
      },
      err => {
        console.log(err);
      });
  }

    /* Logout Function: When users logout from the Session*/

    logoutSession() {
      this.sessionService.logOutSessionUsingPOSTWithHttpInfo(Constants.AuditLoginAction.LOGOUT).subscribe(
        res => {
          console.log(res)
          console.log("LogoutSession, Server Reached: Logging out now...")
          if (res.status === 200) {
            console.log("Publish Logging out now...")
            this.broadCastService.publish({
              type: Constants.BROADCAST_MESSAGES.LOGOUT.type,
              payload: Constants.BROADCAST_MESSAGES.LOGOUT.payload
            })
            console.log("Logging out")
            this.isAuthenticatedSubject.next(false);
            this.clearSession(true);
          }
          else if(res.status>300){
            console.log(res)
            this.closeBigSlide();
            jQuery('#logout-popup').modal('hide');
            this.clearSession(true);
          }
        }
      );
    }

    expireSession() {
      //if have StartTime then call logout
      if(this.cookieService.get('StartTime')) {
        this.cookieService.delete('StartTime', '/');
        this.sessionService.logOutSessionUsingPOSTWithHttpInfo(Constants.AuditLoginAction.SESSION_TIMEOUT).subscribe(
          res => {
            console.log("Expire Session, Server Reached: Logging out now...")
            if (res.status === 200) {
              
              this.isAuthenticatedSubject.next(false);
              this.clearSession(true);
            }
            else if(res.status>300){
              console.log(res)
              this.closeBigSlide();
              jQuery('#session-extension-pop-up').modal('hide');
              // this.clearSession(false);
            }
          }, error => {
            console.log(error)
            this.closeBigSlide();
            jQuery('#session-extension-pop-up').modal('hide');
          }
        );
      } else {
        // else don't need to call backend logout
        this.isAuthenticatedSubject.next(false);
        this.clearSession(true);
      }
    }

    clearSession(redirectLanding){
      this.closeBigSlide();
      window.sessionStorage.clear();
      window.localStorage.clear();
      this.cookieService.deleteAll('/');

      if(redirectLanding) {
        this.redirectToLanding();
      }
    }

    // Set timeout
    setIdleTimeout() {
      console.log('auth service set idle timeout')
      //set Idle
      this.keepalive.onPing.subscribe(() => {
        console.log('keep alive on ping at: ');
        console.log(new Date());
        // call backend to sync session on every ping
        this.extendSessionApi();

         // Close extension popup when idle reset
      if(jQuery('#session-extension-pop-up').hasClass('show')) {
        jQuery('#session-extension-pop-up').modal('hide');
      }
      });
  
      this.idle.onTimeout.subscribe((x) => {
        console.log('timeout at: ');
        console.log(new Date());
        this.stopIdleAndKeepalive();
        this.expireSession();
      });
  
      this.idle.onIdleStart.subscribe((x) => {
        console.log('idle start at: ');
        console.log(new Date());
        // Change interrupt behaviour, only stop timeout when extend button clicked
        this.idle.clearInterrupts();

        // Set timeout to be 3 minutes later
        this.timeoutAt = new Date();
        this.timeoutAt.setMinutes(this.timeoutAt.getMinutes() + environment.TIMEOUT_BUFFER);
        
      });

      this.idle.onTimeoutWarning.subscribe((x) => {
        let counter = this.timeoutAt.getTime() - new Date().getTime();

        // Clear cookie if counter less than 1 second
        if(counter < 1000) {
          this.idle.timeout();
        }

        this.countDownTimerSubject.next(counter);
        this.displayExtensionPopup();
      });

      // sets an idle timeout
      this.idle.setIdle((environment.SESSION_DURATION_IN_MINUTES - environment.TIMEOUT_BUFFER) * 60);
      // sets a timeout period inactivity, the user will be considered timed out.
      this.idle.setTimeout(environment.TIMEOUT_BUFFER * 60);
      // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      // set keepalive interval
      this.keepalive.interval(environment.KEEP_ALIVE_INTERVAL * 60); // will ping at this interval while not idle, in seconds
  
      this.reset();
    }

    reset() {
      // call watch() to start/reset the idle
      this.idle.watch();
      this.keepalive.start();

      // Close extension popup when idle reset
      if(jQuery('#session-extension-pop-up').hasClass('show')) {
        jQuery('#session-extension-pop-up').modal('hide');
      }
    }

    stopIdleAndKeepalive() {
      this.idle.stop();
      this.keepalive.stop();
    }

    extendSession() {
      this.stopIdleAndKeepalive();
      // restore interrupt
      this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
      // restart idle
      this.reset();

      this.extendSessionApi();
    }

    displayTimeoutPopup() {
      if(jQuery('#session-extension-pop-up').hasClass('show')) {
        jQuery('#session-extension-pop-up').modal('hide');
      }
      
      //hide all modal
      jQuery('.modal').modal('hide');

      jQuery('#session-expired-pop-up').modal({
        backdrop: 'static',
        keyboard: false
      });
      jQuery('#session-expired-pop-up').modal('show');
   
    }

    displayExtensionPopup() {
      if(!jQuery('#session-extension-pop-up').hasClass('show') && !jQuery('#session-expired-pop-up').hasClass('show')) {
        // Show extension pop up
        jQuery('#session-extension-pop-up').modal({
          backdrop: 'static',
          keyboard: false
        });
        jQuery('#session-extension-pop-up').modal('show');
      }
    }

    redirectToLanding() {
      
      jQuery('.modal').modal('hide');
      jQuery('.modal-backdrop').remove();
      this.stopIdleAndKeepalive();
      location.href = this.loginUrl;
    }
}
