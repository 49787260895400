/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class BusinessRulesManagementApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient,) {
        
    }

    /**
     * getBusinessRules
     * Get business rules
     */
    public getBusinessRulesUsingGET(extraHttpRequestParams?: any): Observable<models.GetBusinessRulesResponse> {
        return this.getBusinessRulesUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                if (response.status === 204) {
                    return undefined;
                } else {
                    return response;
                }
            }));
    }

    /**
     * saveBusinessRules
     * Save business rules
     * @param request request
     */
    public saveBusinessRulesUsingPOST(request: models.BusinessRulesDTO, extraHttpRequestParams?: any): Observable<models.SaveBusinessRulesResponse> {
        return this.saveBusinessRulesUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                if (response.status === 204) {
                    return undefined;
                } else {
                    return response;
                }
            }));
    }


    /**
     * getBusinessRules
     * Get business rules
     */
    public getBusinessRulesUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/businessRules';

        return this.httpClient.get(path, {withCredentials: true});
    }

    /**
     * saveBusinessRules
     * Save business rules
     * @param request request
     */
    public saveBusinessRulesUsingPOSTWithHttpInfo(request: models.BusinessRulesDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/businessRules';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveBusinessRulesUsingPOST.');
        }

        return this.httpClient.post(path, request, {withCredentials: true});
    }

}
