<section id="masthead" class="hidden-print">
    <div class="container-fluid">
        <div class="container">
            <div class="emart-logo">
                <a>
                    <img src="{{configSvc.deployPath}}./assets/images/masthead-logo-emart.png" alt="">
                </a>
            </div>
        </div>
    </div>
</section>

<!-- mobile view header banner-->
<nav class="navbar navbar-default hidden-print">
    <div class="container-fluid">
        <div class="container">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="navbar-header">
                <div class="emart-logo-nav">
                    <a>
                        <img src="{{configSvc.deployPath}}./assets/images/masthead-logo-emart.png" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
    <!-- /.container-fluid -->
</nav>

<app-banner *ngIf="showAnnouncement==true"></app-banner>
