<!-- content-->
<div class="col-md-12">
  <div class="collapse-box">
    <div class="collapse-title">
      <h1>Collection Points</h1>
    </div>
    <div id="credits1" class="collapse in">
      <div class="box-content">
        <div class="find-box">
          <h4>Collection Mode:</h4>
          <table class="role-form">
            <tr>
              <td>
                <table class="checkbox-wrapper">
                  <tr>
                    <td>
                      <div class="checkbox-square checkbox">
                        <input type="checkbox" id="show1" [(ngModel)]="viewCP" (ngModelChange)="filterItems()">
                        <label class="cbox" for="show1"></label>
                      </div>
                    </td>
                    <td class="radio-content">Self Collect</td>
                  </tr>
                </table>
                <table class="checkbox-wrapper">
                  <tr>
                    <td>
                      <div class="checkbox-square checkbox">
                        <input type="checkbox" id="show2" [(ngModel)]="viewEL" (ngModelChange)="filterItems()">
                        <label class="cbox" for="show2"></label>
                      </div>
                    </td>
                    <td class="radio-content">eLocker</td>
                  </tr>
                </table>
                <table class="checkbox-wrapper">
                  <tr>
                    <td>
                      <div class="checkbox-square checkbox">
                        <input type="checkbox" id="show3" [(ngModel)]="viewWI" (ngModelChange)="filterItems()">
                        <label class="cbox" for="show3"></label>
                      </div>
                    </td>
                    <td class="radio-content">Walk-in</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
        <div *ngIf="allItems != null && allItems.length > 0">
          <table class="table locationlist">
            <thead>
              <tr>
                <th>Collection Point</th>
                <th>Collection Mode</th>
                <th>Address</th>
                <th>Contact</th>
                <th>Opening Hours</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cp of getItemsToDisplay()">
                <td class="cp">{{cp.name}}</td>
                <td class="mode">{{getCollectionModes(cp)}}</td>
                <td class="address">
                  <div>{{cp.address.blkNo}} {{cp.address.streetName}}</div>
                  <div>{{cp.address.unitNo}}</div>
                  <div>{{cp.address.buildingName}}</div>
                  <div>Singapore {{cp.address.postalCode}}</div>
                </td>
                <td class="contact">{{cp.telephone}}</td>
                <td class="block open">
                  <div *ngFor="let openingHrs of cp.openingHrs">
                    <strong>{{openingHrs.days}}</strong><br/>{{openingHrs.hours}}
                  </div>
                </td>
                <td class="map"><a style="cursor:pointer;" (click)="viewMap(cp.address)">View Map</a></td>
              </tr>
            </tbody>
          </table>

          <div class="pagination">
            <button type="button" class="btn btn-square" [disabled]="prevPaginationDisabled" (click)="gotoFirstPage()"><span class="glyphicon glyphicon-step-backward" aria-hidden="true"></span></button>
            <button type="button" class="btn btn-square" [disabled]="prevPaginationDisabled" (click)="gotoPreviousPage()"><span class="glyphicon glyphicon-triangle-left" aria-hidden="true"></span></button>
            <div class="page">
              <input #page type="text" class="form-control" [value]="currentPage" (change)="gotoPage($event.target.value)">              / {{pageCount}}
            </div>
            <button type="button" class="btn btn-square" [disabled]="nextPaginationDisabled" (click)="gotoNextPage()"><span class="glyphicon glyphicon-triangle-right" aria-hidden="true"></span></button>
            <button type="button" class="btn btn-square" [disabled]="nextPaginationDisabled" (click)="gotoLastPage()"><span class="glyphicon glyphicon-step-forward" aria-hidden="true"></span></button>
          </div>
        </div>

        <div id="nodata" class="alert alert-info text-center" role="alert" *ngIf="allItems != null && allItems.length == 0">
          <i class="fa fa-info-circle fa-2x"></i>
          <p>No collection point is found</p>
        </div>
        <div class="text-center" *ngIf="allItems == null">
          <i class="fa fa-refresh fa-2x spinner text-primary"></i>
        </div>

      </div>
    </div>
  </div>
</div>