import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NavigationEnd, Router } from '@angular/router';
import { StoreConfigService } from 'app/services/store-config.service';
import { Constants } from 'app/shared/global-constants/constants';
import { CommonUtils } from 'app/shared/utilities/common-utils';

import { CartService } from './../../../services/cart.service';
import { CatalogueService } from './../../../services/catalogue.service';
import { NameTagCartService } from './../../../services/name-tag-cart.service';
import { ProductsService } from './../../../services/products.service';
import { TailorCartService } from './../../../services/tailor-cart.service';
import { WaitlistService } from './../../../services/waitlist.service'

declare const jQuery: any;

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  private subscribe: any;
  private scrollTop: number;
  private PRODUCT_MAX_QUANTITY = new Array(10);
  private totalSpend: number;
  private selectedSize: number;
  private selectedTailor: number;
  private selectedQty: number;
  public isTwoSize: boolean;

  public notificationOptions = {
    position: ['top', 'right'],
    lastOnBottom: false,
    timeOut: 2000
  };

  constructor(public catalogueService: CatalogueService, private cartService: CartService,
    private tailorCartService: TailorCartService, private nameTagCartService: NameTagCartService,
    private activatedRoute: ActivatedRoute, private productsService: ProductsService,
    public configSvc: StoreConfigService, private waitlistService: WaitlistService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.subscribe = this.activatedRoute.params.subscribe((params: Params) => {
      this.catalogueService.productDetail = null;
      const productId = params['productId'];
      this.catalogueService.forceLoadProductDetail(productId).subscribe(response => {
        this.productsService.loadProductCaps().subscribe(() => {
          this.selectedQty = 1;
          this.selectedSize = -1;
          this.catalogueService.productDetail.skus = this.catalogueService.productDetail.skus.filter(sku => !(sku.status === false && sku.allowed === false));
          for (const sku of this.catalogueService.productDetail.skus) {
            if (sku.preferred || this.catalogueService.productDetail.skus.length === 1) {
              this.selectedSize = sku.skuId;
              break;
            }
          }
          this.selectedTailor = -1;
          if (this.filterUniformPrice(this.catalogueService.productDetail.tailors).length === 1) {
            this.selectedTailor = this.filterUniformPrice(this.catalogueService.productDetail.tailors)[0];
          }

          this.isTwoSize = this.isTwoSizePreview();
          this.configureCarousel();
        });
      },
        error => {
          this.router.navigate(['/view-products/All']);
        });
      this.cartService.loadCartDetail().subscribe();

      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0)
      });
    });
  }

  isNormalProduct() {
    return this.catalogueService.productDetail.productType === Constants.ProductType.NORMAL;
  }

  isTailorProduct() {
    return this.catalogueService.productDetail.productType === Constants.ProductType.TAILOR;
  }

  isNameTagProduct() {
    return this.catalogueService.productDetail.productType === Constants.ProductType.NAME_TAG;
  }

  trackByFn(index, category): number {
    return index;
  }

  configureCarousel() {
    const $ = jQuery;
    const twosize = this.isTwoSize;

    $(document).ready(function () {
      // Instantiate the Bootstrap carousel
      $('.size-chart-content').carousel({
        interval: false,
      });

      // use a timeout to make sure that the carousel initialize happens before it.
      setTimeout(() => {
        // for every slide in carousel, copy the next slide's item in the slide.
        // Do the same for the next, next item.
        $('.size-chart-content .carousel-item').each(function () {
          let next = $(this).next();
          if (!next.length) {
            next = $(this).siblings(':first');
          }
          next.children(':first-child').clone().appendTo($(this));

          if (!twosize) {
            if (next.next().length > 0) {
              next.next().children(':first-child').clone().appendTo($(this));
            } else {
              $(this).siblings(':first').children(':first-child').clone().appendTo($(this));
            }
          }
        });
      }, 50);
    });
  }

  filterUniformPrice(tailors): any[] {
    return Array.from(new Set(tailors.map((tailor) => tailor.price)));
  }

  isTwoSizePreview(): boolean {
    if (!this.catalogueService.measureMentData || !this.catalogueService.measureMentData.details) {
      return false;
    }
    const details = this.catalogueService.measureMentData.details;
    return !CommonUtils.isEmptyArray(details) && (details.length === 2);
  }

  sendFeedback(productId: number) {
    this.router.navigate(['feedback/', productId]);
  }

}
