<div class="list-group">
    <button 
      type="button"     
      *ngFor="let list of getList; let i = index"
      class="list-group-item list-group-item-action"
      (click)="onSelected(i, list)"
      [ngClass]="list.clicked ? 'clicked':''"
    >
      {{list.name}}
    </button>
</div>
  
  
  