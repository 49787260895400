import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import { catchError, map, Observable, throwError, TimeoutError } from 'rxjs';
import { Constants } from 'app/shared/global-constants/constants';
import { Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthService, private cookieService: CookieService) {}

  // intercepts request and response
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        //to avoid first time login
        //eg. when login the first time, the status code is undefined. 
        //Once statuscode == undefined, the system unable to load dashboard.
        if (event['body']) {
          if (event['body'].status == Constants.HttpStatus.UNAUTHORIZED) {
            // auto logout if http status 401 returned from protected api
            // TODO: logout user
            if(this.cookieService.get('StartTime')) {
              this.authService.displayTimeoutPopup();
              console.log("With cookie start time and logout");
            }
            else {
              this.authService.redirectToLanding();
              console.log("No session No cookie Login TS");
            }
            return null;

          } else if (event['body'].status == Constants.HttpStatus.FORBIDDEN) {
            // redirect to access-error if http status 403 returned from protected api
            // TODO: redirect to access-error
            this.router.navigate(['/access-error']);
            return null;
          }
        }
        return event;
      }),
      catchError(error => {
        // time out exception
        if (error instanceof TimeoutError) {
          return throwError(error);
        }
        throw new Error(error);
      })
    );
  }
}
