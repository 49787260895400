<div id="department-popup" class="modal fade" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">Department</h1>
      </div>
      <div class="modal-body">
        <p>Select Department</p>
        <button type="button" *ngFor="let department of multiPersonaDepartment" (click)="loadPersona(department)"
          class="btn btn-shape" data-bs-dismiss="modal">{{department}}</button>
      </div>
    </div>
  </div>
</div>