import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { StoreConfigService } from 'app/services/store-config.service';
import { UserProductDTO, UserSkuDTO, GetProductsResponseError } from 'app/shared/backend-api/emart';
import * as naturalSort from 'javascript-natural-sort';

import { CartService } from './../../../services/cart.service';
import { NameTagCartService } from './../../../services/name-tag-cart.service';
import { ProductsService } from './../../../services/products.service';
import { ResourceService } from './../../../services/resource.service';
import { SharedSearchService } from './../../../services/shared-search.service';
import { UserProfileService } from './../../../services/user-profile.service';
import { Constants } from './../../../shared/global-constants/constants';

@Component({
  selector: 'app-view-name-tags',
  templateUrl: './view-name-tags.component.html',
  styleUrls: ['./view-name-tags.component.css'],
})
export class ViewNameTagsComponent implements OnInit {

  listView = false;

  private filterByCategory: string;
  public sortType = 0;
  private searchString = '';

  products: UserProductDTO[];
  skus: UserSkuDTO[];
  originProducts: UserProductDTO[];

  imagestore: any = {};

  // Pagination
  private currentPage = 1;
  private itemPerPage = 12;
  private pageCount = 1;
  private nextPaginationDisabled = false;
  private prevPaginationDisabled = false;
  // ---

  loadProductsFailed = false;
  invalidRank = false;

  public notificationOptions = {
    position: ['top', 'right'],
    lastOnBottom: false,
    timeOut: 2000
  };

  constructor(
    private activateRoute: ActivatedRoute,
    private resourceService: ResourceService,
    private productsService: ProductsService,
    private cartService: CartService,
    private nameTagCartService: NameTagCartService,
    private userProfileService: UserProfileService,
    private cookieService: CookieService,
    public configSvc: StoreConfigService,
    private searchService: SharedSearchService
  ) { }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    this.searchService.currentPage = '';
  }

  ngOnInit() {
    this.cartService.loadCartDetail().subscribe(response => {
      if (response.status === 200) {
        this.cartService.shoppingCart = response.data;
      } else {
        // TODO: error to get products. display error message
      }
    })
    this.searchService.currentPage = 'VIEW_NAME_TAGS';
    this.searchService.listenFor(SharedSearchService.SEARCH_STRING).subscribe(
      searchFor => {
        this.searchString = searchFor;
        if (this.originProducts) {
          this.filterProducts();
        }
      }
    );

    const cookiePerPage = this.cookieService.get('name_tags_per_page');
    if (cookiePerPage) {
      this.itemPerPage = Number(cookiePerPage);
    }
    const cookieListView = this.cookieService.get('name_tags_list_view');
    if (cookieListView) {
      this.listView = cookieListView === 'true';
    }
    const cookieSortType = this.cookieService.get('name_tags_sort_type');
    if (cookieSortType) {
      this.sortType = Number(cookieSortType);
    }

    this.activateRoute.params.subscribe((params: Params) => {
      const selectedCategory = params['selectedCategory'];
      this.filterByCategory = selectedCategory;
      if (this.originProducts) {
        this.filterProducts();
      }
    });
    this.loadProductsFailed = false;
    this.invalidRank = false;
    this.productsService.loadProducts(Constants.ProductType.NAME_TAG).subscribe(response => {
      this.productsService.loadProductCaps().subscribe(() => {
        if (response.status === 200) {
          this.originProducts = response.data;
          this.filterProducts();
        } else if (response.status === 204) {
          this.originProducts = [];
          this.filterProducts();
        } else {
          this.loadProductsFailed = true;
          if (response.errors[0].code === GetProductsResponseError.CodeEnum.RANK) {
            this.invalidRank = true;
          }
        }
      });
    });

  }

  getProduct(id: number): UserProductDTO {
    for (const product of this.productsService.cachedNameTags.data) {
      if (product.productId === id) {
        return product;
      }
    }
  }

  filterProducts() {
    this.currentPage = 1;
    // copy the origin product list
    this.products = this.originProducts.map(p => Object.assign({}, p));
    this.searchProducts();
  }

  searchProducts() {
    if (this.searchString) {
      const sstr = this.searchString.trim().toUpperCase();
      this.products = this.products.filter(p => {
        return p.productName.toUpperCase().indexOf(sstr) >= 0;
      });
    }
    this.sortProducts();
  }

  selectSortProductType($event) {
    this.sortType = Number($event.target.value);
    this.cookieService.set('name_tags_sort_type', String(this.sortType));
    this.sortProducts();
  }

  sortProducts() {
    this.currentPage = 1;
    /*
     *  Sort the index first using naturalSort
     */
    const sortedIndex = new Array;
    naturalSort.insensitive = true;
    for (const product of this.products) {
      sortedIndex.push(product.productName);
    }
    sortedIndex.sort(naturalSort);

    switch (this.sortType) {
      case 0:
        this.products.sort((a, b) => {
          return sortedIndex.indexOf(a.productName) <= sortedIndex.indexOf(b.productName) ? -1 : 1;
        })
        break;

      case 1:
        this.products.sort((a, b) => {
          return sortedIndex.indexOf(a.productName) <= sortedIndex.indexOf(b.productName) ? 1 : -1;
        });
        break;

      case 2:
        this.products.sort((a, b) => {
          return a.productPrice <= b.productPrice ? -1 : 1;
        });
        break;

      case 3:
        this.products.sort((a, b) => {
          return a.productPrice <= b.productPrice ? 1 : -1;
        });
        break;

      default:

    }
    this.updatePagination();
  }

  private loadProductImage(product: UserProductDTO) {
    if (this.imagestore[product.productId]) { return; }// cached.
    if (product.images && product.images.length > 0) {
      this.resourceService.loadImageWithId(product.images[0].resourceId).then(img => {
        this.imagestore[product.productId] = img;
      });
    } else {
      this.imagestore[product.productId] = this.configSvc.dummyImgPath;
    }
  }

  updateViewMode(listView: boolean) {
    this.listView = listView;
    this.cookieService.set('name_tags_list_view', String(listView));
  }

  updateItemPerPage($event) {
    this.itemPerPage = $event.target.value;
    if (Number(this.itemPerPage) === 6 || Number(this.itemPerPage) === 12) {
      this.cookieService.set('name_tags_per_page', String(this.itemPerPage));
    }
    this.updatePagination();
  }

  fetchImagesInThePage() {
    for (const product of this.getProductsToDisplay()) {
      this.loadProductImage(product);
    }
  }

  // Pagination
  getProductsToDisplay(): any[] {
    if (this.products && this.products.length > 0) {
      const firstItemIndex = (this.currentPage - 1) * this.itemPerPage;
      const endItemIndex = this.currentPage * this.itemPerPage;
      return this.products.slice(firstItemIndex, endItemIndex);
    } else {
      return [];
    }
  }

  updatePagination() {
    try {
      this.pageCount = Math.ceil(this.products.length / this.itemPerPage);
      this.currentPage = this.currentPage > this.pageCount ? this.pageCount : this.currentPage;
      this.nextPaginationDisabled = this.currentPage * this.itemPerPage >= this.products.length;
      this.prevPaginationDisabled = this.currentPage <= 1;
    } catch (e) {
      this.pageCount = 1;
      this.currentPage = 1;
      this.nextPaginationDisabled = true;
      this.prevPaginationDisabled = true;
    }

    this.fetchImagesInThePage();
  }

  gotoPreviousPage() {
    this.gotoPage(Number(this.currentPage) - 1);
  }

  gotoNextPage() {
    this.gotoPage(Number(this.currentPage) + 1);
  }

  gotoFirstPage() {
    this.gotoPage(1);
  }

  gotoLastPage() {
    this.gotoPage(this.pageCount);
  }

  gotoPage(page: number, event?: any) {
    if (event && event.key !== 'Enter') {
      return;
    }

    if (page > 0 && page <= this.pageCount) {
      this.currentPage = page;
      this.updatePagination();
    }
  }
  // ---
}
