import { Component, OnInit } from '@angular/core';
import { NotificationsService } from 'assets/lib/angular2-notifications';
import { StoreConfigService } from 'app/services/store-config.service';

import { ResourceService } from '../../../services/resource.service';
import { CartItemDTO, OrderItemDTO } from '../../../shared/backend-api/emart/index';
import { OrdersApi, ServicemanApi } from '../../../shared/backend-api/emart/index';
import { CartService } from './../../../services/cart.service';
import { CurrentOrdersService } from './../../../services/current-orders.service';
import { NameTagCartService } from './../../../services/name-tag-cart.service';
import { ProductsService } from './../../../services/products.service';
import { TailorCartService } from './../../../services/tailor-cart.service';
import { UserProfileService } from './../../../services/user-profile.service';
import { Constants } from './../../../shared/global-constants/constants';

@Component({
  selector: 'app-current-orders',
  templateUrl: './current-orders.component.html',
  styleUrls: ['./current-orders.component.css']
})
export class CurrentOrdersComponent implements OnInit {

  recentItems: OrderItemDTO[] = null;
  allRecentItemsChecked: boolean;
  orderToViewDetail: OrderItemDTO;
  imagestore: any = {};
  recentItemsErrorMessage: string;
  currentOrdersErrorMessage: string;
  hasPurchasable = false;

  public notificationOptions = {
    position: ['top', 'right'],
    lastOnBottom: false,
    timeOut: 2000
  };

  constructor(
    private api: ServicemanApi,
    private orderApi: OrdersApi,
    private productsService: ProductsService,
    private cartService: CartService,
    private tailorCartService: TailorCartService,
    private nameTagCartService: NameTagCartService,
    private userProfileService: UserProfileService,
    private resourceService: ResourceService,
    public currentOrdersService: CurrentOrdersService,
    private notificationService: NotificationsService,
    public configSvc: StoreConfigService
  ) { }

  ngOnInit() {
    this.currentOrdersErrorMessage = null;
    this.recentItemsErrorMessage = null;

    this.currentOrdersService.currentOrders = null;
    this.currentOrdersService.orderPrint.prevPath = 'current-orders';

    if (this.currentOrdersService.orderPrint.currentOrderListData) {
      this.allRecentItemsChecked = false;
      this.currentOrdersService.currentOrders = this.currentOrdersService.orderPrint.currentOrderListData;
      this.currentOrdersService.orderPrint.currentOrderListData = null;

    } else {
      this.orderApi.getCurrentOrdersUsingGET().subscribe(response => {
        if (response.status === 200) {
          this.allRecentItemsChecked = false;
          this.currentOrdersService.currentOrders = response.data ? response.data : [];
        } else if (response.status === 204) {
          this.allRecentItemsChecked = false;
          this.currentOrdersService.currentOrders = [];
        } else {
          // handle errors correspondingly.
          this.currentOrdersErrorMessage = 'Fail to get current orders data, please try again later.';
        }
      });
    }

    if (this.currentOrdersService.orderPrint.recentPurchaseData) {
      this.recentItems = this.currentOrdersService.orderPrint.recentPurchaseData;
      for (const item of this.recentItems) {
        this.loadImage(item.imageResourceId, item.orderItemId);

        if (item.purchasable) {
          this.hasPurchasable = true;
        }
      }
      this.currentOrdersService.orderPrint.recentPurchaseData = null;

    } else {
      this.orderApi.getRecentPurchasesUsingGET().subscribe(response => {
        if (response.status === 200) {
          this.recentItems = response.data ? response.data : [];
          for (const item of this.recentItems) {
            this.loadImage(item.imageResourceId, item.orderItemId);

            if (item.purchasable) {
              this.hasPurchasable = true;
            }
          }
        } else if (response.status === 204) {
          this.recentItems = [];
        } else {
          // TODO: display error
          this.recentItemsErrorMessage = 'Failed to get recent purchases, please try again later.';
        }
      });
    }

    this.cartService.loadCartDetail().subscribe(response => {
      if (response.status === 200) {
        this.cartService.shoppingCart = response.data;
      } else {
        // TODO: error to get products. display error message
      }
    });

    this.userProfileService.loadCreditSummary().subscribe(response => {
      if (response.status === 200) {
        this.userProfileService.creditSummary = response.data;
      } else {
        // TODO: error to get products. display error message
      }
    });
  }

  private loadImage(withId: number, orderItemId: number) {
    if (withId) {
      this.resourceService.loadImageWithId(withId).then(img => {
        this.imagestore[orderItemId] = img;
      })
    } else {
      this.imagestore[orderItemId] = this.configSvc.dummyImgPath;
    }
  }

  viewOrderDetail(order: any) {
    this.orderToViewDetail = order;
    this.currentOrdersService.orderPrint.currentOrderListData = this.currentOrdersService.currentOrders;
    this.currentOrdersService.orderPrint.recentPurchaseData = this.recentItems;
  }

  selectRecentItemToPurchase(item: any) {
    item['selected'] = !item['selected'];
    let allChecked = true;
    for (const recentItem of this.recentItems) {
      if (!recentItem['selected'] && recentItem.purchasable) {
        allChecked = false;
        break;
      }
    }
    this.allRecentItemsChecked = allChecked;
  }

  selectAllRecentItemsToPurchase() {
    this.allRecentItemsChecked = !this.allRecentItemsChecked;
    for (const item of this.recentItems) {
      if (item.purchasable) {
        item['selected'] = this.allRecentItemsChecked;
      }
    }
  }

  addToCart() {
    const items: Array<CartItemDTO> = [];
    const tailorItems: Array<CartItemDTO> = [];
    const nameTagItems: Array<CartItemDTO> = [];
    let type: number = null;

    for (const recentItem of this.recentItems) {
      if (recentItem['selected'] !== undefined && recentItem['selected']) {
        const tempType = recentItem.tailorId !== null ? Constants.ProductType.TAILOR : recentItem.nameTagText != null ?
          Constants.ProductType.NAME_TAG : Constants.ProductType.NORMAL;
        if (type === null) {
          type = tempType;
        } else if (type !== tempType) {
          this.notificationService.warn(
            'Shopping Cart',
            'Catalogue items, tailored uniforms and name tags cannot be in the same shopping cart'
          );
          return;
        }

        if (type === Constants.ProductType.NORMAL) {
          const item: CartItemDTO = {};
          item.productId = recentItem.productId;
          item.skuId = recentItem.skuId;
          item.quantity = recentItem.quantity;
          items.push(item);
        } else if (type === Constants.ProductType.TAILOR) {
          const item: any = {};
          item.productId = recentItem.productId;
          item.quantity = recentItem.quantity;
          item.tailorId = recentItem.tailorId;
          tailorItems.push(item);
        } else if (type === Constants.ProductType.NAME_TAG) {
          const item: any = {};
          item.productId = recentItem.productId;
          item.skuId = recentItem.skuId;
          item.quantity = recentItem.quantity;
          nameTagItems.push(item);
        }
      }
    }

    if (type === Constants.ProductType.NORMAL) {
      this.cartService.addItemsToCart(items);
    } else if (type === Constants.ProductType.TAILOR) {
      this.tailorCartService.addOrderItemsToCart(tailorItems);
    } else if (type === Constants.ProductType.NAME_TAG) {
      this.nameTagCartService.addOrderItemsToCart(nameTagItems);
    }
  }

  hasSelected() {
    for (const recentItem of this.recentItems) {
      if (recentItem['selected'] !== undefined && recentItem['selected']) {
        return true;
      }
    }
    return false;
  }

}
