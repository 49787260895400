import { Component, OnInit } from '@angular/core';

import { CatalogueService } from './../../../services/catalogue.service';
import { UserProfileService } from './../../../services/user-profile.service';

declare global{
  interface Window{
    wogaaCustom:any;
  }
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})


export class DashboardComponent implements OnInit {

  constructor(public userProfileService: UserProfileService, public catalogueService: CatalogueService) { }

  
  ngOnInit() {
    // let wogaaCustom = window.wogaaCustom.startTransactionalService('emartmha-4707');
  }

  trackByFn(index, category): number {
    return index;
  }
}
