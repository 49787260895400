import { Injectable } from '@angular/core';
import { NotificationsService } from 'assets/lib/angular2-notifications';
import { OrdersApi } from 'app/shared/backend-api/emart';
import { Observable, of, throwError, catchError, tap, flatMap } from 'rxjs';
import { CartApi } from './../shared/backend-api/emart/api/CartApi';
import { CommonApi } from './../shared/backend-api/emart/api/CommonApi';
import { CartDTO } from './../shared/backend-api/emart/model/CartDTO';
import { OrderDTO } from './../shared/backend-api/emart/model/OrderDTO';
import { CartItemDTO, UserProductDTO, GetProductCapsResult } from './../shared/backend-api/emart/model/models';
import { Constants } from './../shared/global-constants/constants';
import { CommonUtils } from './../shared/utilities/common-utils';
import { ProductsService } from './products.service';
import { UserProfileService } from './user-profile.service';
import * as naturalSort from 'javascript-natural-sort';

declare const jQuery: any;

@Injectable()
export class CartService {
  private EMPTY_CART_MSG = 'Shopping cart is empty';

  public shoppingCart: CartDTO;
  public totalPrice: number;
  public deliveryFee: number;
  public deliveryReminderMsg: string;
  public deliveryInsufficientMsg: string;
  public selectedDeliveryMode: number;
  public product:any;
  public skuId:number;
  public qty:number;

  public tempShoppingCart: CartDTO;
  public loadTempShoppingCart = false;

  // Variables used to create order
  // true: validation ok; false: validation fails
  public hasCartBeingValidated = false;
  // true: user has confirmed the cart
  public hasCartBeingConfirmed = false;
  // has value if validation ok, used as request param for createOrder api
  public validatedCart: CartDTO;
  // has value if order is created successfully, response of createdOrder api
  public orderDetail: OrderDTO = {};
  public orderDetailImageStore: any = {};
  public softErrors: string[] = [];

  private DeliveryMode = Constants.DeliveryMode;

  constructor(
    private orderApi: OrdersApi,
    private cartApi: CartApi,
    private commonApi: CommonApi,
    private productsService: ProductsService,
    private userProfileService: UserProfileService,
    private notificationService: NotificationsService
  ) {
    this.productsService.loadProducts(Constants.ProductType.NORMAL);
  }

  getDeliveryFee(): Observable<any> {
    return this.commonApi.getDeliveryFeeUsingGET().pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.deliveryFee = payload.data.deliveryFee;
          this.deliveryReminderMsg = payload.data.warnMsg;
          this.deliveryInsufficientMsg = payload.data.insufficientMsg;

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
      ));
  }

  getProduct(productId: number): UserProductDTO {
    for (const product of this.productsService.cachedProducts.data) {
      if (product.productId === productId) {
        return product;
      }
    }
  }

  getTailorProduct(productId: number): UserProductDTO {
    for (const product of this.productsService.cachedUniforms.data) {
      if (product.productId === productId) {
        const sortedIndex = new Array;
        naturalSort.insensitive = true;
        for (const tailor of product.tailors) {
          sortedIndex.push(tailor.name);
        }
        sortedIndex.sort(naturalSort);
        product.tailors.sort((a, b) => {
          return sortedIndex.indexOf(a.name) <= sortedIndex.indexOf(b.name) ? -1 : 1;
        });

        return product;
      }
    }
  }

  getNameTagProduct(productId: number): UserProductDTO {
    for (const product of this.productsService.cachedNameTags.data) {
      if (product.productId === productId) {
        return product;
      }
    }
  }

  getTailorProductPrice(productId: number, productTailorId: number): number {
    const tailors = this.getTailorProduct(productId).tailors;

    for (const tailor of tailors) {
      if (tailor.productTailorId === productTailorId) {
        return tailor.price;
      }
    }
  }

  getProductBySkuId(skuId: number): UserProductDTO {
    for (const product of this.productsService.cachedProducts.data) {
      for (const sku of product.skus) {
        if (sku.skuId === skuId) {
          return product;
        }
      }
    }
  }

  // Force reload cart detail
  getCartDetail(): Observable<any> {
    return this.cartApi.getCartUsingGET().pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          if (this.loadTempShoppingCart) {
            this.tempShoppingCart = payload.data as CartDTO;
          } else {
            this.shoppingCart = payload.data as CartDTO;
          }

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
      ));
  }

  loadCartDetail(): Observable<any> {
    if (this.shoppingCart) {
      return of('No need to reload shopping cart.');
    } else {
      return this.getCartDetail();
    }
  }

  addItemToCart(skuId: number, qty: number) {
    skuId = Number(skuId);
    qty = Number(qty);

    if (skuId == null || skuId <= 0) {
      this.notificationService.warn(
        'Shopping Cart',
        'Invalid size'
      )
      return;
    }
    if (qty == null || qty <= 0) {
      this.notificationService.warn(
        'Shopping Cart',
        'Invalid quantity'
      )
      return;
    }

    const item: CartItemDTO = {};
    item.productId = this.getProductBySkuId(skuId).productId;
    item.skuId = skuId;
    item.quantity = qty;

    const items: Array<CartItemDTO> = [];
    items.push(item);

    this.addItemsToCart(items);
  }

  addItemsToCart(items: Array<CartItemDTO>) {
    this.getCartDetail().subscribe(data => {
      if (this.shoppingCart.tailorItems != null && this.shoppingCart.tailorItems.length > 0) {
        this.notificationService.warn(
          'Shopping Cart',
          'Catalogue items and tailored uniforms cannot be in the same shopping cart'
        )
        return;
      }
      if (this.shoppingCart.nameTagItems != null && this.shoppingCart.nameTagItems.length > 0) {
        this.notificationService.warn(
          'Shopping Cart',
          'Catalogue items and name tags cannot be in the same shopping cart'
        )
        return;
      }

      let tempCartItems = CommonUtils.cloneDeep(this.shoppingCart.items);
      if (tempCartItems == null) {
        tempCartItems = [];
      }

      let existing: boolean;

      const modifiedGroupIds: Array<Number> = [];

      for (const item of items) {
        existing = false;

        for (const groupId of this.getProduct(item.productId).productGroupIds) {
          modifiedGroupIds.push(groupId);
        }

        for (const tempCartItem of tempCartItems) {
          if (Number(tempCartItem.skuId) === Number(item.skuId)) {
            tempCartItem.quantity = Number(tempCartItem.quantity) + Number(item.quantity);
            existing = true;
            break;
          }
        }

        if (!existing) {
          tempCartItems.push(item);
        }
      }

      this.checkCapAndCreditBalanceAndSaveCart(Constants.ProductType.NORMAL, tempCartItems, modifiedGroupIds);
    });
  }

  updateCartItem(oriSkuId: number, skuId: number, qty: number) {
    oriSkuId = Number(oriSkuId);
    skuId = Number(skuId);
    qty = Number(qty);

    if (skuId == null || skuId <= 0) {
      this.notificationService.warn(
        'Shopping Cart',
        'Invalid size'
      )
      return;
    }
    if (qty == null || qty <= 0) {
      this.notificationService.warn(
        'Shopping Cart',
        'Invalid quantity'
      )
      return;
    }

    this.loadTempShoppingCart = true;
    this.getCartDetail().subscribe(() => {
      this.loadTempShoppingCart = false;
      const tempCartItems = this.tempShoppingCart.items;

      let existingQty = 0;
      if (oriSkuId !== skuId) {
        for (let i = 0; i < tempCartItems.length; i++) {
          if (tempCartItems[i].skuId === skuId) {
            existingQty = Number(tempCartItems[i].quantity);
            tempCartItems.splice(i, 1);
            break;
          }
        }
      }

      const modifiedGroupIds: Array<Number> = [];

      for (const item of tempCartItems) {
        if (item.skuId === oriSkuId) {
          item.skuId = skuId;
          item.quantity = qty + existingQty;
          for (const groupId of this.getProduct(item.productId).productGroupIds) {
            modifiedGroupIds.push(groupId);
          }
          break;
        }
      }

      this.checkCapAndCreditBalanceAndSaveCart(Constants.ProductType.NORMAL, tempCartItems, modifiedGroupIds);
    });
  }

  removeItemFromCart(skuId: number) {
    skuId = Number(skuId);

    if (skuId == null || skuId <= 0) {
      this.notificationService.warn(
        'Shopping Cart',
        'Invalid size'
      )
      return;
    }

    this.getCartDetail().subscribe(() => {
      const tempCartItems = CommonUtils.cloneDeep(this.shoppingCart.items);

      for (let i = 0; i < tempCartItems.length; i++) {
        if (tempCartItems[i].skuId === skuId) {
          tempCartItems.splice(i, 1);
          break;
        }
      }

      this.checkCapAndCreditBalanceAndSaveCart(Constants.ProductType.NORMAL, tempCartItems, []);
    });
  }

  checkCapAndCreditBalanceAndSaveCart(productType: number, allItems: Array<CartItemDTO>, modifiedGroupIds: Array<Number>) {
    const checkCap = this.checkCap(productType, allItems, modifiedGroupIds);
    if (checkCap === -2) {
      this.notificationService.info(
        'Shopping Cart',
        'Exceeded soft cap'
      );
    } else if (checkCap >= 0) {
      this.notificationService.warn(
        'Shopping Cart',
        'You have exceeded the maximum quantity (' + checkCap + ') allowed for this year'
      );
      return;
    }
    if (!this.checkCreditBalance(productType, allItems)) {
      this.notificationService.warn(
        'Shopping Cart',
        'Insufficient credit balance'
      );
      this.getCartDetail().subscribe();
      return;
    }
    this.saveCart(productType, allItems).subscribe();
  }

  checkCreditBalance(productType: number, items: Array<CartItemDTO>): boolean {
    let total = 0;
    for (const item of items) {
      if (productType === Constants.ProductType.TAILOR) {
        total += item.quantity * this.getTailorProductPrice(item.productId, item.productTailorId);
      } else if (productType === Constants.ProductType.NAME_TAG) {
        total += item.quantity * this.getNameTagProduct(item.productId).productPrice;
      } else {
        total += item.quantity * this.getProduct(item.productId).productPrice;
      }
    }
    if (this.selectedDeliveryMode === this.DeliveryMode.HOME_DELIVERY) {
      total += this.deliveryFee;
    }
    return total <= this.userProfileService.creditSummary.creditBalance;
  }

  /* -1 = No problem
   * -2 = Any of the item in modifiedProductIds has exceeded soft cap
   * >=0 = Any of the item in modifiedProductIds has exceeded hard cap, number is cap quantity */
  checkCap(productType: number, allItems: Array<CartItemDTO>, modifiedGroupIds: Array<Number>): number {
    let hasExceedSoftCap = false;
    let hasExceedHardCap = false;
    let minHardCap = 99999999;

    let caps: Array<GetProductCapsResult> = CommonUtils.cloneDeep(this.productsService.cachedProductCaps).data;
    if (!caps) {
      caps = [];
    }

    const capMap = new Map<number, GetProductCapsResult>();
    for (const group of caps) {
      capMap.set(group.productGroupId, group);
    }

    for (const item of allItems) {
      let product;
      if (productType === Constants.ProductType.TAILOR) {
        product = this.getTailorProduct(item.productId);
      } else if (productType === Constants.ProductType.NAME_TAG) {
        product = this.getNameTagProduct(item.productId);
      } else {
        product = this.getProduct(item.productId);
      }
      for (const productGroupId of product.productGroupIds) {
        const cap = capMap.get(productGroupId);
        if (!cap) {
          continue;
        }
        const remaining = cap.remaining - item.quantity;
        if (remaining < 0) {
          if (modifiedGroupIds.indexOf(productGroupId) >= 0) {
            if (cap.capType === Constants.ProductCapType.HARD) {
              hasExceedHardCap = true;
              if (minHardCap > cap.cap) {
                minHardCap = cap.cap;
              }
            } else {
              hasExceedSoftCap = true;
            }
          }
        }
        cap.remaining = remaining;
        capMap.set(productGroupId, cap);
      }
    }

    if (hasExceedHardCap) {
      return minHardCap;
    } else if (hasExceedSoftCap) {
      return -2;
    } else {
      return -1;
    }
  }

  saveCart(productType: number, items: Array<CartItemDTO>): Observable<any> {
    const postbody = <CartDTO>{};
    if (productType === Constants.ProductType.TAILOR) {
      postbody.tailorItems = items;
    } else if (productType === Constants.ProductType.NAME_TAG) {
      postbody.nameTagItems = items;
    } else {
      postbody.items = items;
    }

    return this.cartApi.saveCartUsingPOST(postbody).pipe(tap(
      response => {
        //when status == 200 & 204, execute working part
        if (response.status == 200 || response.status == 204){ 
          if (productType === Constants.ProductType.TAILOR) {
            this.shoppingCart.tailorItems = items;
          } else if (productType === Constants.ProductType.NAME_TAG) {
            this.shoppingCart.nameTagItems = items;
          } else {
            this.shoppingCart.items = items;
          }
          this.totalPrice = this.getTotalPrice();

          this.notificationService.success(
            'Shopping Cart',
            'Updated successfully'
          );

        // other than 200, 204, 401, 403, throws error
        }else if (response.status != 401 && response.status != 403){
          this.notificationService.error(
            'Shopping Cart',
            'Not updated, please try again later'
          )
          this.getCartDetail().subscribe();
        }
      }
    ), catchError(
      err => {
        this.notificationService.error(
          'Shopping Cart',
          'Not updated, please try again later'
        )
        return err;
      }
      )).pipe(flatMap(() => this.getCartDetail()));
  }

  getTotalPrice() {
    this.totalPrice = 0;
    if (this.shoppingCart.tailorItems != null) {
      for (const item of this.shoppingCart.tailorItems) {
        this.totalPrice += item.quantity * this.getTailorProductPrice(item.productId, item.productTailorId);
      }
    } else if (this.shoppingCart.nameTagItems != null) {
      for (const item of this.shoppingCart.nameTagItems) {
        this.totalPrice += item.quantity * this.getNameTagProduct(item.productId).productPrice;
      }
    } else if (this.shoppingCart.items != null) {
      for (const item of this.shoppingCart.items) {
        this.totalPrice += item.quantity * this.getProduct(item.productId).productPrice;
      }
    }
    return this.totalPrice;
  }

  validate(validateCartRequest: CartDTO) {
    return this.cartApi.validateCartUsingPOST(validateCartRequest).pipe(tap(
      response => {
        if (response.status === 200 && response.data.errors.length === 0) {
          this.hasCartBeingValidated = true;
          this.validatedCart = validateCartRequest;
          this.softErrors = response.data.softErrors;
        } else {
          throw throwError(() => response);
        }
      }
    ), catchError(
      err => {
        return err;
      }
      ));
  }

  createOrder(postbody: CartDTO): Observable<any> {
    this.hasCartBeingConfirmed = true;
    return this.orderApi.createOrderUsingPOST(postbody).pipe(tap(
      response => {
        if (response.status === 200) {
          this.orderDetail = response.data ? response.data : {};
          this.shoppingCart.items = [];
          this.shoppingCart.nameTagItems = [];
          this.shoppingCart.tailorItems = [];
        } else {
          this.orderDetail = {};
          this.orderDetailImageStore = {};

          this.resetFlag();
          throw throwError(() => response);
        }
      }
    ), catchError(
      err => {
        return err;
      }
      ));
  }

  resetFlag() {
    this.hasCartBeingValidated = false;
    this.validatedCart = {};
    this.hasCartBeingConfirmed = false;
  }

  getCartItems(): number {
    let result = 0;
    if (this.shoppingCart) {
      if (this.shoppingCart.tailorItems != null) {
        this.shoppingCart.tailorItems.forEach((item) => result += item.quantity)
      } else if (this.shoppingCart.nameTagItems != null) {
        this.shoppingCart.nameTagItems.forEach((item) => result += item.quantity);
      } else {
        this.shoppingCart.items.forEach((item) => result += item.quantity);
      }
    }
    return result;
  }

    // check SKU status. Active sku will be added to cart directly
    // Otherwise a warning subpage will be pop-up
    checkItem(chosenItem: any, chosenSkuId: number, chosenQty: number) {
       this.product =chosenItem;
       this.skuId = chosenSkuId;
       this.qty = chosenQty;
        for(const sku of chosenItem.skus){
          if(Number(chosenSkuId) === Number(sku.skuId)){
            if(sku.status === false){
                   jQuery('#select-inactive-pop-up').modal({
                       backdrop: 'static',
                       keyboard: false
                   })
                   jQuery('#select-inactive-pop-up').modal('show');
              }
              else{
                this.addItemToCart(this.skuId,this.qty);
              }
            }
            else{
                if (this.skuId == null || this.skuId <= 0) {
                  this.notificationService.warn(
                    'Shopping Cart',
                    'Invalid size'
                  )
                  return;
                }
                if (this.qty == null || this.qty <= 0) {
                  this.notificationService.warn(
                    'Shopping Cart',
                    'Invalid quantity'
                      )
                   return;
                }
            }
          }
        }
}
