/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as models from './models';

export interface ReportRequestDTO {
    emails?: Array<string>;

    filters?: models.ReportFiltersDTO;

    format?: number;

    report?: ReportRequestDTO.ReportEnum;

    mode?: number;

}
export namespace ReportRequestDTO {
    export enum ReportEnum {
        TOPPRODUCTSBYSALES = <any> 'TOP_PRODUCTS_BY_SALES',
        TRENDFORCOLLECTEDITEMS = <any> 'TREND_FOR_COLLECTED_ITEMS',
        ORDERSTATUSES = <any> 'ORDER_STATUSES',
        NUMBEROFORDERFORFEITS = <any> 'NUMBER_OF_ORDER_FORFEITS',
        ORDERCOLLECTIONSTATISTICS = <any> 'ORDER_COLLECTION_STATISTICS',
        NUMBEROFITEMFORFEITS = <any> 'NUMBER_OF_ITEM_FORFEITS',
        ADHOCCREDITALLOCATION = <any> 'ADHOC_CREDIT_ALLOCATION',
        CREDITUTILIZATION = <any> 'CREDIT_UTILIZATION',
        SERVICEMANCREDITHISTORY = <any> 'SERVICEMAN_CREDIT_HISTORY',
        CANCELLEDITEMS = <any> 'CANCELLED_ITEMS',
        MONTHLYTRANSACTION = <any> 'MONTHLY_TRANSACTION',
        GENERALFEEDBACK = <any> 'GENERAL_FEEDBACK',
        USERROLES = <any> 'USER_ROLES',
        EXCEEDEDSOFTCAPS = <any> 'EXCEEDED_SOFT_CAPS',
        AUDITTRAIL = <any> 'AUDIT_TRAIL',
        TOPCOLLECTIONMODEREPORT = <any> 'TOP_COLLECTION_MODE_REPORT',
        SERVICEMANORDERTRANSACTIONHISTORY = <any> 'SERVICEMAN_ORDER_TRANSACTION_HISTORY',
        CURRENTORDERSTATUS = <any> 'CURRENT_ORDER_STATUS'
    }
}
