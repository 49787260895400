import { Pipe, PipeTransform } from '@angular/core';

const SECONDS_IN_MINUTE = 60;
const MILLISECONDS_IN_SECOND: number = 1000;
const MILLISECONDS_IN_MINUTE: number = 60 * MILLISECONDS_IN_SECOND;
const SINGLE_MINUTE: string = ' minute ';
const PLURAL_MINUTE: string = ' minutes ';
const SINGLE_SECOND: string = ' second ';
const PLURAL_SECONDS: string = ' seconds ';

@Pipe({
    name: 'countdown'
})
export class TimeoutCountdownPipe implements PipeTransform {

    remainingMinutes = 0;
    remainingSeconds = 0;

    timeUnit_Min = (() => (this.remainingMinutes == 1) ? SINGLE_MINUTE : PLURAL_MINUTE);
    timeUnit_Sec = (() => (this.remainingSeconds == 1) ? SINGLE_SECOND : PLURAL_SECONDS);

    timeDiff: string;

    transform(value: number): string {

        if (value <= 0 && this.remainingSeconds === 0) {
            this.timeDiff = this.remainingSeconds + SINGLE_SECOND;
        }
        else if (value < MILLISECONDS_IN_MINUTE) {
            this.remainingSeconds = Math.floor(value / MILLISECONDS_IN_SECOND) % SECONDS_IN_MINUTE;
            this.timeDiff = this.remainingSeconds + this.timeUnit_Sec();
        }
        else {
            this.remainingMinutes = Math.floor((value / MILLISECONDS_IN_MINUTE) % SECONDS_IN_MINUTE);
            this.remainingSeconds = Math.floor(value / MILLISECONDS_IN_SECOND) % SECONDS_IN_MINUTE;
            this.timeDiff = this.remainingMinutes + this.timeUnit_Min() + this.remainingSeconds + this.timeUnit_Sec();
        }

        return this.timeDiff;
    }
}