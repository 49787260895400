/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class CollectionPointManagementApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient,) {
        
    }

    /**
     * addZone
     * add zone
     * @param request request
     */
    public addZoneUsingPOST(request: models.AddZoneRequest, extraHttpRequestParams?: any): Observable<models.AddZoneResponse> {
        return this.addZoneUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * AssignManagerLocationPoints
     * Assign Manager Location
     * @param request request
     */
    public assignManagerLocationUsingPOST(request: models.AssignManagerLocationsRequest, extraHttpRequestParams?: any): Observable<models.AssignManagerLocationResponse> {
        return this.assignManagerLocationUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * deleteCollectionPoints
     * Delete collection points
     * @param locationIds locationIds
     */
    public deleteCollectionPointsUsingPOST(locationIds: Array<number>, extraHttpRequestParams?: any): Observable<models.DeleteCollectionPointsResponse> {
        return this.deleteCollectionPointsUsingPOSTWithHttpInfo(locationIds, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * GetCollectionPointsForAdmin
     * Get collection points for Admin
     * @param cp Must be collection points
     * @param el Must be eLockers
     * @param wi Must be walk-in collection points
     */
    public getCollectionPointsForAdminUsingGET(cp?: boolean, el?: boolean, wi?: boolean, extraHttpRequestParams?: any): Observable<models.GetCollectionPointsForAdminResponse> {
        return this.getCollectionPointsForAdminUsingGETWithHttpInfo(cp, el, wi, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * GetManagerAssignedCollectionPoints
     * Get assigned collection points for Manager
     * @param request request
     */
    public getManagerLocationsUsingPOST(request: models.GetManagerLocationsRequest, extraHttpRequestParams?: any): Observable<models.GetManagerLocationsResponse> {
        return this.getManagerLocationsUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * GetZones
     * Get Zones
     */
    public getZonesUsingGET(extraHttpRequestParams?: any): Observable<models.GetZonesResponse> {
        return this.getZonesUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * saveCollectionPoint
     * Save new or update existed collection point
     * @param request Request body for save collection point.
     */
    public saveCollectionPointUsingPOST(request: models.LocationDTO, extraHttpRequestParams?: any): Observable<models.SaveCollectionPointResponse> {
        return this.saveCollectionPointUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * addZone
     * add zone
     * @param request request
     */
    public addZoneUsingPOSTWithHttpInfo(request: models.AddZoneRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/collectonPointManagement/zones';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling addZoneUsingPOST.');
        }

        return this.httpClient.post(path, request, {withCredentials: true});
    }

    /**
     * AssignManagerLocationPoints
     * Assign Manager Location
     * @param request request
     */
    public assignManagerLocationUsingPOSTWithHttpInfo(request: models.AssignManagerLocationsRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/collectonPointManagement/collectionPoints/assign';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling assignManagerLocationUsingPOST.');
        }

        return this.httpClient.post(path, request, {withCredentials: true});
    }

    /**
     * deleteCollectionPoints
     * Delete collection points
     * @param locationIds locationIds
     */
    public deleteCollectionPointsUsingPOSTWithHttpInfo(locationIds: Array<number>, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/collectonPointManagement/collectionPoints/delete';

        // verify required parameter 'locationIds' is not null or undefined
        if (locationIds === null || locationIds === undefined) {
            throw new Error('Required parameter locationIds was null or undefined when calling deleteCollectionPointsUsingPOST.');
        }

        return this.httpClient.post(path, locationIds, {withCredentials: true});
    }

    /**
     * GetCollectionPointsForAdmin
     * Get collection points for Admin
     * @param cp Must be collection points
     * @param el Must be eLockers
     * @param wi Must be walk-in collection points
     */
    public getCollectionPointsForAdminUsingGETWithHttpInfo(cp?: boolean, el?: boolean, wi?: boolean, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/collectonPointManagement/collectionPoints';

        let queryParameters = new HttpParams();

        if (cp !== undefined) {
            queryParameters = queryParameters.append('cp', <any>cp);
        }

        if (el !== undefined) {
            queryParameters = queryParameters.append('el', <any>el);
        }

        if (wi !== undefined) {
            queryParameters = queryParameters.append('wi', <any>wi);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true});
    }

    /**
     * GetManagerAssignedCollectionPoints
     * Get assigned collection points for Manager
     * @param request request
     */
    public getManagerLocationsUsingPOSTWithHttpInfo(request: models.GetManagerLocationsRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/collectonPointManagement/managers';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling getManagerLocationsUsingPOST.');
        }

        return this.httpClient.post(path, request, {withCredentials: true});
    }

    /**
     * GetZones
     * Get Zones
     */
    public getZonesUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/collectonPointManagement/zones';

        return this.httpClient.get(path, {withCredentials: true});
    }

    /**
     * saveCollectionPoint
     * Save new or update existed collection point
     * @param request Request body for save collection point.
     */
    public saveCollectionPointUsingPOSTWithHttpInfo(request: models.LocationDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/collectonPointManagement/collectionPoints';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveCollectionPointUsingPOST.');
        }

        return this.httpClient.post(path, request, {withCredentials: true});
    }

}
