import { Component, Input } from '@angular/core';
import { StoreConfigService } from 'app/services/store-config.service';
import { Constants } from 'app/shared/global-constants/constants';

const typeDescription = Constants.MeasurementTypeDescription;

@Component({
  selector: 'app-view-visual-size-guide',
  templateUrl: './view-visual-size-guide.component.html',
  styleUrls: ['./view-visual-size-guide.component.css']
})
export class ViewVisualSizeGuideComponent {

  @Input() guideType: string;

  private guideId: string;
  public guideAssetUrl: string;
  private guideHelpText: string;

  constructor(public configSvc: StoreConfigService) { }

  makeGuideId(): string {
    return !this.guideType ? '' : this.guideType.replace(' ', '');
  }

  isImageGuide(): boolean {
    return [typeDescription.HEAD_CIRCUM, typeDescription.FOOT_LENGTH, typeDescription.HAND_WIDTH].indexOf(this.guideType) > -1;
  }

  initGuideAssetAndText(): void {

    let fileName = this.configSvc.deployPath + 'assets/sizeguide/';
    let helpText: string;

    switch (this.guideType) {
      case typeDescription.HEAD_CIRCUM:
        fileName += 'HEAD_CIRCUM.jpg';
        helpText = 'Wrap the measuring tape around your head, approximately 2.5cm above your ear.';
        break;
      case typeDescription.SHOULDER_WIDTH:
        fileName += 'SHOULDER_WIDTH.mp4';
        helpText = 'Place one end of the measuring tape at the middle of where your shoulder bone ends on one arm, \
        measure up along your shoulder, and read the measuring tape at the middle of where your shoulder bone ends \
        on the other arm.';
        break;
      case typeDescription.CHEST:
        fileName += 'CHEST.mp4';
        helpText = ''; // TODO: to be advised by MHA
        break;
      case typeDescription.WAIST:
        fileName += 'WAIST.mp4';
        helpText = 'Wrap the measuring tape around your waist and measure the smallest part of your waist.';
        break;
      case typeDescription.PANTS_LENGTH:
        fileName += 'PANTS_LENGTH.mp4';
        helpText = ''; // TODO: to be advised by MHA
        break;
      case typeDescription.FOOT_LENGTH:
        fileName += 'FOOT_LENGTH.jpg';
        helpText = 'Measure your foot length, starting from the back of your heel to your toe line (where the longest toe ends).';
        break;
      case typeDescription.HAND_WIDTH:
        fileName += 'HAND_WIDTH.jpg';
        helpText = 'Measure the fullest width of your hand, excluding your thumb.';
        break;
      default:
        fileName = this.configSvc.dummyImgPath;
        break;
    }

    this.guideAssetUrl = fileName;
    this.guideHelpText = helpText;
  }

}
