import { CommonModule } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SimpleNotificationsModule } from 'assets/lib/angular2-notifications';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';

import { PriceFormatPipe } from '../shared/utilities/price-format.pipe';
import { TitleCasePipe } from '../shared/utilities/title-case.pipe';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { CollectionPointListComponent } from './mainbody/collection-point-list/collection-point-list.component';
import { CreditsComponent } from './mainbody/credits/credits.component';
import { CurrentOrdersComponent } from './mainbody/current-orders/current-orders.component';
import { DashboardComponent } from './mainbody/dashboard/dashboard.component';
import { ErrorpageComponent } from './mainbody/errorpage/errorpage.component';
import { FaqComponent } from './mainbody/faq/faq.component';
import { FeedbackComponent } from './mainbody/feedback/feedback.component';
import { NameTagShoppingCartComponent } from './mainbody/name-tag-shopping-cart/name-tag-shopping-cart.component';
import { OrderConfirmationComponent } from './mainbody/order-confirmation/order-confirmation.component';
import { OrderDetailModifyComponent } from './mainbody/order-detail-modify/order-detail-modify.component';
import { ProductDetailComponent } from './mainbody/product-detail/product-detail.component';
import {
  ViewVisualSizeGuideComponent,
} from './mainbody/product-detail/view-visual-size-guide/view-visual-size-guide.component';
import { ProfileComponent } from './mainbody/profile/profile.component';
import { ShoppingCartComponent } from './mainbody/shopping-cart/shopping-cart.component';
import { WaitlistComponent } from './mainbody/waitlist/waitlist.component';
import { SidebarComponent } from './mainbody/sidebar/sidebar.component';
import { TailorListComponent } from './mainbody/tailor-list/tailor-list.component';
import { TailorShoppingCartComponent } from './mainbody/tailor-shopping-cart/tailor-shopping-cart.component';
import { TransactionHistoryComponent } from './mainbody/transaction-history/transaction-history.component';
import { ViewNameTagsComponent } from './mainbody/view-name-tags/view-name-tags.component';
import { ViewProductsComponent } from './mainbody/view-products/view-products.component';
import { ViewTailoredUniformsComponent } from './mainbody/view-tailored-uniforms/view-tailored-uniforms.component';
import { PushmenuComponent } from './pushmenu/pushmenu.component';
import { OrderDetailPrintComponent } from './mainbody/order-detail-print/order-detail-print.component';
import { PersonaSelectionComponent } from './mainbody/persona-selection/persona-selection.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    FormsModule,
    SimpleNotificationsModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule
  ],
  declarations: [FooterComponent, SidebarComponent, HeaderComponent, PushmenuComponent, ErrorpageComponent,
    DashboardComponent, ProfileComponent, CurrentOrdersComponent, PriceFormatPipe, TransactionHistoryComponent,
    PriceFormatPipe, TitleCasePipe, ViewProductsComponent, CreditsComponent,
    ProductDetailComponent, ShoppingCartComponent, OrderConfirmationComponent, OrderDetailModifyComponent,
    TailorListComponent, ViewTailoredUniformsComponent, TailorShoppingCartComponent, ViewNameTagsComponent,
    NameTagShoppingCartComponent, FaqComponent, CollectionPointListComponent, FeedbackComponent,
    ViewVisualSizeGuideComponent, OrderDetailPrintComponent, WaitlistComponent, PersonaSelectionComponent],
  exports: [FooterComponent, SidebarComponent, HeaderComponent,
    PushmenuComponent, ErrorpageComponent, DashboardComponent, ProfileComponent, ProductDetailComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class LayoutModule { }
