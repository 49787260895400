/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class WalkinApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {}

    /**
     * collectWalkInOrder
     * Collect walk-in order
     * @param request request
     */
    public collectWalkInOrderUsingPOST(request: models.CollectOrderRequestDTO, extraHttpRequestParams?: any): Observable<models.CollectWalkInOrderResponse> {
        return this.collectWalkInOrderUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * createWalkInOrder
     * Create walk-in order
     * @param request request
     */
    public createWalkInOrderUsingPOST(request: models.CreateWalkInOrderRequest, extraHttpRequestParams?: any): Observable<models.CreateWalkInOrderResponse> {
        return this.createWalkInOrderUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * generateWalkInSecretCode
     * Generate secret code for walk-in order
     * @param request request
     */
    public generateWalkInSecretCodeUsingPOST(request: models.GenerateWalkInSecretCodeRequest, extraHttpRequestParams?: any): Observable<models.GenerateWalkInSecretCodeResponse> {
        return this.generateWalkInSecretCodeUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getOrdersForWalkin
     * Get orders for Walkin
     * @param request request
     */
    public getOrdersForWalkinUsingPOST(request: models.GetOrdersForWalkInRequest, extraHttpRequestParams?: any): Observable<models.GetOrdersForWalkInResponse> {
        return this.getOrdersForWalkinUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getProductCapsForWalkIn
     * Get product caps for walk in
     * @param request request
     */
    public getProductCapsUsingPOST(request: models.GetProductCapsForWalkInRequest, extraHttpRequestParams?: any): Observable<models.GetProductCapsForWalkInResponse> {
        return this.getProductCapsUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getProductsForWalkIn
     * Get products for walk-in
     * @param request request
     */
    public getProductsForWalkInUsingPOST(request: models.GetProductsForWalkInRequest, extraHttpRequestParams?: any): Observable<models.GetProductsForWalkInResponse> {
        return this.getProductsForWalkInUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getUserProfileForWalkIn
     * Get user profile for walk-in order
     * @param request request
     */
    public getUserProfileForWalkInUsingPOST(request: models.GetUserProfileForWalkInRequest, extraHttpRequestParams?: any): Observable<models.GetUserProfileForWalkInResponse> {
        return this.getUserProfileForWalkInUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * collectWalkInOrder
     * Collect walk-in order
     * @param request request
     */
    public collectWalkInOrderUsingPOSTWithHttpInfo(request: models.CollectOrderRequestDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/walkin/orders/collect';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling collectWalkInOrderUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * createWalkInOrder
     * Create walk-in order
     * @param request request
     */
    public createWalkInOrderUsingPOSTWithHttpInfo(request: models.CreateWalkInOrderRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/walkin/orders/create';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling createWalkInOrderUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * generateWalkInSecretCode
     * Generate secret code for walk-in order
     * @param request request
     */
    public generateWalkInSecretCodeUsingPOSTWithHttpInfo(request: models.GenerateWalkInSecretCodeRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/walkin/generateSecretCode';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling generateWalkInSecretCodeUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * getOrdersForWalkin
     * Get orders for Walkin
     * @param request request
     */
    public getOrdersForWalkinUsingPOSTWithHttpInfo(request: models.GetOrdersForWalkInRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/walkin/orders';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling getOrdersForWalkinUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * getProductCapsForWalkIn
     * Get product caps for walk in
     * @param request request
     */
    public getProductCapsUsingPOSTWithHttpInfo(request: models.GetProductCapsForWalkInRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/walkin/productCaps';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling getProductCapsUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * getProductsForWalkIn
     * Get products for walk-in
     * @param request request
     */
    public getProductsForWalkInUsingPOSTWithHttpInfo(request: models.GetProductsForWalkInRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/walkin/products';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling getProductsForWalkInUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * getUserProfileForWalkIn
     * Get user profile for walk-in order
     * @param request request
     */
    public getUserProfileForWalkInUsingPOSTWithHttpInfo(request: models.GetUserProfileForWalkInRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/walkin/userProfile';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling getUserProfileForWalkInUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

}
