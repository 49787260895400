import { Inject, Injectable, Optional } from '@angular/core';
import { catchError, tap, throwError, of, Observable } from 'rxjs';
import * as models from '../shared/backend-api/emart/model/models';
import { DEPLOY_URL } from '../auth/auth.tokens';
import { ServicemanApi } from '../shared/backend-api/emart/api/ServicemanApi';
import { FaqDTO } from '../shared/backend-api/emart/model/FaqDTO';
import { GetCollectionPointSelectionsResult } from '../shared/backend-api/emart/model/GetCollectionPointSelectionsResult';
import { SelectionDTO } from '../shared/backend-api/emart/model/SelectionDTO';
import { UserLocationDTO } from '../shared/backend-api/emart/model/UserLocationDTO';
import { CommonApi } from './../shared/backend-api/emart/api/CommonApi';

@Injectable()
export class StoreConfigService {

  // Provide an alternative approach to control loading screen, other than resolve guards.
  public isLoading: boolean;
  public deployPath: string;
  public dummyImgPath: string;

  public allDeliveryModes: SelectionDTO[];
  public deliveryModes: SelectionDTO[];
  public allTailorDeliveryModes: SelectionDTO[];
  public tailorDeliveryModes: SelectionDTO[];
  public allNameTagDeliveryModes: SelectionDTO[];
  public nameTagDeliveryModes: SelectionDTO[];
  public collectionPointSelections: GetCollectionPointSelectionsResult[];
  public eLockerSelections: GetCollectionPointSelectionsResult[];
  public walkInCollectionPointSelections: GetCollectionPointSelectionsResult[];
  public collectionPoints: UserLocationDTO[];
  public eLockers: UserLocationDTO[];
  public walkInCollectionPoints: UserLocationDTO[];
  public tailors: UserLocationDTO[];
  public faqList: Array<FaqDTO>;
  public allCollectionPoints: UserLocationDTO[];

  constructor(
    private commonApi: CommonApi,
    private servicemanApi: ServicemanApi,
    @Optional() @Inject(DEPLOY_URL) private deployUrl: string
  ) {
    this.isLoading = true;
    this.deployPath = deployUrl || '';
    this.dummyImgPath = this.deployPath + 'assets/images/dummy.png';
  }

  loadAllDeliveryModes(): Observable<any> {
    if (this.allDeliveryModes) {
      return of('No need to reload allDeliveryModes.');
    } else {
      return this.forceLoadAllDeliveryModes();
    }
  }

  loadDeliveryModes(): Observable<any> {
    if (this.deliveryModes) {
      return of('No need to reload deliveryModes.');
    } else {
      return this.forceLoadDeliveryModes();
    }
  }

  loadAllTailorDeliveryModes(): Observable<any> {
    if (this.allTailorDeliveryModes) {
      return of('No need to reload allDeliveryModes.');
    } else {
      return this.forceLoadAllTailorDeliveryModes();
    }
  }

  loadTailorDeliveryModes(): Observable<any> {
    if (this.tailorDeliveryModes) {
      return of('No need to reload tailorDeliveryModes.');
    } else {
      return this.forceLoadTailorDeliveryModes();
    }
  }

  loadAllNameTagDeliveryModes(): Observable<any> {
    if (this.allNameTagDeliveryModes) {
      return of('No need to reload allDeliveryModes.');
    } else {
      return this.forceLoadAllNameTagDeliveryModes();
    }
  }

  loadNameTagDeliveryModes(): Observable<any> {
    if (this.nameTagDeliveryModes) {
      return of('No need to reload nameTagDeliveryModes.');
    } else {
      return this.forceLoadNameTagDeliveryModes();
    }
  }

  loadCollectionPointSelections(): Observable<any> {
    if (this.collectionPointSelections) {
      return of('No need to reload collectionPointSelections.');
    } else {
      return this.forceLoadCollectionPointSelections();
    }
  }

  loadElockerSelections(checkAvailability = false): Observable<any> {
    return this.forceLoadElockerSelections(checkAvailability);
  }

  loadWalkInCollectionPointSelections(): Observable<any> {
    if (this.walkInCollectionPointSelections) {
      return of('No need to reload walkInCollectionPointSelections.');
    } else {
      return this.forceLoadWalkInCollectionPointSelections();
    }
  }

  loadCollectionPoints(): Observable<any> {
    if (this.collectionPoints) {
      return of('No need to reload collectionPoints.');
    } else {
      return this.forceLoadCollectionPoints();
    }
  }

  loadElockers(): Observable<any> {
    return this.forceLoadElockers();
  }

  loadWalkInCollectionPoints(): Observable<any> {
    if (this.walkInCollectionPoints) {
      return of('No need to reload walkInCollectionPoints.');
    } else {
      return this.forceLoadWalkInCollectionPoints();
    }
  }

  loadTailors(): Observable<any> {
    if (this.tailors) {
      return of('No need to reload tailors.');
    } else {
      return this.forceLoadTailors();
    }
  }

  loadAllCollectionPoints(): Observable<any> {
    if (this.allCollectionPoints) {
      return of('No need to reload allCollectionPoints.');
    } else {
      return this.forceLoadAllCollectionPoints();
    }
  }

  forceLoadAllDeliveryModes(): Observable<any> {
    return this.commonApi.getDeliveryModesUsingGET(true).pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.allDeliveryModes = payload.data as SelectionDTO[];

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
    ));
  }

  forceLoadDeliveryModes(): Observable<any> {
    return this.commonApi.getDeliveryModesUsingGET(false).pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.deliveryModes = payload.data as SelectionDTO[];

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
    ));
  }

  forceLoadAllTailorDeliveryModes(): Observable<any> {
    return this.commonApi.getTailorDeliveryModesUsingGET(true).pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.allTailorDeliveryModes = payload.data as SelectionDTO[];

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
    ));
  }

  forceLoadTailorDeliveryModes(): Observable<any> {
    return this.commonApi.getTailorDeliveryModesUsingGET(false).pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.tailorDeliveryModes = payload.data as SelectionDTO[];

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
    ));
  }

  forceLoadAllNameTagDeliveryModes(): Observable<any> {
    return this.commonApi.getNameTagDeliveryModesUsingGET(true).pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.allNameTagDeliveryModes = payload.data as SelectionDTO[];

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
    ));
  }

  forceLoadNameTagDeliveryModes(): Observable<any> {
    return this.commonApi.getNameTagDeliveryModesUsingGET(false).pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.nameTagDeliveryModes = payload.data as SelectionDTO[];

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
    ));
  }

  forceLoadCollectionPointSelections(): Observable<any> {
    return this.commonApi.getCollectionPointSelectionsForUserUsingGET(true, false, false, false, false, false).pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.collectionPointSelections = payload.data as GetCollectionPointSelectionsResult[];
          if (!this.collectionPointSelections) {
            this.collectionPointSelections = [];
          }

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
    ));
  }

  forceLoadElockerSelections(checkAvailability: boolean): Observable<any> {
    return this.commonApi.getCollectionPointSelectionsForUserUsingGET(false, true, checkAvailability, false, false, false).pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.eLockerSelections = payload.data as GetCollectionPointSelectionsResult[];
          if (!this.eLockerSelections) {
            this.eLockerSelections = [];
          }

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
    ));
  }

  forceLoadWalkInCollectionPointSelections(): Observable<any> {
    return this.commonApi.getCollectionPointSelectionsForUserUsingGET(false, false, false, false, true, false).pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.walkInCollectionPointSelections = payload.data as GetCollectionPointSelectionsResult[];
          if (!this.walkInCollectionPointSelections) {
            this.walkInCollectionPointSelections = [];
          }

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
    ));
  }

  forceLoadCollectionPoints(): Observable<any> {
    return this.commonApi.getCollectionPointsUsingGET().pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.collectionPoints = payload.data as UserLocationDTO[];
          if (!this.collectionPoints) {
            this.collectionPoints = [];
          }

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
    ));
  }

  forceLoadElockers(): Observable<any> {
    return this.commonApi.getElockersUsingGET().pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.eLockers = payload.data as UserLocationDTO[];
          if (!this.eLockers) {
            this.eLockers = [];
          }

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
    ));
  }

  forceLoadWalkInCollectionPoints(): Observable<any> {
    return this.commonApi.getWalkInCollectionPointsUsingGET().pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.walkInCollectionPoints = payload.data as UserLocationDTO[];
          if (!this.walkInCollectionPoints) {
            this.walkInCollectionPoints = [];
          }

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
    ));
  }

  forceLoadTailors(): Observable<any> {
    return this.commonApi.getTailorsUsingGET().pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.tailors = payload.data as UserLocationDTO[];
          if (!this.tailors) {
            this.tailors = [];
          }

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
    ));
  }

  forceLoadAllCollectionPoints(): Observable<any> {
    return this.commonApi.getAllCollectionPointsUsingGET().pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.allCollectionPoints = payload.data as UserLocationDTO[];
          if (!this.allCollectionPoints) {
            this.allCollectionPoints = [];
          }

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
    ));
  }

  loadFaq(): Observable<any> {
    if (this.faqList) {
      return of('Reload of faqlist is not necessary');
    } else {
      return this.forceLoadFaq();
    }
  }

  forceLoadFaq(): Observable<any> {
    return this.servicemanApi.getFAQsUsingGET().pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.faqList = payload.data.faqs as Array<FaqDTO>;

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return throwError(() => err);
      }
    ));
  }

}
