import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})


export class BroadcastService {

  broadcastChannel: any;
  onMessage = new Subject<any>();
  name = environment.channelNAME;
  constructor() {
    this.initialize();
  }

  initialize() {
    this.broadcastChannel = new BroadcastChannel(this.name);
    this.broadcastChannel.onmessage = (message) => this.onMessage.next(message.data);
  }

  publish(message: BroadcastMessage): void {
    this.broadcastChannel.postMessage(message);
  }

  messagesOfType(type: string): Observable<BroadcastMessage> {
    return this.onMessage.pipe(
      filter(message => message.type === type)
    );
  }

}

export interface BroadcastMessage {
  type: string;
  payload: any;
}