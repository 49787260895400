import { CreditsGuard } from './credits-guard';
import { CurrentOrdersGuard } from './current-orders-guard';
import { DashboardGuard } from './dashboard-guard';
import { FeedbackGuard } from './feedback-guard';
import { NameTagShoppingCartGuard } from './name-tag-shopping-cart-guard';
import { UserProfileGuard } from './profile-guard';
import { ShoppingCartGuard } from './shopping-cart-guard';
import { WaitlistGuard } from './waitlist-guard';
import { TailorShoppingCartGuard } from './tailor-shopping-cart-guard';
import { TransactionHistorysGuard } from './transaction-history-guard';
import { ViewNameTagProductsGuard, ViewProductsGuard, ViewUniformProductsGuard } from './view-products-guard';
import { OrderDetailPrintGuard } from './order-detail-print.guard';

/**
 * This is a wrapper class for all resolve guards.
 * In Store, any other modules or tests, simply import and provide STORE_RESOLVERS.
 */
export const STORE_RESOLVERS = [DashboardGuard, UserProfileGuard, CreditsGuard, ViewProductsGuard,
    ShoppingCartGuard, TailorShoppingCartGuard, NameTagShoppingCartGuard, CurrentOrdersGuard, TransactionHistorysGuard,
    FeedbackGuard, ViewUniformProductsGuard, ViewNameTagProductsGuard, OrderDetailPrintGuard, WaitlistGuard];
