import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { StoreConfigService } from 'app/services/store-config.service';
import * as bootstrap from 'bootstrap';
import { forEach } from 'lodash';

import { CartService } from './../../services/cart.service';
import { CatalogueService } from './../../services/catalogue.service';
import { SharedSearchService } from './../../services/shared-search.service';
import { UserProfileService } from './../../services/user-profile.service';
import { WaitlistService } from './../../services/waitlist.service';

declare const jQuery: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  private totalItem = 0;
  private totalWaitlist = 0;
  public searchString: string = '';

  // Used to listen to the Child element on the 
  @ViewChild('closeBtn') closeBtn: ElementRef | undefined;



  constructor(public catalogueService: CatalogueService,
    public userProfileService: UserProfileService,
    public cartService: CartService,
    public waitlistService: WaitlistService,
    public searchService: SharedSearchService,
    public configSvc: StoreConfigService,
    private router: Router,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.cartService.loadCartDetail().subscribe(response => {
      if (response.status === 200) {
        this.cartService.shoppingCart = response.data;
        for (const item of this.cartService.shoppingCart.items) {
          this.totalItem += item.quantity;
        }
      } else {
        console.log('fail to loadCart');
      }
    });

     this.waitlistService.loadWaitlistDetail().subscribe(response => {
      if(response.status === 200) {
        this.waitlistService.waitlist=response.data;
        for (const item of this.waitlistService.waitlist.items){
          this.totalWaitlist += item.quantity;
          }
        }else{
          console.log('fail to loadWaitlist');
      }
    });

    // const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
    // console.log(tooltipTriggerList)
    // const tooltipList = tooltipTriggerList.forEach( tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl)) 
  }

  // moved jquery declaration from app module to header due to conflicting with ngIf implementation
  ngAfterViewInit() {

    const $ = jQuery;

    $(document).ready(function () {

      $('.menu-link').bigSlide({
        menu: '#menu',
        side: 'right',
        easyClose: 'true',
        afterOpen: () => {
          $('a.menu-link').hover().focus();
        },
        afterClose: () => {
          $('a.menu-link').blur();
        }
      });

      $('#menu a, #menu button').on('click touchend', function (e) {

        $('.menu-link').click(); // eMart fix

        e.preventDefault();                   // prevent default anchor behavior
        const goTo = this.getAttribute('href'); // store anchor href

        setTimeout(function () {
          window.location.href = goTo;
        }, 300);
      });

      $('#search-button').on('click touchend', function (e) {
        if ($('#search-input-container').hasClass('hdn')) {
          e.preventDefault();
          $('#search-input-container').removeClass('hdn')
          return false;
        }
      });

      $('#hide-search-input-container').on('click touchend', function (e) {
        e.preventDefault();
        $('#search-input-container').addClass('hdn')
        return false;
      });
    });

      $('[data-bs-toggle="tooltip"]').tooltip()



  }

  getCartItems(): number {
    let result = 0;
    if (this.cartService.shoppingCart !== undefined) {
      if (this.cartService.shoppingCart.tailorItems != null) {
        for (const item of this.cartService.shoppingCart.tailorItems) {
          result += item.quantity;
        }
      } else if (this.cartService.shoppingCart.nameTagItems != null) {
        for (const item of this.cartService.shoppingCart.nameTagItems) {
          result += item.quantity;
        }
      } else if (this.cartService.shoppingCart.items != null) {
        for (const item of this.cartService.shoppingCart.items) {
          result += item.quantity;
        }
      }
    }
    return result;
  }

  gotoCart() {
    if (this.cartService.shoppingCart.tailorItems != null) {
      this.router.navigate(['/tailor-shopping-cart']);
    } else if (this.cartService.shoppingCart.nameTagItems != null) {
      this.router.navigate(['/name-tag-shopping-cart']);
    } else {
      this.router.navigate(['/shopping-cart']);
    }
  }

  getWaitlistItems(): number {
    let result = 0;
    if (this.waitlistService.waitlist !== undefined) {
        if (this.waitlistService.waitlist.items != null) {
          for (const item of this.waitlistService.waitlist.items) {
            result += item.quantity;
          }
        }
      }
      return result;
  }

  gotoWaitlist(){

      this.router.navigate(['/waitlist']);

  }

  searchFor(str) {
    this.searchString = str;
    this.searchService.update(SharedSearchService.SEARCH_STRING, str);
    if (this.searchString && !this.searchService.currentPage) {
      if (this.router.url.startsWith('/tailored-uniform')) {
        this.router.navigate(['/view-uniforms']);
      } else if (this.router.url.startsWith('/name-tag')) {
        this.router.navigate(['/view-name-tags']);
      } else {
        this.router.navigate(['/view-products/All']);
      }
    }
  }

}
