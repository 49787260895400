import { Component, OnInit } from '@angular/core';
import { AnnouncementService } from 'app/services/announcement.service';
import { BannerAnnouncementModel } from './banner-announcement.model';

declare const jQuery: any;

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.css']
})
export class BannerComponent implements OnInit {

  announcementMessages: BannerAnnouncementModel[] = [];
  currentDateTime: Date;

  constructor(
    private announcementService: AnnouncementService
  ) { }

  ngOnInit(): void {

    this.currentDateTime = new Date();

    this.announcementService.getAnnouncements().subscribe(x => {
      console.log(x.messagesList);

      // filtering announcement out expired messages 
      this.announcementMessages = x.messagesList.filter(v => {
        const startDateTime = new Date(v.startDateTime);
        const endDateTime = new Date(v.endDateTime);
        if (this.currentDateTime > startDateTime && this.currentDateTime < endDateTime) {
          return true;
        }
        else {
          return false;
        }
      });

      console.log("announcementMessages " + this.announcementMessages);

    });
  }

  closeAnnouncementBanner() {
    jQuery("#announcement").removeClass("show");
  }
}
