import { Injectable } from '@angular/core';
import { UserProfileService } from 'app/services/user-profile.service';
import { map, Observable, forkJoin, throwError, catchError } from 'rxjs';

import { CartService } from './../services/cart.service';
import { CatalogueService } from './../services/catalogue.service';
import { WaitlistService } from './../services/waitlist.service';

@Injectable()
export class PreloaderService {

  constructor(
    private userProfileService: UserProfileService,
    private cartService: CartService,
    private catalogueService: CatalogueService, 
    private waitlistService: WaitlistService
  ) { }

  /**
   * Main function for pre-loading essential data:
   * (1) user's credit summary (force)
   * (2) user's shopping cart detail (force)
   * (3) eMart product categories (soft)
   * (4) user's waitlist detail (force)
   * @returns {Observable<any>}
   * @memberof PreloaderService
   */
  public load(): Observable<any> {

    let $obs = forkJoin(
      this.userProfileService.forceLoadCreditSummary(),
      this.cartService.getCartDetail(),
      this.catalogueService.loadCategories(),
      this.waitlistService.getWaitlistDetail()
    ).pipe(
      catchError(
        error => {
          console.log("Preloader Service Fail to Load");
          throw throwError(error);
        }
      )
    );

    return $obs;
  }

  // public failPreload() {
  //   /* do some special handling*/
  // }

}
