/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';

/* tslint:disable:no-unused-variable member-ordering */

@Injectable()
export class ELockerApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient,) {
        
    }

    /**
     * getOrderStatusSelectionsForElocker
     * Get order status selections for eLocker
     */
    public getOrderStatusSelectionsForElockerUsingGET(extraHttpRequestParams?: any): Observable<models.GetOrderStatusSelectionsForElockerResponse> {
        return this.getOrderStatusSelectionsForElockerUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getOrdersForElocker
     * Get orders for eLocker
     * @param page to set the index of pages to retrieve the order. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param cp to set the list of eLockers interested. If it is null, all eLockers will be returned.
     * @param department to indicate the service unit for the orders to be retrieved. If it is null, all service unis will be returned.
     * @param from to indicate the start date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of start date will be imposed.
     * @param to to indicate the end date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of end date will be imposed.
     * @param status to indicate the status for the orders to be retrieved. If it is null, all available status will be returned. status map: &lt;br&gt;&lt;ul&gt;&lt;li&gt;16: PACKED_FOR_ELOCKER&lt;/li&gt; &lt;li&gt;18: ELOCKER_COLLECTED&lt;/li&gt;&lt;/ul&gt;
     * @param ref to set the order reference number for the order to be retrieved. If it is set, the order reference number must be matched. If other params is not null, they are all need to be matched the corresponding order as well.
     */
    public getOrdersForElockerUsingGET(page: number, size: number, cp?: Array<number>, department?: number, from?: string, to?: string, status?: number, ref?: string, extraHttpRequestParams?: any): Observable<models.GetOrdersForElockerResponse> {
        return this.getOrdersForElockerUsingGETWithHttpInfo(page, size, cp, department, from, to, status, ref, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * updateOrderStatusForElocker
     * Update orders statuses for eLocker
     * @param request request
     */
    public updateOrderStatusForElockerUsingPOST(request: models.UpdateOrderStatusRequestDTO, extraHttpRequestParams?: any): Observable<models.UpdateOrderStatusForElockerResponse> {
        return this.updateOrderStatusForElockerUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * getOrderStatusSelectionsForElocker
     * Get order status selections for eLocker
     */
    public getOrderStatusSelectionsForElockerUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/eLocker/orderStatusSelections';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getOrdersForElocker
     * Get orders for eLocker
     * @param page to set the index of pages to retrieve the order. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param cp to set the list of eLockers interested. If it is null, all eLockers will be returned.
     * @param department to indicate the service unit for the orders to be retrieved. If it is null, all service unis will be returned.
     * @param from to indicate the start date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of start date will be imposed.
     * @param to to indicate the end date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of end date will be imposed.
     * @param status to indicate the status for the orders to be retrieved. If it is null, all available status will be returned. status map: &lt;br&gt;&lt;ul&gt;&lt;li&gt;16: PACKED_FOR_ELOCKER&lt;/li&gt; &lt;li&gt;18: ELOCKER_COLLECTED&lt;/li&gt;&lt;/ul&gt;
     * @param ref to set the order reference number for the order to be retrieved. If it is set, the order reference number must be matched. If other params is not null, they are all need to be matched the corresponding order as well.
     */
    public getOrdersForElockerUsingGETWithHttpInfo(page: number, size: number, cp?: Array<number>, department?: number, from?: string, to?: string, status?: number, ref?: string, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/eLocker/orders';
        let queryParameters = new HttpParams();

        // verify required parameter 'page' is not null or undefined
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getOrdersForElockerUsingGET.');
        }
        // verify required parameter 'size' is not null or undefined
        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling getOrdersForElockerUsingGET.');
        }
        if (cp) {
            cp.forEach((element) => {
                queryParameters = queryParameters.append('cp', <any>element);
            })
        }

        if (department !== undefined) {
            queryParameters = queryParameters.append('department', <any>department);
        }

        if (from !== undefined) {
            queryParameters = queryParameters.append('from', <any>from);
        }

        if (to !== undefined) {
            queryParameters = queryParameters.append('to', <any>to);
        }

        if (status !== undefined) {
            queryParameters = queryParameters.append('status', <any>status);
        }

        if (ref !== undefined) {
            queryParameters = queryParameters.append('ref', <any>ref);
        }

        if (page !== undefined) {
            queryParameters = queryParameters.append('page', <any>page);
        }

        if (size !== undefined) {
            queryParameters = queryParameters.append('size', <any>size);
        }

        return this.httpClient.get(path, { params: queryParameters, withCredentials: true });
    }

    /**
     * updateOrderStatusForElocker
     * Update orders statuses for eLocker
     * @param request request
     */
    public updateOrderStatusForElockerUsingPOSTWithHttpInfo(request: models.UpdateOrderStatusRequestDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/eLocker/orders/updateStatus';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling updateOrderStatusForElockerUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

}
