import { Constants } from './../shared/global-constants/constants';
import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { CatalogueApi, UserProductDTO, GetProductsResponse, GetProductCapsResponse } from '../shared/backend-api/emart/index';

@Injectable()
export class ProductsService {

  public cachedProducts: GetProductsResponse;
  public cachedProductCaps: GetProductCapsResponse;

  public cachedUniforms: GetProductsResponse;
  public cachedNameTags: GetProductsResponse;

  constructor(private api: CatalogueApi) { }

  loadProducts(type: number): Observable<GetProductsResponse> {
    if (type === Constants.ProductType.NORMAL && this.cachedProducts) {
      return of(this.cachedProducts)
    } else if (type === Constants.ProductType.TAILOR && this.cachedUniforms) {
      return of(this.cachedUniforms);
    } else if (type === Constants.ProductType.NAME_TAG && this.cachedNameTags) {
      return of(this.cachedNameTags);
    } else {
      return this.forceLoadProducts(type);
    }
  }

  forceLoadProducts(type: number): Observable<GetProductsResponse> {
    return this.api.getProductsUsingGET(type).pipe(tap((response => {
      if (response.status < 300) {
        if (type === Constants.ProductType.NORMAL) {
          this.cachedProducts = response;
          for(const product of this.cachedProducts.data) {
            for(let i= product.skus.length-1;i>=0;i--){
              if(product.skus[i].status === false && product.skus[i].allowed === false){
                product.skus.splice(i,1);
              }
            }
          }
            for(let j= this.cachedProducts.data.length-1;j>=0;j--){
              if(this.cachedProducts.data[j].skus.length === 0){
                this.cachedProducts.data.splice(j,1);
              }
            }
        } else if (type === Constants.ProductType.TAILOR) {
          this.cachedUniforms = response;
        } else if (type === Constants.ProductType.NAME_TAG) {
          this.cachedNameTags = response;
        }
      }
    })));
  }

  loadProductCaps(type?: number): Observable<GetProductCapsResponse> {
    if (this.cachedProductCaps) {
      return of(this.cachedProductCaps)
    } else {
      return this.forceLoadProductCaps(type);
    }
  }

  forceLoadProductCaps(type?: number): Observable<GetProductCapsResponse> {
    return this.api.getProductCapsUsingGET().pipe(tap((response => {
      if (response.status < 300) {
        this.cachedProductCaps = response;
      }
    })));
  }

}
