import { Injectable } from '@angular/core';
import { StoreConfigService } from 'app/services/store-config.service';

import { CommonApi } from '../shared/backend-api/emart/index';

@Injectable()
export class ResourceService {

  imagestore: any = {};

  constructor(private commonApi: CommonApi, public configSvc: StoreConfigService) { }

  loadImageWithId(id: number): Promise<string> {
    return new Promise<string>(resolve => {
      if (this.imagestore[id]) {
        resolve(this.imagestore[id]);
      } else {
        this.commonApi.getResourceUsingGET(id).subscribe(response => {
          if (response.status === 200) {
            this.imagestore[id] = 'data:image/jpeg;base64,' + response.data.resource;
            resolve(this.imagestore[id]);
          } else {
            resolve(this.configSvc.dummyImgPath);
          }
        })
      }
    });
  }

}
