import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationsService } from 'assets/lib/angular2-notifications';
import {
  AddressDTO,
  CartApi,
  CartDeliveryDTO,
  CartDTO,
  CartItemDTO,
  OrdersApi,
  UserLocationDTO,
  UserProductDTO,
} from 'app/shared/backend-api/emart';
import { finalize } from "rxjs";

import { CartService } from './../../../services/cart.service';
import { NameTagCartService } from './../../../services/name-tag-cart.service';
import { ProductsService } from './../../../services/products.service';
import { ResourceService } from './../../../services/resource.service';
import { StoreConfigService } from './../../../services/store-config.service';
import { UserProfileService } from './../../../services/user-profile.service';
import { Constants } from './../../../shared/global-constants/constants';
import { CommonUtils } from './../../../shared/utilities/common-utils';

declare const jQuery: any;

@Component({
  selector: 'app-name-tag-shopping-cart',
  templateUrl: './name-tag-shopping-cart.component.html',
  styleUrls: ['./name-tag-shopping-cart.component.css']
})
export class NameTagShoppingCartComponent implements OnInit {

  public reminderTitle;
  public reminderContent;

  private shoppingCartDetail: CartDTO;

  public allProducts: Array<UserProductDTO>;
  private productMap = new Map<number, UserProductDTO>();
  private total = 0;
  private PRODUCT_MAX_QUANTITY = Array.from(Array(10).keys());
  private CheckOutFormGroup: UntypedFormGroup;
  private CheckOutFormGroupMessages = {
    EmailCtrl: { required: 'Empty email address', pattern: 'Invalid email address' },
    MobileCtrl: { required: 'Empty mobile number', pattern: 'Invalid mobile number' },
    BlkCtrl: { required: 'Empty house/block' },
    StreetCtrl: { required: 'Empty street name' },
    PostalCodeCtrl: { required: 'Empty postal code', minlength: 'Incomplete postal code', pattern: 'Invalid postal code' },
    tnc1Ctrl: { required: 'You need to agree with tnc1' },
  }
  private SuccessDisplay: string;
  private ErrorDisplay;
  private imagestore: any = {};
  private products: any = {};
  private skuNames: any = {};
  private product: UserProductDTO;
  private tnc1: boolean;

  public validateOrderErrorMsg: any[];
  private validatedTotalItems = 0;
  private validatedTotalPrice = 0;

  public deliveryMode = Constants.DeliveryMode.SELF_COLLECTION;
  private selectedCP: UserLocationDTO;
  private selectedEL: UserLocationDTO;
  private selectedWICP: UserLocationDTO;
  private deliveryAddr: CartDeliveryDTO;

  private nameTagText = '';
  private nameTagTextError = 'Empty name';

  public DeliveryMode = Constants.DeliveryMode;

  public notificationOptions = {
    position: ['top', 'right'],
    lastOnBottom: false,
    timeOut: 2000
  };

  public postCreateOrderAction = 0;
  private isCreatingOrder = false;

  private resetSuccessDisplay() {
    this.tnc1 = false;
    this.SuccessDisplay = null;
  }
  private resetErrorDisplay() {
    this.ErrorDisplay = {};
  }

  constructor(
    private router: Router,
    private notificationService: NotificationsService,
    private orderApi: OrdersApi,
    private cartApi: CartApi,
    private userProfileService: UserProfileService,
    private storeConfigService: StoreConfigService,
    private productsService: ProductsService,
    private resourceService: ResourceService,
    public cartService: CartService,
    private nameTagCartService: NameTagCartService,
  ) {
    this.shoppingCartDetail = CommonUtils.cloneDeep(this.cartService.shoppingCart);
    this.allProducts = CommonUtils.cloneDeep(this.productsService.cachedNameTags.data);
    if (!this.cartService.shoppingCart.delivery.homeDeliveryAddress) { // Temp solution
      this.cartService.shoppingCart.delivery['homeDeliveryAddress'] = <AddressDTO>{};
    }

    // Force collection mode to "Self collection"
    this.deliveryMode = 1;

    // this.deliveryMode = this.cartService.shoppingCart.delivery.deliveryMode;
    // if (this.deliveryMode !== this.DeliveryMode.SELF_COLLECTION
    //   && this.deliveryMode !== this.DeliveryMode.HOME_DELIVERY
    //   && this.deliveryMode !== this.DeliveryMode.ELOCKER) {
    //   this.deliveryMode = this.DeliveryMode.SELF_COLLECTION;
    // }

    // if (this.deliveryMode === this.DeliveryMode.HOME_DELIVERY) {
    //   if (this.getTotalAmount() > this.userProfileService.creditSummary.creditBalance) {
    //     this.deliveryMode = this.DeliveryMode.SELF_COLLECTION;
    //   }
    // }

    if (this.deliveryMode === this.DeliveryMode.SELF_COLLECTION) {
      this.selectedCP = this.findCollectionPoint(this.cartService.shoppingCart.delivery.locationId);
    } else if (this.deliveryMode === this.DeliveryMode.ELOCKER) {
      this.selectedEL = this.findElocker(this.cartService.shoppingCart.delivery.locationId);
    }

    if (this.selectedCP == null && this.storeConfigService.collectionPoints && this.storeConfigService.collectionPoints.length > 0
      && this.storeConfigService.collectionPointSelections && this.storeConfigService.collectionPointSelections.length > 0) {
      this.selectedCP = this.storeConfigService.collectionPoints
        .find(cp => cp.locationId === this.storeConfigService.collectionPointSelections[0].locations[0].value);
    }
    if (this.selectedEL == null && this.storeConfigService.eLockers && this.storeConfigService.eLockers.length > 0
      && this.storeConfigService.eLockerSelections && this.storeConfigService.eLockerSelections.length > 0) {
      this.selectedEL = this.storeConfigService.eLockers
        .find(cp => cp.locationId === this.storeConfigService.eLockerSelections[0].locations[0].value);
    }

    if ((this.deliveryMode === this.DeliveryMode.ELOCKER && !this.selectedEL)) {
      this.deliveryMode = this.DeliveryMode.SELF_COLLECTION;
    }

    this.onNameTagTextChanged(this.cartService.shoppingCart.nameTagText);
  }

  findCollectionPoint(locationId) {
    for (const location of this.storeConfigService.collectionPoints) {
      if (location.locationId === locationId) {
        return location;
      }
    }
    return null;
  }

  findElocker(locationId) {
    for (const location of this.storeConfigService.eLockers) {
      if (location.locationId === locationId) {
        return location;
      }
    }
    return null;
  }

  ngOnInit() {
    if (this.cartService.shoppingCart !== undefined && this.cartService.shoppingCart.delivery !== undefined) {
      this.deliveryAddr = CommonUtils.cloneDeep(this.cartService.shoppingCart.delivery);
    }

    this.createFormGroup();
    this.resetSuccessDisplay();
    this.resetErrorDisplay();

    this.loadProducts();    // comment it for unit test
    this.fetchImagesInThePage();    // comment it for unit test

    this.postCreateOrderAction = 0;
  }

  loadProducts() {
    for (const product of this.allProducts) {
      this.productMap.set(product.productId, product);
    }
    this.cartService.getTotalPrice();
    if (this.getTotalAmount() > this.userProfileService.creditSummary.creditBalance) {
      this.deliveryMode = this.DeliveryMode.SELF_COLLECTION;
    }
  }

  getProduct(id: number): UserProductDTO {
    for (const product of this.allProducts) {
      if (product.productId === id) {
        return product;
      }
    }
  }

  private loadProductImage(product: UserProductDTO) {
    if (this.imagestore[product.productId]) { // cached
      return;
    }
    if (product.images && product.images.length > 0) {
      this.resourceService.loadImageWithId(product.images[0].resourceId).then(img => {
        this.imagestore[product.productId] = img;
      });
    } else {
      this.imagestore[product.productId] = this.storeConfigService.dummyImgPath;
    }
  }

  checkQty(qty: number) {
    if (Number(qty) <= 10) {
      qty = 10;
    }
    return Array.from(Array(qty).keys());
  }

  fetchImagesInThePage() {
    for (const product of this.allProducts) {
      this.loadProductImage(product);
    }
  }

  createFormGroup(): void {
    this.CheckOutFormGroup = new UntypedFormGroup({
      EmailCtrl: new UntypedFormControl('', [Validators.required, Validators.pattern(Constants.EmailRegex)]),
      MobileCtrl: new UntypedFormControl('', [Validators.required, Validators.pattern(Constants.PhoneRegex)]),
      BlkCtrl: new UntypedFormControl('', [Validators.required]),
      UnitCtrl: new UntypedFormControl('', []),
      StreetCtrl: new UntypedFormControl('', [Validators.required]),
      BuildingCtrl: new UntypedFormControl('', []),
      PostalCodeCtrl: new UntypedFormControl('', [Validators.required, Validators.pattern(Constants.PostalCodeRegex)]),
      tnc1Ctrl: new UntypedFormControl('', [Validators.requiredTrue]),
    });

    this.resetFormValidation();
    this.scanFormGroupForError();
    this.CheckOutFormGroup.valueChanges.subscribe(data => this.scanFormGroupForError()); // validate onValueChanged
  }

  onNameTagTextChanged($event) {
    this.nameTagTextError = null;
    this.nameTagText = $event;
    if (this.nameTagText === null || this.nameTagText.length === 0) {
      this.nameTagTextError = 'Empty name';
    }
  }

  updateQuantity(item: CartItemDTO, $event) {
    this.nameTagCartService.updateCartItemQty(item.productId, $event.target.value);
    jQuery('#itemquantity_' + item.productId).trigger('reset');
  }

  scanFormGroupForError() {
    this.resetErrorDisplay(); // Flush old error
    const FG = this.CheckOutFormGroup;
    if (!FG) { return; }

    // Match new errors and push to error message array
    const FGMsgs = this.CheckOutFormGroupMessages;
    Object.keys(FG.controls).forEach((ctrlname) => {
      const currentCtrl = FG.get(ctrlname);
      if (currentCtrl && !currentCtrl.valid && currentCtrl.errors) {
        const errMsgObj = FGMsgs[ctrlname];
        Object.keys(currentCtrl.errors).forEach(element => {
          const errMsg = errMsgObj[element];
          if (errMsg) { this.ErrorDisplay[ctrlname] = errMsg; }
        }
        );
      }
    });
  }

  scanFormGroupForDirty(): boolean {
    let isDirty = false;
    if (!this.CheckOutFormGroup) {
      return isDirty;
    }
    Object.keys(this.CheckOutFormGroup.controls).some((element) => {
      const currentCtrl = this.CheckOutFormGroup.get(element);
      if (currentCtrl.dirty) { isDirty = true; return true; }
    });
    return isDirty;
  }

  onValidateOrder() {
    this.validateOrderErrorMsg = null;

    const shoppingCart = <CartDTO>{};
    shoppingCart.delivery = this.deliveryAddr;
    shoppingCart.delivery.deliveryMode = this.deliveryMode;
    if (this.deliveryMode === this.DeliveryMode.SELF_COLLECTION) {
      shoppingCart.delivery.locationId = this.selectedCP.locationId;
    } else if (this.deliveryMode === this.DeliveryMode.ELOCKER) {
      shoppingCart.delivery.locationId = this.selectedEL.locationId;
    }
    shoppingCart.nameTagItems = this.cartService.shoppingCart.nameTagItems;
    shoppingCart.nameTagText = this.nameTagText;

    this.cartService.validate(shoppingCart).subscribe(
      data => {
        this.validatedTotalItems = this.getValidatedItems();
        this.validatedTotalPrice = this.getValidatedTotalPrice();
        this.validateOrderErrorMsg = [];
      },
      err => {
        this.validateOrderErrorMsg = err.data.errors;
      }
    );
  }

  getValidatedItems(): number {
    let result = 0;
    if (this.cartService.validatedCart !== undefined && this.cartService.validatedCart.nameTagItems != null) {
      for (const item of this.cartService.shoppingCart.nameTagItems) {
        result += item.quantity;
      }
    }
    return result;
  }

  getValidatedTotalPrice(): number {
    let result = 0;
    if (this.cartService.validatedCart !== undefined && this.cartService.validatedCart.nameTagItems != null) {
      for (const item of this.cartService.shoppingCart.nameTagItems) {
        result += item.quantity * this.getProduct(item.productId).productPrice;
      }
    }
    return result;
  }

  onConfirmPurchase() {
    if(window.wogaaCustom) {
      let wogaaCustom = window.wogaaCustom.startTransactionalService('emartmha-4707');
    }
    this.isCreatingOrder = true;

    if (this.cartService.hasCartBeingValidated && this.cartService.validatedCart != null) {
      this.postCreateOrderAction = 3;
      const postbody = CommonUtils.cloneDeep(this.cartService.validatedCart);
      this.cartService.createOrder(postbody)
        .pipe(finalize(() => this.isCreatingOrder = false))
        .subscribe(
          data => {
            this.userProfileService.forceLoadCreditSummary().subscribe(() => {
              this.postCreateOrder();
            });
            this.productsService.forceLoadProducts(Constants.ProductType.NAME_TAG).subscribe(() => {
              this.postCreateOrder();
            });
            this.productsService.forceLoadProductCaps().subscribe(() => {
              this.postCreateOrder();
            });
          },
          err => {
            try {
              if (err.errors[0].code === 'INVALID_CART') {
                this.postCreateOrderAction = 0;
                this.notificationService.error('Shopping Cart', 'Invalid cart, please try again later');
              } else {
                this.router.navigate(['/general-error']);
              }
            } catch (err2) {
              this.router.navigate(['/general-error']);
            }
          }
        );
    }
  }

  postCreateOrder() {
    this.postCreateOrderAction--;
    if (this.postCreateOrderAction === 0) {
      this.router.navigate(['/order-confirmation']);
    }
  }

  resetFormValidation() {
    if (this.deliveryMode === this.DeliveryMode.HOME_DELIVERY) {
      this.CheckOutFormGroup.get('BlkCtrl').enable();
      this.CheckOutFormGroup.get('UnitCtrl').enable();
      this.CheckOutFormGroup.get('StreetCtrl').enable();
      this.CheckOutFormGroup.get('BuildingCtrl').enable();
      this.CheckOutFormGroup.get('PostalCodeCtrl').enable();
    } else {
      this.CheckOutFormGroup.get('BlkCtrl').disable();
      this.CheckOutFormGroup.get('UnitCtrl').disable();
      this.CheckOutFormGroup.get('StreetCtrl').disable();
      this.CheckOutFormGroup.get('BuildingCtrl').disable();
      this.CheckOutFormGroup.get('PostalCodeCtrl').disable();
    }
  }

  onDeliveryModeChanged(event) {
    this.deliveryMode = Number(event.target.value);

    this.resetFormValidation();

    if (this.deliveryMode === this.DeliveryMode.HOME_DELIVERY) {
      this.reminderTitle = 'Home Delivery';
      if (this.getTotalAmount() <= this.userProfileService.creditSummary.creditBalance) {
        this.reminderContent = this.cartService.deliveryReminderMsg.replace('{placeholder}', 'S$' + this.cartService.deliveryFee);
      } else {
        this.reminderContent = this.cartService.deliveryInsufficientMsg.replace('{placeholder}', 'S$' + this.cartService.deliveryFee);
        if (this.userProfileService.userProfile.preferredDeliveryMode === this.DeliveryMode.HOME_DELIVERY) {
          this.deliveryMode = this.DeliveryMode.SELF_COLLECTION;
          event.target.value = this.DeliveryMode.SELF_COLLECTION;
        } else {
          // tslint:disable-next-line:max-line-length
          this.deliveryMode = this.userProfileService.userProfile.preferredDeliveryMode ? this.userProfileService.userProfile.preferredDeliveryMode : this.DeliveryMode.SELF_COLLECTION;
          event.target.value = this.userProfileService.userProfile.preferredDeliveryMode ? this.userProfileService.userProfile.preferredDeliveryMode : this.DeliveryMode.SELF_COLLECTION;
        }
      }
    } else if (this.deliveryMode === this.DeliveryMode.ELOCKER && this.storeConfigService.eLockerSelections.length === 0) {
      this.reminderTitle = 'eLocker Unavailable';
      this.reminderContent = 'Please note that there is no eLocker Collection Point available for you at the moment.';
      this.deliveryMode = this.DeliveryMode.SELF_COLLECTION;
      event.target.value = this.DeliveryMode.SELF_COLLECTION;
    } else {
      this.reminderContent = null;
    }

    this.cartService.selectedDeliveryMode = this.deliveryMode;

    if (this.reminderTitle && this.reminderContent) {
      document.getElementById('openReminderModalButton').click();
    }
  }

  getDeliveryFee() {
    if (this.deliveryMode === Constants.DeliveryMode.HOME_DELIVERY) {
      return this.cartService.deliveryFee;
    } else {
      return 0;
    }
  }

  getTotalAmount() {
    return this.cartService.totalPrice + this.getDeliveryFee();
  }

  getDeliveryMode() {
    // Commented out bcs only have 1 delivery mode, so force display "Self Collection"
    // return this.storeConfigService.nameTagDeliveryModes.find(mode => mode.value === this.deliveryMode).text;
    return "Self Collection";
  }

}
