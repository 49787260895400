/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as models from './models';

export interface SaveManagerPayload {
    active: boolean;

    contact?: string;

    email?: string;

    /**
     * null for both singpass and corppass.
     */
    loginType?: SaveManagerPayload.LoginTypeEnum;

    /**
     * It is the manager id if you want to update the manager.
     */
    managerId?: number;

    name: string;

    /**
     * Nric
     */
    nric: string;

    /**
     * null for both. For loginType is not CORPPASS, service unit must not be null. For loginType is CORPPASS, service unit must be null.
     */
    serviceUnit?: SaveManagerPayload.ServiceUnitEnum;

    /**
     * unit group id for the manager.
     */
    unitGroupId?: number;

}
export namespace SaveManagerPayload {
    export enum LoginTypeEnum {
        INTRANET = <any> 'INTRANET',
        CORPPASS = <any> 'CORPPASS'
    }
    export enum ServiceUnitEnum {
        NUMBER_1 = <any> 1,
        NUMBER_2 = <any> 2
    }
}
