/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as models from './models';

export interface ReportFiltersDTO {
    accountStatus?: string;

    auditResource?: ReportFiltersDTO.AuditResourceEnum;

    deliveryMode?: number;

    endDate?: string;

    feedbackStatus?: number;

    feedbackSubjectHeader?: number;

    limit?: number;

    locationIds?: Array<number>;

    loginType?: string;

    nric?: string;

    nrics?: Array<string>;

    operationType?: number;

    orderRefNo?: Array<string>;

    orderStatusEndDate?: string;

    orderStatusGroupIds?: Array<number>;

    orderStatusStartDate?: string;

    productType?: number;

    roleIds?: Array<number>;

    serviceType?: number;

    serviceUnit?: number;

    skuCodes?: Array<string>;

    startDate?: string;

    unitGroupId?: number;

    unitGroupIds?: Array<number>;

}
export namespace ReportFiltersDTO {
    export enum AuditResourceEnum {
        USER = <any> 'USER',
        ROLE = <any> 'ROLE',
        ROLEASSIGNMENT = <any> 'ROLE_ASSIGNMENT',
        BUSINESSRULE = <any> 'BUSINESS_RULE'
    }
}
