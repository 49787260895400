import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { NotificationsService } from 'assets/lib/angular2-notifications';

import { CartService } from './cart.service';
import { CatalogueService } from './catalogue.service';
import { CurrentOrdersService } from './current-orders.service';
import { FeedbackService } from './feedback.service';
import { NameTagCartService } from './name-tag-cart.service';
import { ProductsService } from './products.service';
import { ResourceService } from './resource.service';
import { SharedSearchService } from './shared-search.service';
import { StoreConfigService } from './store-config.service';
import { TailorCartService } from './tailor-cart.service';
import { TransactionHistoryService } from './transaction-history.service';
import { UserProfileService } from './user-profile.service';
import { WaitlistService } from './waitlist.service';

/**
 * This is a wrapper class for all services.
 * In Store, any other modules or tests, simply import and provide STORE_SERVICES.
 */
export const STORE_SERVICES = [StoreConfigService, UserProfileService, CatalogueService, ResourceService,
    ProductsService, CartService, CurrentOrdersService, TransactionHistoryService, TailorCartService, NameTagCartService,
    SharedSearchService, FeedbackService, WaitlistService];

export const EXTERNAL_SERVICES = [
    CookieService,
    NotificationsService
];
