import { UserProfileService } from './../../services/user-profile.service';
import { CatalogueService } from './../../services/catalogue.service';
import { Observable, of, forkJoin, catchError } from 'rxjs';
import { Router, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class DashboardGuard implements Resolve<any> {

    constructor(
        private router: Router,
        private catalogueService: CatalogueService,
        private userProfileService: UserProfileService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        const $obs = forkJoin({
            categories: this.catalogueService.forceLoadCategories(),
            userProfile: this.userProfileService.loadUserProfile(),
            creditSummary: this.userProfileService.loadCreditSummary()
        }).pipe(catchError(err => {
            this.router.navigate(['/general-error']);
            return of(err);
        }));
        
        return $obs;
    }

}
