<div class="col-md-12">
  <div class="collapse-box">
    <div class="collapse-title">
      <h1>Feedback</h1>
    </div>
    <div class="collapse in">
      <form [formGroup]="feedbackFormGroup" (ngSubmit)="doSubmit()">
        <div class="box-content">
          <table class="feedback-form">
            <tbody>
              <tr>
                <td>NRIC</td>
                <td>
                  <input type="text" [ngModel]="userProfileServce.userProfile.nric" class="form-control" readonly [ngModelOptions]="{standalone: true}">
                </td>
              </tr>
              <tr>
                <td>Name</td>
                <td>
                  <input type="text" [ngModel]="userProfileServce.userProfile.name" class="form-control" readonly [ngModelOptions]="{standalone: true}">
                </td>
              </tr>
              <tr>
                <td>Mobile
                  <span class="text-danger">*</span>
                </td>
                <td [ngClass]="{ 'has-error': (ErrorDisplay?.MobileNumberCtrl)}">
                  <input type="text" maxlength="8" formControlName="MobileNumberCtrl" class="form-control">
                  <span class="help-block">{{ErrorDisplay?.MobileNumberCtrl}}</span>
                </td>
              </tr>
              <tr>
                <td>Email
                  <span class="text-danger">*</span>
                </td>
                <td [ngClass]="{ 'has-error': (ErrorDisplay?.EmailCtrl)}">
                  <input type="text" maxlength="255" formControlName="EmailCtrl" class="form-control">
                  <span class="help-block">{{ErrorDisplay?.EmailCtrl}}</span>
                </td>
              </tr>
              <tr>
                <td class="middle-aligned">Subject
                  <span class="text-danger">*</span>
                </td>
                <td *ngIf="feedbackService.subjects && feedbackService.subjects.length > 0">
                  <select *ngIf="!isProductFeedback; else elseBlock" name="subject" class="form-control" (change)="onSubjectChange($event)">
                    <option [ngValue]="subject.value" *ngFor="let subject of feedbackService.subjects" [selected]="subject.value == this.selectedSubject">{{subject.text}}</option>
                  </select>
                  <ng-template #elseBlock>
                    <select name="subject" class="form-control" type="text" class="form-control" disabled>
                      <option [value]="feedbackService.subjects[4].value">
                        {{feedbackService.subjects[4].text}} for {{productName}}
                      </option>
                    </select>
                  </ng-template>
                </td>
              </tr>
              <tr>
                <td>Details
                  <span class="text-danger">*</span>
                </td>
                <td [ngClass]="{ 'has-error': (ErrorDisplay?.DetailCtrl)}">
                  <textarea formControlName="DetailCtrl" rows="4" class="form-control" maxlength="1000"></textarea>
                  <span class="help-block">{{ErrorDisplay?.DetailCtrl}}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <i class="fa fa-refresh fa-2x spinner text-primary" *ngIf="isSubmitting"></i>
          <div *ngIf="!isSubmitting && !isRedirecting">
            <button [disabled]="!feedbackFormGroup.valid && !feedbackFormGroup.disabled" type="submit" class="btn voffset2">Submit</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


<!-- Feedback submission successfull model !-->
<div id="feedback-submission-pop-up" class="modal fade" role="dialog">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">{{feedbackSubmissionTitle}}</h1>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        {{feedbackSubmissionContent}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn" data-bs-dismiss="modal" (click)="resetFeedbackForm()">Yes</button>
        <button type="button" class="btn" data-bs-dismiss="modal" (click)="redirectToDashBoard()">No</button>
      </div>
    </div>
  </div>
</div>


<simple-notifications [options]="notificationOptions"></simple-notifications>