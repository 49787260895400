import { Injectable } from '@angular/core';
import { SubmitDepartmentRequest } from 'app/shared/backend-api/emart';
import { ServicemanApi } from 'app/shared/backend-api/emart/api/ServicemanApi';
import { PersonaSelectionDTO } from 'app/shared/backend-api/emart/model/PersonaSelectionDTO';
import { Observable, catchError, tap, throwError } from 'rxjs';


export interface Persona {
  servicemanDepartments ?: Array<PersonaSelectionDTO>;
}

@Injectable({
  providedIn: 'root'
})
export class PersonaSelectionService {

  // Servicemen service unit
  personaServiceUnit: Persona = null;

  constructor(private servicemanApi: ServicemanApi) {}

  forceLoadServicemanServiceUnit():Observable<any> {
    return this.servicemanApi.getServicemanServiceUnitUsingPOST().pipe(tap(
      payload => {
        //when status == 200 & 204, execute working part
        if (payload.status == 200 || payload.status == 204) {
          this.personaServiceUnit = payload.data as Persona;

          // other than 200, 204, 401, 403, throws error
        } else if (payload.status != 401 && payload.status != 403) {
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        throw throwError(err);
      }
    ));
  }

  submitDepartment(request : SubmitDepartmentRequest): Observable<any> {
    return this.servicemanApi.submitServiceUnitUsingPOST(request).pipe(tap(
      payload => {
        // other than 200, 204, 401, 403, raise error
        if (payload.status != 200 && payload.status != 204 && payload.status != 401 && payload.status != 403){
           throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        throw throwError(() => err);
      }
    ));
  }
}
