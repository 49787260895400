/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class ServicemanApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {}

    /**
     * GetCreditHistory
     * Get credit History (list of Transactions)
     * @param from The start date of the credit hisotry. Included. In yyyy-MM-dd format.
     * @param to The end date of the credit history. Included. In yyyy-MM-dd format.
     */
    public getCreditHistoryUsingGET(from: string, to: string, extraHttpRequestParams?: any): Observable<models.GetCreditHistoryResponse> {
        return this.getCreditHistoryUsingGETWithHttpInfo(from, to, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * GetCreditSummary
     * Get credit summary
     */
    public getCreditSummaryUsingGET(extraHttpRequestParams?: any): Observable<models.GetCreditSummaryResponse> {
        return this.getCreditSummaryUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getFAQs
     * Get FAQs for emart store user
     */
    public getFAQsUsingGET(extraHttpRequestParams?: any): Observable<models.GetFAQsResponse> {
        return this.getFAQsUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * GetProfile
     * Get User Profile
     */
    public getProfileUsingGET(extraHttpRequestParams?: any): Observable<models.GetProfileResponse> {
        return this.getProfileUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * submitFeedback
     * Submit serviceman general and product feedback
     * @param feedback feedback
     */
    public submitFeedbackUsingPOST(feedback: models.CreateFeedbackDTO, extraHttpRequestParams?: any): Observable<models.SubmitFeedbackResponse> {
        return this.submitFeedbackUsingPOSTWithHttpInfo(feedback, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * UpdateProfile
     * Update user profile
     * @param requestParams requestParams
     */
    public updateProfileUsingPOST(requestParams: models.UpdateProfileRequest, extraHttpRequestParams?: any): Observable<models.UpdateProfileResponse> {
        return this.updateProfileUsingPOSTWithHttpInfo(requestParams, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * GetCreditHistory
     * Get credit History (list of Transactions)
     * @param from The start date of the credit hisotry. Included. In yyyy-MM-dd format.
     * @param to The end date of the credit history. Included. In yyyy-MM-dd format.
     */
    public getCreditHistoryUsingGETWithHttpInfo(from: string, to: string, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/serviceman/credits/history';

        let queryParameters = new HttpParams();

        // verify required parameter 'from' is not null or undefined
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getCreditHistoryUsingGET.');
        }
        // verify required parameter 'to' is not null or undefined
        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling getCreditHistoryUsingGET.');
        }
        if (from !== undefined) {
            queryParameters = queryParameters.append('from', <any>from);
        }

        if (to !== undefined) {
            queryParameters = queryParameters.append('to', <any>to);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
    }

    /**
     * GetCreditSummary
     * Get credit summary
     */
    public getCreditSummaryUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/serviceman/credit';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getFAQs
     * Get FAQs for emart store user
     */
    public getFAQsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/serviceman/faqs';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * GetProfile
     * Get User Profile
     */
    public getProfileUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/serviceman/profile';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * submitFeedback
     * Submit serviceman general and product feedback
     * @param feedback feedback
     */
    public submitFeedbackUsingPOSTWithHttpInfo(feedback: models.CreateFeedbackDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/serviceman/feedback';

        // verify required parameter 'feedback' is not null or undefined
        if (feedback === null || feedback === undefined) {
            throw new Error('Required parameter feedback was null or undefined when calling submitFeedbackUsingPOST.');
        }

        return this.httpClient.post(path, feedback, { withCredentials: true });
    }

    /**
     * UpdateProfile
     * Update user profile
     * @param requestParams requestParams
     */
    public updateProfileUsingPOSTWithHttpInfo(requestParams: models.UpdateProfileRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/serviceman/profile';

        // verify required parameter 'requestParams' is not null or undefined
        if (requestParams === null || requestParams === undefined) {
            throw new Error('Required parameter requestParams was null or undefined when calling updateProfileUsingPOST.');
        }

        return this.httpClient.post(path, requestParams, { withCredentials: true });
    }

    /**
     * getServicemanServiceUnit
     * Get serviceman serviceunit for multi persona
     */
    public getServicemanServiceUnitUsingPOST(extraHttpRequestParams?: any): Observable<any> {
        return this.getServicemanServiceUnitUsingPOSTWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getServicemanServiceUnit
     * Get serviceman serviceunit for multi persona
     */
    public getServicemanServiceUnitUsingPOSTWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/serviceman/departmentSelections';

        return this.httpClient.post(path, null, { withCredentials: true });
    }


    /**
     * submitServiceUnit
     * Submit serviceman Service Unit
     */
    public submitServiceUnitUsingPOST(request: models.SubmitDepartmentRequest, extraHttpRequestParams?: any): Observable<models.SubmitDepartmentResponse> {
        return this.submitServiceUnitUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * submitServiceUnit
     * Submit serviceman Service Unit
     */
    public submitServiceUnitUsingPOSTWithHttpInfo(request: models.SubmitDepartmentRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/login/serviceman';

        // verify required parameter 'ServiceUnit' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter ServiceUnit was null or undefined when calling submitServiceUnitUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

}
