/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class TailorApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {}

    /**
     * getOrderStatusSelectionsTailor
     * Get order status selections for tailor
     */
    public getOrderStatusSelectionsTailorUsingGET(extraHttpRequestParams?: any): Observable<models.GetOrderStatusSelectionsForTailorResponse> {
        return this.getOrderStatusSelectionsTailorUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getOrdersForTailor
     * Get orders for tailor
     * @param page to set the index of pages to retrieve the order. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param cp to set the list of collection points interested. If it is null, all collection points will be returned.
     * @param deliveryMode to indicate the delivery mode for the orders to be retrieved. If it is null, all delivery mode will be returned.
     * @param from to indicate the start date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of start date will be imposed.
     * @param to to indicate the end date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of end date will be imposed.
     * @param status to indicate the status for the orders to be retrieved. If it is null, all available status will be returned. status map: &lt;br&gt;&lt;ul&gt;&lt;li&gt;21: TAILOR_CHECKED_OUT&lt;/li&gt; &lt;li&gt;28: TAILOR_PROCESSING&lt;/li&gt; &lt;li&gt;29: TAILOR_SELF_COLLECT_PROCESSING&lt;&lt;/li&gt; &lt;li&gt;22: TAILOR_COLLECTED&lt;/li&gt; &lt;li&gt;23: TAILOR_FORFEITED&lt;/li&gt; &lt;/ul&gt;
     * @param tailor to find by tailor involved in the order
     */
    public getOrdersForTailorUsingGET(page: number, size: number, cp?: Array<number>, deliveryMode?: number, from?: string, to?: string, status?: number, tailor?: number, extraHttpRequestParams?: any): Observable<models.GetOrdersForTailorResponse> {
        return this.getOrdersForTailorUsingGETWithHttpInfo(page, size, cp, deliveryMode, from, to, status, tailor, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getTailorSelections
     * Get tailor selections
     */
    public getTailorSelectionsUsingGET(extraHttpRequestParams?: any): Observable<models.GetTailorSelectionsResponse> {
        return this.getTailorSelectionsUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * updateOrderStatusForTailor
     * Update orders statuses for tailor
     * @param request request
     */
    public updateOrderStatusForTailorUsingPOST(request: models.UpdateOrderStatusRequestDTO, extraHttpRequestParams?: any): Observable<models.UpdateOrderStatusForTailorResponse> {
        return this.updateOrderStatusForTailorUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * getOrderStatusSelectionsTailor
     * Get order status selections for tailor
     */
    public getOrderStatusSelectionsTailorUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/tailor/orderStatusSelections';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getOrdersForTailor
     * Get orders for tailor
     * @param page to set the index of pages to retrieve the order. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param cp to set the list of collection points interested. If it is null, all collection points will be returned.
     * @param deliveryMode to indicate the delivery mode for the orders to be retrieved. If it is null, all delivery mode will be returned.
     * @param from to indicate the start date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of start date will be imposed.
     * @param to to indicate the end date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of end date will be imposed.
     * @param status to indicate the status for the orders to be retrieved. If it is null, all available status will be returned. status map: &lt;br&gt;&lt;ul&gt;&lt;li&gt;21: TAILOR_CHECKED_OUT&lt;/li&gt; &lt;li&gt;28: TAILOR_PROCESSING&lt;/li&gt; &lt;li&gt;29: TAILOR_SELF_COLLECT_PROCESSING&lt;&lt;/li&gt; &lt;li&gt;22: TAILOR_COLLECTED&lt;/li&gt; &lt;li&gt;23: TAILOR_FORFEITED&lt;/li&gt; &lt;/ul&gt;
     * @param tailor to find by tailor involved in the order
     */
    public getOrdersForTailorUsingGETWithHttpInfo(page: number, size: number, cp?: Array<number>, deliveryMode?: number, from?: string, to?: string, status?: number, tailor?: number, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/tailor/orders';

        let queryParameters = new HttpParams();

        // verify required parameter 'page' is not null or undefined
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getOrdersForTailorUsingGET.');
        }
        // verify required parameter 'size' is not null or undefined
        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling getOrdersForTailorUsingGET.');
        }
        if (cp) {
            cp.forEach((element) => {
                queryParameters = queryParameters.append('cp', <any>element);
            })
        }

        if (deliveryMode !== undefined) {
            queryParameters = queryParameters.append('deliveryMode', <any>deliveryMode);
        }

        if (from !== undefined) {
            queryParameters = queryParameters.append('from', <any>from);
        }

        if (to !== undefined) {
            queryParameters = queryParameters.append('to', <any>to);
        }

        if (status !== undefined) {
            queryParameters = queryParameters.append('status', <any>status);
        }

        if (tailor !== undefined) {
            queryParameters = queryParameters.append('tailor', <any>tailor);
        }

        if (page !== undefined) {
            queryParameters = queryParameters.append('page', <any>page);
        }

        if (size !== undefined) {
            queryParameters = queryParameters.append('size', <any>size);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
    }

    /**
     * getTailorSelections
     * Get tailor selections
     */
    public getTailorSelectionsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/tailor/tailorSelections';
        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * updateOrderStatusForTailor
     * Update orders statuses for tailor
     * @param request request
     */
    public updateOrderStatusForTailorUsingPOSTWithHttpInfo(request: models.UpdateOrderStatusRequestDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/tailor/orders/updateStatus';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling updateOrderStatusForTailorUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

}
