<!-- content-->
<div class="col-md-12">
  <div class="collapse-box">
    <div class="collapse-title">
      <h1>Tailor List</h1>
    </div>
    <div id="credits1" class="collapse in">
      <div class="box-content">
        <div *ngIf="storeConfigService.tailors != null && storeConfigService.tailors.length > 0">
          <table class="table locationlist">
            <thead>
              <tr>
                <th>Tailor</th>
                <th>Address</th>
                <th>Contact</th>
                <th>Opening Hours</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let tailor of getItemsToDisplay()">
                <td class="tailor">{{tailor.name}}</td>
                <td class="address">
                  <div>{{tailor.address.blkNo}} {{tailor.address.streetName}}</div>
                  <div>{{tailor.address.unitNo}}</div>
                  <div>{{tailor.address.buildingName}}</div>
                  <div>Singapore {{tailor.address.postalCode}}</div>
                </td>
                <td class="contact">{{tailor.telephone}}</td>
                <td class="block open">
                  <div *ngFor="let openingHrs of tailor.openingHrs">
                    <strong>{{openingHrs.days}}</strong>
                    <br/>{{openingHrs.hours}}
                  </div>
                </td>
                <td class="map">
                  <a style="cursor:pointer;" (click)="viewMap(tailor.address)">View Map</a>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="pagination">
            <button type="button" class="btn btn-square" [disabled]="prevPaginationDisabled" (click)="gotoFirstPage()">
              <span class="glyphicon glyphicon-step-backward" aria-hidden="true"></span>
            </button>
            <button type="button" class="btn btn-square" [disabled]="prevPaginationDisabled" (click)="gotoPreviousPage()">
              <span class="glyphicon glyphicon-triangle-left" aria-hidden="true"></span>
            </button>
            <div class="page">
              <input #page type="text" class="form-control" [value]="currentPage" (keyup)="gotoPage(page.value, $event)"> / {{pageCount}}
            </div>
            <button type="button" class="btn btn-square" [disabled]="nextPaginationDisabled" (click)="gotoNextPage()">
              <span class="glyphicon glyphicon-triangle-right" aria-hidden="true"></span>
            </button>
            <button type="button" class="btn btn-square" [disabled]="nextPaginationDisabled" (click)="gotoLastPage()">
              <span class="glyphicon glyphicon-step-forward" aria-hidden="true"></span>
            </button>
          </div>
        </div>

        <div id="nodata" class="alert alert-info text-center" role="alert" *ngIf="storeConfigService.tailors != null && storeConfigService.tailors.length == 0">
          <i class="fa fa-info-circle fa-2x"></i>
          <p>No tailor is found</p>
        </div>
        <div class="text-center" *ngIf="storeConfigService.tailors == null">
          <i class="fa fa-refresh fa-2x spinner text-primary"></i>
        </div>

      </div>
    </div>
  </div>
</div>