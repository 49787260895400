import { CreateFeedbackDTO } from './../shared/backend-api/emart/model/CreateFeedbackDTO';
import { ServicemanApi } from './../shared/backend-api/emart/api/ServicemanApi';
import { Observable, throwError, tap, catchError } from 'rxjs';
import { SelectionDTO } from 'app/shared/backend-api/emart';
import { CommonApi } from '../shared/backend-api/emart/api/CommonApi';
import { Injectable } from '@angular/core';
@Injectable()
export class FeedbackService {

  public subjects: Array<SelectionDTO>;

  constructor(private commonApi: CommonApi,
  private servicemanApi: ServicemanApi) { };

  getSubjectsSelection(): Observable<any> {
    return this.commonApi.getFeedbackSelectionsUsingGET().pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.subjects = payload.data as Array<SelectionDTO>;

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        throw throwError(() => err);
      }
      ));
  }

  submitFeedback(postBody: CreateFeedbackDTO): Observable<any> {
    return this.servicemanApi.submitFeedbackUsingPOST(postBody).pipe(tap(
      payload => {
        // other than 200, 204, 401, 403, raise error
        if (payload.status != 200 && payload.status != 204 && payload.status != 401 && payload.status != 403){
           throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        throw throwError(() => err);
      }
    ));
  }
}
