/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class PackerApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {}

    /**
     * getOrderReportForPacker
     * Get order report for packer
     * @param from Status updated date from in yyyy-MM-dd format
     * @param to Status updated date to in yyyy-MM-dd format
     * @param page to set the index of pages to retrieve the order. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     */
    public getOrderReportForPackerUsingGET(from: string, to: string, page: number, size: number, extraHttpRequestParams?: any): Observable<models.GetOrderReportForPackerResponse> {
        return this.getOrderReportForPackerUsingGETWithHttpInfo(from, to, page, size, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getOrderStatusSelectionsForPacker
     * Get order status selections for packer
     */
    public getOrderStatusSelectionsForPackerUsingGET(extraHttpRequestParams?: any): Observable<models.GetOrderStatusSelectionsForPackerResponse> {
        return this.getOrderStatusSelectionsForPackerUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getOrdersForPacker
     * Get orders for packer
     * @param page to set the index of pages to retrieve the order. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param cp to set the list of collection points interested. If it is null, all collection points will be returned.
     * @param deliveryMode to indicate the delivery mode for the orders to be retrieved. If it is null, all delivery mode will be returned.
     * @param department to indicate the service unit for the orders to be retrieved. If it is null, all service unis will be returned.
     * @param from to indicate the start date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of start date will be imposed.
     * @param to to indicate the end date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of end date will be imposed.
     * @param status to indicate the status for the orders to be retrieved. If it is null, all available status will be returned. status map: &lt;br&gt;&lt;ul&gt;&lt;li&gt;1: ADVANCE_CHECKED_OUT&lt;/li&gt; &lt;li&gt;15: STARTED_PACKING&lt;/li&gt; &lt;li&gt;3: PACKED&lt;/li&gt; &lt;li&gt;12: PACKED_SENT_FOR_DELIVERY&lt;/li&gt; &lt;li&gt;16: PACKED_FOR_ELOCKER&lt;/li&gt; &lt;/ul&gt;
     * @param ref to set the order reference number for the order to be retrieved. If it is set, the order reference number must be matched. If other params is not null, they are all need to be matched the corresponding order as well.
     */
    public getOrdersForPackerUsingGET(page: number, size: number, cp?: Array<number>, deliveryMode?: number, department?: number, from?: string, to?: string, status?: number, ref?: string, extraHttpRequestParams?: any): Observable<models.GetOrdersForPackerResponse> {
        return this.getOrdersForPackerUsingGETWithHttpInfo(page, size, cp, deliveryMode, department, from, to, status, ref, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * updateOrderStatusForPacker
     * Update orders statuses for packer
     * @param request request
     */
    public updateOrderStatusForPackerUsingPOST(request: models.UpdateOrderStatusRequestDTO, extraHttpRequestParams?: any): Observable<models.UpdateOrderStatusForPackerResponse> {
        return this.updateOrderStatusForPackerUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * getOrderReportForPacker
     * Get order report for packer
     * @param from Status updated date from in yyyy-MM-dd format
     * @param to Status updated date to in yyyy-MM-dd format
     * @param page to set the index of pages to retrieve the order. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     */
    public getOrderReportForPackerUsingGETWithHttpInfo(from: string, to: string, page: number, size: number, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/packer/orders/report';

        let queryParameters = new HttpParams();

        // verify required parameter 'from' is not null or undefined
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getOrderReportForPackerUsingGET.');
        }
        // verify required parameter 'to' is not null or undefined
        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling getOrderReportForPackerUsingGET.');
        }
        // verify required parameter 'page' is not null or undefined
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getOrderReportForPackerUsingGET.');
        }
        // verify required parameter 'size' is not null or undefined
        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling getOrderReportForPackerUsingGET.');
        }
        if (from !== undefined) {
            queryParameters = queryParameters.append('from', <any>from);
        }

        if (to !== undefined) {
            queryParameters = queryParameters.append('to', <any>to);
        }

        if (page !== undefined) {
            queryParameters = queryParameters.append('page', <any>page);
        }

        if (size !== undefined) {
            queryParameters = queryParameters.append('size', <any>size);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
    }

    /**
     * getOrderStatusSelectionsForPacker
     * Get order status selections for packer
     */
    public getOrderStatusSelectionsForPackerUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/packer/orderStatusSelections';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getOrdersForPacker
     * Get orders for packer
     * @param page to set the index of pages to retrieve the order. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param cp to set the list of collection points interested. If it is null, all collection points will be returned.
     * @param deliveryMode to indicate the delivery mode for the orders to be retrieved. If it is null, all delivery mode will be returned.
     * @param department to indicate the service unit for the orders to be retrieved. If it is null, all service unis will be returned.
     * @param from to indicate the start date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of start date will be imposed.
     * @param to to indicate the end date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of end date will be imposed.
     * @param status to indicate the status for the orders to be retrieved. If it is null, all available status will be returned. status map: &lt;br&gt;&lt;ul&gt;&lt;li&gt;1: ADVANCE_CHECKED_OUT&lt;/li&gt; &lt;li&gt;15: STARTED_PACKING&lt;/li&gt; &lt;li&gt;3: PACKED&lt;/li&gt; &lt;li&gt;12: PACKED_SENT_FOR_DELIVERY&lt;/li&gt; &lt;li&gt;16: PACKED_FOR_ELOCKER&lt;/li&gt; &lt;/ul&gt;
     * @param ref to set the order reference number for the order to be retrieved. If it is set, the order reference number must be matched. If other params is not null, they are all need to be matched the corresponding order as well.
     */
    public getOrdersForPackerUsingGETWithHttpInfo(page: number, size: number, cp?: Array<number>, deliveryMode?: number, department?: number, from?: string, to?: string, status?: number, ref?: string, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/packer/orders';

        let queryParameters = new HttpParams();

        // verify required parameter 'page' is not null or undefined
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getOrdersForPackerUsingGET.');
        }
        // verify required parameter 'size' is not null or undefined
        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling getOrdersForPackerUsingGET.');
        }
        if (cp) {
            cp.forEach((element) => {
                queryParameters = queryParameters.append('cp', <any>element);
            })
        }

        if (deliveryMode !== undefined) {
            queryParameters = queryParameters.append('deliveryMode', <any>deliveryMode);
        }

        if (department !== undefined) {
            queryParameters = queryParameters.append('department', <any>department);
        }

        if (from !== undefined) {
            queryParameters = queryParameters.append('from', <any>from);
        }

        if (to !== undefined) {
            queryParameters = queryParameters.append('to', <any>to);
        }

        if (status !== undefined) {
            queryParameters = queryParameters.append('status', <any>status);
        }

        if (ref !== undefined) {
            queryParameters = queryParameters.append('ref', <any>ref);
        }

        if (page !== undefined) {
            queryParameters = queryParameters.append('page', <any>page);
        }

        if (size !== undefined) {
            queryParameters = queryParameters.append('size', <any>size);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
    }

    /**
     * updateOrderStatusForPacker
     * Update orders statuses for packer
     * @param request request
     */
    public updateOrderStatusForPackerUsingPOSTWithHttpInfo(request: models.UpdateOrderStatusRequestDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/packer/orders/updateStatus';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling updateOrderStatusForPackerUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

}
