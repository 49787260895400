import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { CartService } from './../../services/cart.service';
import { WaitlistService } from './../../services/waitlist.service';
import { SharedSearchService } from './../../services/shared-search.service';
import { AuthService } from 'app/auth/auth.service';

declare const jQuery: any;

@Component({
  selector: 'app-pushmenu',
  templateUrl: './pushmenu.component.html',
  styleUrls: ['./pushmenu.component.css']
})
export class PushmenuComponent implements OnInit {
  searchString = '';

  /* Login Page Link */ 
  loginPage = 'http://localhost/index_emart.html';
  
  constructor(
    public cartService: CartService,
    public waitlistService : WaitlistService,
    public searchService: SharedSearchService,
    public router: Router,
    private auth: AuthService
    ) { }

  ngOnInit() {
  }

  searchFor(str) {
    this.searchString = str;
    this.searchService.update(SharedSearchService.SEARCH_STRING, str);
    if (this.searchString && !this.searchService.currentPage) {
      if (this.router.url.startsWith('/tailored-uniform')) {
        this.router.navigate(['/view-uniforms']);
      } else if (this.router.url.startsWith('/name-tag')) {
        this.router.navigate(['/view-name-tags']);
      } else {
        this.router.navigate(['/view-products/All']);
      }
      this.closeBigSlide();
    }
  }

  closeBigSlide() {
    jQuery('.navbar-header .menu-link').click();
  }

  gotoCart() {
    if (this.cartService.shoppingCart.tailorItems != null) {
      this.router.navigate(['/tailor-shopping-cart']);
    } else if (this.cartService.shoppingCart.nameTagItems != null) {
      this.router.navigate(['/name-tag-shopping-cart']);
    } else {
      this.router.navigate(['/shopping-cart']);
    }

    this.closeBigSlide();
  }

  gotoWaitlist(){

      this.router.navigate(['/waitlist']);
      this.closeBigSlide();

  }

}
