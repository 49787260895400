import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { catchError, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementService {
  
  announcementFileURL = environment.announcementURL;

  constructor(
    private httpClient: HttpClient
  ) { }

  getAnnouncements(): Observable<any> {
    return this.httpClient.get(this.announcementFileURL).pipe(tap(
      data => {
        console.log(data);
      }
    ), catchError(
      err => {
        return err;
      }
    ));
  }
}
