import { UserProfileService } from './../../services/user-profile.service';
import { StoreConfigService } from './../../services/store-config.service';
import { Observable, of, forkJoin, catchError } from 'rxjs';
import { Router, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class UserProfileGuard implements Resolve<any> {

    constructor(
        private router: Router,
        private userProfileService: UserProfileService,
        private storeConfigService: StoreConfigService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        const $obs = forkJoin({
            userProfile: this.userProfileService.forceLoadUserProfile(),
            creditSummary: this.userProfileService.forceLoadCreditSummary(),
            deliveryModes: this.storeConfigService.loadDeliveryModes(),
            collectionPoints: this.storeConfigService.loadCollectionPoints(),
            elocker: this.storeConfigService.loadElockers(),
            walkInCollectionPoints: this.storeConfigService.loadWalkInCollectionPoints(),
            cpSelections: this.storeConfigService.loadCollectionPointSelections(),
            elockerSelections: this.storeConfigService.loadElockerSelections(),
            walkinCPSelection: this.storeConfigService.loadWalkInCollectionPointSelections()
        }).pipe(catchError(err => {
            this.router.navigate(['/general-error']);
            return of(err);
        }));

        return $obs;
    }

}
