<div class="container">
    
    <div class="text-center">
        <div class="rounded mx-auto d-block maintenance-icon"></div>
        <h1 [innerHTML]="maintenanceMessages.maintenanceTitle"></h1>
        <p [innerHTML]="maintenanceMessages.maintenanceContent">
        </p>
        <p [innerHTML]="maintenanceMessages.maintenanceRemarks">
        </p>
    </div>
</div>