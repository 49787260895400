<div class="breadcrumb-section">
  <div class="container">
    <ul class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" aria-label="breadcrumb-item"></a>
      </li>
      <li class="breadcrumb-item active">
        <a id="breadcrumb-active-link" routerLink="/faq" aria-label="breadcrumb-item">FAQs</a>
      </li>
    </ul>
  </div>
</div>

<h1>FAQs</h1>


<!--*******************************************************
*      <app-left-navbar> = left side navigation bar       *
*   <app-question-answer> = right side question & answer  *
********************************************************-->
<div class="row">
  <div class="col-sm-4 aside content-left">
    <app-left-navbar (selectedList)="listSelected = $event"></app-left-navbar>
  </div>
  <div class="col-sm-8 content-right">
    <app-question-answer [selectedListReceive]="listSelected"></app-question-answer>
  </div>
</div>