const API_BASEURL = 'https://sit.emart.mha.gov.sg/mha/htemart';
const STORE_BASEURL = "https://sit.emart.mha.gov.sg/store/#/mha-emart/";
const LOGISTICS_BASEURL = "https://sit.emart.mha.gov.sg/logistics/";

export const environment = {
  production: false,
  isNgnspEnv: false,
  apiBaseUrl: API_BASEURL,
  storeBaseUrl: STORE_BASEURL,
  logisticsBaseUrl: LOGISTICS_BASEURL,
  ngnspErrorRedirectUrl: '/nsp/nsp-servlets/redirectErrorPage',
  deployUrl: '',
  timeStamp: 'Thu Nov 14 2024 03:57:25 GMT+0000 (Coordinated Universal Time)',
  singpassLoginUrl: API_BASEURL + '/oauth2/authorization/spcp-singpass',
  corppassLoginUrl: API_BASEURL + '/oauth2/authorization/spcp-corppass',
  SESSION_DURATION_IN_MINUTES: 15,
  TIMEOUT_BUFFER: 2,
  KEEP_ALIVE_INTERVAL: 12,
  loginPage: STORE_BASEURL + 'login',
  //Broadcast Channel Name
  channelNAME: "BroadcastChannel",
  bypassStoreLoginUrl: STORE_BASEURL + 'dashboard',
  bypassStoreLogisticsUrl: LOGISTICS_BASEURL + '#/dashboard',
  accountlist: [
      {
        name: 'Store S0028701X SPF Male',
        ivUser: 'S0028701X',
        ivGroup: 'EMART-SERVICEMEN-INTERNET'
      },
      {
        name: 'Store S0028711X SPF Male',
        ivUser: 'S0028711X',
        ivGroup: 'EMART-SERVICEMEN-INTERNET'
      },
      {
        name: 'Store S0028801X SPF Female',
        ivUser: 'S0028801X',
        ivGroup: 'EMART-SERVICEMEN-INTERNET'
      },
      {
        name: 'Store S0028810X SCDF Male',
        ivUser: 'S0028810X',
        ivGroup: 'EMART-SERVICEMEN-INTERNET'
      },
      {
        name: 'Store S0028819X SCDF Male',
        ivUser: 'S0028819X',
        ivGroup: 'EMART-SERVICEMEN-INTERNET'
      },
      {
        name: 'Store S0028829X SCDF Female',
        ivUser: 'S0028829X',
        ivGroup: 'EMART-SERVICEMEN-INTERNET'
      },
      {
        name: 'Logistics S0000001L Operator Manager',
        ivUser: 'S0000001L',
        ivGroup: 'PORTAL-SUPPLIERS-INTERNET'
      },
      {
        name: 'Logistics S0000002L Tailor Operator',
        ivUser: 'S0000002L',
        ivGroup: 'PORTAL-SUPPLIERS-INTERNET'
      },
      {
        name: 'Logistics S0000003L Catalogue Operator',
        ivUser: 'S0000003L',
        ivGroup: 'PORTAL-SUPPLIERS-INTERNET'
      },
      {
        name: 'Logistics S0000004L Storefront Operator',
        ivUser: 'S0000004L',
        ivGroup: 'PORTAL-SUPPLIERS-INTERNET'
      },
      {
        name: 'Logistics S0000005L Packing Operator',
        ivUser: 'S0000005L',
        ivGroup: 'PORTAL-SUPPLIERS-INTERNET'
      },
      {
        name: 'Logistics S0000006L Delivery Operator',
        ivUser: 'S0000006L',
        ivGroup: 'PORTAL-SUPPLIERS-INTERNET'
      },
      {
        name: 'Logistics S0000007L Name Tag Operator',
        ivUser: 'S0000007L',
        ivGroup: 'PORTAL-SUPPLIERS-INTERNET'
      },
      {
        name: 'Store S0029818X Inactive',
        ivUser: 'S0029818X',
        ivGroup: 'EMART-SERVICEMEN-INTERNET'
      },
      {
        name: 'Logistics S0029847X  Inactive',
        ivUser: 'S0029847X',
        ivGroup: 'PORTAL-SUPPLIERS-INTERNET'
      },
      {
        name: 'S9999999A  Invalid',
        ivUser: '',
        ivGroup: 'EMART-SERVICEMEN-INTERNET'
      },
  ],
  enableLogs: true,
  announcementURL: "./announcement.json",
  maintenanceURL: "./maintenance.json"
};
