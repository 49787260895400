import { Observable, of, forkJoin, catchError } from 'rxjs';
import { Router, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserProfileService } from './../../services/user-profile.service';
import * as moment from 'moment';

@Injectable()
export class CreditsGuard implements Resolve<any> {

    constructor(
        private router: Router,
        private userProfileService: UserProfileService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        const $obs = forkJoin({
            creditSummary: this.userProfileService.loadCreditSummary(),
            creditHistory: this.userProfileService.getCreditHistory(
                moment(new Date('1900-01-01')).format('YYYY-MM-DD'),
                moment(new Date()).format('YYYY-MM-DD'))
        }).pipe(catchError(err => {
            this.router.navigate(['/general-error']);
            return of(err);
        }));
        return $obs;
    }

}
