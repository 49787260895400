import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceFormat'
})
export class PriceFormatPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (typeof value === 'number') {
      const price = <Number>value;
      const strPrice = value.toFixed(2);
      return this.makePriceFormat(strPrice);
    } else if (typeof value === 'string') {
      return this.isAlreadyPriceFormat(value) ? value : this.makePriceFormat(value);
    }
    return value;
  }

  private isAlreadyPriceFormat(price: string) {
    return price.trim().indexOf('S$') === 0 || price.trim().indexOf('$') === 0;
  }

  private makePriceFormat(origin: string): string {
    return 'S$' + origin.trim();
  }

}
