import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class WaitlistApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {}

    /**
     * GetWaitlist
     * Get waitlist
     */
    public getWaitlistUsingGET(extraHttpRequestParams?: any): Observable<models.GetWaitlistResponse> {
        return this.getWaitlistUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * saveWaitlist
     * Save waitlist
     * @param waitlist waitlist
     */
    public saveWaitlistUsingPOST(waitlist: models.WaitlistDTO, extraHttpRequestParams?: any): Observable<models.SaveWaitlistResponse> {
        return this.saveWaitlistUsingPOSTWithHttpInfo(waitlist, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
         * GetWaitlist
         * Get waitlist
         */
    public getWaitlistUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/waitlist';
        return this.httpClient.get(path, { withCredentials: true });
    }


    /**
     * saveWaitlist
     * Save waitlist
     * @param waitlist waitlist
     */
    public saveWaitlistUsingPOSTWithHttpInfo(waitlist: models.WaitlistDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/waitlist';

        // verify required parameter 'waitlist' is not null or undefined
        if (waitlist === null || waitlist === undefined) {
            throw new Error('Required parameter waitlist was null or undefined when calling saveWaitlistUsingPOST.');
        }

        return this.httpClient.post(path, waitlist, { withCredentials: true });
    }
}
