<div class="col-md-12">
  <div class="collapse-box">
    <div class="collapse-title">
      <h1>Shopping Cart</h1>
    </div>
    <div class="collapse in" *ngIf="showOrderConfirmation">
      <div class="box-content">
        <h4 class="transaction-successful hidden-print">
          <div class="glyphicon glyphicon-ok" aria-hidden="true"></div>
          <!-- Home Delivery -->
          <div *ngIf="orderDetail.deliveryModeCode ===  DeliveryMode.HOME_DELIVERY && orderDetail.orderType !== OrderType.TAILOR">
            Your transaction is successful. Your order will be delivered in around 6 to 8 days.
          </div>
          <!-- Self collection -->
          <div *ngIf="orderDetail.deliveryModeCode !== DeliveryMode.HOME_DELIVERY && orderDetail.orderType !== OrderType.TAILOR">
            Your transaction is successful. A notification email and SMS will be sent when your order is ready for collection.
          </div>
          <div *ngIf="orderDetail.orderType === OrderType.TAILOR">
            Your transaction is successful. Please take your measurements at your selected tailor within two months.
          </div>
        </h4>
        <!-- Personal Details -->
        <table class="table orders">
          <thead>
            <tr>
              <th>Order No.</th>
              <th>Order Date</th>
              <th>NRIC</th>
              <th>Name</th>
              <th>Collection Point</th>
              <th>No. of Items</th>
            </tr>
          </thead>
          <tbody>
            <!-- <tr data-bs-toggle="modal" data-bs-target="#item-pop-up"> -->
            <tr>
              <td class="number">{{ orderDetail.orderRefNo }}</td>
              <td class="date">{{ orderDetail.orderDate }}</td>
              <td class="nric">{{ orderDetail.nric }}</td>
              <td class="name">{{ orderDetail.name }}</td>
              <td class="point">{{ orderDetail.collectionPointName }}</td>
              <td class="qty">{{ orderDetail.totalQuantity }}</td>
            </tr>
          </tbody>
        </table>

        <div class="collection-wrapper">
          <table class="collection" *ngIf="orderDetail.deliveryModeCode != DeliveryMode.COLLECT_AT_TAILOR">
            <tr>
              <td [ngClass]="{ 'single-cp': orderDetail.orderType != OrderType.TAILOR }">
                <div class="collect-location">
                  <table>
                    <tr>
                      <td class="col">
                        <div class="box-content">
                          <h4 class="collection-title">
                            <span class="glyphicon glyphicon-map-marker"></span>{{ orderDetail.deliveryModeCode ===
                            DeliveryMode.HOME_DELIVERY ? 'Delivery Address' :
                            orderDetail.deliveryModeCode === DeliveryMode.SELF_COLLECTION ||
                            orderDetail.deliveryModeCode
                            === DeliveryMode.WALK_IN_SELF_ORDER ? 'Collection Center':'eLocker' }}</h4>
                          <div *ngIf="orderDetail.deliveryMode !== 'Home Delivery'">
                            <b>{{ orderDetail.collectionPointName }}</b>
                          </div>
                          <div *ngIf="orderDetail && orderDetail.collectionPointAddress">{{
                            orderDetail.collectionPointAddress?.blkNo }} {{
                            orderDetail.collectionPointAddress?.streetName
                            }}
                          </div>
                          <div *ngIf="orderDetail && orderDetail.collectionPointAddress">{{
                            orderDetail.collectionPointAddress?.unitNo }}</div>
                          <div *ngIf="orderDetail && orderDetail.collectionPointAddress">{{
                            orderDetail.collectionPointAddress?.buildingName }}</div>
                          <div *ngIf="orderDetail && orderDetail.collectionPointAddress">Singapore {{
                            orderDetail.collectionPointAddress?.postalCode }}</div>
                          <div *ngIf="orderDetail && orderDetail.collectionPointTelephone">
                            <p>
                              <b>Tel:</b> {{orderDetail.collectionPointTelephone}}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td class="col"
                        *ngIf="orderDetail.deliveryModeCode !== DeliveryMode.HOME_DELIVERY && collectionPoint && collectionPoint.openingHrs">
                        <div class="box-content">
                          <h4 class="collection-title">
                            <span class="glyphicon glyphicon-time "></span>Collection Hours</h4>
                          <div *ngFor="let openingHrs of collectionPoint.openingHrs">
                            <strong>{{openingHrs.days}}</strong>
                            <br />{{openingHrs.hours}}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
              <td>
                <div class="collect-location" *ngIf="orderDetail.orderType == OrderType.TAILOR">
                  <table>
                    <tr>
                      <td class="col">
                        <div class="box-content">
                          <h4 class="collection-title">
                            <span class="glyphicon glyphicon-map-marker"></span>{{ getFirstTailor().name }}</h4>
                          <div>
                            <b>{{ getFirstTailor().name }}</b>
                          </div>
                          <div>{{ getFirstTailor().address.blkNo }} {{ getFirstTailor().address.streetName }}
                          </div>
                          <div>{{ getFirstTailor().address.unitNo }}</div>
                          <div>{{ getFirstTailor().address.buildingName }}</div>
                          <div>Singapore {{ getFirstTailor().address.postalCode }}</div>
                          <div>
                            <p>
                              <b>Tel:</b> {{getFirstTailor().telephone}}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td class="col">
                        <div class="box-content">
                          <h4 class="collection-title">
                            <span class="glyphicon glyphicon-time "></span>Opening Hours</h4>
                          <div *ngFor="let openingHrs of getFirstTailor().openingHrs">
                            <strong>{{openingHrs.days}}</strong>
                            <br />{{openingHrs.hours}}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
          <table class="collection" *ngFor="let i of getTailorRowNo()"
            [ngClass]="{ 'single-tailor': getTailorRow(i).length == 1 }">
            <tr>
              <td *ngFor="let tailor of getTailorRow(i);">
                <div class="collect-location">
                  <table>
                    <tr>
                      <td class="col">
                        <div class="box-content">
                          <h4 class="collection-title">
                            <span class="glyphicon glyphicon-map-marker"></span>{{ tailor.name }}</h4>
                          <div>
                            <b>{{ tailor.name }}</b>
                          </div>
                          <div>{{ tailor.address.blkNo }} {{ tailor.address.streetName }}
                          </div>
                          <div>{{ tailor.address.unitNo }}</div>
                          <div>{{ tailor.address.buildingName }}</div>
                          <div>Singapore {{ tailor.address.postalCode }}</div>
                          <div>
                            <p>
                              <b>Tel:</b> {{tailor.telephone}}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td class="col">
                        <div class="box-content">
                          <h4 class="collection-title">
                            <span class="glyphicon glyphicon-time "></span>Opening Hours</h4>
                          <div *ngFor="let openingHrs of tailor.openingHrs">
                            <strong>{{openingHrs.days}}</strong>
                            <br />{{openingHrs.hours}}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </div>

        <!-- Order Details -->
        <table class="table purchases cart">
          <thead>
            <tr>
              <th>Item(s)</th>
              <th
                *ngIf="orderDetail.orderType == OrderType.ADVANCED_ORDER || orderDetail.orderType == OrderType.WALK_IN">
                Size</th>
              <th *ngIf="orderDetail.orderType == OrderType.TAILOR">Tailor</th>
              <th *ngIf="orderDetail.orderType == OrderType.NAME_TAG">Name</th>
              <th class="qty">Qty</th>
              <th class="price">Unit Price</th>
              <th class="price">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of orderDetail.orderItems;">
              <td>
                <div class="item-image">
                  <img src="{{imagestore[item.orderItemId]}}" alt="">
                </div>
                <div class="item-name">{{item.productName}}</div>
              </td>
              <td class="block size"
                *ngIf="orderDetail.orderType == OrderType.ADVANCED_ORDER || orderDetail.orderType == OrderType.WALK_IN">{{
                item.skuName ? item.skuName : 'N.A' }}</td>
              <td class="block tailor" *ngIf="orderDetail.orderType == OrderType.TAILOR">{{ item.tailorName }}</td>
              <td class="block text" *ngIf="orderDetail.orderType == OrderType.NAME_TAG">{{ item.nameTagText }}</td>
              <td class="block qty">{{ item.quantity }}</td>
              <td class="block price">{{ item.unitPrice | priceFormat }}</td>
              <td class="block price total">{{ item.totalPrice | priceFormat }}</td>
            </tr>
          </tbody>
          <tfoot class="cart-footer" style="display: table-row-group;">
            <tr *ngIf="orderDetail.deliveryFee > 0">
              <td colspan="4" align="right" style="text-align: right;">Delivery Fee</td>
              <td style="text-align: right;">{{ orderDetail.deliveryFee | priceFormat }}</td>
            </tr>
            <tr class="{{orderDetail.deliveryFee > 0 ? 'balance-after' : ''}}">
              <td colspan="4" align="right" style="text-align: right;">Total Price</td>
              <td style="text-align: right;">{{ orderDetail.totalAmount | priceFormat }}</td>
            </tr>
            <tr class="available-credit">
              <td colspan="4" align="right" style="text-align: right;">Credit Before Checkout</td>
              <td style="text-align: right;">{{ userProfileService.creditSummary.creditBalance +
                orderDetail.totalAmount | priceFormat }}</td>
            </tr>
            <tr class="balance-after">
              <td colspan="4" align="right" style="text-align: right;">Credit Balance</td>
              <td style="text-align: right;">{{ (userProfileService.creditSummary.creditBalance) | priceFormat }}</td>
            </tr>
          </tfoot>
        </table>


        <strong>Terms</strong>
        <table>
          <tr>
            <td valign="top" width="16">1. </td>
            <td valign="top">Purchased items are non-refundable.</td>
          </tr>
          <tr>
            <td valign="top" width="16">2. </td>
            <td valign="top">Purchased items are to be collected within 30 days (for Self-Collection) 
              after they are ready for collection.</td>
          </tr>
          <tr>
            <td valign="top" width="16">3. </td>
            <td valign="top">Purchased items will be delivered within 6 – 8days (for Home Delivery)</td>
          </tr>
          <tr>
            <td valign="top" width="16"></td>
            <td valign="top">
              <ul>
                <li>Acknowledgement (signature) would be required by yourself or anyone residing in your unit upon the delivery of the parcel.</li>
                <li>The delivery timing is 9am to 6pm (weekday) and 9am to 12pm (Sat). The courier will arrange with you for a 2nd delivery attempt if the 1st delivery is unsuccessful.</li>
                <li>If the courier is not able to contact you to make a scheduled delivery or the 2nd scheduled delivery is also unsuccessful, please note that you may no longer collect this order or rearrange delivery, your order will be forfeited and the credits will not be refunded.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td valign="top" width="16">4. </td>
            <td valign="top">Purchased items are not supposed to be Sold / Transferred / Distributed to any other party.
              Non-compliance will result in disciplinary actions taken.
            </td>
          </tr>
          <tr>
            <td valign="top" width="16">5. </td>
            <td valign="top">For tailored uniforms, officers are to proceed to the tailors for 
              measurement taking within two months upon order confirmation else items and credits will be forfeited.</td>
          </tr>
          <tr>
            <td valign="top" width="16">6. </td>
            <td valign="top">Orders may be cancelled or partially fulfilled in unforeseen circumstances. 
              If such a situation occurs, you will be alerted soonest .</td>
          </tr>
        </table>
        <br />
        <button type="button" class="btn voffset2 hidden-print" (click)="printOrder()">
          <span class="glyphicon glyphicon-print" aria-hidden="true"></span>Print
        </button>
      </div>
    </div>
  </div>
</div>