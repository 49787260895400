/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class ReportApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {}

    /**
     * generateReport
     * Generate report
     * @param request request
     */
    public generateReportUsingPOST(request: models.ReportRequestDTO, extraHttpRequestParams?: any): Observable<models.GenerateReportResponse> {
        return this.generateReportUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getProductTypeSelections
     * Get product type selections for report
     */
    public getProductTypeSelectionsUsingGET(extraHttpRequestParams?: any): Observable<models.GetProductTypeSelectionsForReportResponse> {
        return this.getProductTypeSelectionsUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * reportFormatSelections
     * Get report format selections
     */
    public getReportFormatSelectionsUsingGET(extraHttpRequestParams?: any): Observable<models.GetReportFormatSelectionsResponse> {
        return this.getReportFormatSelectionsUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * reportOrderStatusSelections
     * Get report order status selections
     */
    public getReportOrderStatusSelectionsUsingGET(extraHttpRequestParams?: any): Observable<models.GetReportOrderStatusSelectionsResponse> {
        return this.getReportOrderStatusSelectionsUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getReportRoleSelections
     * Get report role selections
     */
    public getRoleSelectionsUsingGET(extraHttpRequestParams?: any): Observable<models.GetRoleSelectionsResponse> {
        return this.getRoleSelectionsUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getServiceUnitSelections
     * Get service unit selections for report
     */
    public getServiceUnitSelectionsUsingGET2(extraHttpRequestParams?: any): Observable<models.GetServiceUnitSelectionsForReportResponse> {
        return this.getServiceUnitSelectionsUsingGET2WithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getSkuSelections
     * Get SKU selections for report
     */
    public getSkuSelectionsUsingGET(extraHttpRequestParams?: any): Observable<models.GetSkuSelectionsForReportResponse> {
        return this.getSkuSelectionsUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * generateReport
     * Generate report
     * @param request request
     */
    public generateReportUsingPOSTWithHttpInfo(request: models.ReportRequestDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/reports';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling generateReportUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * getProductTypeSelections
     * Get product type selections for report
     */
    public getProductTypeSelectionsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/reports/productTypeSelections';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * reportFormatSelections
     * Get report format selections
     */
    public getReportFormatSelectionsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/reports/reportFormatSelections';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * reportOrderStatusSelections
     * Get report order status selections
     */
    public getReportOrderStatusSelectionsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/reports/reportOrderStatusSelections';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getReportRoleSelections
     * Get report role selections
     */
    public getRoleSelectionsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/reports/reportRoleSelections';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getServiceUnitSelections
     * Get service unit selections for report
     */
    public getServiceUnitSelectionsUsingGET2WithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/reports/serviceUnitSelections';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getSkuSelections
     * Get SKU selections for report
     */
    public getSkuSelectionsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/reports/skuSelections';

        return this.httpClient.get(path, { withCredentials: true });
    }

}
