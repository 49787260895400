import { Component, OnInit } from '@angular/core';
import { MaintenanceService } from 'app/services/maintenance.service';
import { MaintenanceModel } from './maintenance.model';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-live-maintenance',
  templateUrl: './live-maintenance.component.html',
  styleUrls: ['./live-maintenance.component.css']

})
export class LiveMaintenanceComponent implements OnInit {

  maintenanceMessages: MaintenanceModel = {};

  constructor(
    private maintenanceService: MaintenanceService,
    private sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    // this.maintenanceMessages.maintenanceTitle = this.sanitizer.bypassSecurityTrustHtml("ANNOUNCEMENT <span style ='color: #ff0000';>people</span>") as string;
    this.maintenanceService.getMaintenanceFromS3().subscribe(x => {
      this.maintenanceMessages.maintenanceTitle = this.sanitizer.bypassSecurityTrustHtml(x.maintenanceTitle) as string;
      this.maintenanceMessages.maintenanceContent = this.sanitizer.bypassSecurityTrustHtml(x.maintenanceContent) as string;
      this.maintenanceMessages.maintenanceRemarks = this.sanitizer.bypassSecurityTrustHtml(x.maintenanceRemarks) as string;
     });

    console.log("Maintenace Details:" + this.maintenanceMessages)
  }

}
