import { GetProfileResult } from './../shared/backend-api/emart/model/GetProfileResult';
import { GetCreditSummaryResult } from './../shared/backend-api/emart/model/GetCreditSummaryResult';
import { UpdateProfileResult } from './../shared/backend-api/emart/model/UpdateProfileResult';
import { CreditTransactionDTO } from './../shared/backend-api/emart/model/CreditTransactionDTO';
import { ServicemanApi } from './../shared/backend-api/emart/api/ServicemanApi';
import { Injectable } from '@angular/core';
import { Observable, of, catchError, throwError, tap, flatMap } from 'rxjs';
import { UpdateProfileRequest } from './../shared/backend-api/emart';
import { CommonUtils } from './../shared/utilities/common-utils';

// Singleton data storage for eMart user's profile.
// Read and write all his credit balance, mobile, email, etc related ojects here.
@Injectable()
export class UserProfileService {

  public userProfile: GetProfileResult;
  public creditSummary: GetCreditSummaryResult;
  public creditHistorys: CreditTransactionDTO[];
  constructor(private servicemanApi: ServicemanApi) { }

  getCreditHistory(from: string, to: string): Observable<any> {
    return this.servicemanApi.getCreditHistoryUsingGET(from, to).pipe(tap(
      response => {
        //when status == 200 & 204, execute happy flow
        if (response.status == 200 || response.status == 204){ 
          this.creditHistorys = response.data as CreditTransactionDTO[];

        // other than 200, 204, 401, 403, raise error
        }else if (response.status != 401 && response.status != 403){
          throw throwError(response);
        }
      }
    ), catchError(
      err => {
        return err;
      }
      ));
  }

  /**
   *
   * @param {UpdateProfileRequest} requestParams
   * @returns {Observable<any>}
   * @memberof UserProfileService
   */
  updateProfile(requestParams: UpdateProfileRequest): Observable<any> {

    return this.servicemanApi.updateProfileUsingPOST(requestParams).pipe(tap(
      response => {
        //when status == 200 & 204, execute happy flow
        if (response.status == 200 || response.status == 204){ 
          // GetProfileResult should be same with UpdateProfileResult
          // Current backend API could not return latest profile. Have to call GET again.
          // this.userProfile = response.data as GetProfileResult;

        // other than 200, 204, 401, 403, raise error
        }else if (response.status != 401 && response.status != 403){
          throw throwError(response);
        }
      }
    ), catchError(
      err => {
        return err;
      }
    )).pipe(flatMap(() => this.forceLoadUserProfile()));
  }

  loadCreditSummary(): Observable<any> {
    if (this.creditSummary) {
      return of('No need to reload creditSummary.');
    } else {
      return this.forceLoadCreditSummary();
    }
  }

  /**
   *
   * @returns {Observable<any>}
   * @memberof UserProfileService
   */
  forceLoadCreditSummary(): Observable<any> {

    return this.servicemanApi.getCreditSummaryUsingGET().pipe(tap(
      payload => {
        //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.creditSummary = payload.data as GetCreditSummaryResult;

        // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
      ));
  }


  loadUserProfile(): Observable<any> {
    if (this.userProfile) {
      return of('No need to reload UserProfile.');
    } else {
      return this.forceLoadUserProfile();
    }
  }

  /**
   *
   * @returns {Observable<any>}
   * @memberof UserProfileService
   */
  forceLoadUserProfile(): Observable<any> {
    return this.servicemanApi.getProfileUsingGET().pipe(tap(
      payload => {
        //when status == 200 & 204, execute working part
        if (payload.status == 200 || payload.status == 204){ 
          this.userProfile = payload.data as GetProfileResult;

        // other than 200, 204, 401, 403, throws error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        throw throwError(err);
      }
      ));
  }



}


