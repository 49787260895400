/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as models from './models';

/**
 * Error object definition. It will be used in an error response
 */
export interface SaveCartItemResponseError {
    /**
     * Backend error code
     */
    code: SaveCartItemResponseError.CodeEnum;

    /**
     * Object containing detailed information for the error
     */
    source?: models.AbstractResponseErrorSource;

    /**
     * Error message that should be displayed on the front-end
     */
    title: string;

}
export namespace SaveCartItemResponseError {
    export enum CodeEnum {
        CART = <any> 'INVALID_CART'
    }
}
