import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AddressDTO } from 'app/shared/backend-api/emart';

import { UserLocationDTO } from '../../../shared/backend-api/emart/index';
import { StoreConfigService } from './../../../services/store-config.service';

@Component({
  selector: 'app-collection-point-list',
  templateUrl: './collection-point-list.component.html',
  styleUrls: ['./collection-point-list.component.css']
})
export class CollectionPointListComponent implements OnInit {

  private router: Router;

  public viewCP = true;
  public viewEL = true;
  public viewWI = true;

  // Pagination
  private currentPage = 1;
  private itemPerPage = 10;
  private pageCount = 1;
  private nextPaginationDisabled = false;
  private prevPaginationDisabled = false;
  // ---

  public allItems = null;

  constructor(
    private storeConfigService: StoreConfigService,
  ) { }

  ngOnInit() {
    this.currentPage = 1;

    this.storeConfigService.loadAllCollectionPoints().subscribe(() => {
      this.filterItems();
    });
  }

  filterItems() {
    if (!this.storeConfigService.allCollectionPoints) {
      return;
    }

    this.allItems = this.storeConfigService.allCollectionPoints.filter(
      cp => (this.viewCP && cp.isCollectionPoint) || (this.viewEL && cp.isElocker) || (this.viewWI && cp.isWalkInCollectionPoint)
    );

    this.updatePagination();
  }

  getCollectionModes(cp: UserLocationDTO) {
    const collectionModes = Array<String>();
    if (cp.isCollectionPoint) {
      collectionModes.push('Self Collection');
    }
    if (cp.isElocker) {
      collectionModes.push('eLocker');
    }
    if (cp.isWalkInCollectionPoint) {
      collectionModes.push('Walk-in');
    }
    return collectionModes.join(', ');
  }

  // Pagination
  getItemsToDisplay(): UserLocationDTO[] {
    if (this.allItems.length > 0) {
      const firstItemIndex = (this.currentPage - 1) * this.itemPerPage;
      const endItemIndex = this.currentPage * this.itemPerPage;
      return this.allItems.slice(firstItemIndex, endItemIndex);
    } else {
      return [];
    }
  }

  updatePagination() {
    try {
      this.pageCount = Math.ceil(this.allItems.length / this.itemPerPage);
      this.currentPage = this.currentPage > this.pageCount ? this.pageCount : this.currentPage;
      if (this.currentPage < 1) {
        this.currentPage = 1;
      }
      this.nextPaginationDisabled = this.currentPage * this.itemPerPage >= this.allItems.length;
      this.prevPaginationDisabled = this.currentPage <= 1;
    } catch (e) {
      this.pageCount = 1;
      this.currentPage = 1;
      this.nextPaginationDisabled = true;
      this.prevPaginationDisabled = true;
    }
  }

  gotoPreviousPage() {
    this.gotoPage(Number(this.currentPage) - 1);
  }

  gotoNextPage() {
    this.gotoPage(Number(this.currentPage) + 1);
  }

  gotoFirstPage() {
    this.gotoPage(1);
  }

  gotoLastPage() {
    this.gotoPage(this.pageCount);
  }

  gotoPage(page: number) {

    if (page > 0 && page <= this.pageCount) {
      this.currentPage = page;
      this.updatePagination();
    }
  }
  // ---

  viewMap(address: AddressDTO) {
    let url = 'https://www.google.com.sg/maps/place/';
    url += address.blkNo.split(' ').join('+') + '+' + address.streetName.split(' ').join('+') + '+';
    if (address.buildingName) {
      url += address.buildingName.split(' ').join('+') + '+';
    }
    url += 'Singapore+' + address.postalCode;
    url = encodeURI(url);
    window.open(url, '_blank');
  }

}
