/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class ServicemanManagementApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {}

    /**
     * getGenderSelectionsForEnrollment
     * Get gender selections for enrollment
     */
    public getGenderSelectionsUsingGET1(extraHttpRequestParams?: any): Observable<models.GetGenderSelectionsForEnrollmentResponse> {
        return this.getGenderSelectionsUsingGET1WithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getServiceTypeSelections
     * Get service type selections
     */
    public getServiceTypeSelectionsUsingGET1(extraHttpRequestParams?: any): Observable<models.GetServiceTypeSelectionsForEnrollmentResponse> {
        return this.getServiceTypeSelectionsUsingGET1WithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getServiceUnitSelectionsForEnrollment
     * Get service unit selections for enrollment
     */
    public getServiceUnitSelectionsUsingGET1(extraHttpRequestParams?: any): Observable<models.GetServiceUnitSelectionsForEnrollmentResponse> {
        return this.getServiceUnitSelectionsUsingGET1WithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getServiceman
     * Get Serviceman
     * @param request request
     */
    public getServicemanUsingPOST(request: models.GetServicemanRequest, extraHttpRequestParams?: any): Observable<models.GetServicemanResponse> {
        return this.getServicemanUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * queryServiceman
     * Query Serviceman
     * @param request request
     */
    public queryServicemanUsingPOST(request: models.QueryServicemanRequest, extraHttpRequestParams?: any): Observable<models.QueryServicemanResponse> {
        return this.queryServicemanUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * saveServiceman
     * Save Serviceman
     * @param request request
     */
    public saveServicemanUsingPOST(request: models.SaveServicemanRequest, extraHttpRequestParams?: any): Observable<models.SaveServicemanResponse> {
        return this.saveServicemanUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * getGenderSelectionsForEnrollment
     * Get gender selections for enrollment
     */
    public getGenderSelectionsUsingGET1WithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/servicemanManagement/genderSelections';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getServiceTypeSelections
     * Get service type selections
     */
    public getServiceTypeSelectionsUsingGET1WithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/servicemanManagement/serviceTypeSelections';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getServiceUnitSelectionsForEnrollment
     * Get service unit selections for enrollment
     */
    public getServiceUnitSelectionsUsingGET1WithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/servicemanManagement/serviceUnitSelections';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getServiceman
     * Get Serviceman
     * @param request request
     */
    public getServicemanUsingPOSTWithHttpInfo(request: models.GetServicemanRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/servicemanManagement/servicemen';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling getServicemanUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * queryServiceman
     * Query Serviceman
     * @param request request
     */
    public queryServicemanUsingPOSTWithHttpInfo(request: models.QueryServicemanRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/servicemanManagement/servicemen/query';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling queryServicemanUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * saveServiceman
     * Save Serviceman
     * @param request request
     */
    public saveServicemanUsingPOSTWithHttpInfo(request: models.SaveServicemanRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/servicemanManagement/servicemen/save';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveServicemanUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

}
