/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class CartApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient,) {
        
    }

    /**
     * GetCart
     * Get cart
     */
    public getCartUsingGET(extraHttpRequestParams?: any): Observable<models.GetCartResponse> {
        return this.getCartUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * saveCart
     * Save cart
     * @param cart cart
     */
    public saveCartUsingPOST(cart: models.CartDTO, extraHttpRequestParams?: any): Observable<models.SaveCartResponse> {
        return this.saveCartUsingPOSTWithHttpInfo(cart, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * ValidateCart
     * Validate cart
     * @param request request
     */
    public validateCartUsingPOST(request: models.CartDTO, extraHttpRequestParams?: any): Observable<models.ValidateCartResponse> {
        return this.validateCartUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * GetCart
     * Get cart
     */
    public getCartUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/cart';

        return this.httpClient.get(path, {withCredentials: true});
    }

    /**
     * saveCart
     * Save cart
     * @param cart cart
     */
    public saveCartUsingPOSTWithHttpInfo(cart: models.CartDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/cart';

        // verify required parameter 'cart' is not null or undefined
        if (cart === null || cart === undefined) {
            throw new Error('Required parameter cart was null or undefined when calling saveCartUsingPOST.');
        }
        
        return this.httpClient.post(path, cart, {withCredentials: true});
    }

    /**
     * ValidateCart
     * Validate cart
     * @param request request
     */
    public validateCartUsingPOSTWithHttpInfo(request: models.CartDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/cart/validate';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling validateCartUsingPOST.');
        }

        return this.httpClient.post(path, request, {withCredentials: true});
    }

}
