/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class CatalogueApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient,) {}

    /**
     * getCategories
     * Get catalogue categories
     */
    public getCategoriesUsingGET(extraHttpRequestParams?: any): Observable<models.GetCategoriesResponse> {
        return this.getCategoriesUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                console.log('getCategoriesUsingGETWithHttpInfo');
                console.log(response);
                return response;
            }));
    }

    /**
     * getProductCaps
     * Get product caps
     */
    public getProductCapsUsingGET(extraHttpRequestParams?: any): Observable<models.GetProductCapsResponse> {
        return this.getProductCapsUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * GetProductDetail
     * Get Product Detail
     * @param id Product ID
     */
    public getProductDetailUsingGET(id: number, extraHttpRequestParams?: any): Observable<models.GetProductDetailResponse> {
        return this.getProductDetailUsingGETWithHttpInfo(id, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * GetProducts
     * Get products
     * @param productType Product type: &lt;br&gt;&lt;ul&gt;&lt;li&gt;1: Normal Product&lt;/li&gt; &lt;li&gt;2: Name Tag&lt;/li&gt; &lt;li&gt;3: Tailor Product&lt;/li&gt; &lt;/ul&gt;
     */
    public getProductsUsingGET(productType: number, extraHttpRequestParams?: any): Observable<models.GetProductsResponse> {
        return this.getProductsUsingGETWithHttpInfo(productType, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * getCategories
     * Get catalogue categories
     */
    public getCategoriesUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/catalogue/categories';

        return this.httpClient.get(path, {withCredentials: true});
    }

    /**
     * getProductCaps
     * Get product caps
     */
    public getProductCapsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/catalogue/productCaps';

        return this.httpClient.get(path, {withCredentials: true});
    }

    /**
     * GetProductDetail
     * Get Product Detail
     * @param id Product ID
     */
    public getProductDetailUsingGETWithHttpInfo(id: number, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/catalogue/products/${id}'
            .replace('${' + 'id' + '}', String(id));

        return this.httpClient.get(path, {withCredentials: true});
    }

    /**
     * GetProducts
     * Get products
     * @param productType Product type: &lt;br&gt;&lt;ul&gt;&lt;li&gt;1: Normal Product&lt;/li&gt; &lt;li&gt;2: Name Tag&lt;/li&gt; &lt;li&gt;3: Tailor Product&lt;/li&gt; &lt;/ul&gt;
     */
    public getProductsUsingGETWithHttpInfo(productType: number, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/catalogue/products';

        let queryParameters = new HttpParams();

        // verify required parameter 'productType' is not null or undefined
        if (productType === null || productType === undefined) {
            throw new Error('Required parameter productType was null or undefined when calling getProductsUsingGET.');
        }
        if (productType !== undefined) {
            queryParameters = queryParameters.append('productType', <any>productType);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true});
    }

}
