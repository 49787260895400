/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class UnitManagementApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {}

    /**
     * getServiceTypeSelectionsForUnitManagement
     * Get service type selections
     */
    public getServiceTypeSelectionsForUnitManagementUsingGET(extraHttpRequestParams?: any): Observable<models.GetServiceTypeSelectionsForUnitManagementResponse> {
        return this.getServiceTypeSelectionsForUnitManagementUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getUnitGroups
     * Get unit groups
     */
    public getUnitGroupsUsingGET(extraHttpRequestParams?: any): Observable<models.GetUnitGroupsResponse> {
        return this.getUnitGroupsUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getUnits
     * Get units
     */
    public getUnitsUsingGET(extraHttpRequestParams?: any): Observable<models.GetUnitsResponse> {
        return this.getUnitsUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * saveUnitGroup
     * Save new or update existing unit group
     * @param request Request body for save unit group
     */
    public saveUnitGroupUsingPOST(request: models.UnitGroupDTO, extraHttpRequestParams?: any): Observable<models.SaveUnitGroupResponse> {
        return this.saveUnitGroupUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * saveUnit
     * Add new or update unit
     * @param request request
     */
    public saveUnitUsingPOST(request: models.UnitDTO, extraHttpRequestParams?: any): Observable<models.SaveUnitResponse> {
        return this.saveUnitUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * getServiceTypeSelectionsForUnitManagement
     * Get service type selections
     */
    public getServiceTypeSelectionsForUnitManagementUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/unitManagement/serviceTypeSelections';
        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getUnitGroups
     * Get unit groups
     */
    public getUnitGroupsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/unitManagement/unitGroups';
        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getUnits
     * Get units
     */
    public getUnitsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/unitManagement/units';
        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * saveUnitGroup
     * Save new or update existing unit group
     * @param request Request body for save unit group
     */
    public saveUnitGroupUsingPOSTWithHttpInfo(request: models.UnitGroupDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/unitManagement/unitGroups';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveUnitGroupUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * saveUnit
     * Add new or update unit
     * @param request request
     */
    public saveUnitUsingPOSTWithHttpInfo(request: models.UnitDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/unitManagement/units';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveUnitUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

}
