import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PersonaSelectionService } from 'app/services/persona-selection.service';
import { SubmitDepartmentRequest } from 'app/shared/backend-api/emart';

declare const jQuery: any;

@Component({
  selector: 'app-persona-selection',
  templateUrl: './persona-selection.component.html',
  styleUrls: ['./persona-selection.component.css']
})

export class PersonaSelectionComponent implements OnInit {

  multiPersonaDepartment: string[];
  department: string;
  subscribe: any;
  constructor(
    private personaSelectionService: PersonaSelectionService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.multiPersonaDepartment = [];

    this.personaSelectionService.forceLoadServicemanServiceUnit().subscribe(res => {

      // filter active persona, then map out department
      this.multiPersonaDepartment = res.data.servicemanDepartments.filter(ele=>ele.status=="A").map(ele=>ele.department);

      // Show popup
      jQuery('#department-popup').modal('show');
    })
  }

  loadPersona(department: string) {
    const postbody = <SubmitDepartmentRequest>{};
    postbody.department = department;

    // Submit request to backend
    this.personaSelectionService.submitDepartment(postbody)
      .pipe().subscribe(
        response => {
          if (response.status === 200 || response.status === 204){
            this.router.navigate(['/dashboard']);
          }
        },
        err => {
          this.router.navigate(['/general-error']);
        })
  }
}
