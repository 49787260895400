import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';
import { AuthService } from './auth/auth.service';

import { PreloaderService } from './auth/preloader.service';
import { BroadcastService } from './services/broadcast-service';
import { UserProfileService } from './services/user-profile.service';
import { Constants } from './shared/global-constants/constants';

declare const jQuery: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {

  isAuthenticated = false;
  private authenticationSub: Subscription;
  private broadCastExtendSub: Subscription = new Subscription();
  private broadCastLogoutSub: Subscription = new Subscription();
  timeoutDifferenceSub: Subscription;
  timeoutDiff = environment.TIMEOUT_BUFFER * 60 * 1000;

  //set publicPage to true to avoid flicker
  publicPage = undefined;
  private routerSub : Subscription;

  wogaaTest=false;
  wogaaProd=false;
  wogaalink='';

  showFooter = undefined;

  constructor(private userProfileService: UserProfileService, 
    private preloaderService: PreloaderService, 
    public authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private broadCastService: BroadcastService) {
  }

  ngOnInit() {
    this.enableLogs();
    this.checkLogin();
    /****************************************************************************************
    *         - This chunk of code will retrieve data property from routing module.         *
    *         - publicPage value will pass through app.component.html.                      *
    *         - if it is public page, it uses public page header.                           *
    *         - if it is protected page, it uses protected page header.                     *
    ****************************************************************************************/
     this.routerSub = 
     this.router.events.subscribe((evt) => {
       if (evt instanceof NavigationEnd) {
          let data = this.activatedRoute.firstChild.snapshot.data;
          console.log('route events data ', JSON.stringify(data));
          if (data && data.public) {
            this.publicPage = true;
            this.showFooter = true;
          }
          else { 
            //  this.preloaderService.load().subscribe();
            this.publicPage = false;
            this.showFooter = true;
          }
       }
     });

     // Subscribe to update whether user has extended their session when multiple tabs opened
     this.broadCastExtendSub.add(
      this.broadCastService.messagesOfType(Constants.BROADCAST_MESSAGES.EXTEND.type).subscribe(
        message => {
          /* 
            Close the session extend pop up modal in the other tabs
            when user extends the session
          */
          if(jQuery('#session-extension-pop-up').hasClass('show')) {
            jQuery('#session-extension-pop-up').modal('hide');
          }
          
          this.authService.sessionDuration = this.authService.sessionDuration + environment.SESSION_DURATION_IN_MINUTES;
        }));

      // Subscribe to update whether user has logout from their session when multiple tabs opened
      this.broadCastLogoutSub = this.broadCastService.messagesOfType(Constants.BROADCAST_MESSAGES.LOGOUT.type).subscribe(
        message => {
          this.authService.clearSession(true);
         });


     this.timeoutDifferenceSub = this.authService.countDownTimerSubject.subscribe(countDown => {
      this.timeoutDiff = countDown;
     });
  }

  onActivate(componentRef?: any) {
    /*You can put if-else condition to reload data on certain pages only*/
    this.preloaderService.load().subscribe();
  }

  ngOnDestroy() {

    /*** 
     *  Unsubscribe from the broadcast channel when it gets destroyed
    */
    if(this.authenticationSub) {
      this.authenticationSub.unsubscribe();
    }
    if(this.routerSub){
      this.routerSub.unsubscribe();
    }
    if(this.broadCastExtendSub) {
      this.broadCastExtendSub.unsubscribe();
    }
    if(this.timeoutDifferenceSub) {
      this.timeoutDifferenceSub.unsubscribe();
    }

    if(this.broadCastLogoutSub) {
      this.broadCastLogoutSub.unsubscribe();
    }

  }

  // get login status
  checkLogin() {
    this.authenticationSub = this.authService.isAuthenticated.subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
    });
  }

  extendSession() {
    // close pop up and reset idle settings
    this.authService.extendSession();
    jQuery('#session-extension-pop-up').modal('hide');
  }

  enableLogs() {
    if (!environment.enableLogs) {
      window.console.log = window.console.warn = window.console.info = window.console.error = function () {
        // Don't log anything.
      };
    }
  }
}

