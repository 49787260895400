import { Component, OnInit, Input, OnDestroy, AfterViewInit } from '@angular/core';
import { OrderDTO, OrderItemDTO } from '../../../shared/backend-api/emart';
import { CurrentOrdersService } from '../../../services/current-orders.service';
import { Constants } from '../../../shared/global-constants/constants';
import { StoreConfigService } from '../../../services/store-config.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-order-detail-print',
  templateUrl: './order-detail-print.component.html',
  styleUrls: ['./order-detail-print.component.css']
})
export class OrderDetailPrintComponent implements OnInit, AfterViewInit, OnDestroy {
  orderToViewDetail: OrderDTO;
  imageStore: {};
  DeliveryMode = Constants.DeliveryMode;
  OrderType = Constants.OrderType;
  OrderStatus = Constants.OrderStatus;

  constructor(public currentOrdersService: CurrentOrdersService,
    public storeConfigService: StoreConfigService,
    private router: Router) {
    this.orderToViewDetail = this.currentOrdersService.orderPrint.order;
    this.imageStore = this.currentOrdersService.orderPrint.imageStore;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    console.log("Starting to print ...", this.orderToViewDetail);

    setTimeout(() => {
      window.print();
    }, 1000);
    
    setTimeout(() => {
      this.router.navigate([this.currentOrdersService.orderPrint.prevPath]);
    }, 1000);
  }

  ngOnDestroy() {
    this.currentOrdersService.orderPrint.imageStore = {};
    this.currentOrdersService.orderPrint.order = {};

  }

  getExpiredCredits(cancelledAmt: number, cancelledItems: OrderItemDTO[]): number {
    let totalAmount = 0;

    if (cancelledItems.length > 0) {
      for (const item of cancelledItems) {
        totalAmount += item.totalPrice;
      }
    }

    if (totalAmount > cancelledAmt) {
      return totalAmount - cancelledAmt;
    }

    return 0;
  }

}
