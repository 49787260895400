<div id="item-detail-pop-up" class="modal fade hidden-print" role="dialog">
  <div class="modal-dialog modal-lg">

    <!-- Modal content-->
    <div id="printModal" class="modal-content" *ngIf="orderToViewDetail && profile">
      <div class="modal-header">
        <h1 class="modal-title">Order No: {{orderToViewDetail?.orderRefNo}}</h1>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="order-info">
          <table class="details">
            <tr>
              <td class="left">Name</td>
              <td class="right">{{orderToViewDetail?.name}}</td>
            </tr>
            <tr>
              <td class="left">NRIC</td>
              <td class="right">{{orderToViewDetail?.nric}}</td>
            </tr>
          </table>
          <table class="details">
            <tr>
              <td class="left">No. of Items</td>
              <td class="right">{{orderToViewDetail?.totalQuantity}}</td>
            </tr>
            <tr>
              <td class="left">Order Date</td>
              <td class="right">{{orderToViewDetail?.orderDate}}</td>
            </tr>
          </table>
          <table class="details">
            <tr>
              <td class="left">{{orderToViewDetail?.deliveryModeCode === DeliveryMode.HOME_DELIVERY || orderToViewDetail?.deliveryModeCode
                === DeliveryMode.COLLECT_AT_TAILOR ? 'Collection Mode' : 'Collection Point'}}</td>
              <td class="right">{{orderToViewDetail?.collectionPointName}}</td>
            </tr>
            <tr *ngIf="orderToViewDetail?.deliveryModeCode === DeliveryMode.HOME_DELIVERY">
              <td class="left">Address</td>
              <td class="right">
                <div>{{orderToViewDetail?.collectionPointAddress.blkNo}}
                  {{orderToViewDetail?.collectionPointAddress.streetName}}</div>
                <div>{{orderToViewDetail?.collectionPointAddress.unitNo}}</div>
                <div>{{orderToViewDetail?.collectionPointAddress.buildingName}}</div>
                <div>Singapore {{orderToViewDetail?.collectionPointAddress.postalCode}}</div>
              </td>
            </tr>
          </table>
        </div>
        <table class="table purchases" *ngIf="orderToViewDetail?.orderItems.length > 0" id="availableItems">
          <thead>
            <tr>
              <th class="check">
                <div class="checkbox-square checkbox" *ngIf="hasPurchasable">
                  <input type="checkbox" id="select-item-detail-all" [checked]="allItemsCheckedStatus"
                    (change)="allItemsSelected();">
                  <label class="cbox" for="select-item-detail-all"></label>
                </div>
              </th>
              <th>Item(s)</th>
              <th
                *ngIf="orderToViewDetail?.orderType == OrderType.ADVANCED_ORDER || orderToViewDetail?.orderType == OrderType.WALK_IN">
                Size</th>
              <th *ngIf="orderToViewDetail?.orderType == OrderType.TAILOR">Tailor</th>
              <th *ngIf="orderToViewDetail?.orderType == OrderType.NAME_TAG">Name</th>
              <th class="qty">Qty</th>
              <th class="price">Unit Price</th>
              <th class="price">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of orderToViewDetail?.orderItems">
              <td class="checkbox-width">
                <div class="checkbox-square checkbox" *ngIf="item.purchasable">
                  <input type="checkbox" id="select-item-detail-{{item.orderItemId}}" [checked]="!!item[ITEM_CHECKED_KEY]"
                    (change)="singleItemSelected(item);">
                  <label class="cbox" for="select-item-detail-{{item.orderItemId}}"></label>
                </div>
              </td>
              <td class="item">
                <div class="item-image">
                  <img [src]="imageStore[item.orderItemId] || storeConfigService.dummyImgPath" alt="">
                </div>
                <div class="item-name">{{item.productName}}</div>
              </td>
              <td class="block size"
                *ngIf="orderToViewDetail?.orderType == OrderType.ADVANCED_ORDER || orderToViewDetail?.orderType == OrderType.WALK_IN">
                {{ item.skuName ? item.skuName : 'N.A' }}</td>
              <td class="block tailor" *ngIf="orderToViewDetail?.orderType == OrderType.TAILOR">{{ item.tailorName }}</td>
              <td class="block text" *ngIf="orderToViewDetail?.orderType == OrderType.NAME_TAG">{{ item.nameTagText }}
              </td>
              <td class="block qty">{{item.quantity}}</td>
              <td class="block price">{{item.unitPrice | priceFormat}}</td>
              <td class="block price total">{{item.totalPrice | priceFormat}}</td>
            </tr>
          </tbody>
          <tfoot class="cart-footer">
            <tr *ngIf="orderToViewDetail?.deliveryFee > 0">
              <td colspan="5" align="right" style="text-align: right;">Delivery Fee</td>
              <td style="text-align: right;">{{orderToViewDetail?.deliveryFee | priceFormat}}</td>
            </tr>
            <tr class="{{orderToViewDetail?.deliveryFee > 0 ? 'balance-after' : ''}}">
              <td colspan="5" align="right" style="text-align: right;">Total Price</td>
              <td style="text-align: right;">{{orderToViewDetail?.totalAmount | priceFormat}}</td>
            </tr>
          </tfoot>
        </table>
        <table class="table purchases" *ngIf="orderToViewDetail?.statusCode === OrderStatus.CANCELLED && orderToViewDetail?.deliveryFee > 0">
          <tfoot class="cart-footer">
          <tr>
            <td width="86.2%" align="right" style="text-align: right;">Delivery Fee</td>
            <td style="text-align: right;">{{orderToViewDetail?.deliveryFee | priceFormat}}</td>
          </tr>
          <tr class="balance-after">
            <td align="right" style="text-align: right;">Total Price</td>
            <td style="text-align: right;">{{orderToViewDetail?.totalAmount | priceFormat}}</td>
          </tr>
          </tfoot>
        </table>
        <h2 *ngIf="orderToViewDetail?.cancelledItems.length > 0">Item(s) Cancelled</h2>
        <table *ngIf="orderToViewDetail?.cancelledItems.length > 0" class="table purchases" id="cancelledItems">
          <thead>
            <tr>
              <th class="check"></th>
              <th>Item(s)</th>
              <th
                *ngIf="orderToViewDetail?.orderType == OrderType.ADVANCED_ORDER || orderToViewDetail?.orderType == OrderType.WALK_IN">
                Size</th>
              <th *ngIf="orderToViewDetail?.orderType == OrderType.TAILOR">Tailor</th>
              <th *ngIf="orderToViewDetail?.orderType == OrderType.NAME_TAG">Name</th>
              <th class="qty">Qty</th>
              <th class="price">Unit Price</th>
              <th class="price">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of orderToViewDetail?.cancelledItems">
              <td class="checkbox-width"></td>
              <td class="item">
                <div class="item-image">
                  <img [src]="imageStore[item.orderItemId] || storeConfigService.dummyImgPath" alt="">
                </div>
                <div class="item-name">{{item.productName}}</div>
              </td>
              <td class="block size"
                *ngIf="orderToViewDetail?.orderType == OrderType.ADVANCED_ORDER || orderToViewDetail?.orderType == OrderType.WALK_IN">
                {{ item.skuName ? item.skuName : 'N.A' }}</td>
              <td class="block tailor" *ngIf="orderToViewDetail?.orderType == OrderType.TAILOR">{{ item.tailorName }}</td>
              <td class="block text" *ngIf="orderToViewDetail?.orderType == OrderType.NAME_TAG">{{ item.nameTagText }}
              </td>
              <td class="block qty">{{item.quantity}}</td>
              <td class="block price">{{item.unitPrice | priceFormat}}</td>
              <td class="block price total">{{item.totalPrice | priceFormat}}</td>
            </tr>
          </tbody>
          <tfoot class="cart-footer">
          <tr *ngIf="orderToViewDetail?.refundedDeliveryFee > 0">
            <td colspan="5" align="right" style="text-align: right;">Delivery Fee</td>
            <td style="text-align: right;">{{orderToViewDetail?.refundedDeliveryFee | priceFormat}}</td>
          </tr>
            <tr>
              <td colspan="5" align="right" style="text-align: right;">Refunded Credits</td>
              <td style="text-align: right;">{{orderToViewDetail?.cancelledAmount | priceFormat}}</td>
            </tr>
            <tr>
              <td colspan="5" align="right" style="text-align: right;">Expired Credits</td>
              <td style="text-align: right;">
                {{getExpiredCredits(orderToViewDetail?.cancelledAmount, orderToViewDetail?.cancelledItems) | priceFormat}}
              </td>
            </tr>
          </tfoot>
        </table>
        <button type="button" class="btn hidden-print voffset2" (click)="addToCart()" [disabled]="!hasSelected()">
          <span class="glyphicon glyphicon-plus" aria-hidden="true"></span>Add to Cart</button>
        <!-- <button type="button" class="btn hidden-print voffset2" onclick="javascript:window.scrollTo(0, 0), window.print()"> -->
        <button type="button" class="btn hidden-print voffset2" (click)="printOrderDetail()" data-bs-dismiss="modal">
          <span class="glyphicon glyphicon-print" aria-hidden="true"></span>Print</button>
        <button type="button" class="btn hidden-print open-modify-order voffset2" data-bs-toggle="modal"
          data-bs-dismiss="modal" data-bs-target="#modify-order" *ngIf="orderToViewDetail?.statusCode === OrderStatus.ADVANCE_CHECKED_OUT || orderToViewDetail?.statusCode === OrderStatus.WALK_IN_ADVANCE_CHECKED_OUT
          ||orderToViewDetail?.statusCode === OrderStatus.NAME_TAG_CHECKED_OUT
          ||orderToViewDetail?.statusCode === OrderStatus.TAILOR_CHECKED_OUT">
          <span class="glyphicon glyphicon-edit" aria-hidden="true"></span>Modify Order</button>
      </div>
    </div>
  </div>
</div>

<!-- Modify Order Modal -->
<div id="modify-order" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">Modify Order No: {{updatedOrder?.orderRefNo}}</h1>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <form [formGroup]="CheckOutFormGroup">
          <div class="order-info">
            <table class="details">
              <tr>
                <td class="left">Name</td>
                <td class="right">{{updatedOrder?.name}}</td>
              </tr>
              <tr>
                <td class="left">NRIC</td>
                <td class="right">{{updatedOrder?.nric}}</td>
              </tr>
            </table>
            <table class="details">
              <tr>
                <td class="left">No. of Items</td>
                <td class="right">{{updatedOrder?.totalQuantity}}</td>
              </tr>
              <tr>
                <td class="left">Order Date</td>
                <td class="right">{{updatedOrder?.orderDate}}</td>
              </tr>
            </table>

            <table class="details">
              <tr>
                <td class="left middle-aligned">Collection Mode</td>
                <td>
                  <select name="collection-point-list" class="collection-point-list form-control"
                    (change)="onDeliveryModeChanged($event)" required>
                    <option *ngFor="let mode of availableDeliveryModes" [value]="mode.value"
                      [selected]="updatedDeliveryMode == mode.value">{{mode.text}}</option>
                  </select>
                </td>
              </tr>
              <tbody class="more-info collectionpoint"
                *ngIf="updatedDeliveryMode != DeliveryMode.HOME_DELIVERY && updatedDeliveryMode != DeliveryMode.COLLECT_AT_TAILOR">
                <tr>
                  <td class="left middle-aligned">
                    {{updatedDeliveryMode === DeliveryMode.ELOCKER ? "eLocker" : "Collection Point"}}</td>
                  <td>
                    <button type="button" class="btn btn-collection-point truncate" data-bs-toggle="modal"
                      data-bs-target="#select-collection-point">
                      <span class="name-wrapper">{{updatedDeliveryMode === DeliveryMode.SELF_COLLECTION ? updatedCP?.name : updatedDeliveryMode ===
                        DeliveryMode.ELOCKER ? updatedEL?.name : updatedWICP?.name}}</span>
                      <span class="glyphicon glyphicon-triangle-right" aria-hidden="true"></span>
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody class="more-info homedelivery"
                *ngIf="updatedDeliveryMode === DeliveryMode.HOME_DELIVERY && updatedDeliveryMode != DeliveryMode.COLLECT_AT_TAILOR">
                <tr>
                  <td class="left middle-aligned">House/Block
                    <span class="text-danger">*</span>
                  </td>
                  <td [ngClass]="{'has-error':(ErrorDisplay.BlkCtrl)}">
                    <input type="text" id="blkNo" maxlength="10" class="form-control" placeholder=""
                      formControlName="BlkCtrl" [ngModel]="updatedDelivery?.homeDeliveryAddress?.blkNo"
                      (ngModelChange)="updatedDelivery.homeDeliveryAddress.blkNo=$event">
                    <div *ngIf="ErrorDisplay.BlkCtrl" [ngClass]="{'has-error':(ErrorDisplay.BlkCtrl)}">
                      <span class="help-block">{{ErrorDisplay.BlkCtrl}}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="left middle-aligned">Unit No.</td>
                  <td>
                    <input type="text" id="unitNo" maxlength="10" class="form-control" placeholder=""
                      formControlName="UnitCtrl" [ngModel]="updatedDelivery?.homeDeliveryAddress?.unitNo"
                      (ngModelChange)="updatedDelivery.homeDeliveryAddress.unitNo=$event">
                  </td>
                </tr>
                <tr>
                  <td class="left middle-aligned">Street Name
                    <span class="text-danger">*</span>
                  </td>
                  <td [ngClass]="{'has-error':(ErrorDisplay.StreetCtrl)}">
                    <input id="street" type="text" maxlength="120" class="form-control" placeholder=""
                      formControlName="StreetCtrl" [ngModel]="updatedDelivery?.homeDeliveryAddress?.streetName"
                      (ngModelChange)="updatedDelivery.homeDeliveryAddress.streetName=$event">
                    <div *ngIf="ErrorDisplay.StreetCtrl" [ngClass]="{'has-error':(ErrorDisplay.StreetCtrl)}">
                      <span class="help-block">{{ErrorDisplay.StreetCtrl}}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="left middle-aligned">Building Name</td>
                  <td>
                    <input id="building" type="text" maxlength="120" class="form-control" placeholder=""
                      formControlName="BuildingCtrl" [ngModel]="updatedDelivery?.homeDeliveryAddress?.buildingName"
                      (ngModelChange)="updatedDelivery.homeDeliveryAddress.buildingName=$event">
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td class="left middle-aligned">Postal Code
                    <span class="text-danger">*</span>
                  </td>
                  <td [ngClass]="{'has-error':(ErrorDisplay.PostalCodeCtrl)}">
                    <input type="text" maxlength="6" class="form-control" placeholder=""
                      formControlName="PostalCodeCtrl" [ngModel]="updatedDelivery?.homeDeliveryAddress?.postalCode"
                      (ngModelChange)="updatedDelivery.homeDeliveryAddress.postalCode=$event">
                    <div *ngIf="ErrorDisplay.PostalCodeCtrl" [ngClass]="{'has-error':(ErrorDisplay.PostalCodeCtrl)}">
                      <span class="help-block">{{ErrorDisplay.PostalCodeCtrl}}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="left middle-aligned">Email Address
                    <span class="text-danger">*</span>
                  </td>
                  <td [ngClass]="{'has-error':(ErrorDisplay.EmailCtrl)}">
                    <input type="text" maxlength="75" class="form-control" placeholder="" formControlName="EmailCtrl"
                      [ngModel]="updatedDelivery?.email" (ngModelChange)="updatedDelivery.email=$event">
                    <div *ngIf="ErrorDisplay.EmailCtrl" [ngClass]="{'has-error':(ErrorDisplay.EmailCtrl)}">
                      <span class="help-block">{{ErrorDisplay.EmailCtrl}}</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="left middle-aligned">Mobile Number
                    <span class="text-danger">*</span>
                  </td>
                  <td [ngClass]="{'has-error':(ErrorDisplay.MobileCtrl)}">
                    <input type="text" maxlength="8" class="form-control" placeholder="" formControlName="MobileCtrl"
                      [ngModel]="updatedDelivery?.contactNo" (ngModelChange)="updatedDelivery.contactNo=$event">
                    <div *ngIf="ErrorDisplay.MobileCtrl" [ngClass]="{'has-error':(ErrorDisplay.MobileCtrl)}">
                      <span class="help-block">{{ErrorDisplay.MobileCtrl}}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
        <table class="table purchases">
          <thead>
            <tr>
              <th>Item(s)</th>
              <th
                *ngIf="updatedOrder?.orderType == OrderType.ADVANCED_ORDER || updatedOrder?.orderType == OrderType.WALK_IN">
                Size</th>
              <th *ngIf="updatedOrder?.orderType == OrderType.TAILOR">Tailor</th>
              <th *ngIf="updatedOrder?.orderType == OrderType.NAME_TAG">Name</th>
              <th class="qty">Qty</th>
              <th class="price">Unit Price</th>
              <th class="price">Subtotal</th>
              <th class="text-center" *ngIf="canCancel">Cancel Item</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of updatedOrder?.orderItems">
              <td class="item">
                <div class="item-image">
                  <img [src]="imageStore[item.orderItemId] || storeConfigService.dummyImgPath" alt="">
                </div>
                <div class="item-name">{{item.productName}}</div>
              </td>
              <td class="block size"
                *ngIf="updatedOrder?.orderType == OrderType.ADVANCED_ORDER || updatedOrder?.orderType == OrderType.WALK_IN">
                {{ item.skuName ? item.skuName : 'N.A' }}</td>
              <td class="block tailor" *ngIf="updatedOrder?.orderType == OrderType.TAILOR">{{ item.tailorName }}</td>
              <td class="block text" *ngIf="updatedOrder?.orderType == OrderType.NAME_TAG">{{ item.nameTagText }}</td>
              <td class="block qty">{{item.quantity}}</td>
              <td class="block price">{{item.unitPrice | priceFormat}}</td>
              <td class="block price total">{{item.totalPrice | priceFormat}}</td>
              <td class="block remove open-confirm-cancel-item" *ngIf="canCancel">
                <a href="#" data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#confirm-cancel-item"
                  (click)="setOrderItemToBeCancelled(item)">
                  <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                </a>
              </td>
            </tr>
          </tbody>
          <tfoot class="cart-footer">
            <tr *ngIf="getUpdatedDeliveryFee() > 0">
              <td colspan="4" align="right" style="text-align: right;">Delivery Fee</td>
              <td style="text-align: right;">{{getUpdatedDeliveryFee() | priceFormat}}</td>
              <td *ngIf="canCancel"></td>
            </tr>
            <tr class="{{getUpdatedDeliveryFee() > 0 ? 'balance-after' : ''}}">
              <td colspan="4" align="right" style="text-align: right;">Total Price</td>
              <td style="text-align: right;">{{getUpdatedTotalAmount() | priceFormat}}</td>
              <td *ngIf="canCancel"></td>
            </tr>
          </tfoot>
        </table>
        <button type="button" class="btn open-confirm-cancel-order voffset2" data-bs-toggle="modal" data-bs-dismiss="modal"
          data-bs-target="#confirm-cancel-order" (click)="checkRefundableAmount(updatedOrder?.totalAmount)"
          *ngIf="canCancel">
          <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>Cancel This Order</button>
        <button type="button" class="btn voffset2" data-bs-toggle="modal" data-bs-dismiss="modal"
          [disabled]="updatedDeliveryMode == DeliveryMode.HOME_DELIVERY && (!CheckOutFormGroup.valid || CheckOutFormGroup.disabled)"
          (click)="saveDeliveryInfo()">
          <span class="glyphicon glyphicon-floppy-disk" aria-hidden="true"></span>Save Delivery Info</button>
        <button type="button" class="btn voffset2" data-bs-toggle="modal" data-bs-dismiss="modal"
          data-bs-target="#item-detail-pop-up">Back</button>
      </div>
    </div>
  </div>
</div>

<!-- Cancel Order Modal -->
<div id="confirm-cancel-order" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">Confirm Cancellation of Order?</h1>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body" *ngIf="!isCheckingRefundable">
        <table class="table packer align-width block-style">
          <thead>
            <tr>
              <th>Order No.</th>
              <th>Name</th>
              <th>NRIC</th>
              <th>Collection Point</th>
              <th>Status</th>
              <th>Updated Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="block number">{{updatedOrder?.orderRefNo}}</td>
              <td class="block name">{{updatedOrder?.name}}</td>
              <td class="block nric">{{updatedOrder?.nric}}</td>
              <td *ngIf="updatedDeliveryMode !== DeliveryMode.HOME_DELIVERY" class="block point">{{updatedDeliveryMode === DeliveryMode.SELF_COLLECTION ? updatedCP?.name : updatedDeliveryMode === DeliveryMode.ELOCKER
                ? updatedEL?.name : updatedWICP?.name}}</td>
              <td *ngIf="updatedDeliveryMode === DeliveryMode.HOME_DELIVERY" class="block point">Home Delivery</td>
              <td class="block status">{{updatedOrder?.status}}</td>
              <td class="block updated">{{updatedOrder?.orderDate}}</td>
            </tr>
          </tbody>
        </table>
        <h2 *ngIf="updatedOrder?.orderItems.length > 0">Cancelled Item(s)</h2>
        <table *ngIf="updatedOrder?.orderItems.length > 0" class="table purchases cart collect">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Items</th>
              <th
                *ngIf="updatedOrder?.orderType == OrderType.ADVANCED_ORDER || updatedOrder?.orderType == OrderType.WALK_IN">
                Size</th>
              <th *ngIf="updatedOrder?.orderType == OrderType.TAILOR">Tailor</th>
              <th *ngIf="updatedOrder?.orderType == OrderType.NAME_TAG">Name</th>
              <th class="qty">Qty</th>
              <th class="price">Unit Price</th>
              <th class="price">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of updatedOrder?.orderItems let i = index">
              <td class="block series">{{i+1}}</td>
              <td class="item">
                <div class="item-image">
                  <img src="{{imageStore[item.orderItemId] || storeConfigService.dummyImgPath}}" alt="">
                </div>
                <div class="item-name">{{item.productName}}</div>
              </td>
              <td class="block size"
                *ngIf="updatedOrder?.orderType == OrderType.ADVANCED_ORDER || updatedOrder?.orderType == OrderType.WALK_IN">
                {{ item.skuName ? item.skuName : 'N.A' }}</td>
              <td class="block tailor" *ngIf="updatedOrder?.orderType == OrderType.TAILOR">{{ item.tailorName }}</td>
              <td class="block text" *ngIf="updatedOrder?.orderType == OrderType.NAME_TAG">{{ item.nameTagText }}</td>
              <td class="block qty">{{item.quantity}}</td>
              <td class="block price">{{item.unitPrice | priceFormat}}</td>
              <td class="block price total">{{item.totalPrice | priceFormat}}</td>
            </tr>
          </tbody>
          <tfoot class="cart-footer">
            <tr>
              <td colspan="5" align="right" style="text-align: right;">Refunded Credits</td>
              <td style="text-align: right;">{{ refundableAmont | priceFormat}}</td>
            </tr>
            <tr>
              <td colspan="5" align="right" style="text-align: right;">Expired Credits</td>
              <td style="text-align: right;">{{ expiredAmount | priceFormat}}</td>
            </tr>
          </tfoot>
        </table>
        <h2 *ngIf="orderToViewDetail?.cancelledItems.length > 0">Item(s) Cancelled</h2>
        <table *ngIf="orderToViewDetail?.cancelledItems.length > 0" class="table purchases cart collect" id="cancelledItems">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Item</th>
              <th
                *ngIf="orderToViewDetail?.orderType == OrderType.ADVANCED_ORDER || orderToViewDetail?.orderType == OrderType.WALK_IN">
                Size</th>
              <th *ngIf="orderToViewDetail?.orderType == OrderType.TAILOR">Tailor</th>
              <th *ngIf="orderToViewDetail?.orderType == OrderType.NAME_TAG">Name</th>
              <th class="qty">Qty</th>
              <th class="price">Unit Price</th>
              <th class="price">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of orderToViewDetail?.cancelledItems let i = index">
              <td class="block series">{{i+1}}</td>
              <td class="item">
                <div class="item-image">
                  <img [src]="imageStore[item.orderItemId] || storeConfigService.dummyImgPath" alt="">
                </div>
                <div class="item-name">{{item.productName}}</div>
              </td>
              <td class="block size"
                *ngIf="orderToViewDetail?.orderType == OrderType.ADVANCED_ORDER || orderToViewDetail?.orderType == OrderType.WALK_IN">
                {{ item.skuName ? item.skuName : 'N.A' }}</td>
              <td class="block tailor" *ngIf="orderToViewDetail?.orderType == OrderType.TAILOR">{{ item.tailorName }}</td>
              <td class="block text" *ngIf="orderToViewDetail?.orderType == OrderType.NAME_TAG">{{ item.nameTagText }}
              </td>
              <td class="block qty">{{item.quantity}}</td>
              <td class="block price">{{item.unitPrice | priceFormat}}</td>
              <td class="block price total">{{item.totalPrice | priceFormat}}</td>
            </tr>
          </tbody>
          <tfoot class="cart-footer">
            <tr>
              <td colspan="5" align="right" style="text-align: right;">Refunded Credits</td>
              <td style="text-align: right;">{{orderToViewDetail?.cancelledAmount | priceFormat}}</td>
            </tr>
            <tr>
              <td colspan="5" align="right" style="text-align: right;">Expired Credits</td>
              <td style="text-align: right;">
                {{getExpiredCredits(orderToViewDetail?.cancelledAmount, orderToViewDetail?.cancelledItems) | priceFormat}}
              </td>
            </tr>
          </tfoot>
        </table>
        <br>
        <span>{{refundableAmont | priceFormat}} will be refunded to your account.
          <br>
        </span>
        <span *ngIf="expiredAmount > 0">{{expiredAmount | priceFormat}} will not be refunded because the credit has
          already expired.
          <br>
        </span>
        <span>Do you still want to proceed?</span>
      </div>
      <div class="modal-footer" *ngIf="!isCheckingRefundable">
        <button type="button" class="btn open-item-pop-up-2" data-bs-toggle="modal" data-bs-dismiss="modal"
          data-bs-target="#item-detail-pop-up" (click)="cancelEntireOrder()">Yes</button>
        <button type="button" class="btn" data-bs-toggle="modal" data-bs-dismiss="modal"
          data-bs-target="#item-detail-pop-up">No</button>
      </div>
      <div class="modal-body text-center" id="wait-confirm-order" *ngIf="isCheckingRefundable">
        <i class="fa fa-refresh fa-2x spinner text-primary"></i>
      </div>
    </div>
  </div>
</div>

<!-- Cancel Item Modal -->
<div id="confirm-cancel-item" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">Confirm Cancellation of Item?</h1>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body" *ngIf="!isCheckingRefundable">
        <table class="table packer align-width block-style">
          <thead>
            <tr>
              <th>Order No.</th>
              <th>Name</th>
              <th>NRIC</th>
              <th>Collection Point</th>
              <th>Status</th>
              <th>Updated Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="block number">{{updatedOrder?.orderRefNo}}</td>
              <td class="block name">{{updatedOrder?.name}}</td>
              <td class="block nric">{{updatedOrder?.nric}}</td>
              <td *ngIf="updatedDeliveryMode !== DeliveryMode.HOME_DELIVERY" class="block point">{{updatedDeliveryMode === DeliveryMode.SELF_COLLECTION ? updatedCP?.name : updatedDeliveryMode === DeliveryMode.ELOCKER
                ? updatedEL?.name : updatedWICP?.name}}</td>
              <td *ngIf="updatedDeliveryMode === DeliveryMode.HOME_DELIVERY" class="block point">Home Delivery</td>
              <td class="block status">{{updatedOrder?.status}}</td>
              <td class="block updated">{{updatedOrder?.orderDate}}</td>
            </tr>
          </tbody>
        </table>
        <table *ngIf="tempUpdatedOrder?.orderItems.length > 0" class="table purchases cart collect">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Items</th>
              <th
                *ngIf="tempUpdatedOrder?.orderType == OrderType.ADVANCED_ORDER || tempUpdatedOrder?.orderType == OrderType.WALK_IN">
                Size</th>
              <th *ngIf="tempUpdatedOrder?.orderType == OrderType.TAILOR">Tailor</th>
              <th *ngIf="tempUpdatedOrder?.orderType == OrderType.NAME_TAG">Name</th>
              <th class="qty">Qty</th>
              <th class="price">Unit Price</th>
              <th class="price">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of tempUpdatedOrder?.orderItems let i = index">
              <td class="block series">{{i+1}}</td>
              <td class="item">
                <div class="item-image">
                  <img src="{{imageStore[item.orderItemId] || storeConfigService.dummyImgPath}}" alt="">
                </div>
                <div class="item-name">{{item.productName}}</div>
              </td>
              <td class="block size"
                *ngIf="tempUpdatedOrder?.orderType == OrderType.ADVANCED_ORDER || tempUpdatedOrder?.orderType == OrderType.WALK_IN">
                {{ item.skuName ? item.skuName : 'N.A' }}</td>
              <td class="block tailor" *ngIf="tempUpdatedOrder?.orderType == OrderType.TAILOR">{{ item.tailorName }}</td>
              <td class="block text" *ngIf="tempUpdatedOrder?.orderType == OrderType.NAME_TAG">{{ item.nameTagText }}</td>
              <td class="block qty">{{item.quantity}}</td>
              <td class="block price">{{item.unitPrice | priceFormat}}</td>
              <td class="block price total">{{item.totalPrice | priceFormat}}</td>
            </tr>
          </tbody>
          <tfoot class="cart-footer">
            <tr *ngIf="getUpdatedDeliveryFee() > 0">
              <td colspan="5" align="right" style="text-align: right;">Delivery Fee</td>
              <td style="text-align: right;">{{getUpdatedDeliveryFee() | priceFormat}}</td>
            </tr>
            <tr class="{{getUpdatedDeliveryFee() > 0 ? 'balance-after' : ''}}">
              <td colspan="5" align="right" style="text-align: right;">Total Price</td>
              <td style="text-align: right;">{{getTempUpdatedTotalAmount() | priceFormat}}</td>
            </tr>
          </tfoot>
        </table>
        <h2 *ngIf="orderItemToBeCancelled != null">Cancelled Item(s)</h2>
        <table *ngIf="orderItemToBeCancelled != null" class="table purchases cart collect" id="itemsForCancellation">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Items</th>
              <th
                *ngIf="tempUpdatedOrder?.orderType == OrderType.ADVANCED_ORDER || tempUpdatedOrder?.orderType == OrderType.WALK_IN">
                Size</th>
              <th *ngIf="tempUpdatedOrder?.orderType == OrderType.TAILOR">Tailor</th>
              <th *ngIf="tempUpdatedOrder?.orderType == OrderType.NAME_TAG">Name</th>
              <th class="qty">Qty</th>
              <th class="price">Unit Price</th>
              <th class="price">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="block series">1</td>
              <td class="item">
                <div class="item-image">
                  <img src="{{imageStore[orderItemToBeCancelled.orderItemId] || storeConfigService.dummyImgPath}}" alt="">
                </div>
                <div class="item-name">{{orderItemToBeCancelled.productName}}</div>
              </td>
              <td class="block size"
                *ngIf="tempUpdatedOrder?.orderType == OrderType.ADVANCED_ORDER || tempUpdatedOrder?.orderType == OrderType.WALK_IN">
                {{ orderItemToBeCancelled.skuName ? orderItemToBeCancelled.skuName : 'N.A' }}</td>
              <td class="block tailor" *ngIf="tempUpdatedOrder?.orderType == OrderType.TAILOR">
                {{ orderItemToBeCancelled.tailorName }}</td>
              <td class="block text" *ngIf="tempUpdatedOrder?.orderType == OrderType.NAME_TAG">
                {{ orderItemToBeCancelled.nameTagText }}</td>
              <td class="block qty">{{orderItemToBeCancelled.quantity}}</td>
              <td class="block price">{{orderItemToBeCancelled.unitPrice | priceFormat}}</td>
              <td class="block price total">{{orderItemToBeCancelled.totalPrice | priceFormat}}</td>
            </tr>
          </tbody>
          <tfoot class="cart-footer">
            <tr>
              <td colspan="5" align="right" style="text-align: right;">Refunded Credits</td>
              <td style="text-align: right;">{{ refundableAmont | priceFormat}}</td>
            </tr>
            <tr>
              <td colspan="5" align="right" style="text-align: right;">Expired Credits</td>
              <td style="text-align: right;">{{ expiredAmount | priceFormat}}</td>
            </tr>
          </tfoot>
        </table>
        <h2 *ngIf="orderToViewDetail?.cancelledItems.length > 0">Item(s) Cancelled</h2>
        <table *ngIf="orderToViewDetail?.cancelledItems.length > 0" class="table purchases cart collect" id="cancelledItems">
          <thead>
            <tr>
              <th>S/N</th>
              <th>Item</th>
              <th
                *ngIf="orderToViewDetail?.orderType == OrderType.ADVANCED_ORDER || orderToViewDetail?.orderType == OrderType.WALK_IN">
                Size</th>
              <th *ngIf="orderToViewDetail?.orderType == OrderType.TAILOR">Tailor</th>
              <th *ngIf="orderToViewDetail?.orderType == OrderType.NAME_TAG">Name</th>
              <th class="qty">Qty</th>
              <th class="price">Unit Price</th>
              <th class="price">Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of orderToViewDetail?.cancelledItems let i = index">
              <td class="block series">{{i+1}}</td>
              <td class="item">
                <div class="item-image">
                  <img [src]="imageStore[item.orderItemId] || storeConfigService.dummyImgPath" alt="">
                </div>
                <div class="item-name">{{item.productName}}</div>
              </td>
              <td class="block size"
                *ngIf="orderToViewDetail?.orderType == OrderType.ADVANCED_ORDER || orderToViewDetail?.orderType == OrderType.WALK_IN">
                {{ item.skuName ? item.skuName : 'N.A' }}</td>
              <td class="block tailor" *ngIf="orderToViewDetail?.orderType == OrderType.TAILOR">{{ item.tailorName }}</td>
              <td class="block text" *ngIf="orderToViewDetail?.orderType == OrderType.NAME_TAG">{{ item.nameTagText }}
              </td>
              <td class="block qty">{{item.quantity}}</td>
              <td class="block price">{{item.unitPrice | priceFormat}}</td>
              <td class="block price total">{{item.totalPrice | priceFormat}}</td>
            </tr>
          </tbody>
          <tfoot class="cart-footer">
            <tr>
              <td colspan="5" align="right" style="text-align: right;">Refunded Credits</td>
              <td style="text-align: right;">{{orderToViewDetail?.cancelledAmount | priceFormat}}</td>
            </tr>
            <tr>
              <td colspan="5" align="right" style="text-align: right;">Expired Credits</td>
              <td style="text-align: right;">
                {{getExpiredCredits(orderToViewDetail?.cancelledAmount, orderToViewDetail?.cancelledItems) | priceFormat}}
              </td>
            </tr>
          </tfoot>
        </table>
        <br>
        <span>{{refundableAmont | priceFormat}} will be refunded to your account.
          <br>
        </span>
        <span *ngIf="expiredAmount > 0">{{expiredAmount | priceFormat}} will not be refunded because the credit has
          already expired.
          <br>
        </span>
        <span>Do you still want to proceed?</span>
      </div>
      <div class="modal-footer" *ngIf="!isCheckingRefundable">
        <button type="button" class="btn open-item-pop-up-2" data-bs-toggle="modal" data-bs-dismiss="modal"
          data-bs-target="#item-detail-pop-up" (click)="cancelOrderItem()">Yes</button>
        <button type="button" class="btn" data-bs-toggle="modal" data-bs-dismiss="modal"
          data-bs-target="#item-detail-pop-up">No</button>
      </div>
      <div class="modal-body text-center" id="wait-confirm-order" *ngIf="isCheckingRefundable">
        <i class="fa fa-refresh fa-2x spinner text-primary"></i>
      </div>
    </div>
  </div>
</div>

<!-- Collection Point Modal -->
<div id="select-collection-point" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">Select {{updatedDeliveryMode == DeliveryMode.ELOCKER ? 'eLocker' : 'Collection Point'}}</h1>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body scroll">
        <div class="row" *ngIf="updatedDeliveryMode == DeliveryMode.SELF_COLLECTION">
          <ng-container *ngFor="let selection of storeConfigService.collectionPointSelections; let idx = index">
            <div class="col-sm-3">
              <div class="ns-radio radio">
                <label class="no-radio-btn" for="location-{{selection.zone}}">
                  <strong>{{selection.zone}}</strong>
                </label>
              </div>
              <div class="ns-radio radio" *ngFor="let location of selection.locations">
                <input type="radio" name="{{location.text}}" id="location-{{selection.zone}}-{{location.value}}"
                  value="{{location.text}}" [checked]="updatedCP?.locationId === location.value"
                  (click)="updatedCP = findCollectionPoint(location.value)">
                <label for="location-{{selection.zone}}-{{location.value}}">{{location.text}}</label>
              </div>
            </div>
            <div class="clearfix" *ngIf="(idx+1)%4==0"></div>
          </ng-container>
        </div>
        <div class="row" *ngIf="updatedDeliveryMode == DeliveryMode.ELOCKER">
          <ng-container *ngFor="let selection of storeConfigService.eLockerSelections; let idx = index">
            <div class="col-sm-3">
              <div class="ns-radio radio">
                <label class="no-radio-btn" for="location-{{selection.zone}}">
                  <strong>{{selection.zone}}</strong>
                </label>
              </div>
              <div class="ns-radio radio" *ngFor="let location of selection.locations">
                <input [disabled]="location.text.endsWith(' (No Vacancy)')" type="radio" name="{{location.text}}"
                  id="location-{{selection.zone}}-{{location.value}}" value="{{location.text}}"
                  [checked]="updatedEL?.locationId === location.value"
                  (click)="updatedEL = findElocker(location.value)">
                <label for="location-{{selection.zone}}-{{location.value}}">{{location.text}}</label>
              </div>
            </div>
            <div class="clearfix" *ngIf="(idx+1)%4==0"></div>
          </ng-container>
        </div>
        <div class="row" *ngIf="updatedDeliveryMode == DeliveryMode.WALK_IN_SELF_ORDER">
          <ng-container *ngFor="let selection of storeConfigService.walkInCollectionPointSelections; let idx = index">
            <div class="col-sm-3" *ngFor="let selection of storeConfigService.walkInCollectionPointSelections">
              <div class="ns-radio radio">
                <label class="no-radio-btn" for="location-{{selection.zone}}">
                  <strong>{{selection.zone}}</strong>
                </label>
              </div>
              <div class="ns-radio radio" *ngFor="let location of selection.locations">
                <input type="radio" name="{{location.text}}" id="location-{{selection.zone}}-{{location.value}}"
                  value="{{location.text}}" [checked]="updatedWICP?.locationId === location.value"
                  (click)="updatedWICP = findWalkInCollectionPoint(location.value)">
                <label for="location-{{selection.zone}}-{{location.value}}">{{location.text}}</label>
              </div>
            </div>
            <div class="clearfix" *ngIf="(idx+1)%4==0"></div>
          </ng-container>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn" data-bs-dismiss="modal" data-bs-toggle="modal" data-bs-target="#modify-order">Continue</button>
      </div>
    </div>
  </div>
</div>

<!-- Reminder Modal -->
<button type="button" id="openReminderModalButton" [hidden]="true" data-bs-toggle="modal"
  data-bs-target="#reminder-pop-up"></button>
<div id="reminder-pop-up" class="modal fade" role="dialog">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">{{reminderTitle}}</h1>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        {{reminderContent}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn" data-bs-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>

<simple-notifications [options]="notificationOptions"></simple-notifications>
