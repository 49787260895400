export * from './AdhocCreditAllocationApi';
import { AdhocCreditAllocationApi } from './AdhocCreditAllocationApi';
export * from './BusinessRulesManagementApi';
import { BusinessRulesManagementApi } from './BusinessRulesManagementApi';
export * from './CartApi';
import { CartApi } from './CartApi';
export * from './CatalogueApi';
import { CatalogueApi } from './CatalogueApi';
export * from './CatalogueManagementApi';
import { CatalogueManagementApi } from './CatalogueManagementApi';
export * from './CollectionPointManagementApi';
import { CollectionPointManagementApi } from './CollectionPointManagementApi';
export * from './CommonApi';
import { CommonApi } from './CommonApi';
export * from './ELockerApi';
import { ELockerApi } from './ELockerApi';
export * from './EMartManagerApi';
import { EMartManagerApi } from './EMartManagerApi';
export * from './FeedbackManagementApi';
import { FeedbackManagementApi } from './FeedbackManagementApi';
export * from './HomeDeliveryApi';
import { HomeDeliveryApi } from './HomeDeliveryApi';
export * from './NameTagApi';
import { NameTagApi } from './NameTagApi';
export * from './NameTagManagementApi';
import { NameTagManagementApi } from './NameTagManagementApi';
export * from './OrdersApi';
import { OrdersApi } from './OrdersApi';
export * from './PackerApi';
import { PackerApi } from './PackerApi';
export * from './ReportApi';
import { ReportApi } from './ReportApi';
export * from './RoleManagementApi';
import { RoleManagementApi } from './RoleManagementApi';
export * from './ServicemanApi';
import { ServicemanApi } from './ServicemanApi';
export * from './ServicemanManagementApi';
import { ServicemanManagementApi } from './ServicemanManagementApi';
export * from './StoremanApi';
import { StoremanApi } from './StoremanApi';
export * from './TailorApi';
import { TailorApi } from './TailorApi';
export * from './TailoringManagementApi';
import { TailoringManagementApi } from './TailoringManagementApi';
export * from './UnitManagementApi';
import { UnitManagementApi } from './UnitManagementApi';
export * from './UserManagementApi';
import { UserManagementApi } from './UserManagementApi';
export * from './WalkinApi';
import { WalkinApi } from './WalkinApi';
export * from './WaitlistApi';
import { WaitlistApi } from './WaitlistApi';
export * from './LoginAndSessionApi';
import { LoginAndSessionApi } from './LoginAndSessionApi';

export const APIS = [AdhocCreditAllocationApi, BusinessRulesManagementApi, CartApi, CatalogueApi, CatalogueManagementApi, CollectionPointManagementApi, CommonApi, ELockerApi, EMartManagerApi, FeedbackManagementApi, HomeDeliveryApi, NameTagApi, NameTagManagementApi, OrdersApi, PackerApi, ReportApi, RoleManagementApi, ServicemanApi, ServicemanManagementApi, StoremanApi, TailorApi, TailoringManagementApi, UnitManagementApi, UserManagementApi, WalkinApi, WaitlistApi, LoginAndSessionApi];

