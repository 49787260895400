/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class LoginAndSessionApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient) { }

    /**
     * Extend current user session
     */
    public extendSessionUsingPOST(extraHttpRequestParams?: any): Observable<Response> {
        return this.extendSessionUsingPOSTWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * Logout from the session
     */
    public logOutSessionUsingPOST(extraHttpRequestParams?: any): Observable<Response> {
        return this.logOutSessionUsingPOSTWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                console.log(response)
                return response;
            }));
    }

    /**
  * Extend current user session
  */
    public extendSessionUsingPOSTWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/session/extends';

        return this.httpClient.post(path, '', { withCredentials: true });
    }

    /**
     * Logout current user session API
     */
    public logOutSessionUsingPOSTWithHttpInfo(auditAction: any): Observable<any> {
        const path = this.basePath + '/session/internet/logout';

        if (auditAction === null || auditAction === undefined) {
            throw new Error('Audit Action not defined, failed to audit.');
        }

        return this.httpClient.post(path, auditAction, { withCredentials: true });
    }

}

