import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FaqService } from 'app/services/faq.service';

@Component({
  selector: 'app-question-answer',
  templateUrl: './question-answer.component.html',
  styleUrls: ['./question-answer.component.css']
})
export class QuestionAnswerComponent implements OnInit {
  expandText: string = "Expand All";
  hasExpandAll: boolean = false;

  
  /***************************************************************
  *   variable selectedListReceive is used to receive the list   *
  *   from faqs.component.ts                                     *
  ***************************************************************/
  @Input() selectedListReceive = [];

  constructor(
    private faqService: FaqService,
    private el: ElementRef
  ){}

  ngOnInit() {
    /********************************************************** 
    *   Get the list from faq.service.ts once the page load   *
    *   for the first time                                    *
    **********************************************************/
    this.selectedListReceive = this.faqService.selectedlist;
  }


  /***************************************************** 
  *   Refresh the name for the btn to "Expand All" &   *
  *   refresh the variable "hasExpandAll" to false     *
  *   once the user select other item from navbar      *
  *****************************************************/
  ngOnChanges(){
    this.expandText = "Expand All";
    this.hasExpandAll = false;
  }


  /************************************************************************************************************************************
  *   changetext() used to determine all the subtext either to be collapse or hidden based on the value of "hasExpandAll" variable.   *
  *   if hasExpandAll = true:                                                                                                         *                                                      
  *      selectedListReceive.map() - change all the elements' state to false (if state = false, thn subtext are collapsed)            *
  *      ExpandBtnFunc - select all the html tag with ".accordion-collapse.collapse" class and add "show" class to the tag            *
  *      iconRotate - select all html tag with ".accordion-button.collapsed" class and remove "collapsed" class to the tag            * 
  *   elseif hasExpandAll = false:                                                                                                    *
  *      selectedListReceive.map() - change all the elements' state to true (if state = true, thn subtext are hidden)                 *
  *      ExpandBtnFunc - select all the html tag with ".accordion-collapse.collapse.show" class and remove "show" class to the tag    *
  *      iconRotate - select all html tag with ".accordion-button" class and add "collapsed" class to the tag                         *
  *************************************************************************************************************************************/
  changetext() {
    if (!this.hasExpandAll) {
      this.selectedListReceive.map((v, i) => v.state = false); 
      let ExpandBtnFunc = this.el.nativeElement.querySelectorAll(".accordion-collapse.collapse")
      let iconRotate = this.el.nativeElement.querySelectorAll(".accordion-button.collapsed")
  
      ExpandBtnFunc.forEach(elem => { 
        if (!elem.classList.contains('show')) {
          elem.classList.add('show');
        }
      })

      iconRotate.forEach(elems => { 
        if (elems.classList.contains('collapsed')) {
          elems.classList.remove('collapsed');
        }
      })
    } else {
      this.selectedListReceive.map((v, i) => v.state = true); 
      let ExpandBtnFunc = this.el.nativeElement.querySelectorAll(".accordion-collapse.collapse.show")
      let iconRotate = this.el.nativeElement.querySelectorAll(".accordion-button")

      ExpandBtnFunc.forEach(elem => { 
        if (elem.classList.contains('show')) {
          elem.classList.remove('show');
        }
      })
      iconRotate.forEach(elems => { 
        if (!elems.classList.contains('collapsed')) {
          elems.classList.add('collapsed');
        }
      })
    }
    this.checkExpandOrCollapse();
  }


  /*******************************************************************************************************************
  *   checkExpandorCollapse() used to check all the state of the element in the list.                                *
  *   if all the elements' state = false, then the text of the btn will change from "Expand All" to "Collapse All" , *
  *   else the elements' state = true, the text of the btn will change from "Collapse All" to "Expand All"           *
  *******************************************************************************************************************/
  checkExpandOrCollapse() {
    let checkExpandAll = arr => arr.every(elem => elem.state == false);
    if (checkExpandAll(this.selectedListReceive) == true) { 
      this.hasExpandAll = true;
      this.expandText = "Collapse All"
    } else {
      this.hasExpandAll = false;
      this.expandText = "Expand All"
    }
  }


  /******************************************************************
  *   onQuestionClick() change the element's state from true        *
  *   to false and vice versa and trigger checkExpandorCollapse()   *
  ******************************************************************/
  onQuestionClick(item) {
    item.state = !item.state;
    this.checkExpandOrCollapse()
  }

}
