<div class="container hidden-print">
  <div class="row">
    <!-- Current Orders -->
    <div class="col-md-12">
      <div class="collapse-box">
        <div class="collapse-title">
          <h1>Current Orders</h1>
          <div class="btn-toggle-collapse-wrapper"> <a class="btn-toggle-collapse" href="#order1" data-bs-toggle="collapse"></a> </div>
        </div>
        <div id="order1" class="collapse show">
          <div class="box-content">
            <table class="table orders" *ngIf="currentOrdersService.currentOrders != null && currentOrdersService.currentOrders.length>0 && !currentOrdersErrorMessage">
              <thead>
                <tr>
                  <th>Order No.</th>
                  <th>Order Date</th>
                  <th>Collection Point</th>
                  <th>No. of Items</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr data-bs-toggle="modal" data-bs-target="#item-detail-pop-up" (click)="viewOrderDetail(order);" *ngFor="let order of currentOrdersService.currentOrders">
                  <td class="number"><a href="javascript:void(0);" data-bs-target="#item-detail-pop-up">{{order.orderRefNo}}</a></td>
                  <td class="date">{{order.orderDate}}</td>
                  <td class="point">{{order.collectionPointName}}</td>
                  <td class="qty">{{order.totalQuantity}}</td>
                  <td class="status">{{order.status}}</td>
                </tr>
              </tbody>
            </table>
            <div class="alert alert-info text-center" role="alert" *ngIf="currentOrdersService.currentOrders != null && currentOrdersService.currentOrders.length==0 && !currentOrdersErrorMessage">
              <i class="fa fa-info-circle fa-2x"></i>
              <p>You have no current orders</p>
            </div>
            <div class="text-center" *ngIf="currentOrdersService.currentOrders == null && !currentOrdersErrorMessage">
              <i class="fa fa-refresh fa-2x spinner text-primary"></i>
            </div>

            <div class="alert alert-danger text-center" role="alert" *ngIf="currentOrdersErrorMessage">
              <i class="fa fa-exclamation-triangle fa-2x"></i>
              <p>{{currentOrdersErrorMessage}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Current Orders END -->

    <!-- Recent Purchases -->
    <div class="col-md-12">
      <div class="collapse-box">
        <div class="collapse-title">
          <h1>Recent Purchases (Past 3 Orders)</h1>
          <div class="btn-toggle-collapse-wrapper"> <a class="btn-toggle-collapse" href="#order2" data-bs-toggle="collapse"></a> </div>
        </div>
        <div id="order2" class="collapse show">
          <div class="box-content">
            <ng-container *ngIf="recentItems != null && recentItems.length > 0 && !recentItemsErrorMessage">
              <table class="table purchases">
                <thead>
                  <tr>
                    <th class="check">
                      <div class="checkbox-square checkbox" *ngIf="hasPurchasable">
                        <input type="checkbox" id="selectall" [checked]="allRecentItemsChecked" (change)="selectAllRecentItemsToPurchase()">
                        <label class="cbox" for="selectall"></label>
                      </div>
                    </th>
                    <th>Item(s)</th>
                    <th>Size</th>
                    <th class="qty">Qty</th>
                    <th class="price">Unit Price</th>
                    <th class="price">Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of recentItems; let i = index;" [attr.data-bs-index]="i">
                    <td>
                      <div class="checkbox-square checkbox" *ngIf="item.purchasable">
                        <input type="checkbox" id="select{{i}}" [checked]="item['selected']" (change)="selectRecentItemToPurchase(item)">
                        <label class="cbox" for="select{{i}}"></label>
                      </div>
                    </td>
                    <td>
                      <div class="item-image"><img src="{{imagestore[item.orderItemId]}}" alt=""></div>
                      <div class="item-name">{{item.productName}}</div>
                    </td>
                    <td class="block size">{{item.skuName ? item.skuName : 'N.A'}}</td>
                    <td class="block qty">{{item.quantity}}</td>
                    <td class="block price">{{item.unitPrice | priceFormat}}</td>
                    <td class="block price total">{{item.totalPrice | priceFormat}}</td>
                  </tr>
                </tbody>
              </table>
              <button type="button" class="btn" (click)="addToCart()" [disabled]="!hasSelected()"><span class="glyphicon glyphicon-plus" aria-hidden="true"></span>Add to Cart</button>
            </ng-container>
            <div class="alert alert-info text-center" role="alert" *ngIf="recentItems != null && recentItems.length==0 && !recentItemsErrorMessage">
              <i class="fa fa-info-circle fa-2x"></i>
              <p>You did not purchase anything recently</p>
            </div>
            <div class="text-center" *ngIf="recentItems == null && !recentItemsErrorMessage">
              <i class="fa fa-refresh fa-2x spinner text-primary"></i>
            </div>
            <div class="alert alert-danger text-center" role="alert" *ngIf="recentItemsErrorMessage">
              <i class="fa fa-exclamation-triangle fa-2x"></i>
              <p>{{recentItemsErrorMessage}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Recent Purchases End-->
  </div>
</div>

<app-order-detail-modify [orderToViewDetail]="orderToViewDetail"></app-order-detail-modify>

<simple-notifications [options]="notificationOptions"></simple-notifications>