<simple-notifications [(options)]="notificationOptions"></simple-notifications>
<!-- shopping cart -->
<div class="col-md-12">
  <div class="collapse-box">
    <div class="collapse-title">
      <h1>Shopping Cart</h1>
    </div>
    <div class="collapse in">
      <div class="box-content">
        <div id="nodata" class="alert alert-info text-center" role="alert"
          *ngIf="postCreateOrderAction === 0 && (!cartService.shoppingCart.nameTagItems || cartService.shoppingCart.nameTagItems.length === 0)">
          <i class="fa fa-info-circle fa-2x"></i>
          <p>{{cartService.EMPTY_CART_MSG}}</p>
        </div>
        <div id="wait-confirm-order" *ngIf="postCreateOrderAction > 0" class="text-center">
          <i class="fa fa-refresh fa-2x spinner text-primary"></i>
        </div>
        <div *ngIf="cartService.shoppingCart.nameTagItems?.length > 0">
          <table class="table purchases cart">
            <thead>
              <tr>
                <th>Item(s)</th>
                <th>Qty</th>
                <th class="price">Unit Price</th>
                <th class="price">Subtotal</th>
                <th class="remove">Remove</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of cartService.shoppingCart.nameTagItems">
                <td>
                  <div class="item-image">
                    <img [src]="imagestore[item.productId] || storeConfigService.dummyImgPath" alt="">
                  </div>
                  <div class="item-name">{{productMap.get(item.productId).productName}}</div>
                </td>
                <td class="block qty">
                  <div class="qty">
                    <form id='itemquantity_{{item.productId}}'>
                      <select name="qty" class="form-control" [ngModel]="item.quantity"
                        (change)="updateQuantity(item,$event)" required>
                        <option *ngFor="let q of checkQty(item.quantity)" value="{{q+1}}"
                          [attr.selected]="q+1 == item.quantity ? 'selected' : null">{{q+1}}</option>
                      </select>
                    </form>
                  </div>
                </td>
                <td class="block price">{{ productMap.get(item.productId).productPrice | priceFormat }}</td>
                <td class="block price total">{{ productMap.get(item.productId).productPrice*item.quantity | priceFormat
                  }}</td>
                <td class="block remove">
                  <a href="javascript:void(0);" (click)="nameTagCartService.removeItemFromCart(item.productId)">
                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                  </a>
                </td>
              </tr>
            </tbody>
            <tfoot class="cart-footer">
              <tr *ngIf="getDeliveryFee() > 0">
                <td colspan="3" align="right" style="text-align: right;">Delivery Fee</td>
                <td id="totalPrice" style="text-align: right;">{{ getDeliveryFee() | priceFormat }}</td>
                <td></td>
              </tr>
              <tr class="{{getDeliveryFee() > 0 ? 'balance-after' : ''}}">
                <td colspan="3" align="right" style="text-align: right;">Total Price</td>
                <td id="totalPrice" style="text-align: right;">{{ getTotalAmount() | priceFormat }}</td>
                <td></td>
              </tr>
              <tr class="available-credit">
                <td colspan="3" align="right" style="text-align: right;">Available Credit</td>
                <td id="availableCredit" style="text-align: right;">{{userProfileService.creditSummary.creditBalance |
                  priceFormat}}</td>
                <td></td>
              </tr>
              <tr class="balance-after">
                <td colspan="3" align="right" style="text-align: right;">Balance After Checkout</td>
                <td id="balanceAfterCheckout" style="text-align: right;">{{
                  userProfileService.creditSummary.creditBalance - getTotalAmount() | priceFormat }}
                </td>
                <td></td>
              </tr>
            </tfoot>
          </table>

          <div class="nametag-name-wrapper">
            <table class="nametag-name">
              <tr>
                <td class="left-col">Name for the Name Tag(s)
                  <span class="text-danger">*</span>
                  <!-- <span class="glyphicon glyphicon-info-sign" title="Maximum size allowed including blank space is 17 characters"></span> -->
                  <span class="glyphicon glyphicon-info-sign tooltipcontainer">
                    <span class="tooltiptext">Maximum size allowed including blank space is 17 characters</span>
                  </span>

                </td>
                <td [ngClass]="{'has-error': (nameTagTextError!=null)}">
                  <input type="text" class="form-control" maxlength="17" [ngModel]="nameTagText"
                    (ngModelChange)="onNameTagTextChanged($event)">
                </td>
                <td [ngClass]="{'has-error': (nameTagTextError!=null)}">
                  <span class="help-block">&nbsp;{{nameTagTextError}}</span>
                </td>
              </tr>
            </table>
          </div>

          <h2>Checkout</h2>
          <hr>
          <form [formGroup]="CheckOutFormGroup" (ngSubmit)="doSubmit()">
            <table class="checkout-data">
              <tr>
                <td class="left-col">Collection Mode</td>
                <td>
                  <input type="text" class="form-control" value="Self Collection" [attr.disabled]="true">
                </td>

                <!-- Commented out bcs only have 1 delivery mode -->
                  <!-- <select name="collection-point-list" class="collection-point-list form-control" (change)="onDeliveryModeChanged($event)" required>
                    <option *ngFor="let mode of storeConfigService.nameTagDeliveryModes" [value]="mode.value" [selected]="deliveryMode == mode.value">{{mode.text}}</option>
                  </select>
                </td>
                <td colspan="2">
                  <table>
                    <tr>
                      <td>
                        <div class="checkbox-square checkbox">
                          <input type="checkbox" id="set" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="deliveryAddr.savePreferredDeliveryMode">
                          <label class="cbox" for="set"></label>
                        </div>
                      </td>
                      <td class="radio-content">Set as preferred collection mode in Emart profile</td>
                    </tr>
                  </table>
                </td> -->
              </tr>
              <tbody class="more-info collectionpoint" *ngIf="deliveryMode != DeliveryMode.HOME_DELIVERY">

                <tr>
                  <td class="left-col">{{deliveryMode === DeliveryMode.ELOCKER ? "eLocker" : "Collection Point"}}</td>
                  <td>
                    <button type="button" class="btn btn-collection-point truncate" data-bs-toggle="modal"
                      data-bs-target="#select-collection-point">
                      <span class="name-wrapper">{{deliveryMode === DeliveryMode.SELF_COLLECTION ? selectedCP.name :
                        selectedEL.name }}</span>
                      <span class="glyphicon glyphicon-triangle-right" aria-hidden="true"></span>
                    </button>
                  </td>
                  <td colspan="2"></td>
                </tr>

                <tr *ngIf="deliveryMode === DeliveryMode.SELF_COLLECTION">
                  <td class="left-col" valign="top">Collection Point Info</td>
                  <td valign="top" *ngIf="selectedCP.address">
                    <div>
                      <b>Address</b>
                    </div>
                    <div>{{selectedCP.address.blkNo}} {{selectedCP.address.streetName}}</div>
                    <div>{{selectedCP.address.unitNo}}</div>
                    <div>{{selectedCP.address.buildingName}}</div>
                    <div>Singapore {{selectedCP.address.postalCode}}</div>
                    <p>
                      <b>Tel:</b> {{selectedCP.telephone}}
                    </p>
                  </td>
                  <td valign="top" *ngIf="selectedCP.openingHrs">
                    <b>Collection Hours</b>
                    <div *ngFor="let openingHrs of selectedCP.openingHrs">
                      <strong>{{openingHrs.days}}</strong>
                      <br />{{openingHrs.hours}}
                      <br>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="deliveryMode === DeliveryMode.ELOCKER">
                  <td class="left-col" valign="top">eLocker Info</td>
                  <td valign="top" *ngIf="selectedEL.address">
                    <div>
                      <b>Address</b>
                    </div>
                    <div>{{selectedEL.address.blkNo}} {{selectedEL.address.streetName}}</div>
                    <div>{{selectedEL.address.unitNo}}</div>
                    <div>{{selectedEL.address.buildingName}}</div>
                    <div>Singapore {{selectedEL.address.postalCode}}</div>
                    <p>
                      <b>Tel:</b> {{selectedEL.telephone}}
                    </p>
                  </td>
                  <td valign="top" *ngIf="selectedEL.openingHrs">
                    <b>Collection Hours</b>
                    <div *ngFor="let openingHrs of selectedEL.openingHrs">
                      <strong>{{openingHrs.days}}</strong>
                      <br />{{openingHrs.hours}}
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody class="more-info homedelivery" *ngIf="deliveryMode === DeliveryMode.HOME_DELIVERY">
                <tr>
                  <td class="left-col">House/Block
                    <span class="text-danger">*</span>
                  </td>
                  <td [ngClass]="{ 'has-error': (ErrorDisplay.BlkCtrl)}">
                    <input type="text" id="blkNo" maxlength="10" class="form-control" placeholder=""
                      formControlName="BlkCtrl" [ngModel]="deliveryAddr.homeDeliveryAddress?.blkNo"
                      (ngModelChange)="deliveryAddr.homeDeliveryAddress.blkNo=$event">
                  </td>
                  <td>Unit No.</td>
                  <td>
                    <input type="text" id="unitNo" maxlength="10" class="form-control" placeholder=""
                      formControlName="UnitCtrl" [ngModel]="deliveryAddr.homeDeliveryAddress?.unitNo"
                      (ngModelChange)="deliveryAddr.homeDeliveryAddress.unitNo=$event">
                  </td>
                  <td [ngClass]="{ 'has-error': (ErrorDisplay.BlkCtrl)}">
                    <span class="help-block">{{ErrorDisplay.BlkCtrl}}</span>
                  </td>
                </tr>
                <tr>
                  <td class="left-col">Street Name
                    <span class="text-danger">*</span>
                  </td>
                  <td colspan="3" [ngClass]="{ 'has-error': (ErrorDisplay.StreetCtrl)}">
                    <input id="street" type="text" maxlength="120" class="form-control" placeholder=""
                      formControlName="StreetCtrl" [ngModel]="deliveryAddr.homeDeliveryAddress?.streetName"
                      (ngModelChange)="deliveryAddr.homeDeliveryAddress.streetName=$event">
                  </td>
                  <td [ngClass]="{ 'has-error': (ErrorDisplay.StreetCtrl)}">
                    <span class="help-block">{{ErrorDisplay.StreetCtrl}}</span>
                  </td>
                </tr>
                <tr>
                  <td class="left-col">Building Name</td>
                  <td colspan="3">
                    <input id="building" type="text" maxlength="120" class="form-control" placeholder=""
                      formControlName="BuildingCtrl" [ngModel]="deliveryAddr.homeDeliveryAddress?.buildingName"
                      (ngModelChange)="deliveryAddr.homeDeliveryAddress.buildingName=$event">
                  </td>
                </tr>
                <tr>
                  <td class="left-col">Postal Code
                    <span class="text-danger">*</span>
                  </td>
                  <td [ngClass]="{ 'has-error': (ErrorDisplay.PostalCodeCtrl)}">
                    <input type="text" maxlength="6" class="form-control" placeholder=""
                      formControlName="PostalCodeCtrl" [ngModel]="deliveryAddr.homeDeliveryAddress?.postalCode"
                      (ngModelChange)="deliveryAddr.homeDeliveryAddress.postalCode=$event">
                  </td>
                  <td [ngClass]="{ 'has-error': (ErrorDisplay.PostalCodeCtrl)}" colspan="2">
                    <span class="help-block">{{ErrorDisplay.PostalCodeCtrl}}</span>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td colspan="2">
                    <table>
                      <tr>
                        <td>
                          <div class="checkbox-square checkbox lessmargin">
                            <input type="checkbox" id="set1" [ngModelOptions]="{standalone: true}"
                              [(ngModel)]="deliveryAddr.saveAddress">
                            <label class="cbox" for="set1"></label>
                          </div>
                        </td>
                        <td class="radio-content">Set as delivery address in Emart profile</td>
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
              <tr>
                <td class="left-col">Email Address
                  <span class="text-danger">*</span>
                </td>
                <td colspan="3" [ngClass]="{ 'has-error': (ErrorDisplay.EmailCtrl)}">
                  <input type="text" maxlength="75" class="form-control" placeholder="" formControlName="EmailCtrl"
                    [ngModel]="deliveryAddr?.email" (ngModelChange)="deliveryAddr.email=$event">

                </td>
                <td [ngClass]="{ 'has-error': (ErrorDisplay.EmailCtrl)}">
                  <span class="help-block">{{ErrorDisplay.EmailCtrl}}</span>
                </td>
              </tr>
              <tr>
                <td></td>
                <td colspan="2">
                  <table>
                    <tr>
                      <td>
                        <div class="checkbox-square checkbox lessmargin">
                          <input type="checkbox" id="set2" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="deliveryAddr.saveEmail">
                          <label class="cbox" for="set2"></label>
                        </div>
                      </td>
                      <td class="radio-content">Set as alternate email address in Emart profile</td>
                    </tr>
                  </table>
                </td>
              </tr>
              <tr>
                <td class="left-col">Mobile Number
                  <span class="text-danger">*</span>
                </td>
                <td colspan="3" [ngClass]="{ 'has-error': (ErrorDisplay.MobileCtrl)}">
                  <input type="text" maxlength="8" class="form-control" placeholder="" formControlName="MobileCtrl"
                    [ngModel]="deliveryAddr?.contactNo" (ngModelChange)="deliveryAddr.contactNo=$event">
                </td>
                <td [ngClass]="{ 'has-error': (ErrorDisplay.MobileCtrl)}">
                  <span class="help-block">{{ErrorDisplay.MobileCtrl}}</span>
                </td>
              </tr>
              <tr>
                <td></td>
                <td colspan="2">
                  <table>
                    <tr>
                      <td>
                        <div class="checkbox-square checkbox lessmargin">
                          <input type="checkbox" id="set3" [ngModelOptions]="{standalone: true}"
                            [(ngModel)]="deliveryAddr.saveContactNo">
                          <label class="cbox" for="set3"></label>
                        </div>
                      </td>
                      <td class="radio-content">Set as alternate mobile number in Emart profile</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
            <strong>Terms</strong>
        <table>
          <tr>
            <td valign="top" width="16">1. </td>
            <td valign="top">Purchased items are non-refundable.</td>
          </tr>
          <tr>
            <td valign="top" width="16">2. </td>
            <td valign="top">Purchased items are to be collected within 30 days (for Self-Collection) 
              after they are ready for collection.</td>
          </tr>
          <tr>
            <td valign="top" width="16">3. </td>
            <td valign="top">Purchased items will be delivered within 6 – 8days (for Home Delivery)</td>
          </tr>
          <tr>
            <td valign="top" width="16"></td>
            <td valign="top">
              <ul>
                <li>Acknowledgement (signature) would be required by yourself or anyone residing in your unit upon the delivery of the parcel.</li>
                <li>The delivery timing is 9am to 6pm (weekday) and 9am to 12pm (Sat). The courier will arrange with you for a 2nd delivery attempt if the 1st delivery is unsuccessful.</li>
                <li>If the courier is not able to contact you to make a scheduled delivery or the 2nd scheduled delivery is also unsuccessful, please note that you may no longer collect this order or rearrange delivery, your order will be forfeited and the credits will not be refunded.</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td valign="top" width="16">4. </td>
            <td valign="top">Purchased items are not supposed to be Sold / Transferred / Distributed to any other party.
              Non-compliance will result in disciplinary actions taken.
            </td>
          </tr>
          <tr>
            <td valign="top" width="16">5. </td>
            <td valign="top">For tailored uniforms, officers are to proceed to the tailors for 
              measurement taking within two months upon order confirmation else items and credits will be forfeited.</td>
          </tr>
          <tr>
            <td valign="top" width="16">6. </td>
            <td valign="top">Orders may be cancelled or partially fulfilled in unforeseen circumstances. 
              If such a situation occurs, you will be alerted soonest .</td>
          </tr>
            </table>
            <br />
            <table class="iagree">
              <tr>
                <td valign="top">
                  <div class="checkbox-square checkbox">
                    <input type="checkbox" id="a" formControlName="tnc1Ctrl" (ngModelChange)="tnc1=$event"
                      [(ngModel)]="tnc1">
                    <label class="cbox" for="a" id="tnc1"></label>
                  </div>
                </td>
                <td valign="top" class="radio-content">I agree and consent to the above *Terms and that the data
                  collected in eMart (eg. mobile number) may be used
                  by eMart authorised vendors and officers/staffs to fulfil, manage and/or deliver my purchased
                  items(s).</td>
              </tr>
            </table>
            <button type="button" (click)="onValidateOrder()"
              [disabled]="!CheckOutFormGroup.valid || CheckOutFormGroup.disabled || !cartService.shoppingCart.nameTagItems || cartService.shoppingCart.nameTagItems.length === 0 || nameTagTextError != null || isCreatingOrder"
              class="btn voffset2" data-bs-toggle="modal" data-bs-target="#confirm-pop-up">Checkout Now</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div id="confirm-pop-up" class="modal fade" role="dialog">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">{{validateOrderErrorMsg == null || validateOrderErrorMsg.length == 0 ? "Confirm
          Purchase?" : "Invalid Cart"}}</h1>
          <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body" *ngIf="validateOrderErrorMsg != null">
        <div *ngIf="validateOrderErrorMsg.length == 0">
          <div *ngFor="let softError of cartService.softErrors">
            {{softError}}
            <br>
            <br>
          </div>
          <table class="confirm-purchases">
            <tr>
              <td>No. of Items</td>
              <td>{{ validatedTotalItems }}</td>
            </tr>
            <tr>
              <td>Total Price</td>
              <td>{{ getTotalAmount() | priceFormat}}</td>
            </tr>
            <tr>
              <td>Collection Mode</td>
              <td>{{ getDeliveryMode() }}</td>
            </tr>
            <tr *ngIf="deliveryMode != DeliveryMode.HOME_DELIVERY">
              <td>{{deliveryMode === DeliveryMode.ELOCKER ? "eLocker" : "Collection Point"}}</td>
              <td> {{deliveryMode === DeliveryMode.SELF_COLLECTION ? selectedCP.name : selectedEL.name }} </td>
            </tr>
          </table>
        </div>
        <div *ngIf="validateOrderErrorMsg.length > 0">
          We apologize that you cannot proceed because of the following errors:
          <ul>
            <li *ngFor="let error of validateOrderErrorMsg">{{ error }}</li>
          </ul>
        </div>
      </div>
      <div class="modal-footer" *ngIf="validateOrderErrorMsg != null">
        <button type="button" class="btn" data-bs-dismiss="modal" *ngIf="validateOrderErrorMsg.length == 0"
          (click)="onConfirmPurchase()">Yes</button>
        <button type="button" class="btn"
          data-bs-dismiss="modal">{{validateOrderErrorMsg.length == 0 ? "Cancel" : "OK"}}</button>
      </div>
      <div class="modal-body" id="wait-confirm-order" *ngIf="validateOrderErrorMsg == null">
        <div class="text-center">
          <i class="fa fa-refresh fa-2x spinner text-primary"></i>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Reminder Modal -->
<button id="openReminderModalButton" [hidden]="true" data-bs-toggle="modal" data-bs-target="#reminder-pop-up"></button>
<div id="reminder-pop-up" class="modal fade" role="dialog">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">{{reminderTitle}}</h1>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        {{reminderContent}}
      </div>
      <div class="modal-footer">
        <button type="button" class="btn" data-bs-dismiss="modal">OK</button>
      </div>
    </div>
  </div>
</div>

<!-- Collection Point Modal -->
<div id="select-collection-point" class="modal fade" role="dialog">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">Select {{deliveryMode == DeliveryMode.ELOCKER ? 'eLocker' : 'Collection Point'}}</h1>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body scroll">
        <div class="row" *ngIf="deliveryMode == DeliveryMode.SELF_COLLECTION">
          <ng-container *ngFor="let selection of storeConfigService.collectionPointSelections; let idx = index">
            <div class="col-sm-3">
              <div class="ns-radio radio">
                <label class="no-radio-btn" for="location-{{selection.zone}}">
                  <strong>{{selection.zone}}</strong>
                </label>
              </div>
              <div class="ns-radio radio" *ngFor="let location of selection.locations">
                <input type="radio" name="{{location.text}}" id="location-{{selection.zone}}-{{location.value}}"
                  value="{{location.text}}" [checked]="selectedCP.locationId === location.value"
                  (click)="selectedCP = findCollectionPoint(location.value)">
                <label for="location-{{selection.zone}}-{{location.value}}">{{location.text}}</label>
              </div>
            </div>
            <div class="clearfix" *ngIf="(idx+1)%4==0"></div>
          </ng-container>
        </div>
        <div class="row" *ngIf="deliveryMode == DeliveryMode.ELOCKER">
          <ng-container *ngFor="let selection of storeConfigService.eLockerSelections; let idx = index">
            <div class="col-sm-3">
              <div class="ns-radio radio">
                <label class="no-radio-btn" for="location-{{selection.zone}}">
                  <strong>{{selection.zone}}</strong>
                </label>
              </div>
              <div class="ns-radio radio" *ngFor="let location of selection.locations">
                <input [disabled]="location.text.endsWith(' (No Vacancy)')" type="radio" name="{{location.text}}"
                  id="location-{{selection.zone}}-{{location.value}}" value="{{location.text}}"
                  [checked]="selectedEL.locationId === location.value"
                  (click)="selectedEL = findElocker(location.value)">
                <label for="location-{{selection.zone}}-{{location.value}}">{{location.text}}</label>
              </div>
            </div>
            <div class="clearfix" *ngIf="(idx+1)%4==0"></div>
          </ng-container>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn" data-bs-dismiss="modal">Continue</button>
      </div>
    </div>
  </div>
</div>
