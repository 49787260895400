import { Injectable } from '@angular/core';
import { NotificationsService } from 'assets/lib/angular2-notifications';
import { Observable, of, tap, catchError, throwError, flatMap } from 'rxjs';
import { WaitlistApi } from './../shared/backend-api/emart/api/WaitlistApi';
import { WaitlistDTO } from './../shared/backend-api/emart/model/WaitlistDTO';
import { WaitlistItemDTO, UserProductDTO,GetProductCapsResult} from './../shared/backend-api/emart/model/models';
import { Constants } from './../shared/global-constants/constants';
import { CommonUtils } from './../shared/utilities/common-utils';
import { ProductsService } from './products.service';
import { UserProfileService } from './user-profile.service';
import { CartService } from './cart.service';
import * as naturalSort from 'javascript-natural-sort';

@Injectable()
export class WaitlistService{
  private EMPTY_WAITLIST_MSG = 'Waitlist is empty';

  public waitlist: WaitlistDTO;
  public tempWaitlist: WaitlistDTO;
  public loadTempWaitlist = false;

  constructor(
    private waitlistApi: WaitlistApi,
    private productsService: ProductsService,
    private userProfileService: UserProfileService,
    private notificationService: NotificationsService,
    private cartService: CartService
  ) {
    this.productsService.loadProducts(Constants.ProductType.NORMAL);
  }


  getProduct(productId: number): UserProductDTO {
    for (const product of this.productsService.cachedProducts.data) {
      if (product.productId === productId) {
        return product;
      }
    }
  }


  getProductBySkuId(skuId: number): UserProductDTO {
    for (const product of this.productsService.cachedProducts.data) {
      for (const sku of product.skus) {
        if (sku.skuId === skuId) {
          return product;
        }
      }
    }
  }

  // Force reload waitlist detail
  getWaitlistDetail(): Observable<any> {
    return this.waitlistApi.getWaitlistUsingGET().pipe(tap(
      payload => {
        //when status == 200 & 204, execute working part
        if (payload.status == 200 || payload.status == 204){ 
          if (this.loadTempWaitlist) {
            this.tempWaitlist = payload.data as WaitlistDTO;
          } else {
            this.waitlist = payload.data as WaitlistDTO;
          }

        // other than 200, 204, 401, 403, throws error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        return err;
      }
      ));
  }

  loadWaitlistDetail(): Observable<any> {
    if (this.waitlist) {
      return of('No need to reload waitlist.');
    } else {
      return this.getWaitlistDetail();
    }
  }

  addItemToWaitlist(skuId: number, qty: number) {
    skuId = Number(this.cartService.skuId);
    qty = Number(this.cartService.qty);
    if (skuId == null || skuId <= 0) {
      this.notificationService.warn(
        'Waitlist',
        'Invalid size'
      )
      return;
    }
    if (qty == null || qty <= 0) {
      this.notificationService.warn(
        'Waitlist',
        'Invalid quantity'
      )
      return;
    }

    const item: WaitlistItemDTO = {};
    item.productId = this.getProductBySkuId(skuId).productId;
    item.skuId = skuId;
    item.quantity = qty;

    const items: Array<WaitlistItemDTO> = [];
    items.push(item);

    this.addItemsToWaitlist(items);
  }

  addItemsToWaitlist(items: Array<WaitlistItemDTO>) {
    this.getWaitlistDetail().subscribe(data => {

      let tempWaitlistItems = CommonUtils.cloneDeep(this.waitlist.items);
      if (tempWaitlistItems == null) {
        tempWaitlistItems = [];
      }

      let existing: boolean;
      for (const item of items) {
        existing = false;

        for (const tempWaitlistItem of tempWaitlistItems) {
          if (Number(tempWaitlistItem.skuId) === Number(item.skuId)) {
            tempWaitlistItem.quantity = Number(tempWaitlistItem.quantity) + Number(item.quantity);
            existing = true;
            break;
          }
        }

        if (!existing) {
          tempWaitlistItems.push(item);
        }
      }

      this.checkAndSaveWaitlist(Constants.ProductType.NORMAL, tempWaitlistItems);
    });
  }

  updateWaitlistItem(oriSkuId: number, skuId: number, qty: number) {
    oriSkuId = Number(oriSkuId);
    skuId = Number(skuId);
    qty = Number(qty);

    if (skuId == null || skuId <= 0) {
      this.notificationService.warn(
        'Waitlist',
        'Invalid size'
      )
      return;
    }
    if (qty == null || qty <= 0) {
      this.notificationService.warn(
        'Waitlist',
        'Invalid quantity'
      )
      return;
    }

    this.loadTempWaitlist = true;
    this.getWaitlistDetail().subscribe(() => {
      this.loadTempWaitlist = false;
      const tempWaitlistItems = this.tempWaitlist.items;

      let existingQty = 0;
      if (oriSkuId !== skuId) {
        for (let i = 0; i < tempWaitlistItems.length; i++) {
          if (tempWaitlistItems[i].skuId === skuId) {
            existingQty = Number(tempWaitlistItems[i].quantity);
            tempWaitlistItems.splice(i, 1);
            break;
          }
        }
      }

      for (const item of tempWaitlistItems) {
        if (item.skuId === oriSkuId) {
          item.skuId = skuId;
          item.quantity = qty + existingQty;
          break;
        }
      }

      this.checkAndSaveWaitlist(Constants.ProductType.NORMAL, tempWaitlistItems);
    });
  }

  removeItemFromWaitlist(skuId: number) {
    skuId = Number(skuId);

    if (skuId == null || skuId <= 0) {
      this.notificationService.warn(
        'Waitlist',
        'Invalid size'
      )
      return;
    }

    this.getWaitlistDetail().subscribe(() => {
      const tempWaitlistItems = CommonUtils.cloneDeep(this.waitlist.items);

      for (let i = 0; i < tempWaitlistItems.length; i++) {
        if (tempWaitlistItems[i].skuId === skuId) {
          tempWaitlistItems.splice(i, 1);
          break;
        }
      }

      this.checkAndSaveWaitlist(Constants.ProductType.NORMAL, tempWaitlistItems);
    });
  }

  checkAndSaveWaitlist(productType: number, allItems: Array<WaitlistItemDTO>) {

    this.saveWaitlist(productType, allItems).subscribe();
  }



  saveWaitlist(productType: number, items: Array<WaitlistItemDTO>): Observable<any> {
    const postbody = <WaitlistDTO>{};

      postbody.items = items;

    return this.waitlistApi.saveWaitlistUsingPOST(postbody).pipe(tap(
      response => {
        //when status == 200 & 204, execute happy flow
        if (response.status == 200 || response.status == 204){ 
          if (productType === Constants.ProductType.NORMAL) {
            this.waitlist.items = items;
          }

          this.notificationService.success(
            'Waitlist',
            'Updated successfully'
          );

        // other than 200, 204, 401, 403, raise error
        }else if (response.status != 401 && response.status != 403){
          this.notificationService.error(
            'Waitlist',
            'Not updated, please try again later'
          )
          this.getWaitlistDetail().subscribe();
        }
      }
    ), catchError(
      err => {
        this.notificationService.error(
          'Waitlist',
          'Not updated, please try again later'
        )
        return err;
      }
      )).pipe(flatMap(() => this.getWaitlistDetail()));
  }

    getWaitlistItems(): number {
      let result = 0;
      if (this.waitlist) {
          this.waitlist.items.forEach((item) => result += item.quantity);
    }
    return result;
   }

     addDetailedItemToWaitlist(skuId: number, qty: number) {
       skuId = Number(skuId);
       qty = Number(qty);
       if (skuId == null || skuId <= 0) {
         this.notificationService.warn(
           'Waitlist',
           'Invalid size'
         )
         return;
       }
       if (qty == null || qty <= 0) {
         this.notificationService.warn(
           'Waitlist',
           'Invalid quantity'
         )
         return;
       }

       const item: WaitlistItemDTO = {};
       item.productId = this.getProductBySkuId(skuId).productId;
       item.skuId = skuId;
       item.quantity = qty;

       const items: Array<WaitlistItemDTO> = [];
       items.push(item);

       this.addItemsToWaitlist(items);
     }
}




