/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class UserManagementApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {}

    /**
     * GetManagerUsers
     * Get Manager users
     * @param isAdmin to set the if the requestor is admin
     * @param page to set the index of pages to retrieve the managers. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param username to set the username of manager to be retrieved. If it is set, the username must be matched.
     */
    public getManagerUsersUsingGET(isAdmin: boolean, page: number, size: number, username?: string, extraHttpRequestParams?: any): Observable<models.GetManagerUsersResponse> {
        return this.getManagerUsersUsingGETWithHttpInfo(isAdmin, page, size, username, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * saveManager
     * Create or update manager
     * @param request request
     */
    public saveManagerUsingPOST(request: models.SaveManagerPayload, extraHttpRequestParams?: any): Observable<models.SaveManagerResponse> {
        return this.saveManagerUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * GetManagerUsers
     * Get Manager users
     * @param isAdmin to set the if the requestor is admin
     * @param page to set the index of pages to retrieve the managers. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param username to set the username of manager to be retrieved. If it is set, the username must be matched.
     */
    public getManagerUsersUsingGETWithHttpInfo(isAdmin: boolean, page: number, size: number, username?: string, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/userManagement/managers';

        let queryParameters = new HttpParams();

        // verify required parameter 'isAdmin' is not null or undefined
        if (isAdmin === null || isAdmin === undefined) {
            throw new Error('Required parameter isAdmin was null or undefined when calling getManagerUsersUsingGET.');
        }
        // verify required parameter 'page' is not null or undefined
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getManagerUsersUsingGET.');
        }
        // verify required parameter 'size' is not null or undefined
        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling getManagerUsersUsingGET.');
        }
        if (username !== undefined) {
            queryParameters = queryParameters.append('username', <any>username);
        }

        if (isAdmin !== undefined) {
            queryParameters = queryParameters.append('isAdmin', <any>isAdmin);
        }

        if (page !== undefined) {
            queryParameters = queryParameters.append('page', <any>page);
        }

        if (size !== undefined) {
            queryParameters = queryParameters.append('size', <any>size);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
    }

    /**
     * saveManager
     * Create or update manager
     * @param request request
     */
    public saveManagerUsingPOSTWithHttpInfo(request: models.SaveManagerPayload, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/userManagement/managers';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveManagerUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

}
