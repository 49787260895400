import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FaqService } from 'app/services/faq.service';
import { FaqsComponent } from '../faqs.component';

@Component({
  selector: 'app-left-navbar',
  templateUrl: './left-navbar.component.html',
  styleUrls: ['./left-navbar.component.css']
})
export class LeftNavbarComponent implements OnInit {

  getList: any;
  getListContent: any;

  /***********************************************************
  *   this selectedList EventEmitter will pass the list to   *
  *   parent component which is faqs.component.ts            *
  ************************************************************/
  @Output() selectedList = new EventEmitter<any[]>();

  constructor(private faqService: FaqService) { }

  ngOnInit() {

    /******************************************************
    *   Get FAQ json content from faqService.service.ts   *
    *   and store it inside variable "getList"            *
    ******************************************************/
    this.faqService.getnavitem().subscribe(
      item => {
        this.getList = item
      },
      error => {
        console.log(error);
      }
    );

    /***************************************************************
    *   Set the first item, "Collection Queries", in the list to   *
    *   active when the page loads the first time                  *
    ***************************************************************/
    this.getList[0].clicked = true;
  }

  //onSelected function is triggered when user click the item of the navbar
  onSelected(index, item) {

    /*********************************************************** 
    *   Get the list by filtering with "index" and "content"   *
    *   and pass the list to EventEmitter                      *
    ***********************************************************/
    this.getListContent = this.getList[index]['content'];
    this.selectedList.emit(this.getListContent); 

    
    /******************************************************************
    *   Add one more (key,value) to each of the element in the list.  *
    *                         key - clicked                           *
    *                         value - false                           *
    *                      eg. clicked : false                        *
    ******************************************************************/
    this.getList.forEach(elem => { elem.clicked = false });


    /************************************************************************* 
    *   Change the value of "clicked" to "true" when the item is selected.   *
    *   when it is true, it will trigger the "clicked" class in css.         *
    *************************************************************************/
    item.clicked = true;
  }
}
