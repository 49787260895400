<section id="masthead" [class.scdf]="userProfileService?.userProfile?.serviceUnit === 'SCDF'"
                    [class.ica]="userProfileService?.userProfile?.serviceUnit === 'ICA'"
                    [class.sps]="userProfileService?.userProfile?.serviceUnit === 'SPS'"
    class="hidden-print">
    <div class="container-fluid">
        <div class="container">
            <div class="emart-logo">
                <a routerLink="dashboard">
                    <img src="{{configSvc.deployPath}}./assets/images/masthead-logo-emart.png" alt="">
                </a>
            </div>
            <div class="unit-logo">
                <img src="{{configSvc.deployPath}}./assets/images/masthead-logo-{{userProfileService?.userProfile?.serviceUnit?.toLowerCase()}}.png"
                    alt="">
            </div>
        </div>
    </div>
</section>
<nav class="navbar navbar-default hidden-print">
    <div class="container-fluid">
        <div class="container">
            <!-- Brand and toggle get grouped for better mobile display -->
            <div class="navbar-header">
                <div class="unit-logo-nav">
                    <img src="{{configSvc.deployPath}}./assets/images/unitlogo-{{userProfileService?.userProfile?.serviceUnit?.toLowerCase()}}.png"
                        alt="">
                </div>
                <div class="emart-logo-nav">
                    <a routerLink="dashboard">
                        <img src="{{configSvc.deployPath}}./assets/images/masthead-logo-emart.png" alt="">
                    </a>
                </div>
                <a href="#menu" class="menu-link">
                    <span class="glyphicon glyphicon-menu-hamburger" aria-hidden="true"></span>
                </a>
                <!--<a class="navbar-logo" href="#">
                <div class="logo"></div>
                </a>-->
            </div>
            <!-- Collect the nav links, forms, and other content for toggling -->
            <div class="collapse navbar-collapse" id="defaultNavbar1">
                <ul class="nav navbar-nav navbar-padding flex-row">
                    <li class="dropdown" routerLinkActive="active">
                        <a class="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                            Catalogue
                        </a>
                        <ul class="dropdown-menu" role="menu">
                            <li routerLinkActive="active">
                                <a routerLink="/view-products/All">View all</a>
                            </li>
                            <li *ngFor="let category of catalogueService.categories;" routerLinkActive="active">
                                <a
                                    [routerLink]="['/view-products/'+category.categoryName]">{{category.categoryName}}</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a routerLink="view-uniforms" routerLinkActive="active">Tailored Uniform</a>
                    </li>
                    <li>
                        <a routerLink="view-name-tags" routerLinkActive="active">Name Tag</a>
                    </li>
                    <li>
                        <a routerLink="credits" routerLinkActive="active">Credits</a>
                    </li>

                    <!-- a parent element with routerLinkActive directive will look for all sub-elements routerLinks, and add the class if anyone of them is activated. -->
                    <li class="dropdown" routerLinkActive="active">
                        <a class="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-expanded="false">
                            Orders
                        </a>
                        <ul class="dropdown-menu" role="menu">
                            <li routerLinkActive="active">
                                <a routerLink="current-orders">Recent Orders</a>
                            </li>
                            <li routerLinkActive="active">
                                <a routerLink="transaction-history">Transaction History</a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul class="nav navbar-nav navbar-right flex-row">
                        <li data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Waitlist" >
                            <a style="text-decoration: underline; cursor:pointer;" (click)="gotoWaitlist()"
                                routerLinkActive="active">
                                <span class="glyphicon glyphicon-star" aria-hidden="true"></span>
                                <span class="waitlist-qty">{{getWaitlistItems()}}</span>
                            </a>
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-placement="top" title="Shopping Cart">
                            <a style="text-decoration: underline; cursor:pointer;" (click)="gotoCart()"
                                routerLinkActive="active">
                                <span class="glyphicon glyphicon-shopping-cart" aria-hidden="true"></span>
                                <span class="cart-qty">{{getCartItems()}}</span>
                            </a>
                        </li>

                        <!--  Dropdown Menu: Displays Username logged In and Profile & LogOut Buttons                        -->
                        <li data-bs-toggle="tooltip" data-bs-placement="top" title="My Profile">
                            <a class="dropdown" data-bs-toggle="dropdown" role="button" aria-expanded="false"
                                routerLinkActive="active">
                                <span class="glyphicon glyphicon-user" aria-hidden="true"></span>
                            </a>
                            <ul class="dropdown-menu" role="menu">
                                <li class="logged-in">You're logged in as
                                    <strong>{{userProfileService?.userProfile?.name}}</strong>
                                </li>
                                <li>
                                    <hr class="dropdown-divider">
                                </li>
                                <li routerLinkActive="active">
                                    <a routerLink="profile" routerLinkActive="active">My Profile</a>
                                </li>
                                <li routerLinkActive="active">
                                    <a href="" data-bs-toggle="modal" data-bs-target="#logout-popup">Logout</a>
                                </li>
                            </ul>
                        </li>
                        <!--Logout Icon Added-->
                        <li data-bs-toggle="tooltip" data-bs-placement="top" title="Logout" >
                            <a style="text-decoration: underline; cursor:pointer;" data-bs-toggle="modal" data-bs-target="#logout-popup"
                                routerLinkActive="active">
                            <span class="glyphicon glyphicon-log-out"  aria-hidden="true"></span>
                            </a>
                        </li>
                        <li data-bs-toggle="tooltip" data-bs-placement="top" title="Search">
                        <form class="navbar-form navbar-right navbar-form-search" role="search" >
                            <div class="search-form-container hdn" id="search-input-container">
                                <div class="search-input-group">
                                    <button type="button" class="btn" (click)="searchFor('')"
                                        id="hide-search-input-container">
                                        <span class="glyphicon glyphicon-triangle-right" aria-hidden="true"></span>
                                    </button>
                                    <div class="form-group">
                                        <input #searchField type="text" [value]="searchString"
                                            (keyup)="searchFor($event.target.value)" class="form-control"
                                            placeholder="Search for...">
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn" id="search-button" (click)="searchField.focus()"
                            >
                                <span class="glyphicon glyphicon-search" aria-hidden="true"></span>
                            </button>
                        </form>
                    </li>
                    </ul>
                </div>
            </div>
            <!-- /.navbar-collapse -->
        </div>
    </div>
    <!-- /.container-fluid -->
</nav>