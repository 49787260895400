import * as _ from 'lodash';

export class CommonUtils {

    // https://lodash.com/docs/4.17.4#isEqual
    // This method supports comparing objects, arrays, strings...
    public static isDeepEqual(obj1: any, obj2: any): boolean {
        return _.isEqual(obj1, obj2);
    }

    public static cloneDeep(obj: any) {
        return _.cloneDeep(obj);
    }

    public static isEmptyArray(arr: Array<any>): boolean {
        return !arr || !arr.length;
    }

}
