import { ResourceService } from './resource.service';
import { Observable } from 'rxjs';
import { OrderDTO } from './../shared/backend-api/emart';
import { Injectable } from '@angular/core';

@Injectable()
export class CurrentOrdersService {

  public currentOrders: OrderDTO[] = null;

  public orderPrint = {
    prevPath: '',
    order: null,
    imageStore: null,
    searchOption: null,
    timeRangeOption: null,
    currentFromDate: null,
    currentToDate: null,
    currentOrderListData: null,
    currentPage: null,
    recentPurchaseData: null
  };

  updateOrder(updatedOrder: OrderDTO) {
    if (this.currentOrders === null) {
      return;
    }

    for (let i = 0; i < this.currentOrders.length; i++) {
      if (this.currentOrders[i].orderId === updatedOrder.orderId) {
        this.currentOrders[i] = updatedOrder;
        break;
      }
    }
  }

  removeOrder(updatedOrder: OrderDTO) {
    if (this.currentOrders === null) {
      return;
    }

    for (let i = 0; i < this.currentOrders.length; i++) {
      if (this.currentOrders[i].orderId === updatedOrder.orderId) {
        this.currentOrders.splice(i, 1);
        break;
      }
    }
  }

}
