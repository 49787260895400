import { Component, OnInit } from '@angular/core';
import { FaqService } from 'app/services/faq.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {

  /**************************************************************** 
  *   listSelected is used to transfer the EventEmitter from      *
  *   left-Navbar.component.ts to question-answer.component.ts,   *
  ****************************************************************/
  listSelected: any[];

  getList: string;

  constructor(private faqService: FaqService) { }

  ngOnInit() {
    
    /******************************************************
    *   Get FAQ json content from faqService.service.ts   *
    *   and store it inside variable "getList"            *
    ******************************************************/
    this.faqService.getnavitem().subscribe(
      item => {
        this.getList = item
      },
      error => {
        console.log(error);
      }
    );


    /************************************************************** 
    *   Set the right-side content to display the first element   *
    *   in the list once the page loads for the first time        *
    **************************************************************/
    this.faqService.setlist(this.getList[0]['content']);
  }
}
