<div class="row">
    <div class="col-6"></div>
    <div class="col-6">
      <a class="align-right" [attr.innerText]="expandText " (click)="changetext()">{{expandText}} 
        <i class="fa fa-angle-double-right" aria-hidden="true"></i>
      </a>
    </div>
</div>

<div class="accordion" id="accordionExample">
    <div class="accordion-item" *ngFor="let item of selectedListReceive; let i = index">
        <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#a'+i" aria-expanded="false" aria-controls="collapseOne" (click)="onQuestionClick(item)">
                {{i+1}}. {{item.question}} 
            </button>
        </h2>
        <div [attr.id]="'a'+i" class="accordion-collapse collapse" aria-labelledby="headingOne"  >
            <div 
                class="accordion-body text-justify"
                [innerHtml]="item.answer"
            >
            </div>
        </div>
    </div>
</div>
