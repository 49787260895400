import { UserProfileService } from '../../services/user-profile.service';
import { Observable, of, forkJoin, catchError } from 'rxjs';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class FeedbackGuard implements Resolve<any> {

  constructor(private userProfileService: UserProfileService,
    private router: Router) { };

  resolve(router: ActivatedRouteSnapshot) {
    const $obs = forkJoin({
      userProfile: this.userProfileService.loadUserProfile()
    }).pipe(catchError(err => {
      this.router.navigate(['/general-error']);
      return of(err);
    }));

    return $obs;
  }
}
