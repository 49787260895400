/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as models from './models';

export interface FaqDTO {
    answer?: string;

    faqGroup?: FaqDTO.FaqGroupEnum;

    question?: string;

}
export namespace FaqDTO {
    export enum FaqGroupEnum {
        CREDITQUERIES = <any> 'CREDIT_QUERIES',
        COLLECTIONQUERIES = <any> 'COLLECTION_QUERIES',
        EXCHANGEPOLICY = <any> 'EXCHANGE_POLICY',
        PORTALQUERIES = <any> 'PORTAL_QUERIES'
    }
}
