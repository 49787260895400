import { AddressDTO } from 'app/shared/backend-api/emart';
import { Component, OnInit } from '@angular/core';
import { UserLocationDTO } from '../../../shared/backend-api/emart/index';
import { StoreConfigService } from './../../../services/store-config.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-tailor-list',
  templateUrl: './tailor-list.component.html',
  styleUrls: ['./tailor-list.component.css']
})
export class TailorListComponent implements OnInit {

  private router: Router;

  // Pagination
  private currentPage = 1;
  private itemPerPage = 10;
  private pageCount = 1;
  private nextPaginationDisabled = false;
  private prevPaginationDisabled = false;
  // ---

  constructor(
    public storeConfigService: StoreConfigService,
  ) { }

  ngOnInit() {
    this.currentPage = 1;

    this.storeConfigService.loadTailors().subscribe(() => {
      this.updatePagination();
    });
  }

  // Pagination
  getItemsToDisplay(): any[] {
    if (this.storeConfigService.tailors && this.storeConfigService.tailors.length > 0) {
      const firstItemIndex = (this.currentPage - 1) * this.itemPerPage;
      const endItemIndex = this.currentPage * this.itemPerPage;
      return this.storeConfigService.tailors.slice(firstItemIndex, endItemIndex);
    } else {
      return [];
    }
  }

  updatePagination() {
    try {
      this.pageCount = Math.ceil(this.storeConfigService.tailors.length / this.itemPerPage);
      this.currentPage = this.currentPage > this.pageCount ? this.pageCount : this.currentPage;
      this.nextPaginationDisabled = this.currentPage * this.itemPerPage >= this.storeConfigService.tailors.length;
      this.prevPaginationDisabled = this.currentPage <= 1;
    } catch (e) {
      this.pageCount = 1;
      this.currentPage = 1;
      this.nextPaginationDisabled = true;
      this.prevPaginationDisabled = true;
    }
  }

  gotoPreviousPage() {
    this.gotoPage(Number(this.currentPage) - 1);
  }

  gotoNextPage() {
    this.gotoPage(Number(this.currentPage) + 1);
  }

  gotoFirstPage() {
    this.gotoPage(1);
  }

  gotoLastPage() {
    this.gotoPage(this.pageCount);
  }

  gotoPage(page: number, event?: any) {
    if (event && event.key !== 'Enter') {
      return;
    }

    if (page > 0 && page <= this.pageCount) {
      this.currentPage = page;
      this.updatePagination();
    }
  }
  // ---

  viewMap(address: AddressDTO) {
    let url = 'https://www.google.com.sg/maps/place/';
    url += address.blkNo + ' ' + address.streetName + ' ';
    if (address.buildingName) {
      url += address.buildingName + ' ';
    }
    url += 'Singapore ' + address.postalCode;
    url = encodeURI(url);
    window.open(url, '_blank');
  }

}
