<div class="container">
    <div>
        <!-- sidebar -->
        <app-sidebar [showUnit]="false" [showCatalogue]="true"></app-sidebar>
        <simple-notifications [options]="notificationOptions"></simple-notifications>
        <!-- content -->
        <div class="col-md-9 col-sm-8 col-xs-12">
            <div class="collapse-box">
                <div class="collapse in">
                    <div class="box-content">
                        <div class="catalogue-head">
                            <ol class="breadcrumb">
                                <li class="active" *ngIf="!filterByCategory">Catalogue</li>
                                <li *ngIf="filterByCategory">
                                    <a [routerLink]="['/view-products']">Catalogue</a>
                                </li>
                                <li class="active" *ngIf="filterByCategory">{{filterByCategory}}</li>
                            </ol>
                            <div class="sort-box">
                                <a href="javascript:void(0)" (click)="updateViewMode(false);" id="gridViewIcon">
                                    <span class="glyphicon glyphicon-th-large" aria-hidden="true"></span>
                                </a>
                                <a href="javascript:void(0)" (click)="updateViewMode(true);" id="listViewIcon">
                                    <span class="glyphicon glyphicon-th-list" aria-hidden="true"></span>
                                </a>
                                <div>Sort by</div>
                                <select [(ngModel)]="sortType" name="sortby" class="sortby form-control" (change)="selectSortProductType($event)">
                                    <option value="0" selected>A - Z</option>
                                    <option value="1">Z - A</option>
                                    <option value="2">Price from lowest to highest</option>
                                    <option value="3">Price from highest to lowest</option>
                                </select>
                            </div>
                        </div>
                        <div class="alert alert-info text-center" role="alert" *ngIf="originProducts != null && getProductsToDisplay().length == 0">
                            <i class="fa fa-info-circle fa-2x"></i>
                            <p>There is no product found.
                                <br> You can try to change category or search text.</p>
                        </div>
                        <div class="alert alert-danger text-center" role="alert" *ngIf="loadProductsFailed">
                            <i class="fa fa-exclamation-triangle fa-2x"></i>
                            <p *ngIf="!invalidRank">Some error occured, please refresh the page and try again later!</p>
                            <p *ngIf="invalidRank">Unable to show catalogue because your rank is invalid</p>
                        </div>
                        <div class="row catalogue-item-wrapper" *ngIf="listView">
                            <div class="col-md-4 col-sm-6 col-xs-6 catalogue-item list-view" *ngFor="let product of getProductsToDisplay()">
                              <!-- Inactive sku addToCart Modal -->
                              <div id="select-inactive-pop-up" class="modal fade" role="dialog">
                                <div class="modal-dialog modal-xs">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h1 class="modal-title">Warning Pop-up</h1>
                                    </div>
                                    <div class="modal-body">
                                      <h4>The item is currently out of stock. Would you like to add it into your waitlist? </h4>
                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" class="btnModel" data-bs-dismiss="modal" (click)="waitlistService.addItemToWaitlist(selectSize.value, selectQty.value)">Yes</button>
                                      <button type="button" class="btnModel" data-bs-dismiss="modal">No</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                                <table>
                                    <tr>
                                        <td class="list-1">
                                            <a class="list-img" [routerLink]="['/catalogue/products/', product['productId']]">
                                                <img [src]="imagestore[product.productId] || configSvc.dummyImgPath" alt="" class="img-responsive">
                                            </a>
                                            <div class="item-details">
                                              <a [routerLink]="['/catalogue/products/', product['productId']]">
                                                    <h4>{{product.productName}}</h4>
                                              </a>
                                                <h5>{{product.productPrice | priceFormat}}</h5>
                                            </div>
                                        </td>
                                        <td class="list-2">
                                            <div class="item-options">
                                                <div class="size">
                                                    Size
                                                    <select #selectSize name="size" class="form-control" [disabled]="product.skus.length == 1">
                                                        <option value="-1" *ngIf="product.skus.length > 1">-Select-</option>
                                                        <option value="{{sku.skuId}}" *ngFor="let sku of product.skus" [selected]="sku.preferred">
                                                          {{
                                                          (sku.skuName == null && sku.status == true) ? 'N.A' :
                                                          (sku.skuName != null && sku.status == false)? sku.skuName+' (Out of stock)' :
                                                          (sku.skuName == null && sku.status == false) ? 'N.A (Out of stock)' :
                                                          sku.skuName
                                                          }}</option>
                                                    </select>
                                                </div>
                                                <div class="qty">
                                                    Quantity
                                                    <select #selectQty name="qty" class="form-control">
                                                        <option value="1" selected>1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="list-3">
                                            <div class="list-btn">
                                                <button type="button" class="btn" (click)="cartService.checkItem(product, selectSize.value, selectQty.value)">
                                                  <span class="glyphicon glyphicon-plus" aria-hidden="true"></span>Add to Cart</button>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="text-center" *ngIf="originProducts == null && !loadProductsFailed">
                            <i class="fa fa-refresh fa-2x spinner text-primary"></i>
                        </div>
                        <div class="row catalogue-item-wrapper" *ngIf="!listView">
                            <div class="col-md-4 col-sm-6 col-xs-6 catalogue-item" *ngFor="let product of getProductsToDisplay(); let i=index">
                              <!-- Inactive sku addToCart Modal -->
                              <div id="select-inactive-pop-up" class="modal fade" role="dialog">
                                <div class="modal-dialog modal-xs">
                                  <div class="modal-content">
                                    <div class="modal-header">
                                      <h1 class="modal-title">Warning Pop-up</h1>
                                    </div>
                                    <div class="modal-body">
                                      <h4>The item is currently out of stock. Would you like to add it into your waitlist? </h4>
                                    </div>
                                    <div class="modal-footer">
                                      <button type="button" class="btnModel" data-bs-dismiss="modal" (click)="waitlistService.addItemToWaitlist(selectSize.value, selectQty.value)">Yes</button>
                                      <button type="button" class="btnModel" data-bs-dismiss="modal">No</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                                <a [routerLink]="['/catalogue/products/', product['productId']]">
                                    <img [src]="imagestore[product.productId] || configSvc.dummyImgPath" alt="" class="img-responsive">
                                </a>
                                <div class="item-details">
                                    <a [routerLink]="['/catalogue/products/', product['productId']]">
                                        <h4>{{product.productName}}</h4>
                                    </a>
                                    <h5>{{product.productPrice | priceFormat}}</h5>
                                </div>
                                <div class="item-options">
                                    <div class="size">
                                        Size
                                        <select #selectSize name="size" class="form-control" [disabled]="product.skus.length == 1">
                                            <option value="-1" *ngIf="product.skus.length > 1">-Select-</option>
                                            <option value="{{sku.skuId}}" *ngFor="let sku of product.skus" [selected]="sku.preferred">
                                              {{
                                              (sku.skuName == null && sku.status == true) ? 'N.A' :
                                              (sku.skuName != null && sku.status == false)? sku.skuName+' (Out of stock)' :
                                              (sku.skuName == null && sku.status == false) ? 'N.A (Out of stock)' :
                                              sku.skuName
                                              }}</option>
                                        </select>
                                    </div>
                                    <div class="qty">
                                        Quantity
                                        <select name="qty" #selectQty class="form-control">
                                            <option value="1" selected>1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>
                                    </div>
                                </div>
                                <button type="button" class="btn" (click)="cartService.checkItem(product, selectSize.value, selectQty.value)">
                                  <span class="glyphicon glyphicon-plus" aria-hidden="true"></span>Add to Cart</button>
                            </div>
                        </div>
                        <div *ngIf="getProductsToDisplay().length > 0">
                            <div class="catalogue-foot">
                                <div class="items-per-page">
                                    <select name="per-page" class="form-control" (change)="updateItemPerPage($event);">
                                        <option value="6" [selected]="itemPerPage == 6">6 items per page</option>
                                        <option value="12" [selected]="itemPerPage == 12">12 items per page</option>
                                    </select>
                                </div>
                                <div class="pagination">
                                    <button type="button" class="btn btn-square" [disabled]="prevPaginationDisabled" (click)="gotoFirstPage()">
                                        <span class="glyphicon glyphicon-step-backward" aria-hidden="true"></span>
                                    </button>
                                    <button type="button" class="btn btn-square" [disabled]="prevPaginationDisabled" (click)="gotoPreviousPage()">
                                        <span class="glyphicon glyphicon-triangle-left" aria-hidden="true"></span>
                                    </button>
                                    <div class="page">
                                        <input #page type="text" class="form-control" [value]="currentPage" (keyup)="gotoPage(page.value, $event)"> / {{pageCount}}
                                    </div>
                                    <button type="button" class="btn btn-square" [disabled]="nextPaginationDisabled" (click)="gotoNextPage()">
                                        <span class="glyphicon glyphicon-triangle-right" aria-hidden="true"></span>
                                    </button>
                                    <button type="button" class="btn btn-square" [disabled]="nextPaginationDisabled" (click)="gotoLastPage()">
                                        <span class="glyphicon glyphicon-step-forward" aria-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
