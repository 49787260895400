/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class OrdersApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {}

    /**
     * cancelOrder
     * Cancel order items or order
     * @param request request
     */
    public cancelOrderUsingPOST(request: models.CancelOrderRequest, extraHttpRequestParams?: any): Observable<models.CancelOrderResponse> {
        return this.cancelOrderUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * CreateOrder
     * Create order
     * @param request request
     */
    public createOrderUsingPOST(request: models.CartDTO, extraHttpRequestParams?: any): Observable<models.CreateOrderResponse> {
        return this.createOrderUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * generateSecretCode
     * Generate secret code for order
     * @param request request
     */
    public generateSecretCodeUsingPOST(request: models.GenerateSecretCodeRequest, extraHttpRequestParams?: any): Observable<models.GenerateSecretCodeResponse> {
        return this.generateSecretCodeUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * GetCurrentOrders
     * Get current orders
     */
    public getCurrentOrdersUsingGET(extraHttpRequestParams?: any): Observable<models.GetCurrentOrdersResponse> {
        return this.getCurrentOrdersUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getOrders
     * Get orders
     * @param from The start date of the credit hisotry. Included. In yyyy-MM-dd format.
     * @param to The end date of the credit hisotry. Included. In yyyy-MM-dd format.
     */
    public getOrdersUsingGET(from: string, to: string, extraHttpRequestParams?: any): Observable<models.GetOrdersResponse> {
        return this.getOrdersUsingGETWithHttpInfo(from, to, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * GetRecentPurchases
     * Get User Recent Purchases
     */
    public getRecentPurchasesUsingGET(extraHttpRequestParams?: any): Observable<models.GetRecentPurchasesResponse> {
        return this.getRecentPurchasesUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getRefundableAmount
     * Get refundable amount of an order
     * @param id id
     */
    public getRefundableAmountUsingGET(id: number, extraHttpRequestParams?: any): Observable<models.GetRefundableAmountResponse> {
        return this.getRefundableAmountUsingGETWithHttpInfo(id, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * updateOrder
     * Update order&#39;s delivery mode/collection point
     * @param request request
     */
    public updateOrderUsingPOST(request: models.UpdateOrderRequest, extraHttpRequestParams?: any): Observable<models.UpdateOrderResponse> {
        return this.updateOrderUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * cancelOrder
     * Cancel order items or order
     * @param request request
     */
    public cancelOrderUsingPOSTWithHttpInfo(request: models.CancelOrderRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/orders/cancel';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling cancelOrderUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * CreateOrder
     * Create order
     * @param request request
     */
    public createOrderUsingPOSTWithHttpInfo(request: models.CartDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/orders';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling createOrderUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * generateSecretCode
     * Generate secret code for order
     * @param request request
     */
    public generateSecretCodeUsingPOSTWithHttpInfo(request: models.GenerateSecretCodeRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/orders/generateSecretCode';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling generateSecretCodeUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * GetCurrentOrders
     * Get current orders
     */
    public getCurrentOrdersUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/orders/current';
        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getOrders
     * Get orders
     * @param from The start date of the credit hisotry. Included. In yyyy-MM-dd format.
     * @param to The end date of the credit hisotry. Included. In yyyy-MM-dd format.
     */
    public getOrdersUsingGETWithHttpInfo(from: string, to: string, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/orders';

        let queryParameters = new HttpParams();

        // verify required parameter 'from' is not null or undefined
        if (from === null || from === undefined) {
            throw new Error('Required parameter from was null or undefined when calling getOrdersUsingGET.');
        }
        // verify required parameter 'to' is not null or undefined
        if (to === null || to === undefined) {
            throw new Error('Required parameter to was null or undefined when calling getOrdersUsingGET.');
        }
        if (from !== undefined) {
            queryParameters = queryParameters.append('from', <any>from);
        }

        if (to !== undefined) {
            queryParameters = queryParameters.append('to', <any>to);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
    }

    /**
     * GetRecentPurchases
     * Get User Recent Purchases
     */
    public getRecentPurchasesUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/orders/recent';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getRefundableAmount
     * Get refundable amount of an order
     * @param id id
     */
    public getRefundableAmountUsingGETWithHttpInfo(id: number, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/orders/${id}/refundable'
            .replace('${' + 'id' + '}', String(id));

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getRefundableAmountUsingGET.');
        }
        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * updateOrder
     * Update order&#39;s delivery mode/collection point
     * @param request request
     */
    public updateOrderUsingPOSTWithHttpInfo(request: models.UpdateOrderRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/orders/update';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling updateOrderUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

}
