import { Observable, of, forkJoin, catchError } from 'rxjs';
import { Router, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserProfileService } from './../../services/user-profile.service';
import { StoreConfigService } from './../../services/store-config.service';
import { ProductsService } from './../../services/products.service';
import { CartService } from './../../services/cart.service';
import { Constants } from './../../shared/global-constants/constants';

@Injectable()
export class CurrentOrdersGuard implements Resolve<any> {

  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    private storeConfigService: StoreConfigService,
    private productService: ProductsService,
    private cartService: CartService) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const $obs = forkJoin({
      cartDetail: this.cartService.getCartDetail(),
      deliveryFee: this.cartService.getDeliveryFee(),
      creditSummary: this.userProfileService.loadCreditSummary(),
      allDeliveryModes: this.storeConfigService.loadAllDeliveryModes(),
      deliveryModes: this.storeConfigService.loadDeliveryModes(),
      allTailorDeliveryModes: this.storeConfigService.loadAllTailorDeliveryModes(),
      tailorDeliveryModes: this.storeConfigService.loadTailorDeliveryModes(),
      allNameTagDeliveryModes: this.storeConfigService.loadAllNameTagDeliveryModes(),
      nameTagDeliveryModes: this.storeConfigService.loadNameTagDeliveryModes(),
      collectionPointSelections: this.storeConfigService.loadCollectionPointSelections(),
      elockerSelections: this.storeConfigService.loadElockerSelections(true),
      walkInCollectionPointSelections: this.storeConfigService.loadWalkInCollectionPointSelections(),
      collectionPoints: this.storeConfigService.loadCollectionPoints(),
      elockers: this.storeConfigService.loadElockers(),
      walkInCollectionPoints: this.storeConfigService.loadWalkInCollectionPoints(),
      productCaps: this.productService.loadProductCaps(),
      productsNormal: this.productService.loadProducts(Constants.ProductType.NORMAL),
      productsTailor: this.productService.loadProducts(Constants.ProductType.TAILOR),
      productsNameTag: this.productService.loadProducts(Constants.ProductType.NAME_TAG)
    }).pipe(catchError(error => {
      this.router.navigate(['/general-error']);
        return of(error);
    }));

    return $obs;
  }

}
