/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class CommonApi {
  protected basePath = environment.apiBaseUrl;
  public defaultHeaders: Headers = new Headers();
  public configuration: Configuration = new Configuration();

  constructor(protected httpClient: HttpClient) {}

  /**
   * addRankCaveat
   * Add Rank Caveat
   * @param request request
   */
  public addRankCaveatUsingPOST(request: models.AddRankCaveatRequest, extraHttpRequestParams?: any): Observable<models.AddRankCaveatResponse> {
    return this.addRankCaveatUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getAllCollectionPoints
   * Get all active collection points (including eLockers and walk-in collection points)
   */
  public getAllCollectionPointsUsingGET(extraHttpRequestParams?: any): Observable<models.GetAllCollectionPointsResponse> {
    return this.getAllCollectionPointsUsingGETWithHttpInfo(extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getCapTypeSelections
   * Get cap type selections
   */
  public getCapTypeSelectionsUsingGET(extraHttpRequestParams?: any): Observable<models.GetCapTypeSelectionsResponse> {
    return this.getCapTypeSelectionsUsingGETWithHttpInfo(extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getCollectionPointSelectionsForUser
   * Get collection point selections for user
   * @param cp Include collection points
   * @param el Include eLockers
   * @param cel Check eLockers' availability
   * @param hd Include home delivery
   * @param wi Include walk-in collection points
   * @param tr Include tailors
   */
  public getCollectionPointSelectionsForUserUsingGET(cp: boolean, el: boolean, cel: boolean, hd: boolean, wi: boolean, tr: boolean, extraHttpRequestParams?: any): Observable<models.GetCollectionPointSelectionsForUserResponse> {
    return this.getCollectionPointSelectionsForUserUsingGETWithHttpInfo(cp, el, cel, hd, wi, tr, extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getCollectionPointSelections
   * Get collection point selections
   * @param cp Include collection points
   * @param el Include eLockers
   * @param hd Include home delivery
   * @param wi Include walk-in collection points
   * @param tr Include tailors
   */
  public getCollectionPointSelectionsUsingGET(cp: boolean, el: boolean, hd: boolean, wi: boolean, tr: boolean, extraHttpRequestParams?: any): Observable<models.GetCollectionPointSelectionsResponse> {
    return this.getCollectionPointSelectionsUsingGETWithHttpInfo(cp, el, hd, wi, tr, extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * GetCollectionPoints
   * Get collection points
   */
  public getCollectionPointsUsingGET(extraHttpRequestParams?: any): Observable<models.GetCollectionPointsResponse> {
    return this.getCollectionPointsUsingGETWithHttpInfo(extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getDeliveryFee
   * Get delivery fee
   */
  public getDeliveryFeeUsingGET(extraHttpRequestParams?: any): Observable<models.GetDeliveryFeeResponse> {
    return this.getDeliveryFeeUsingGETWithHttpInfo(extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getDeliveryModes
   * Get delivery modes
   * @param all Include all delivery modes, regardless of sys configs, default &#x3D; false
   */
  public getDeliveryModesUsingGET(all?: boolean, extraHttpRequestParams?: any): Observable<models.GetDeliveryModesResponse> {
    return this.getDeliveryModesUsingGETWithHttpInfo(all, extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getElockers
   * Get eLockers
   */
  public getElockersUsingGET(extraHttpRequestParams?: any): Observable<models.GetElockerResponse> {
    return this.getElockersUsingGETWithHttpInfo(extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getFeedbackSubjectSelections
   * Get feedback subject selections
   */
  public getFeedbackSelectionsUsingGET(extraHttpRequestParams?: any): Observable<models.GetFeedbackSubjectSelectionsResponse> {
    return this.getFeedbackSelectionsUsingGETWithHttpInfo(extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getGenderSelections
   * Get gender selections
   */
  public getGenderSelectionsUsingGET(extraHttpRequestParams?: any): Observable<models.GetGenderSelectionsResponse> {
    return this.getGenderSelectionsUsingGETWithHttpInfo(extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getNameTagDeliveryModes
   * Get delivery modes for name tag orders
   * @param all Include all delivery modes, regardless of sys configs, default &#x3D; false
   */
  public getNameTagDeliveryModesUsingGET(all?: boolean, extraHttpRequestParams?: any): Observable<models.GetNameTagDeliveryModesResponse> {
    return this.getNameTagDeliveryModesUsingGETWithHttpInfo(all, extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getOperationTypeSelections
   * Get operation type selections
   */
  public getOperationTypeSelectionsUsingGET(extraHttpRequestParams?: any): Observable<models.GetOperationTypeSelectionsResponse> {
    return this.getOperationTypeSelectionsUsingGETWithHttpInfo(extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getPositionGroupSelections
   * Get position group selections
   */
  public getPositionGroupSelectionsUsingGET(extraHttpRequestParams?: any): Observable<models.GetPositionGroupSelectionsResponse> {
    return this.getPositionGroupSelectionsUsingGETWithHttpInfo(extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getRankCaveatSelections
   * Get rank caveats selections
   * @param department Department of the rank caveats &lt;br&gt;&lt;ul&gt;&lt;li&gt;1: SPF&lt;/li&gt; &lt;li&gt;2: SCDF&lt;/li&gt;&lt;/ul&gt;
   */
  public getRankCaveatSelectionsUsingGET(department: number, extraHttpRequestParams?: any): Observable<models.GetRankCaveatSelectionsResponse> {
    return this.getRankCaveatSelectionsUsingGETWithHttpInfo(department, extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getRankSelections
   * Get rank selections
   * @param department Department of the units &lt;br&gt;&lt;ul&gt;&lt;li&gt;1: SPF&lt;/li&gt; &lt;li&gt;2: SCDF&lt;/li&gt;&lt;/ul&gt;
   */
  public getRankSelectionsUsingGET(department: number, extraHttpRequestParams?: any): Observable<models.GetRankSelectionsResponse> {
    return this.getRankSelectionsUsingGETWithHttpInfo(department, extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * GetResource
   * Get resource
   * @param id Resource ID
   */
  public getResourceUsingGET(id: number, extraHttpRequestParams?: any): Observable<models.GetResourceResponse> {
    return this.getResourceUsingGETWithHttpInfo(id, extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getServiceTypeSelections
   * Get service type selections
   */
  public getServiceTypeSelectionsUsingGET(extraHttpRequestParams?: any): Observable<models.GetServiceTypeSelectionsResponse> {
    return this.getServiceTypeSelectionsUsingGETWithHttpInfo(extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getServiceUnitSelections
   * Get service unit selections
   */
  public getServiceUnitSelectionsUsingGET(extraHttpRequestParams?: any): Observable<models.GetServiceUnitSelectionsResponse> {
    return this.getServiceUnitSelectionsUsingGETWithHttpInfo(extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getTailorDeliveryModes
   * Get delivery modes for tailoring orders
   * @param all Include all delivery modes, regardless of sys configs, default &#x3D; false
   */
  public getTailorDeliveryModesUsingGET(all?: boolean, extraHttpRequestParams?: any): Observable<models.GetTailorDeliveryModesResponse> {
    return this.getTailorDeliveryModesUsingGETWithHttpInfo(all, extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getTailors
   * Get tailors
   */
  public getTailorsUsingGET(extraHttpRequestParams?: any): Observable<models.GetTailorsResponse> {
    return this.getTailorsUsingGETWithHttpInfo(extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getUnitGroupSelections
   * Get unit group selections
   * @param department Department of the units &lt;br&gt;&lt;ul&gt;&lt;li&gt;0: SPF &amp; SCDF&lt;/li&gt; &lt;li&gt;1: SPF&lt;/li&gt; &lt;li&gt;2: SCDF&lt;/li&gt;&lt;/ul&gt;
   */
  public getUnitGroupSelectionsUsingGET(department: number, extraHttpRequestParams?: any): Observable<models.GetUnitGroupSelectionsResponse> {
    return this.getUnitGroupSelectionsUsingGETWithHttpInfo(department, extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getUserPermissions
   * Get the list of user permissions for current login user.
   */
  public getUserPermissionsUsingGET(extraHttpRequestParams?: any): Observable<models.GetUserPermissionsResponse> {
    return this.getUserPermissionsUsingGETWithHttpInfo(extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * getWalkInCollectionPoints
   * Get walk-in collection points
   */
  public getWalkInCollectionPointsUsingGET(extraHttpRequestParams?: any): Observable<models.GetWalkInCollectionPointsResponse> {
    return this.getWalkInCollectionPointsUsingGETWithHttpInfo(extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * notifyFileUpload
   * Listen to file upload result from NGNSP
   * @param notifyResult notifyResult
   */
  public notifyFileUploadUsingPOST(notifyResult: models.CFUNotifyDTO, extraHttpRequestParams?: any): Observable<{}> {
    return this.notifyFileUploadUsingPOSTWithHttpInfo(notifyResult, extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }

  /**
   * saveResource
   * Save new resource
   * @param request request
   */
  public saveResourceUsingPOST(request: models.SaveResourceRequest, extraHttpRequestParams?: any): Observable<models.SaveResourceResponse> {
    return this.saveResourceUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
      .pipe(map((response: Response) => {
        return response;
      }));
  }


  /**
   * addRankCaveat
   * Add Rank Caveat
   * @param request request
   */
  public addRankCaveatUsingPOSTWithHttpInfo(request: models.AddRankCaveatRequest, extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/rankCaveats';

    // verify required parameter 'request' is not null or undefined
    if (request === null || request === undefined) {
      throw new Error('Required parameter request was null or undefined when calling addRankCaveatUsingPOST.');
    }

    return this.httpClient.post(path, request, { withCredentials: true });
  }

  /**
   * getAllCollectionPoints
   * Get all active collection points (including eLockers and walk-in collection points)
   */
  public getAllCollectionPointsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/allCollectionPoints';

    return this.httpClient.get(path, { withCredentials: true });
  }

  /**
   * getCapTypeSelections
   * Get cap type selections
   */
  public getCapTypeSelectionsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/capTypeSelections';

    return this.httpClient.get(path, { withCredentials: true });
  }

  /**
   * getCollectionPointSelectionsForUser
   * Get collection point selections for user
   * @param cp Include collection points
   * @param el Include eLockers
   * @param cel Check eLockers' availability
   * @param hd Include home delivery
   * @param wi Include walk-in collection points
   * @param tr Include tailors
   */
  public getCollectionPointSelectionsForUserUsingGETWithHttpInfo(cp: boolean, el: boolean, cel: boolean, hd: boolean, wi: boolean, tr: boolean, extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/collectionPointSelectionsForUser';

    let queryParameters = new HttpParams();

    // verify required parameter 'cp' is not null or undefined
    if (cp === null || cp === undefined) {
      throw new Error('Required parameter cp was null or undefined when calling getCollectionPointSelectionsForUserUsingGET.');
    }
    // verify required parameter 'el' is not null or undefined
    if (el === null || el === undefined) {
      throw new Error('Required parameter el was null or undefined when calling getCollectionPointSelectionsForUserUsingGET.');
    }
    // verify required parameter 'cel' is not null or undefined
    if (cel === null || cel === undefined) {
      throw new Error('Required parameter cel was null or undefined when calling getCollectionPointSelectionsForUserUsingGET.');
    }
    // verify required parameter 'hd' is not null or undefined
    if (hd === null || hd === undefined) {
      throw new Error('Required parameter hd was null or undefined when calling getCollectionPointSelectionsForUserUsingGET.');
    }
    // verify required parameter 'wi' is not null or undefined
    if (wi === null || wi === undefined) {
      throw new Error('Required parameter wi was null or undefined when calling getCollectionPointSelectionsForUserUsingGET.');
    }
    // verify required parameter 'tr' is not null or undefined
    if (tr === null || tr === undefined) {
      throw new Error('Required parameter tr was null or undefined when calling getCollectionPointSelectionsForUserUsingGET.');
    }
    if (cp !== undefined) {
      queryParameters = queryParameters.append('cp', <any>cp);
    }

    if (el !== undefined) {
      queryParameters = queryParameters.append('el', <any>el);
    }

    if (cel !== undefined) {
      queryParameters = queryParameters.append('cel', <any>cel);
    }

    if (hd !== undefined) {
      queryParameters = queryParameters.append('hd', <any>hd);
    }

    if (wi !== undefined) {
      queryParameters = queryParameters.append('wi', <any>wi);
    }

    if (tr !== undefined) {
      queryParameters = queryParameters.append('tr', <any>tr);
    }

    return this.httpClient.get(path, {params: queryParameters, withCredentials: true});
  }

  /**
   * getCollectionPointSelections
   * Get collection point selections
   * @param cp Include collection points
   * @param el Include eLockers
   * @param hd Include home delivery
   * @param wi Include walk-in collection points
   * @param tr Include tailors
   */
  public getCollectionPointSelectionsUsingGETWithHttpInfo(cp: boolean, el: boolean, hd: boolean, wi: boolean, tr: boolean, extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/collectionPointSelections';

    let queryParameters = new HttpParams();

    // verify required parameter 'cp' is not null or undefined
    if (cp === null || cp === undefined) {
      throw new Error('Required parameter cp was null or undefined when calling getCollectionPointSelectionsUsingGET.');
    }
    // verify required parameter 'el' is not null or undefined
    if (el === null || el === undefined) {
      throw new Error('Required parameter el was null or undefined when calling getCollectionPointSelectionsUsingGET.');
    }
    // verify required parameter 'hd' is not null or undefined
    if (hd === null || hd === undefined) {
      throw new Error('Required parameter hd was null or undefined when calling getCollectionPointSelectionsUsingGET.');
    }
    // verify required parameter 'wi' is not null or undefined
    if (wi === null || wi === undefined) {
      throw new Error('Required parameter wi was null or undefined when calling getCollectionPointSelectionsUsingGET.');
    }
    // verify required parameter 'tr' is not null or undefined
    if (tr === null || tr === undefined) {
      throw new Error('Required parameter tr was null or undefined when calling getCollectionPointSelectionsUsingGET.');
    }
    if (cp !== undefined) {
      queryParameters = queryParameters.append('cp', <any>cp);
    }

    if (el !== undefined) {
      queryParameters = queryParameters.append('el', <any>el);
    }

    if (hd !== undefined) {
      queryParameters = queryParameters.append('hd', <any>hd);
    }

    if (wi !== undefined) {
      queryParameters = queryParameters.append('wi', <any>wi);
    }

    if (tr !== undefined) {
      queryParameters = queryParameters.append('tr', <any>tr);
    }

    return this.httpClient.get(path, {params: queryParameters, withCredentials: true});
  }

  /**
   * GetCollectionPoints
   * Get collection points
   */
  public getCollectionPointsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/collectionPoints';

    return this.httpClient.get(path, { withCredentials: true });
  }

  /**
   * getDeliveryFee
   * Get delivery fee
   */
  public getDeliveryFeeUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/deliveryFee';

    return this.httpClient.get(path, { withCredentials: true });
  }

  /**
   * getDeliveryModes
   * Get delivery modes
   * @param all Include all delivery modes, regardless of sys configs, default &#x3D; false
   */
  public getDeliveryModesUsingGETWithHttpInfo(all?: boolean, extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/deliveryModes';

    let queryParameters = new HttpParams();

    if (all !== undefined) {
      queryParameters = queryParameters.append('all', <any>all);
    }

    return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
  }

  /**
   * getElockers
   * Get eLockers
   */
  public getElockersUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/eLockers';

    return this.httpClient.get(path, { withCredentials: true });
  }

  /**
   * getFeedbackSubjectSelections
   * Get feedback subject selections
   */
  public getFeedbackSelectionsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/feedbackSubjectSelections';

    return this.httpClient.get(path, { withCredentials: true });
  }

  /**
   * getGenderSelections
   * Get gender selections
   */
  public getGenderSelectionsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/genderSelections';

    return this.httpClient.get(path, { withCredentials: true });
  }

  /**
   * getNameTagDeliveryModes
   * Get delivery modes for name tag orders
   * @param all Include all delivery modes, regardless of sys configs, default &#x3D; false
   */
  public getNameTagDeliveryModesUsingGETWithHttpInfo(all?: boolean, extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/nameTagDeliveryModes';
    let queryParameters = new HttpParams();

    if (all !== undefined) {
      queryParameters = queryParameters.append('all', <any>all);
    }

    return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
  }

  /**
   * getOperationTypeSelections
   * Get operation type selections
   */
  public getOperationTypeSelectionsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/operationTypeSelections';

    return this.httpClient.get(path, { withCredentials: true });
  }

  /**
   * getPositionGroupSelections
   * Get position group selections
   */
  public getPositionGroupSelectionsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/positionGroupSelections';

    return this.httpClient.get(path, { withCredentials: true });
  }

  /**
   * getRankCaveatSelections
   * Get rank caveats selections
   * @param department Department of the rank caveats &lt;br&gt;&lt;ul&gt;&lt;li&gt;1: SPF&lt;/li&gt; &lt;li&gt;2: SCDF&lt;/li&gt;&lt;/ul&gt;
   */
  public getRankCaveatSelectionsUsingGETWithHttpInfo(department: number, extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/rankCaveatSelections';
    let queryParameters = new HttpParams();

    // verify required parameter 'department' is not null or undefined
    if (department === null || department === undefined) {
      throw new Error('Required parameter department was null or undefined when calling getRankCaveatSelectionsUsingGET.');
    }
    if (department !== undefined) {
      queryParameters = queryParameters.append('department', <any>department);
    }  

    return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
  }

  /**
   * getRankSelections
   * Get rank selections
   * @param department Department of the units &lt;br&gt;&lt;ul&gt;&lt;li&gt;1: SPF&lt;/li&gt; &lt;li&gt;2: SCDF&lt;/li&gt;&lt;/ul&gt;
   */
  public getRankSelectionsUsingGETWithHttpInfo(department: number, extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/rankSelections';
    let queryParameters = new HttpParams();

    // verify required parameter 'department' is not null or undefined
    if (department === null || department === undefined) {
      throw new Error('Required parameter department was null or undefined when calling getRankSelectionsUsingGET.');
    }
    if (department !== undefined) {
      queryParameters = queryParameters.append('department', <any>department);
    }

    return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
  }

  /**
   * GetResource
   * Get resource
   * @param id Resource ID
   */
  public getResourceUsingGETWithHttpInfo(id: number, extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/resources/${id}'
      .replace('${' + 'id' + '}', String(id));

    // verify required parameter 'id' is not null or undefined
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling getResourceUsingGET.');
    }

    return this.httpClient.get(path, { withCredentials: true });
  }

  /**
   * getServiceTypeSelections
   * Get service type selections
   */
  public getServiceTypeSelectionsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/serviceTypeSelections';

    return this.httpClient.get(path, { withCredentials: true });
  }

  /**
   * getServiceUnitSelections
   * Get service unit selections
   */
  public getServiceUnitSelectionsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/serviceUnitSelections';

    return this.httpClient.get(path, { withCredentials: true });
  }

  /**
   * getTailorDeliveryModes
   * Get delivery modes for tailoring orders
   * @param all Include all delivery modes, regardless of sys configs, default &#x3D; false
   */
  public getTailorDeliveryModesUsingGETWithHttpInfo(all?: boolean, extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/tailorDeliveryModes';
    let queryParameters = new HttpParams();

    if (all !== undefined) {
      queryParameters = queryParameters.append('all', <any>all);
    }

    return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
  }

  /**
   * getTailors
   * Get tailors
   */
  public getTailorsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/tailors';

    return this.httpClient.get(path, { withCredentials: true });
  }

  /**
   * getUnitGroupSelections
   * Get unit group selections
   * @param department Department of the units &lt;br&gt;&lt;ul&gt;&lt;li&gt;0: SPF &amp; SCDF&lt;/li&gt; &lt;li&gt;1: SPF&lt;/li&gt; &lt;li&gt;2: SCDF&lt;/li&gt;&lt;/ul&gt;
   */
  public getUnitGroupSelectionsUsingGETWithHttpInfo(department: number, extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/unitGroupSelections';
    let queryParameters = new HttpParams();

    // verify required parameter 'department' is not null or undefined
    if (department === null || department === undefined) {
      throw new Error('Required parameter department was null or undefined when calling getUnitGroupSelectionsUsingGET.');
    }
    if (department !== undefined) {
      queryParameters = queryParameters.append('department', <any>department);
    }

    return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
  }

  /**
   * getUserPermissions
   * Get the list of user permissions for current login user.
   */
  public getUserPermissionsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/permissions';

    return this.httpClient.get(path, { withCredentials: true });
  }

  /**
   * getWalkInCollectionPoints
   * Get walk-in collection points
   */
  public getWalkInCollectionPointsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/walkInCollectionPoints';

    return this.httpClient.get(path, { withCredentials: true });
  }

  /**
   * notifyFileUpload
   * Listen to file upload result from NGNSP
   * @param notifyResult notifyResult
   */
  public notifyFileUploadUsingPOSTWithHttpInfo(notifyResult: models.CFUNotifyDTO, extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/notifyFileUpload';

    // verify required parameter 'notifyResult' is not null or undefined
    if (notifyResult === null || notifyResult === undefined) {
      throw new Error('Required parameter notifyResult was null or undefined when calling notifyFileUploadUsingPOST.');
    }   

    return this.httpClient.post(path, notifyResult, { withCredentials: true });
  }

  /**
   * saveResource
   * Save new resource
   * @param request request
   */
  public saveResourceUsingPOSTWithHttpInfo(request: models.SaveResourceRequest, extraHttpRequestParams?: any): Observable<any> {
    const path = this.basePath + '/resources';

    // verify required parameter 'request' is not null or undefined
    if (request === null || request === undefined) {
      throw new Error('Required parameter request was null or undefined when calling saveResourceUsingPOST.');
    }

    return this.httpClient.post(path, request, { withCredentials: true });
  }

}
