import { Injectable } from "@angular/core";
import { Constants } from "app/shared/global-constants/constants";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class FaqService{
    faqList = Constants.FAQConstants;

    private datasource : BehaviorSubject<any> = new BehaviorSubject(this.faqList.list);


    /*****************************************************
    *   Used to get FAQ json content from constants.ts   *
    *****************************************************/
    getnavitem(){
        return this.datasource;
    }



    // selectedindex : number;
    // setIndex(value : number){ //get selected navbar value from left navbar
    //     this.selectedindex = value;
    // }


    /*****************************************************
    *        Used to set right side content (QnA)        *
    *****************************************************/
    selectedlist : string[];
    setlist(listvalue : string[]){
        this.selectedlist = listvalue;
    }
}