/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as models from './models';

/**
 * Error object definition. It will be used in an error response
 */
export interface CreateWalkInOrderResponseError {
    /**
     * Backend error code
     */
    code: CreateWalkInOrderResponseError.CodeEnum;

    /**
     * Object containing detailed information for the error
     */
    source?: models.AbstractResponseErrorSource;

    /**
     * Error message that should be displayed on the front-end
     */
    title: string;

}
export namespace CreateWalkInOrderResponseError {
    export enum CodeEnum {
        SERVICEMANNOTFOUND = <any> 'SERVICEMAN_NOT_FOUND',
        INVALIDSECRETCODE = <any> 'INVALID_SECRET_CODE',
        SECRETCODEEXPIRED = <any> 'SECRET_CODE_EXPIRED',
        INVALIDCART = <any> 'INVALID_CART',
        INSUFFICIENTCREDITBALANCE = <any> 'INSUFFICIENT_CREDIT_BALANCE'
    }
}
