<simple-notifications [(options)]="notificationOptions"></simple-notifications>
<!-- shopping cart -->
<div class="col-md-12">
  <div class="collapse-box">
    <div class="collapse-title">
      <h1>Waitlist</h1>
    </div>
    <div class="collapse in">
      <div class="box-content">
        <div id="nodata" class="alert alert-info text-center" role="alert"
             *ngIf="!waitlistService.waitlist.items ||waitlistService.waitlist.items.length === 0">
          <i class="fa fa-info-circle fa-2x"></i>
          <p>{{waitlistService.EMPTY_WAITLIST_MSG}}</p>
        </div>
        <div *ngIf="waitlistService.waitlist.items?.length > 0">
          <table class="table purchases cart">
            <thead>
            <tr>
              <th>Item(s)</th>
              <th>Size</th>
              <th>Qty</th>
              <th class="price">Unit Price</th>
              <th class="price">Subtotal</th>
              <th class="remove">Remove</th>
            </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of waitlistService.waitlist.items">
                <td>
                  <div class="item-image">
                    <img [src]="imagestore[item.productId] || storeConfigService.dummyImgPath" alt="">
                  </div>
                  <div class="item-name">{{productMap.get(item.productId).productName}}</div>
                </td>
                <td class="block size">
                  <div class="size">
                    {{
                    item.name == null?'N.A':
                    item.name
                    }}
                  </div>
                </td>
                <td class="block qty">
                  <div class="qty">
                    <form id='itemquantity_{{item.skuId}}'>
                      <select name="qty" class="form-control" [ngModel]="item.quantity"
                              (change)="updateQuantity(item,$event)" required>
                        <option *ngFor="let q of checkQty(item.quantity)" value="{{q+1}}"
                                [attr.selected]="q+1 == item.quantity ? 'selected' : null">{{q+1}}</option>
                      </select>
                    </form>
                  </div>
                </td>
                <td class="block price">{{ productMap.get(item.productId).productPrice | priceFormat }}</td>
                <td class="block price total">{{ productMap.get(item.productId).productPrice*item.quantity
                  | priceFormat }}</td>
                <td class="block remove">
                  <a href="javascript:void(0);" (click)="waitlistService.removeItemFromWaitlist(item.skuId)">
                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>

