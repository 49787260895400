/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { Configuration } from '../configuration';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class StoremanApi {
    protected basePath = environment.apiBaseUrl;

    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {}

    /**
     * collectOrder
     * Collect order
     * @param request request
     */
    public collectOrderUsingPOST(request: models.CollectOrderRequestDTO, extraHttpRequestParams?: any): Observable<models.CollectOrderResponse> {
        return this.collectOrderUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getAllOrderStatusSelectionsForStoreman
     * Get all order status selections for Check All Orders
     */
    public getAllOrderStatusSelectionsForStoremanUsingGET(extraHttpRequestParams?: any): Observable<models.GetAllOrderStatusSelectionsForStoremanResponse> {
        return this.getAllOrderStatusSelectionsForStoremanUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getOrderStatusSelectionsForStoreman
     * Get order status selections for storeman
     */
    public getOrderStatusSelectionsForStoremanUsingGET(extraHttpRequestParams?: any): Observable<models.GetOrderStatusSelectionsForStoremanResponse> {
        return this.getOrderStatusSelectionsForStoremanUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getOrdersForCollection
     * Get orders for collection
     * @param request request
     */
    public getOrdersForCollectionUsingPOST(request: models.GetOrdersForCollectionRequest, extraHttpRequestParams?: any): Observable<models.GetOrdersForCollectionResponse> {
        return this.getOrdersForCollectionUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getOrdersForStoreman
     * Get orders for storeman
     * @param page to set the index of pages to retrieve the order. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param cp to set the list of collection points interested. If the &#39;self&#39; param is true, this param will be ignored.
     * @param deliveryMode to indicate the delivery mode for the orders to be retrieved. If it is null, all delivery mode will be returned.
     * @param department to indicate the service unit for the orders to be retrieved. If it is null, all service units will be returned.
     * @param from to indicate the start date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of start date will be imposed.
     * @param to to indicate the end date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of end date will be imposed.
     * @param status to indicate the status for the orders to be retrieved. If it is null, all available status will be returned. status map: &lt;br&gt;&lt;ul&gt;&lt;li&gt;0: WALK_IN_COLLECTED&lt;/li&gt; &lt;li&gt;1: ADVANCE_CHECKED_OUT&lt;/li&gt; &lt;li&gt;3: PACKED&lt;/li&gt; &lt;li&gt;4: ARRIVED_AT_COLLECTION_POINT&lt;/li&gt; &lt;li&gt;9: COLLECTED&lt;/li&gt; &lt;li&gt;10: FORFEITED&lt;/li&gt; &lt;li&gt;15: STARTED_PACKING&lt;/li&gt;&lt;/ul&gt;
     * @param ref to set the order reference number for the order to be retrieved. If it is set, the order reference number must be matched. If other params is not null, they are all need to be matched the corresponding order as well.
     * @param sort to indicate sorting method, default &#x3D; 0. &lt;br&gt;&lt;ul&gt;&lt;li&gt;0: sorted by order ref no.&lt;/li&gt;&lt;li&gt;1: sorted by NRIC, then order ref no.&lt;/li&gt;&lt;/ul&gt; 
     */
    public getOrdersForStoremanUsingGET(page: number, size: number, cp?: Array<number>, deliveryMode?: number, department?: number, from?: string, to?: string, status?: number, ref?: string, sort?: number, extraHttpRequestParams?: any): Observable<models.GetOrdersForStoremanResponse> {
        return this.getOrdersForStoremanUsingGETWithHttpInfo(page, size, cp, deliveryMode, department, from, to, status, ref, sort, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * updateOrderStatusForStoreman
     * Update orders statuses for storeman
     * @param request request
     */
    public updateOrderStatusForStoremanUsingPOST(request: models.UpdateOrderStatusRequestDTO, extraHttpRequestParams?: any): Observable<models.UpdateOrderStatusForStoremanResponse> {
        return this.updateOrderStatusForStoremanUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * collectOrder
     * Collect order
     * @param request request
     */
    public collectOrderUsingPOSTWithHttpInfo(request: models.CollectOrderRequestDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/storeman/orders/collect';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling collectOrderUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * getAllOrderStatusSelectionsForStoreman
     * Get all order status selections for Check All Orders
     */
    public getAllOrderStatusSelectionsForStoremanUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/storeman/orderStatusSelections/all';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getOrderStatusSelectionsForStoreman
     * Get order status selections for storeman
     */
    public getOrderStatusSelectionsForStoremanUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/storeman/orderStatusSelections';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getOrdersForCollection
     * Get orders for collection
     * @param request request
     */
    public getOrdersForCollectionUsingPOSTWithHttpInfo(request: models.GetOrdersForCollectionRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/storeman/orders/collection';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling getOrdersForCollectionUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * getOrdersForStoreman
     * Get orders for storeman
     * @param page to set the index of pages to retrieve the order. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param cp to set the list of collection points interested. If the &#39;self&#39; param is true, this param will be ignored.
     * @param deliveryMode to indicate the delivery mode for the orders to be retrieved. If it is null, all delivery mode will be returned.
     * @param department to indicate the service unit for the orders to be retrieved. If it is null, all service units will be returned.
     * @param from to indicate the start date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of start date will be imposed.
     * @param to to indicate the end date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of end date will be imposed.
     * @param status to indicate the status for the orders to be retrieved. If it is null, all available status will be returned. status map: &lt;br&gt;&lt;ul&gt;&lt;li&gt;0: WALK_IN_COLLECTED&lt;/li&gt; &lt;li&gt;1: ADVANCE_CHECKED_OUT&lt;/li&gt; &lt;li&gt;3: PACKED&lt;/li&gt; &lt;li&gt;4: ARRIVED_AT_COLLECTION_POINT&lt;/li&gt; &lt;li&gt;9: COLLECTED&lt;/li&gt; &lt;li&gt;10: FORFEITED&lt;/li&gt; &lt;li&gt;15: STARTED_PACKING&lt;/li&gt;&lt;/ul&gt;
     * @param ref to set the order reference number for the order to be retrieved. If it is set, the order reference number must be matched. If other params is not null, they are all need to be matched the corresponding order as well.
     * @param sort to indicate sorting method, default &#x3D; 0. &lt;br&gt;&lt;ul&gt;&lt;li&gt;0: sorted by order ref no.&lt;/li&gt;&lt;li&gt;1: sorted by NRIC, then order ref no.&lt;/li&gt;&lt;/ul&gt; 
     */
    public getOrdersForStoremanUsingGETWithHttpInfo(page: number, size: number, cp?: Array<number>, deliveryMode?: number, department?: number, from?: string, to?: string, status?: number, ref?: string, sort?: number, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/storeman/orders';

        let queryParameters = new HttpParams();

        // verify required parameter 'page' is not null or undefined
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getOrdersForStoremanUsingGET.');
        }
        // verify required parameter 'size' is not null or undefined
        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling getOrdersForStoremanUsingGET.');
        }
        if (cp) {
            cp.forEach((element) => {
                queryParameters = queryParameters.append('cp', <any>element);
            })
        }

        if (deliveryMode !== undefined) {
            queryParameters = queryParameters.append('deliveryMode', <any>deliveryMode);
        }

        if (department !== undefined) {
            queryParameters = queryParameters.append('department', <any>department);
        }

        if (from !== undefined) {
            queryParameters = queryParameters.append('from', <any>from);
        }

        if (to !== undefined) {
            queryParameters = queryParameters.append('to', <any>to);
        }

        if (status !== undefined) {
            queryParameters = queryParameters.append('status', <any>status);
        }

        if (ref !== undefined) {
            queryParameters = queryParameters.append('ref', <any>ref);
        }

        if (sort !== undefined) {
            queryParameters = queryParameters.append('sort', <any>sort);
        }

        if (page !== undefined) {
            queryParameters = queryParameters.append('page', <any>page);
        }

        if (size !== undefined) {
            queryParameters = queryParameters.append('size', <any>size);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
    }

    /**
     * updateOrderStatusForStoreman
     * Update orders statuses for storeman
     * @param request request
     */
    public updateOrderStatusForStoremanUsingPOSTWithHttpInfo(request: models.UpdateOrderStatusRequestDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/storeman/orders/updateStatus';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling updateOrderStatusForStoremanUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

}
