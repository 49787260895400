import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleNotificationsModule } from 'assets/lib/angular2-notifications';
import { AuthModule } from 'app/auth/auth.module';
import { DEPLOY_URL } from 'app/auth/auth.tokens';

import { environment } from './../environments/environment';
import { AppComponent } from './app.component';
import { PreloaderService } from './auth/preloader.service';
import { LayoutModule } from './layout/layout.module';
import { STORE_RESOLVERS } from './routes/resolvers/STORE_RESOLVERS';
import { RoutesRoutingModule } from './routes/routes-routing.module';
import { EXTERNAL_SERVICES, STORE_SERVICES } from './services/STORE_SERVICES';
import { APIS } from './shared/backend-api/emart/api/api';
import { BASE_PATH } from './shared/backend-api/emart/variables';
import { SafeStylePipe } from './shared/utilities/safe-style.pipe';
import { TimeoutCountdownPipe } from './shared/utilities/timeout-countdown.pipe';
import { APP_BASE_HREF } from '@angular/common';
import { PublicModuleModule } from './public-module/public-module.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './services/http-interceptor.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Constants } from './shared/global-constants/constants';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

/**
 * Note: There is a Layout Module to wrap all HTML page components.
 * If you want to 'ng g c" new component, cd to ./src/app/layout/ folder then generate.
 * Please don't generate component in this main module.
 * @export
 * @class AppModule
 */
@NgModule({
    declarations: [
        AppComponent,
        SafeStylePipe,
        TimeoutCountdownPipe
    ],
    imports: [
        BrowserModule,
        RoutesRoutingModule,
        HttpClientModule,
        LayoutModule,
        AuthModule,
        BrowserAnimationsModule,
        SimpleNotificationsModule.forRoot(),
        PublicModuleModule,
        NgIdleKeepaliveModule.forRoot()
    ],
    providers: [
        STORE_SERVICES, STORE_RESOLVERS, APIS, PreloaderService, EXTERNAL_SERVICES,
        { provide: BASE_PATH, useValue: environment.apiBaseUrl },
        { provide: DEPLOY_URL, useValue: environment.deployUrl },
        { provide: APP_BASE_HREF, useValue: '/mha-emart/' },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpInterceptorService,
          multi: true
        },
        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        {
          provide: DateAdapter,
          useClass: MomentDateAdapter,
          deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        {provide: MAT_DATE_FORMATS, useValue: Constants.MAT_DATEPICKER_FORMATS.DAY_PICKER}
    ],
    schemas: [
      CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
  constructor() { }

}

