<div class="breadcrumb-section">
  <div class="container1 container">
    <ul class="breadcrumb">
      <li class="breadcrumb-item">
        <a routerLink="/dashboard" aria-label="breadcrumb-item"></a>
      </li>
      <li class="breadcrumb-item active">
        <a id="breadcrumb-active-link" routerLink="/privacy-statement" aria-label="breadcrumb-item">
          Privacy Statement
        </a>
      </li>
    </ul>
  </div>
</div>

<div class="container">
  <h1>Government of Singapore Privacy Statement</h1>
  <ol class="text-justify">
    <li>
      This is a Government of Singapore website.
    </li>
    <li>
      If you are only browsing this website, we do not capture
      data that allows us to identify you individually.
    </li>
    <li>
      If you choose to make an application or send us an e-mail
      for which you provide us with personal data, we may share
      necessary data with other Government agencies, so as to
      serve you in a most efficient and effective way, unless
      such sharing is prohibited by legislation. We will NOT
      share your personal data with non-Government entities,
      except where such entities have been authorised to carry
      out specific Government services.
    </li>
    <li>
      For your convenience, we may also display to you data you
      had previously supplied us or other Government agencies. This
      will speed up the transaction and save you the trouble of repeating
      previous submissions. Should the data be out-of-date, please
      supply us the latest data. We will retain your personal data only
      as necessary for the effective delivery of public services to you.
    </li>
    <li>
      To safeguard your personal data, all electronic storage and
      transmission of personal data are secured with appropriate security
      technologies.
    </li>
    <li>
      This site may contain links to non-Government sites whose data
      protection and privacy practices may differ from ours. We are not
      responsible for the content and privacy practices of these other
      websites and encourage you to consult the privacy notices of
      those sites.&nbsp;
    </li>
    <li>
      Please contact MHA Feedback Unit -
      <a href="mailto:mha_feedback@mha.gov.sg">mha_feedback@mha.gov.sg</a>
    </li>
  </ol>
  For any enquires or feedback on our data protection policies and procedures,
  <br>
  If you require more information on or access to the data which you have earlier provided to us.
</div>