import { CartService } from './cart.service';
import { Injectable } from '@angular/core';
import { NotificationsService } from 'assets/lib/angular2-notifications';
import { OrdersApi } from 'app/shared/backend-api/emart';
import { Observable } from 'rxjs';

import { CartApi } from './../shared/backend-api/emart/api/CartApi';
import { CommonApi } from './../shared/backend-api/emart/api/CommonApi';
import { CatalogueApi } from './../shared/backend-api/emart/api/CatalogueApi';
import {
  CartDTO, CartItemDTO, UserProductDTO, GetProductCapsResult
} from './../shared/backend-api/emart/model/models';
import { Constants } from './../shared/global-constants/constants';
import { CommonUtils } from './../shared/utilities/common-utils';
import { ProductsService } from './products.service';
import { UserProfileService } from './user-profile.service';
import naturalSort from 'javascript-natural-sort';

@Injectable()
export class TailorCartService {

  private DeliveryMode = Constants.DeliveryMode;

  constructor(
    private orderApi: OrdersApi,
    private cartApi: CartApi,
    private commonApi: CommonApi,
    private catalogueApi: CatalogueApi,
    private cartService: CartService,
    private productsService: ProductsService,
    private userProfileService: UserProfileService,
    private notificationService: NotificationsService
  ) {
    this.productsService.loadProducts(Constants.ProductType.TAILOR);
  }

  addItemToCart(price: number, qty: number, productId: number) {
    price = Number(price);
    qty = Number(qty);

    if (price == null || price <= 0) {
      this.notificationService.warn(
        'Shopping Cart',
        'Invalid price'
      )
      return;
    }
    if (qty == null || qty <= 0) {
      this.notificationService.warn(
        'Shopping Cart',
        'Invalid quantity'
      )
      return;
    }

    const product = this.cartService.getTailorProduct(productId);

    const tailoredItems: Array<CartItemDTO> = [];
    const itemT: CartItemDTO = {};
    itemT.productId = product.productId;
    itemT.skuId = product.skus[0].skuId;
    itemT.quantity = qty;
    for (const tailor of product.tailors) {
      if (tailor.price === price) {
        itemT.productTailorId = tailor.productTailorId;
        break;
      }
    }
    tailoredItems.push(itemT);

    this.addItemsToCart(tailoredItems);
  }

  addOrderItemsToCart(items?: Array<any>) {
    const tailoredItems: Array<CartItemDTO> = [];

    for (const item of items) {
      const product = this.cartService.getTailorProduct(item.productId);

      const itemT: CartItemDTO = {};
      itemT.productId = product.productId;
      itemT.skuId = product.skus[0].skuId;
      itemT.quantity = item.quantity;
      for (const tailor of product.tailors) {
        if (tailor.locationId === item.tailorId) {
          itemT.productTailorId = tailor.productTailorId;
          break;
        }
      }
      if (!itemT.productTailorId) {
        itemT.productTailorId = product.tailors[0].productTailorId;
      }
      tailoredItems.push(itemT);
    }

    this.addItemsToCart(tailoredItems);
  }

  addItemsToCart(tailoredItems?: Array<CartItemDTO>) {
    this.cartService.getCartDetail().subscribe(data => {
      if (this.cartService.shoppingCart.items != null && this.cartService.shoppingCart.items.length > 0) {
        this.notificationService.warn(
          'Shopping Cart',
          'Tailored uniforms and catalogue items cannot be in the same shopping cart'
        )
        return;
      }
      if (this.cartService.shoppingCart.nameTagItems != null && this.cartService.shoppingCart.nameTagItems.length > 0) {
        this.notificationService.warn(
          'Shopping Cart',
          'Tailored uniforms and name tags cannot be in the same shopping cart'
        )
        return;
      }

      let tempCartItems = CommonUtils.cloneDeep(this.cartService.shoppingCart.tailorItems);
      if (tempCartItems == null) {
        tempCartItems = [];
      }

      let existing: boolean;
      const itemsToBeAdded = tailoredItems;

      const modifiedGroupIds: Array<Number> = [];

      for (const item of itemsToBeAdded) {
        existing = false;

        for (const groupId of this.cartService.getTailorProduct(item.productId).productGroupIds) {
          modifiedGroupIds.push(groupId);
        }

        for (const tempCartItem of tempCartItems) {
          if (Number(tempCartItem.productTailorId) === Number(item.productTailorId)) {
            tempCartItem.quantity = Number(tempCartItem.quantity) + Number(item.quantity);
            existing = true;
            break;
          }
        }

        if (!existing) {
          tempCartItems.push(item);
        }
      }

      this.cartService.checkCapAndCreditBalanceAndSaveCart(Constants.ProductType.TAILOR, tempCartItems, modifiedGroupIds);
    });
  }

  updateCartItemQty(productTailorId: number, qty: number) {
    productTailorId = Number(productTailorId);
    qty = Number(qty);

    if (productTailorId == null || productTailorId <= 0) {
      this.notificationService.warn(
        'Shopping Cart',
        'Invalid price'
      )
      return;
    }
    if (qty == null || qty <= 0) {
      this.notificationService.warn(
        'Shopping Cart',
        'Invalid quantity'
      )
      return;
    }

    this.cartService.loadTempShoppingCart = true;
    this.cartService.getCartDetail().subscribe(() => {
      this.cartService.loadTempShoppingCart = false;
      const tempCartItems = this.cartService.tempShoppingCart.tailorItems;

      const modifiedGroupIds: Array<Number> = [];

      for (const item of tempCartItems) {
        if (item.productTailorId === productTailorId) {
          item.productTailorId = productTailorId;
          item.quantity = qty;
          for (const groupId of this.cartService.getTailorProduct(item.productId).productGroupIds) {
            modifiedGroupIds.push(groupId);
          }
          break;
        }
      }

      this.cartService.checkCapAndCreditBalanceAndSaveCart(Constants.ProductType.TAILOR, tempCartItems, modifiedGroupIds);
    });
  }

  updateCartItemTailor(oriProductTailorId: number, productTailorId: number) {
    productTailorId = Number(productTailorId);
    oriProductTailorId = Number(oriProductTailorId);

    if (productTailorId == null || productTailorId <= 0) {
      this.notificationService.warn(
        'Shopping Cart',
        'Invalid price'
      )
      return;
    }

    this.cartService.loadTempShoppingCart = true;
    this.cartService.getCartDetail().subscribe(() => {
      this.cartService.loadTempShoppingCart = false;
      const tempCartItems = this.cartService.tempShoppingCart.tailorItems;

      let existingQty = 0;
      for (let i = 0; i < tempCartItems.length; i++) {
        if (tempCartItems[i].productTailorId === productTailorId) {
          existingQty = Number(tempCartItems[i].quantity);
          tempCartItems.splice(i, 1);
          break;
        }
      }

      const modifiedGroupIds: Array<Number> = [];

      let found = false;
      for (const item of tempCartItems) {
        if (item.productTailorId === oriProductTailorId) {
          item.productTailorId = productTailorId;
          item.quantity += existingQty;
          for (const groupId of this.cartService.getTailorProduct(item.productId).productGroupIds) {
            modifiedGroupIds.push(groupId);
          }
          found = true;
          break;
        }
      }

      if (!found) {
        return;
      }

      this.cartService.checkCapAndCreditBalanceAndSaveCart(Constants.ProductType.TAILOR, tempCartItems, modifiedGroupIds);
    });
  }

  removeItemFromCart(productTailorId: number) {
    productTailorId = Number(productTailorId);

    if (productTailorId == null || productTailorId <= 0) {
      this.notificationService.warn(
        'Shopping Cart',
        'Invalid size'
      )
      return;
    }

    this.cartService.getCartDetail().subscribe(() => {
      const tempCartItems = CommonUtils.cloneDeep(this.cartService.shoppingCart.tailorItems);

      for (let i = 0; i < tempCartItems.length; i++) {
        if (tempCartItems[i].productTailorId === productTailorId) {
          tempCartItems.splice(i, 1);
          break;
        }
      }

      this.cartService.checkCapAndCreditBalanceAndSaveCart(Constants.ProductType.TAILOR, tempCartItems, []);
    });
  }

}
