import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationsService } from 'assets/lib/angular2-notifications';
import { AddressDTO, UpdateProfileRequest, UserLocationDTO } from 'app/shared/backend-api/emart';
import { finalize } from "rxjs";

import { StoreConfigService } from './../../../services/store-config.service';
import { UserProfileService } from './../../../services/user-profile.service';
import { GetProfileResult } from './../../../shared/backend-api/emart/model/GetProfileResult';
import { Constants } from './../../../shared/global-constants/constants';
import { CommonUtils } from './../../../shared/utilities/common-utils';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  public reminderTitle;
  public reminderContent;

  public isDataUpdating = false;

  public profileCopy: GetProfileResult;

  public profileFormGroup: UntypedFormGroup;
  private profileFormGroupMessages = {
    AlterEmailCtrl: { required: 'Empty email address', pattern: 'Invalid email address' },
    AlterMobileCtrl: { required: 'Empty mobile number', pattern: 'Invalid mobile number' },
    PreferBlkCtrl: { required: 'Empty house/block' },
    PreferStreetCtrl: { required: 'Empty street address' },
    PostalCodeCtrl: { required: 'Empty postal code', minlength: 'Incomplete postal code', pattern: 'Invalid postal code' },
  }

  public ErrorDisplay;

  public deliveryMode = Constants.DeliveryMode.SELF_COLLECTION;
  private selectedCP: UserLocationDTO;
  private selectedEL: UserLocationDTO;
  private selectedWICP: UserLocationDTO;

  public DeliveryMode = Constants.DeliveryMode;

  public notificationOptions = {
    position: ['top', 'right'],
    lastOnBottom: false,
    timeOut: 2000
  };

  private resetErrorDisplay() {
    this.ErrorDisplay = {};
  }

  constructor(
    public userProfileService: UserProfileService,
    public storeConfigService: StoreConfigService,
    private notificationService: NotificationsService) {

    this.profileCopy = CommonUtils.cloneDeep(this.userProfileService.userProfile);
    if (!this.profileCopy.alternateDeliveryAddr) { // Temp solution
      this.profileCopy.alternateDeliveryAddr = <AddressDTO>{};
    }

    this.deliveryMode = this.userProfileService.userProfile.preferredDeliveryMode;
    if (this.deliveryMode == null) {
      this.deliveryMode = this.DeliveryMode.SELF_COLLECTION;
    }

    if (this.deliveryMode === this.DeliveryMode.SELF_COLLECTION) {
      this.selectedCP = this.findCollectionPoint(this.userProfileService.userProfile.preferredCollectionPoint);
    } else if (this.deliveryMode === this.DeliveryMode.ELOCKER) {
      this.selectedEL = this.findElocker(this.userProfileService.userProfile.preferredCollectionPoint);
    } else if (this.deliveryMode === this.DeliveryMode.WALK_IN_SELF_ORDER) {
      this.selectedWICP = this.findWalkInCollectionPoint(this.userProfileService.userProfile.preferredCollectionPoint);
    }

    if (this.selectedCP == null && this.storeConfigService.collectionPoints && this.storeConfigService.collectionPoints.length > 0
      && this.storeConfigService.collectionPointSelections && this.storeConfigService.collectionPointSelections.length > 0) {
      this.selectedCP = this.storeConfigService.collectionPoints
        .find(cp => cp.locationId === this.storeConfigService.collectionPointSelections[0].locations[0].value);
    }
    if (this.selectedEL == null && this.storeConfigService.eLockers && this.storeConfigService.eLockers.length > 0
      && this.storeConfigService.eLockerSelections && this.storeConfigService.eLockerSelections.length > 0) {
      this.selectedEL = this.storeConfigService.eLockers
        .find(cp => cp.locationId === this.storeConfigService.eLockerSelections[0].locations[0].value);
    }
    if (this.selectedWICP == null && this.storeConfigService.walkInCollectionPoints && this.storeConfigService.walkInCollectionPoints.length > 0
      && this.storeConfigService.walkInCollectionPointSelections && this.storeConfigService.walkInCollectionPointSelections.length > 0) {
      this.selectedWICP = this.storeConfigService.walkInCollectionPoints
        .find(cp => cp.locationId === this.storeConfigService.walkInCollectionPointSelections[0].locations[0].value);
    }

    if ((this.deliveryMode === this.DeliveryMode.ELOCKER && !this.selectedEL)
      || (this.deliveryMode === this.DeliveryMode.WALK_IN_SELF_ORDER && !this.selectedWICP)) {
      this.deliveryMode = this.DeliveryMode.SELF_COLLECTION;
    }

    this.createFormGroup();
    this.resetErrorDisplay();
  }

  ngOnInit() { }

  findCollectionPoint(locationId) {
    for (const location of this.storeConfigService.collectionPoints) {
      if (location.locationId === locationId) {
        return location;
      }
    }
    return null;
  }

  findElocker(locationId) {
    for (const location of this.storeConfigService.eLockers) {
      if (location.locationId === locationId) {
        return location;
      }
    }
    return null;
  }

  findWalkInCollectionPoint(locationId) {
    for (const location of this.storeConfigService.walkInCollectionPoints) {
      if (location.locationId === locationId) {
        return location;
      }
    }
    return null;
  }

  createFormGroup(): void {
    this.profileFormGroup = new UntypedFormGroup({
      AlterEmailCtrl: new UntypedFormControl('', [Validators.required, Validators.pattern(Constants.EmailRegex)]),
      AlterMobileCtrl: new UntypedFormControl('', [Validators.required, Validators.pattern(Constants.PhoneRegex)]),
      PreferBlkCtrl: new UntypedFormControl('', [Validators.required]),
      PreferUnitCtrl: new UntypedFormControl('', []),
      PreferStreetCtrl: new UntypedFormControl('', [Validators.required]),
      PreferBuildingCtrl: new UntypedFormControl('', []),
      PostalCodeCtrl: new UntypedFormControl('', [Validators.required, Validators.minLength(6), Validators.pattern(Constants.PostalCodeRegex)]),
      'optionsRadios-mobile': new UntypedFormControl(this.profileCopy.preferredMobile, []),
      'optionsRadios-email': new UntypedFormControl(this.profileCopy.preferredEmail, []),
      'optionsRadios-address': new UntypedFormControl('', []),
    });

    this.refreshFormGroupCtrls();
    this.scanFormGroupForError(); // (re)set error messages immediately
    this.profileFormGroup.valueChanges.subscribe(data => this.scanFormGroupForError()); // validate onValueChanged
  }

  // Business logic.
  refreshFormGroupCtrls(): void {
    if (this.profileCopy.preferredEmail === 0) {
      this.profileFormGroup.get('AlterEmailCtrl').disable();
      if (!this.profileFormGroup.get('AlterEmailCtrl').value || this.profileFormGroup.get('AlterEmailCtrl').value.trim().length === 0) {
        this.profileFormGroup.get('AlterEmailCtrl').setValue(this.userProfileService.userProfile.alternateEmail);
      }
    } else {
      this.profileFormGroup.get('AlterEmailCtrl').enable();
    }

    if (this.profileCopy.preferredMobile === 0) {
      this.profileFormGroup.get('AlterMobileCtrl').disable();
      if (!this.profileFormGroup.get('AlterMobileCtrl').value || this.profileFormGroup.get('AlterMobileCtrl').value.trim().length === 0) {
        this.profileFormGroup.get('AlterMobileCtrl').setValue(this.userProfileService.userProfile.alternateMobile);
      }
    } else {
      this.profileFormGroup.get('AlterMobileCtrl').enable();
    }

    if (this.deliveryMode !== Constants.DeliveryMode.HOME_DELIVERY) {
      this.profileFormGroup.get('PreferBlkCtrl').disable();
      this.profileFormGroup.get('PreferUnitCtrl').disable();
      this.profileFormGroup.get('PreferStreetCtrl').disable();
      this.profileFormGroup.get('PreferBuildingCtrl').disable();
      this.profileFormGroup.get('PostalCodeCtrl').disable();
    } else {
      this.profileFormGroup.get('PreferBlkCtrl').enable();
      this.profileFormGroup.get('PreferUnitCtrl').enable();
      this.profileFormGroup.get('PreferStreetCtrl').enable();
      this.profileFormGroup.get('PreferBuildingCtrl').enable();
      this.profileFormGroup.get('PostalCodeCtrl').enable();
    }
  }

  scanFormGroupForError() {
    this.resetErrorDisplay(); // Flush old error
    const FG = this.profileFormGroup;
    if (!FG) { return; }

    // Match new errors and push to error message array
    const FGMsgs = this.profileFormGroupMessages;
    Object.keys(FG.controls).forEach((ctrlname) => {
      const currentCtrl = FG.get(ctrlname);
      if (currentCtrl && !currentCtrl.valid && currentCtrl.errors) {
        const errMsgObj = FGMsgs[ctrlname];
        Object.keys(currentCtrl.errors).forEach(element => {
          const errMsg = errMsgObj[element];
          if (errMsg) { this.ErrorDisplay[ctrlname] = errMsg; }
        }
        );
      }
    });
  }

  // Angular2 dirty check is not reliable, still have to deep-diff.
  scanFormGroupForDirty(): boolean {
    let isDirty = false;
    if (!this.profileFormGroup) {
      return isDirty;
    }
    Object.keys(this.profileFormGroup.controls).some((element) => {
      const currentCtrl = this.profileFormGroup.get(element);
      if (currentCtrl.dirty) { isDirty = true; return true; }
    });
    return isDirty;
  }

  onDeliveryModeChanged(event) {
    this.deliveryMode = Number(event.target.value);

    this.refreshFormGroupCtrls();

    this.reminderTitle = null;
    this.reminderContent = null;
    if ((this.deliveryMode === this.DeliveryMode.ELOCKER && !this.selectedEL)
      || (this.deliveryMode === this.DeliveryMode.WALK_IN_SELF_ORDER && !this.selectedWICP)) {
      if (this.deliveryMode === this.DeliveryMode.ELOCKER) {
        this.reminderTitle = 'eLocker Unavailable';
        this.reminderContent = 'Please note that there is no eLocker Collection Point available for you at the moment.';
      } else if (this.deliveryMode === this.DeliveryMode.WALK_IN_SELF_ORDER) {
        this.reminderTitle = 'Walk-in Self Order Unavailable';
        this.reminderContent = 'Please note that there is no Walk-in Self Order Collection Point available for you at the moment.';
      }
      this.deliveryMode = this.DeliveryMode.SELF_COLLECTION;
      event.target.value = this.DeliveryMode.SELF_COLLECTION;
    }

    if (this.reminderTitle && this.reminderContent) {
      document.getElementById('openReminderModalButton').click();
    }
  }

  /**
   *
   * Assemble POST request body, then handle success/error cases.
   * @public
   * @returns
   * @memberof ProfileComponent
   */
  public doSubmit() {
    const postbody = <UpdateProfileRequest>{};

    postbody.preferredDeliveryMode = this.deliveryMode;
    if (this.deliveryMode === this.DeliveryMode.SELF_COLLECTION) {
      postbody.preferredCollectionPoint = this.selectedCP.locationId;
    } else if (this.deliveryMode === this.DeliveryMode.ELOCKER) {
      postbody.preferredCollectionPoint = this.selectedEL.locationId;
    } else if (this.deliveryMode === this.DeliveryMode.WALK_IN_SELF_ORDER) {
      postbody.preferredCollectionPoint = this.selectedWICP.locationId;
    }
    postbody.preferredMobile = this.profileCopy.preferredMobile;
    postbody.preferredEmail = this.profileCopy.preferredEmail;
    postbody.preferredAddress = this.profileCopy.preferredAddr;

    if (this.profileCopy.preferredEmail > 0) {
      postbody.alternateEmail = this.profileCopy.alternateEmail;
    }

    if (this.profileCopy.preferredMobile > 0) {
      postbody.alternateMobile = this.profileCopy.alternateMobile;
    }

    const addrDTO = <AddressDTO>{};
    addrDTO.blkNo = this.profileCopy.alternateDeliveryAddr.blkNo;
    addrDTO.unitNo = this.profileCopy.alternateDeliveryAddr.unitNo;
    addrDTO.buildingName = this.profileCopy.alternateDeliveryAddr.buildingName;
    addrDTO.streetName = this.profileCopy.alternateDeliveryAddr.streetName;
    addrDTO.postalCode = this.profileCopy.alternateDeliveryAddr.postalCode;
    postbody.alternateDeliveryAddress = addrDTO;

    this.isDataUpdating = true;
    this.userProfileService.updateProfile(postbody)
      .pipe(finalize(() => this.isDataUpdating = false))
      .subscribe(
        data => {
          this.handleUpdateSuccess();
        },
        err => {
          this.handleUpdateFailed(err);
        }
      )
  }

  handleUpdateSuccess(msg?: any) {
    this.profileCopy = CommonUtils.cloneDeep(this.userProfileService.userProfile);
    if (!this.profileCopy.alternateDeliveryAddr) {
      this.profileCopy.alternateDeliveryAddr = {};
    }

    this.notificationService.success(
      'Profile',
      'Updated successfully'
    );
  }

  handleUpdateFailed(err) {
    this.notificationService.error(
      'Profile',
      'Update failed, please try again later'
    );
  }

}
