import { Component, OnInit } from '@angular/core';
import { StoreConfigService } from 'app/services/store-config.service';

@Component({
  selector: 'app-access-error',
  templateUrl: './access-error.component.html',
  styleUrls: ['./access-error.component.css']
})
export class AccessErrorComponent implements OnInit {

  constructor(public configSvc: StoreConfigService) { }

  ngOnInit(): void {
  }

}
