/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as models from './models';

/**
 * model contain the list of user permissions.
 */
export interface GetUserPermissionsResponseResult {
    /**
     * List of permissions for current user.
     */
    permissions?: Array<GetUserPermissionsResponseResult.PermissionsEnum>;

}
export namespace GetUserPermissionsResponseResult {
    export enum PermissionsEnum {
        ACCESSEMARTSTORE = <any> 'ACCESS_EMART_STORE',
        CREATEUPDATEROLE = <any> 'CREATE_UPDATE_ROLE',
        ASSIGNROLEINT = <any> 'ASSIGN_ROLE_INT',
        ASSIGNROLEVENDOR = <any> 'ASSIGN_ROLE_VENDOR',
        VIEWBUSINESSCONFIG = <any> 'VIEW_BUSINESS_CONFIG',
        VIEWUPDATEBUSINESSCONFIG = <any> 'VIEW_UPDATE_BUSINESS_CONFIG',
        ALLOCATECREDIT = <any> 'ALLOCATE_CREDIT',
        APPROVECREDITALLOCATE = <any> 'APPROVE_CREDIT_ALLOCATE',
        GENERATEREPORTOWNDEPARTMENT = <any> 'GENERATE_REPORT_OWN_DEPARTMENT',
        GENERATEREPORTALLDEPARTMENT = <any> 'GENERATE_REPORT_ALL_DEPARTMENT',
        MANAGECATALOGUE = <any> 'MANAGE_CATALOGUE',
        MANAGETAILOR = <any> 'MANAGE_TAILOR',
        MANAGETAILORINGCATALOGUE = <any> 'MANAGE_TAILORING_CATALOGUE',
        MANAGETAILORINGORDER = <any> 'MANAGE_TAILORING_ORDER',
        PACKONLINEORDER = <any> 'PACK_ONLINE_ORDER',
        MARKRECEIVEPACKEDORDER = <any> 'MARK_RECEIVE_PACKED_ORDER',
        MARKORDERCOLLECTED = <any> 'MARK_ORDER_COLLECTED',
        WALKINORDER = <any> 'WALK_IN_ORDER',
        HOMEDELIVERY = <any> 'HOME_DELIVERY',
        ELOCKER = <any> 'ELOCKER',
        CREATEVENDORUSER = <any> 'CREATE_VENDOR_USER',
        CREATEINTRANETUSER = <any> 'CREATE_INTRANET_USER',
        MANAGECOLLECTIONPOINT = <any> 'MANAGE_COLLECTION_POINT',
        MANAGENAMETAG = <any> 'MANAGE_NAME_TAG',
        MANAGENAMETAGORDER = <any> 'MANAGE_NAME_TAG_ORDER',
        MANAGEFEEDBACK = <any> 'MANAGE_FEEDBACK',
        MANAGEUNIT = <any> 'MANAGE_UNIT',
        QUERYSERVICEMANOWNDEPARTMENT = <any> 'QUERY_SERVICEMAN_OWN_DEPARTMENT',
        QUERYSERVICEMANALLDEPARTMENT = <any> 'QUERY_SERVICEMAN_ALL_DEPARTMENT',
        ENROLSERVICEMANOWNDEPARTMENT = <any> 'ENROL_SERVICEMAN_OWN_DEPARTMENT',
        ENROLSERVICEMANALLDEPARTMENT = <any> 'ENROL_SERVICEMAN_ALL_DEPARTMENT'
    }
}
