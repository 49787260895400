import { Observable, of, forkJoin, catchError } from 'rxjs';
import { Router, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserProfileService } from './../../services/user-profile.service';
import { StoreConfigService } from './../../services/store-config.service';
import { ProductsService } from './../../services/products.service';
import { CartService } from './../../services/cart.service';
import { Constants } from './../../shared/global-constants/constants';

@Injectable()
export class TailorShoppingCartGuard implements Resolve<any> {

  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    private storeConfigService: StoreConfigService,
    private productService: ProductsService,
    private cartService: CartService) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const $obs = forkJoin({
      cartDetail: this.cartService.getCartDetail(),
      deliveryFee: this.cartService.getDeliveryFee(),
      creditSummary: this.userProfileService.loadCreditSummary(),
      // Commented Out bcs currently only have 1 delivery mode
      // tailorDeliveryModes: this.storeConfigService.loadTailorDeliveryModes(),
      cpSelections: this.storeConfigService.loadCollectionPointSelections(),
      elockerSelections: this.storeConfigService.loadElockerSelections(true),
      collectionPoints: this.storeConfigService.loadCollectionPoints(),
      elocker: this.storeConfigService.loadElockers(),
      tailors: this.storeConfigService.loadTailors(),
      productsTailor: this.productService.loadProducts(Constants.ProductType.TAILOR),
      productCaps: this.productService.loadProductCaps()
    }).pipe(catchError(err => {
      this.router.navigate(['/general-error']);
      return of(err);
    }));
    
    return $obs;
  }

}
