<!-- end push -->
<nav id="menu" class="panel" role="navigation">
  <ul>
    <li class="menu-lv1">
      <a routerLink="/view-products/All" (click)="closeBigSlide()">Catalogue</a>
    </li>
    <li class="menu-lv1">
      <a routerLink="view-uniforms" (click)="closeBigSlide()">Tailored Uniform</a>
    </li>
    <li class="menu-lv1">
      <a routerLink="view-name-tags" (click)="closeBigSlide()">Name Tag</a>
    </li>
    <li class="menu-lv1">
      <a routerLink="credits" (click)="closeBigSlide()">Credits</a>
    </li>
    <li class="menu-lv1">
      <a routerLink="current-orders" (click)="closeBigSlide()">Recent Orders</a>
    </li>
    <li class="menu-lv1">
      <a routerLink="transaction-history" (click)="closeBigSlide()">Transaction History</a>
    </li>
  </ul>
  <div class="bottom-nav">
    <div class="search-input-group mobile">
      <div class="form-group">
        <input type="text" class="form-control" (keyup)="searchFor($event.target.value)" placeholder="Search for...">
        <span class="glyphicon glyphicon-search" aria-hidden="true" (click)="closeBigSlide()"></span>
      </div>
    </div>
    <ul class="middle-nav">
      <li>
        <span class="glyphicon glyphicon-star" aria-hidden="true" (click)="gotoWaitlist()"></span>
        <span class="cart-qty" (click)="gotoWaitlist()">{{waitlistService.getWaitlistItems()}}</span>
      </li>
      <li>
        <span class="glyphicon glyphicon-shopping-cart" aria-hidden="true" (click)="gotoCart()"></span>
        <span class="cart-qty" (click)="gotoCart()">{{cartService.getCartItems()}}</span>
      </li>
      <li>
        <a routerLink="profile">
          <span class="glyphicon glyphicon-user" aria-hidden="true"></span>
        </a>
      </li>
      <!-- Logout Icon to the push menu for Mobile View -->
      <!--Logout Button Added.-->
      <li class="logged-in" style="cursor:pointer; background:none; border:none;" aria-hidden="true"
        data-bs-toggle="modal" data-bs-target="#logout-popup" title="Log Out Session">
          <span class="glyphicon glyphicon-log-out"></span>
      </li>
    </ul>
    <ul>
      <li class="menu-lv1">
        <a routerLink="collection-point-list">Collection Points</a>
      </li>
      <li class="menu-lv1">
        <a routerLink="tailor-list">Tailor List</a>
      </li>
      <li class="menu-lv1">
        <a routerLink="feedback">Feedback</a>
      </li>
      <li class="menu-lv1">
        <a routerLink="faq">FAQ</a>
      </li>
    </ul>
  </div>
</nav>