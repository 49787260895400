<div class="wrap push">
    <!-- masthead -->
    <sgds-masthead></sgds-masthead>
    <!-- /masthead -->
    
    <!-- load header base on login status -->
    <app-header *ngIf="publicPage===false"></app-header>
    <app-public-header *ngIf="publicPage===true"></app-public-header>

    <div class="container align-footer">
        <div class="row">
            <!-- mainbody / errorpage -->
            <!-- <router-outlet (activate)='onActivate($event)'></router-outlet> -->
            <router-outlet></router-outlet>
        </div>
    </div>

    <!--footer-->
    <app-footer-new *ngIf="showFooter === true"></app-footer-new>
</div>
<!-- end push -->
<app-pushmenu></app-pushmenu>

<!-- Session Extension and Reminder Popup -->
<div id="session-extension-pop-up" class="modal fade" role="dialog">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Session Expiring</h1>
        </div>
        <div class="modal-body">
          Your session is about to expire in {{ timeoutDiff | countdown }}. <br>
          Please click Extend to continue your session.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn" data-bs-dismiss="modal" (click)="authService?.logoutSession()">Logout</button>
          <button type="button" class="btn" data-bs-dismiss="modal" (click)="extendSession()">Extend</button>
        </div>
      </div>
    </div>
</div>

<!-- Session Timeout Popup -->
<div id="session-expired-pop-up" class="modal fade" role="dialog">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title">Session Expired</h1>
        </div>
        <div class="modal-body">
          Your session has expired or you are no longer logged in. <br>
          Please proceed back to the eMART website.
        </div>
        <div class="modal-footer">
          <button type="button" class="btn" data-bs-dismiss="modal" (click)="authService?.clearSession(true)">OK</button>
        </div>
      </div>
    </div>
</div>

<!-- Log Out Static Popup -->
<div class="modal fade" id="logout-popup" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="logout-popupLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title" id="logout-popupLabel">Logout</h1>
      </div>
      <div class="modal-body">
        Are you sure you want to logout ?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn " data-bs-dismiss="modal" #closeBtn>Cancel</button>
        <button type="button" class="btn" (click)="authService?.logoutSession()" >OK</button>
      </div>
    </div>
  </div>
</div>