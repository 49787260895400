<div class="breadcrumb-section">
    <div class="container">
        <ul class="breadcrumb">
            <li class="breadcrumb-item">
                <a routerLink="/dashboard" aria-label="breadcrumb-item"></a>
            </li>
            <li class="breadcrumb-item active">
                <a id="breadcrumb-active-link" routerLink="/terms-of-use" aria-label="breadcrumb-item">Terms of Use</a>
            </li>
        </ul>
    </div>
  </div>
  <div class="text-justify">
    <h1>Terms of Use</h1>
    <p>
      Thank you for visiting mha.gov.sg (henceforth known as "Site"). By accessing and using any part of this Site, 
      you shall be deemed to have accepted to be legally bound by these Terms of Use. If you do not agree to these 
      Terms of Use, please do not use this Site.
    </p>
    <h3>General</h3>
    <p>
      2. These Terms of Use may be changed from time to time. All changes will be posted on this page, and your 
      use of this Site after such changes have been posted will constitute your agreement to the modified Terms 
      of Use and all of the changes.
    </p>
    <h3>Proprietary Rights</h3>
    <p>
      3. This Site is the official Singapore Ministry of Home Affairs website. It is owned and operated by the 
      Ministry of Home Affairs (MHA).
      <br><br>
      4. The materials located on this Site including the information and software programs (the Contents), 
      are protected by copyright, trademark and other forms of proprietary rights. All rights, title and 
      interest in the Contents are owned by, licensed to or controlled by MHA.
    </p>
    <h3>Privacy Statement</h3>
    <p>
      5. Click here to review this Site <a routerLink="/privacy-statement">Privacy Statement</a>.
      <br>
    </p>
    <h3>Restrictions on Use of Contents</h3>
    <p>
      6. Except as otherwise provided, the Contents of this Site shall not be reproduced, 
      republished, uploaded, posted, transmitted or otherwise distributed in any way, 
      without the prior permission of MHA. To use any parts of the Contents of this Site, 
      the user must seek permission in writing, stating the Contents for use; intent of use; 
      manner of use; time frame of use and identity of the user. MHA reserves the right to 
      refuse permission without declaring reason(s); and/or if information submitted by user 
      is deemed insufficient.
      <br><br>
      7. Modification of any of the Contents or use of the Contents for any other purpose will 
      be a violation of MHA's copyright and other intellectual property rights. Graphics and 
      images on this Site are protected by copyright and may not be reproduced or appropriated 
      in any manner without written permission of MHA.
    </p>
    <h3>Disclaimer of Warranties and Liability</h3>
    <p>
      8. The Contents of this Site are provided on an "as is" basis without warranties of 
      any kind. Without prejudice to the foregoing, MHA does not make any representations 
      or warranties:
      <br><br>
      (a) as to the accuracy, completeness, reliability, timeliness, currency, quality, 
      non-infringement, title, or fitness for any particular purpose of the Contents of 
      this Site;
      <br><br>
      (b) that the Contents available through this Site or any functions associated 
      therewith will be uninterrupted or error-free, or that defects will be corrected 
      or that this Site and the server is and will be free of all viruses and/or other 
      harmful elements.
      <br><br>
      9(a) MHA shall also not be liable for any damage, loss or expense of any kind 
      arising directly or indirectly from the use of the Site, any inaccuracy or 
      incompleteness in the Contents of the Site, or any errors or omissions in the 
      transmission of the Contents.
      <br><br>
      (b) MHA shall not be responsible or held accountable in any way for any decision 
      made or action taken by you or any third party in reliance upon the Contents 
      of this Site.
    </p>
    <h3>Right of Access</h3>
    <p>
      10. MHA reserves all rights to deny or restrict access to this Site to any 
      particular person, or to block access from a particular Internet address 
      to this Site, at any time, without ascribing any reasons whatsoever.
    </p>
    <p>
      Links "from" this Site to other websites
    </p>
    <p>
      11. This Site contains hyperlinks to websites which are not maintained by 
      MHA. MHA is not responsible for the contents of those websites and shall 
      not be liable for any damages or loss arising from access to those websites. 
      Use of the hyper-links and access to such websites are entirely at your own risk.
      <br><br>
      12. Hyperlinks to other websites are provided as a convenience to the user. In 
      no circumstances shall MHA be considered to be associated or affiliated in 
      whatever manner with any trade or service marks, logos, insignia or other devices 
      used or appearing on websites to which this Site is linked.
      <br>
    </p>
      <h3>Links "to" this Site from other websites</h3>
      <p>
          13. Except as set forth below, caching and links to, and the framing of 
          this Site or any of the Contents are prohibited.
          <br><br>
          14. Linking to the Homepage: You may link to the Homepage of this Site, 
          upon notifying MHA in writing.
          <br><br>
          15. Hyperlinking to an internal page of this Site: You must make a specific 
          request for, and secure permission from MHA prior to hyperlinking to, 
          or framing, this Site or any of the Contents, or engaging in similar 
          activities. MHA reserves the right to impose conditions when permitting 
          any hyperlinking to, or framing of this Site or any of the Contents.
          <br><br>
          16. Your linking to, or your framing of this Site or any of the Contents 
          constitute acceptance of these Terms of Use. This is deemed to be the 
          case even after the posting of any changes or modifications to these 
          Terms of Use. If you do not accept these Terms of Use, you must discontinue 
          linking to, or framing of this Site or any of the Contents.
          <br><br>
          17. Under no circumstances shall MHA be considered to be associated or 
          affiliated in whatever manner with any trade or service marks, logos, 
          insignia or other devices used or appearing on websites that link to 
          this Site or any of the Contents.
          <br><br>
          18. MHA reserves all rights to disable any links to, or frames of any site 
          containing inappropriate, profane, defamatory, infringing, obscene, 
          indecent or unlawful topics, names, material or information, or material 
          or information that violates any written law, any applicable intellectual 
          property, proprietary, privacy or publicity rights.
          <br><br>
          19. MHA reserves the right to disable any unauthorised links or frames and 
          disclaims any responsibility for the content available on any other website 
          reached by links to or from this Site or any of the Contents.
      </p>
      <h3>Governing Law</h3>
      <p>
          20. These Terms of Use shall be governed and construed in accordance with 
          laws of the Republic of Singapore.
      </p>
  </div>
  
  