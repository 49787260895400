<!-- my available credits -->
<div class="col-md-12">
    <div class="collapse-box">
        <div class="collapse-title">
            <h1>My Available Credits</h1>
            <div class="btn-toggle-collapse-wrapper">
                <a class="btn-toggle-collapse" data-bs-target="#credits1" data-bs-toggle="collapse"></a>
            </div>
        </div>
        <div id="credits1" class="collapse show">
            <div class="box-content">
                <table class="table credits" *ngIf="userProfileService.creditSummary != undefined">
                    <thead>
                        <tr>
                            <th>Credit Type</th>
                            <th>Credit Balance</th>
                            <th>Credit Expire On</th>
                            <th>Next Credit Allocation Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let summary of userProfileService.creditSummary.creditSummaries">
                            <td class="type">{{summary.title}}</td>
                            <td class="balance">{{summary.creditBalance | priceFormat}}</td>
                            <td class="exp">{{summary.expiryDate ? summary.expiryDate : '-'}}</td>
                            <td class="next">{{summary.nextAllocationDate ? summary.nextAllocationDate : '-'}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>Total</td>
                            <td>{{userProfileService.creditSummary.creditBalance | priceFormat}}</td>
                            <td colspan="2"></td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    </div>
</div>
<!-- My Credit Transactions -->
<div class="col-md-12">
    <div class="collapse-box">
        <div class="collapse-title">
            <h1>My Credit Transactions</h1>
            <div class="btn-toggle-collapse-wrapper">
                <a class="btn-toggle-collapse" data-bs-target="#credits2" data-bs-toggle="collapse"></a>
            </div>
        </div>
        <div class="collapse show">
            <div class="box-content">
                <div class="find-box">
                    <table>
                        <tr>
                            <td valign="top" class="title">Transaction from</td>
                            <td class="radio-content">
                                <mat-form-field appearance="none">
                                    <input matInput [matDatepicker]="fromDate" id="fromDate" type="text" [formControl]="fromDateForm.controls.date" 
                                        [min]="minDate" [max]="maxDate" (click)="fromDate.open()"
                                        placeholder="Please Select" class="form-control clickable date-input"/>
                                    <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                                    <mat-datepicker #fromDate></mat-datepicker>
                                </mat-form-field>
                            </td> to
                            <td class="radio-content">
                                <mat-form-field appearance="none">
                                    <input matInput [matDatepicker]="toDate" id="toDate" type="text" [formControl]="toDateForm.controls.date" 
                                        [min]="minDate" [max]="maxDate" (click)="toDate.open()"
                                        placeholder="Please Select" class="form-control clickable date-input"/>
                                    <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                                    <mat-datepicker #toDate></mat-datepicker>
                                </mat-form-field>
                            </td>
                            <td class="radio-content">
                                <button type="button" class="btn" (click)="getCreditHistory()">Find</button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div *ngIf="errorMsg != null" class="alert alert-danger box-content">{{errorMsg}}
                </div>
                <div id="credits2" *ngIf="userProfileService.creditHistorys != undefined">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Transactions</th>
                                <th style="text-align: left;">Credit In</th>
                                <th style="text-align: left;">Credit Out</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let creditHistory of getItemsToDisplay()">
                                <td>{{creditHistory.txnDate}}</td>
                                <td>{{creditHistory.txnType}}</td>
                                <td style="text-align: left;">{{creditHistory.creditIn | priceFormat}}</td>
                                <td style="text-align: left;">{{creditHistory.creditOut | priceFormat}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="pagination">
                        <button type="button" class="btn btn-square" [disabled]="prevPaginationDisabled" (click)="gotoFirstPage()">
                            <span class="glyphicon glyphicon-step-backward" aria-hidden="true"></span>
                        </button>
                        <button type="button" class="btn btn-square" [disabled]="prevPaginationDisabled" (click)="gotoPreviousPage()">
                            <span class="glyphicon glyphicon-triangle-left" aria-hidden="true"></span>
                        </button>
                        <div class="page">
                            <input #page type="text" class="form-control" [value]="currentPage" (keyup)="gotoPage(page.value, $event)"> / {{pageCount}}
                        </div>
                        <button type="button" class="btn btn-square" [disabled]="nextPaginationDisabled" (click)="gotoNextPage()">
                            <span class="glyphicon glyphicon-triangle-right" aria-hidden="true"></span>
                        </button>
                        <button type="button" class="btn btn-square" [disabled]="nextPaginationDisabled" (click)="gotoLastPage()">
                            <span class="glyphicon glyphicon-step-forward" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
                <div class="alert alert-info text-center" role="alert" *ngIf="userProfileService.creditHistorys == null || userProfileService.creditHistorys.length == 0">
                    <i class="fa fa-info-circle fa-2x"></i>
                    <p>No transaction is found</p>
                </div>
            </div>
        </div>
    </div>
</div>