import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { TransactionHistoryService } from '../../../services/transaction-history.service';
import { OrderDTO, OrdersApi } from '../../../shared/backend-api/emart/index';
import { ProductsService } from './../../../services/products.service';
import { Constants } from './../../../shared/global-constants/constants';
import { CurrentOrdersService } from '../../../services/current-orders.service';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.css'],
})
export class TransactionHistoryComponent implements OnInit {

  private router: Router;

  searchOption: string;
  timeRangeOption: string;
  fromDateForm: UntypedFormGroup;
  toDateForm: UntypedFormGroup;
  errorMsg: string;
  orderToViewDetail: OrderDTO;
  minDate: Date = new Date();
  maxDate: Date = new Date();

  currentSearchOption;
  currentFromDate;
  currentToDate;

  // Pagination
  currentPage = 1;
  itemPerPage = 10;
  pageCount = 1;
  nextPaginationDisabled = false;
  prevPaginationDisabled = false;
  // ---

  constructor(
    private formBuilder: UntypedFormBuilder,
    private orderApi: OrdersApi,
    private productsService: ProductsService,
    public transactionHistoryService: TransactionHistoryService,
    public currentOrdersService: CurrentOrdersService
  ) { }

  ngOnInit() {  
    this.minDate.setFullYear(this.maxDate.getFullYear() - 10);
    this.maxDate.setFullYear(this.maxDate.getFullYear() + 10);

    this.currentOrdersService.orderPrint.prevPath = 'transaction-history';
    this.errorMsg = null;
    this.fromDateForm = this.formBuilder.group({ date: [], startAt: [], endAt: [] });
    this.toDateForm = this.formBuilder.group({ date: [], startAt: [], endAt: [] });

    if (!this.currentOrdersService.orderPrint.searchOption) {
      this.searchOption = '1';
      this.timeRangeOption = '1';
    } else if (this.currentOrdersService.orderPrint.searchOption) {
      this.searchOption = this.currentOrdersService.orderPrint.searchOption;
      this.timeRangeOption = this.currentOrdersService.orderPrint.timeRangeOption;
      this.currentOrdersService.orderPrint.searchOption = null;
      this.currentOrdersService.orderPrint.timeRangeOption = null;
    }
    this.searchOrder();

    this.productsService.loadProducts(Constants.ProductType.NORMAL).subscribe();
    this.productsService.loadProducts(Constants.ProductType.TAILOR).subscribe();
    this.productsService.loadProducts(Constants.ProductType.NAME_TAG).subscribe();
  }

  searchOrder() {
    this.errorMsg = null;
    this.currentPage = 1;

    let fromDate: Date = new Date();
    let toDate: Date = new Date();

    if (this.searchOption === '1') {
      this.fromDateForm.controls['date'].disable();
      this.toDateForm.controls['date'].disable();
      if (this.timeRangeOption === '1') {
        fromDate.setMonth(fromDate.getMonth() - 1);
      } else if (this.timeRangeOption === '2') {
        fromDate.setMonth(fromDate.getMonth() - 3);
      } else if (this.timeRangeOption === '3') {
        fromDate.setMonth(fromDate.getMonth() - 6);
      } else {
        fromDate.setMonth(fromDate.getMonth() - 12);
      }
    } else {

      fromDate = moment(this.fromDateForm.controls['date'].value, 'DD MMM YYYY').toDate();
      toDate = moment(this.toDateForm.controls['date'].value, 'DD MMM YYYY').toDate();

      if (this.currentOrdersService.orderPrint.currentFromDate) {
        fromDate = this.currentOrdersService.orderPrint.currentFromDate;
        this.currentOrdersService.orderPrint.currentFromDate = null;
        this.fromDateForm.controls['date'].setValue(moment(fromDate).format('DD MMM YYYY'));
      }
      if (this.currentOrdersService.orderPrint.currentToDate) {
        toDate = this.currentOrdersService.orderPrint.currentToDate;
        this.currentOrdersService.orderPrint.currentToDate = null;
        this.toDateForm.controls['date'].setValue(moment(toDate).format('DD MMM YYYY'));
      }
    }

    if (!this.validateDates(fromDate, toDate)) {
      return null;
    }

    if (this.currentOrdersService.orderPrint.currentOrderListData && this.currentOrdersService.orderPrint.currentPage) {
      this.currentPage = this.currentOrdersService.orderPrint.currentPage;
      this.transactionHistoryService.orders = this.currentOrdersService.orderPrint.currentOrderListData;
      if (this.transactionHistoryService.orders == null) {
        this.transactionHistoryService.orders = [];
      }
      this.updatePagination();

      this.currentOrdersService.orderPrint.currentOrderListData = null;
      this.currentOrdersService.orderPrint.currentPage = null;

    } else {
      this.transactionHistoryService.orders = null;
      this.orderApi.getOrdersUsingGET(moment(fromDate).format('YYYY-MM-DD'), moment(toDate).format('YYYY-MM-DD')).subscribe(response => {
        //when status == 200 & 204, execute happy flow
        if (response.status == 200 || response.status == 204){ 
          this.transactionHistoryService.orders = response.data;
          if (this.transactionHistoryService.orders == null) {
            this.transactionHistoryService.orders = [];
          }
          this.currentSearchOption = this.searchOption;
          this.currentFromDate = fromDate;
          this.currentToDate = toDate;
          this.updatePagination();

        // other than 200, 204, 401, 403, raise error
        }else if (response.status != 401 && response.status != 403){
          this.router.navigate(['/general-error']);
        }
      });
    }
  }

  viewOrderDetail(order: any) {
    this.orderToViewDetail = order;
    this.currentOrdersService.orderPrint.searchOption = this.currentSearchOption;
    this.currentOrdersService.orderPrint.timeRangeOption = this.timeRangeOption;
    this.currentOrdersService.orderPrint.currentPage = this.currentPage;
    this.currentOrdersService.orderPrint.currentOrderListData = this.transactionHistoryService.orders;
    this.currentOrdersService.orderPrint.currentFromDate = this.currentFromDate;
    this.currentOrdersService.orderPrint.currentToDate = this.currentToDate;

  }

  validateDates(fromDate, toDate): boolean {
    if (isNaN(fromDate) || isNaN(toDate)) {
      this.errorMsg = 'Invalid date';
      return false;
    }
    if (toDate < fromDate) {
      this.errorMsg = 'Start date must not be greater than end date';
      return false;
    }
    if (toDate > new Date()) {
      this.errorMsg = 'End date must not be greater than today\'s date';
      return false;
    }
    return true;
  }

  // Pagination
  getItemsToDisplay(): any[] {
    if (this.transactionHistoryService.orders && this.transactionHistoryService.orders.length > 0) {
      const firstItemIndex = (this.currentPage - 1) * this.itemPerPage;
      const endItemIndex = this.currentPage * this.itemPerPage;
      return this.transactionHistoryService.orders.slice(firstItemIndex, endItemIndex);
    } else {
      return [];
    }
  }

  updatePagination() {
    try {
      this.pageCount = Math.ceil(this.transactionHistoryService.orders.length / this.itemPerPage);
      this.currentPage = this.currentPage > this.pageCount ? this.pageCount : this.currentPage;
      this.nextPaginationDisabled = this.currentPage * this.itemPerPage >= this.transactionHistoryService.orders.length;
      this.prevPaginationDisabled = this.currentPage <= 1;
    } catch (e) {
      this.pageCount = 1;
      this.currentPage = 1;
      this.nextPaginationDisabled = true;
      this.prevPaginationDisabled = true;
    }
  }

  gotoPreviousPage() {
    this.gotoPage(Number(this.currentPage) - 1);
  }

  gotoNextPage() {
    this.gotoPage(Number(this.currentPage) + 1);
  }

  gotoFirstPage() {
    this.gotoPage(1);
  }

  gotoLastPage() {
    this.gotoPage(this.pageCount);
  }

  gotoPage(page: number, event?: any) {
    if (event && event.key !== 'Enter') {
      return;
    }

    if (page > 0 && page <= this.pageCount) {
      this.currentPage = page;
      this.updatePagination();
    }
  }

  /*
    Initially used html [attr.disabled] to check disable on the input for date picker
    but Angular Material date picker doesn't support reactive forms [disabled].
    https://stackoverflow.com/questions/47571466/how-to-disable-a-text-area-or-mat-form-field
  */
  enableMatDatePicker() {
    this.fromDateForm.controls['date'].enable();
    this.toDateForm.controls['date'].enable();
  }

}
