/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class CatalogueManagementApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient,) {
        
    }

    /**
     * deleteCategory
     * Delete category in category listing
     * @param request request
     */
    public deleteCategoryUsingPOST(request: models.DeleteCategoryRequest, extraHttpRequestParams?: any): Observable<models.DeleteCategoryResponse> {
        return this.deleteCategoryUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * deleteProductGroup
     * Delete product group in group listing
     * @param request Group Id to be deleted.
     */
    public deleteProductGroupUsingPOST(request: models.DeleteProductGroupRequest, extraHttpRequestParams?: any): Observable<models.DeleteProductGroupResponse> {
        return this.deleteProductGroupUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * deleteProducts
     * Delete list of products with the given product id.
     * @param request Should contain the list of product ids to be deleted.
     */
    public deleteProductWithIdsUsingPOST(request: models.DeleteProductRequest, extraHttpRequestParams?: any): Observable<models.DeleteProductResponse> {
        return this.deleteProductWithIdsUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getCategoriesForAdmin
     * Get categories
     * @param department Department of the categories &lt;br&gt;&lt;ul&gt;&lt;li&gt;0: SPF and SCDF&lt;/li&gt;&lt;li&gt;1: SPF&lt;/li&gt; &lt;li&gt;2: SCDF&lt;/li&gt;&lt;/ul&gt;
     */
    public getCategoriesForAdminUsingGET(department: number, extraHttpRequestParams?: any): Observable<models.GetCategoriesForAdminResponse> {
        return this.getCategoriesForAdminUsingGETWithHttpInfo(department, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getCategorySelections
     * Get category selections
     * @param department Department of the categories &lt;br&gt;&lt;ul&gt;&lt;li&gt;0: SPF &amp; SCDF&lt;/li&gt; &lt;li&gt;1: SPF&lt;/li&gt; &lt;li&gt;2: SCDF&lt;/li&gt;&lt;/ul&gt;
     */
    public getCategorySelectionsUsingGET(department: number, extraHttpRequestParams?: any): Observable<models.GetCategorySelectionsResponse> {
        return this.getCategorySelectionsUsingGETWithHttpInfo(department, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getProductByNameForAdmin
     * Get product(s) by name (case insensitive)
     * @param name to set the product name for filtering.
     */
    public getProductByNameForAdminUsingGET(name?: string, extraHttpRequestParams?: any): Observable<models.GetProductsByNameResponse> {
        return this.getProductByNameForAdminUsingGETWithHttpInfo(name, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getProductGroup
     * Get Product Group
     * @param department to indicate the department for product group&lt;br/&gt;&lt;ul&gt;&lt;li&gt;0:SPF_AND_SCDF&lt;/li&gt;&lt;li&gt;1:SPF&lt;/li&gt;&lt;li&gt;2:SCDF&lt;/li&gt;&lt;/ul&gt;
     */
    public getProductGroupUsingGET(department: number, extraHttpRequestParams?: any): Observable<models.GetProductGroupResponse> {
        return this.getProductGroupUsingGETWithHttpInfo(department, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getProductsForAdmin
     * Get products
     * @param department Department of the categories &lt;br&gt;&lt;ul&gt;&lt;li&gt;0: SPF &amp; SCDF&lt;/li&gt;&lt;li&gt;1: SPF&lt;/li&gt; &lt;li&gt;2: SCDF&lt;/li&gt;&lt;/ul&gt;
     * @param page to set the index of pages to retrieve the products. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param skuId to set the product skuID to be retrieved. If it is set, the skuID must be matched. If other params is not null, they are all need to be matched the corresponding products as well.
     * @param name to set the product name to be retrieved. If it is set, the name must be matched fully or partially. If other params is not null, they are all need to be matched the corresponding products as well.
     * @param capType to set the product cap type for filtering.&lt;br&gt;&lt;ul&gt;&lt;li&gt;0: N.A&lt;/li&gt; &lt;li&gt;1: Hard&lt;/li&gt; &lt;li&gt;2: Soft&lt;/li&gt;&lt;/ul&gt;
     * @param category to set the product category for filtering.
     * @param status to set the product status for filtering.&lt;br&gt;&lt;ul&gt;&lt;li&gt;A: Active&lt;/li&gt; &lt;li&gt;I: Inactive&lt;/li&gt;&lt;/ul&gt;
     */
    public getProductsForAdminUsingGET(department: number, page: number, size: number, skuId?: string, name?: string, capType?: number, category?: number, status?: string, extraHttpRequestParams?: any): Observable<models.GetProductsForAdminResponse> {
        return this.getProductsForAdminUsingGETWithHttpInfo(department, page, size, skuId, name, capType, category, status, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * rearrangeCategories
     * Rearrange ordinals for categories
     * @param request request
     */
    public rearrangeCategoriesUsingPOST(request: models.RearrangeCategoriesRequest, extraHttpRequestParams?: any): Observable<models.GetCategoriesForAdminResponse> {
        return this.rearrangeCategoriesUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * saveCategory
     * Add new or update category
     * @param request request
     */
    public saveCategoryUsingPOST(request: models.SaveCategoryRequest, extraHttpRequestParams?: any): Observable<models.SaveCategoryResponse> {
        return this.saveCategoryUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * saveProductGroup
     * Save Product Group
     * @param request Request body for save product group.
     */
    public saveProductGroupUsingPOST(request: models.SaveProductGroupRequest, extraHttpRequestParams?: any): Observable<models.SaveProductGroupResponse> {
        return this.saveProductGroupUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * SaveProduct
     * Add new or update product
     * @param request Request body to save product.
     */
    public saveProductUsingPOST(request: models.ProductDTO, extraHttpRequestParams?: any): Observable<models.SaveProductResponse> {
        return this.saveProductUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * deleteCategory
     * Delete category in category listing
     * @param request request
     */
    public deleteCategoryUsingPOSTWithHttpInfo(request: models.DeleteCategoryRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/catalogueManagement/categories/delete';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling deleteCategoryUsingPOST.');
        }

        return this.httpClient.post(path, request, {withCredentials: true});
    }

    /**
     * deleteProductGroup
     * Delete product group in group listing
     * @param request Group Id to be deleted.
     */
    public deleteProductGroupUsingPOSTWithHttpInfo(request: models.DeleteProductGroupRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/catalogueManagement/productGroups/delete';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling deleteProductGroupUsingPOST.');
        }

        return this.httpClient.post(path, request, {withCredentials: true});
    }

    /**
     * deleteProducts
     * Delete list of products with the given product id.
     * @param request Should contain the list of product ids to be deleted.
     */
    public deleteProductWithIdsUsingPOSTWithHttpInfo(request: models.DeleteProductRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/catalogueManagement/products/delete';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling deleteProductWithIdsUsingPOST.');
        }

        return this.httpClient.post(path, request, {withCredentials: true});
    }

    /**
     * getCategoriesForAdmin
     * Get categories
     * @param department Department of the categories &lt;br&gt;&lt;ul&gt;&lt;li&gt;0: SPF and SCDF&lt;/li&gt;&lt;li&gt;1: SPF&lt;/li&gt; &lt;li&gt;2: SCDF&lt;/li&gt;&lt;/ul&gt;
     */
    public getCategoriesForAdminUsingGETWithHttpInfo(department: number, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/catalogueManagement/categories';

        let queryParameters = new HttpParams();

        // verify required parameter 'department' is not null or undefined
        if (department === null || department === undefined) {
            throw new Error('Required parameter department was null or undefined when calling getCategoriesForAdminUsingGET.');
        }
        if (department !== undefined) {
            queryParameters = queryParameters.append('department', <any>department);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true});
    }

    /**
     * getCategorySelections
     * Get category selections
     * @param department Department of the categories &lt;br&gt;&lt;ul&gt;&lt;li&gt;0: SPF &amp; SCDF&lt;/li&gt; &lt;li&gt;1: SPF&lt;/li&gt; &lt;li&gt;2: SCDF&lt;/li&gt;&lt;/ul&gt;
     */
    public getCategorySelectionsUsingGETWithHttpInfo(department: number, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/catalogueManagement/categorySelections';

        let queryParameters = new HttpParams();

        // verify required parameter 'department' is not null or undefined
        if (department === null || department === undefined) {
            throw new Error('Required parameter department was null or undefined when calling getCategorySelectionsUsingGET.');
        }
        if (department !== undefined) {
            queryParameters = queryParameters.append('department', <any>department);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true});
    }

    /**
     * getProductByNameForAdmin
     * Get product(s) by name (case insensitive)
     * @param name to set the product name for filtering.
     */
    public getProductByNameForAdminUsingGETWithHttpInfo(name?: string, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/catalogueManagement/productsByName';

        let queryParameters = new HttpParams();

        if (name !== undefined) {
            queryParameters = queryParameters.append('name', <any>name);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true});
    }

    /**
     * getProductGroup
     * Get Product Group
     * @param department to indicate the department for product group&lt;br/&gt;&lt;ul&gt;&lt;li&gt;0:SPF_AND_SCDF&lt;/li&gt;&lt;li&gt;1:SPF&lt;/li&gt;&lt;li&gt;2:SCDF&lt;/li&gt;&lt;/ul&gt;
     */
    public getProductGroupUsingGETWithHttpInfo(department: number, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/catalogueManagement/productGroups';

        let queryParameters = new HttpParams();

        // verify required parameter 'department' is not null or undefined
        if (department === null || department === undefined) {
            throw new Error('Required parameter department was null or undefined when calling getProductGroupUsingGET.');
        }
        if (department !== undefined) {
            queryParameters = queryParameters.append('department', <any>department);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true});
    }

    /**
     * getProductsForAdmin
     * Get products
     * @param department Department of the categories &lt;br&gt;&lt;ul&gt;&lt;li&gt;0: SPF &amp; SCDF&lt;/li&gt;&lt;li&gt;1: SPF&lt;/li&gt; &lt;li&gt;2: SCDF&lt;/li&gt;&lt;/ul&gt;
     * @param page to set the index of pages to retrieve the products. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param skuId to set the product skuID to be retrieved. If it is set, the skuID must be matched. If other params is not null, they are all need to be matched the corresponding products as well.
     * @param name to set the product name to be retrieved. If it is set, the name must be matched fully or partially. If other params is not null, they are all need to be matched the corresponding products as well.
     * @param capType to set the product cap type for filtering.&lt;br&gt;&lt;ul&gt;&lt;li&gt;0: N.A&lt;/li&gt; &lt;li&gt;1: Hard&lt;/li&gt; &lt;li&gt;2: Soft&lt;/li&gt;&lt;/ul&gt;
     * @param category to set the product category for filtering.
     * @param status to set the product status for filtering.&lt;br&gt;&lt;ul&gt;&lt;li&gt;A: Active&lt;/li&gt; &lt;li&gt;I: Inactive&lt;/li&gt;&lt;/ul&gt;
     */
    public getProductsForAdminUsingGETWithHttpInfo(department: number, page: number, size: number, skuId?: string, name?: string, capType?: number, category?: number, status?: string, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/catalogueManagement/products';

        let queryParameters = new HttpParams();

        // verify required parameter 'department' is not null or undefined
        if (department === null || department === undefined) {
            throw new Error('Required parameter department was null or undefined when calling getProductsForAdminUsingGET.');
        }
        // verify required parameter 'page' is not null or undefined
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getProductsForAdminUsingGET.');
        }
        // verify required parameter 'size' is not null or undefined
        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling getProductsForAdminUsingGET.');
        }
        if (department !== undefined) {
            queryParameters = queryParameters.append('department', <any>department);
        }

        if (skuId !== undefined) {
            queryParameters = queryParameters.append('skuId', <any>skuId);
        }

        if (name !== undefined) {
            queryParameters = queryParameters.append('name', <any>name);
        }

        if (capType !== undefined) {
            queryParameters = queryParameters.append('capType', <any>capType);
        }

        if (category !== undefined) {
            queryParameters = queryParameters.append('category', <any>category);
        }

        if (status !== undefined) {
            queryParameters = queryParameters.append('status', <any>status);
        }

        if (page !== undefined) {
            queryParameters = queryParameters.append('page', <any>page);
        }

        if (size !== undefined) {
            queryParameters = queryParameters.append('size', <any>size);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true});
    }

    /**
     * rearrangeCategories
     * Rearrange ordinals for categories
     * @param request request
     */
    public rearrangeCategoriesUsingPOSTWithHttpInfo(request: models.RearrangeCategoriesRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/catalogueManagement/categories/rearrange';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling rearrangeCategoriesUsingPOST.');
        }

        return this.httpClient.post(path, request, {withCredentials: true});
    }

    /**
     * saveCategory
     * Add new or update category
     * @param request request
     */
    public saveCategoryUsingPOSTWithHttpInfo(request: models.SaveCategoryRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/catalogueManagement/categories';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveCategoryUsingPOST.');
        }

        return this.httpClient.post(path, request, {withCredentials: true});
    }

    /**
     * saveProductGroup
     * Save Product Group
     * @param request Request body for save product group.
     */
    public saveProductGroupUsingPOSTWithHttpInfo(request: models.SaveProductGroupRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/catalogueManagement/productGroups';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveProductGroupUsingPOST.');
        }

        return this.httpClient.post(path, request, {withCredentials: true});
    }

    /**
     * SaveProduct
     * Add new or update product
     * @param request Request body to save product.
     */
    public saveProductUsingPOSTWithHttpInfo(request: models.ProductDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/catalogueManagement/products';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveProductUsingPOST.');
        }

        return this.httpClient.post(path, request, {withCredentials: true});
    }

}
