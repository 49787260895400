<!-- profile 1 -->
<div class="col-md-12">
    <div class="collapse-box">
        <div class="collapse-title name">
            <h1>{{ userProfileService.userProfile?.name }}</h1>
            <div class="btn-toggle-collapse-wrapper">
                <a class="btn-toggle-collapse" href="#profile1" data-bs-toggle="collapse"></a>
            </div>
        </div>
        <div id="profile1" class="collapse show">
            <div class="box-content">
                <table class="two-col-form">
                    <tr>
                        <td class="left">My Unit</td>
                        <td class="right">{{ userProfileService.userProfile?.unit }}</td>
                    </tr>
                    <tr>
                        <td class="left">NRIC</td>
                        <td class="right">{{ userProfileService.userProfile?.nric }}</td>
                    </tr>
                    <tr>
                        <td class="left">Credit</td>
                        <td class="right">{{ userProfileService.creditSummary?.creditBalance | priceFormat }}</td>
                    </tr>
                    <tr>
                        <td class="left">Next Credit Allocation Date</td>
                        <td class="right">
                            {{ userProfileService.creditSummary?.nextAllocationDate ?
                            (userProfileService.creditSummary?.nextAllocationDate) : '-' }}
                        </td>
                    </tr>
                    <tr>
                        <td class="left">Preferred Collection Mode</td>
                        <td class="right">
                            <select name="collection-point-list" class="collection-point-list form-control" required
                                (change)="onDeliveryModeChanged($event)">
                                <option *ngFor="let mode of storeConfigService.deliveryModes" [value]="mode.value"
                                    [selected]="deliveryMode == mode.value">{{mode.text}}</option>
                            </select>
                        </td>
                    </tr>
                    <tr *ngIf="deliveryMode != DeliveryMode.HOME_DELIVERY">
                        <td class="left">Preferred Collection Point</td>
                        <td class="right">
                            <button type="button" class="btn btn-collection-point truncate" data-bs-toggle="modal"
                                data-bs-target="#select-collection-point">
                                <span class="name-wrapper">{{deliveryMode === DeliveryMode.SELF_COLLECTION ?
                                    selectedCP.name : deliveryMode === DeliveryMode.ELOCKER
                                    ? selectedEL.name : selectedWICP.name}}</span>
                                <span class="glyphicon glyphicon-triangle-right" aria-hidden="true"></span>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>

<!-- profile 2 -->
<form [formGroup]="profileFormGroup" (ngSubmit)="doSubmit()">
    <div class="col-md-12">
        <div class="collapse-box">
            <div class="collapse-title">
                <h1> Personal Information</h1>
                <div class="btn-toggle-collapse-wrapper">
                    <a class="btn-toggle-collapse" href="#profile2" data-bs-toggle="collapse"></a>
                </div>
            </div>
            <div id="profile2" class="collapse show">
                <div class="col-md-12">
                    <p>The default information were retrieved from the Ministry of Home Affairs and are not changeable
                        in eMart.
                        You can enter alternate email, mobile number and select them as the preferred information to be
                        used
                        for eMart transaction.
                    </p>
                </div>
                <div class="col-md-6">
                    <div class="box-content">
                        <h2>Email Address</h2>
                        <p>Select your preferred email address</p>
                        <table>
                            <tr>
                                <td valign="top">
                                    <div class="ns-radio radio">
                                        <input type="radio" name="optionsRadios-email" id="optionsRadios1"
                                            formControlName="optionsRadios-email" [value]=0
                                            (click)="profileCopy.preferredEmail=0; refreshFormGroupCtrls()"
                                            >
                                        <label for="optionsRadios1"> </label>
                                    </div>
                                </td>
                                <td class="radio-content">{{ userProfileService.userProfile?.email }}</td>
                            </tr>
                        </table>

                        <table>
                            <tr>
                                <td valign="top">
                                    <div class="ns-radio radio">
                                        <input type="radio" name="optionsRadios-email" id="optionsRadios2"
                                            formControlName="optionsRadios-email" [value]=1
                                            (click)="profileCopy.preferredEmail=1; refreshFormGroupCtrls()"
                                            >
                                        <label for="optionsRadios2"> </label>
                                    </div>
                                </td>
                                <td class="radio-content">
                                    <div class="form-group inline-block"
                                        [ngClass]="{'has-error': (ErrorDisplay.AlterEmailCtrl)}">
                                        <input type="text" maxlength="75" [ngModel]="profileCopy.alternateEmail"
                                            (ngModelChange)="profileCopy.alternateEmail=$event" class="form-control"
                                            placeholder="Enter an alternate email address"
                                            formControlName="AlterEmailCtrl">
                                        <span class="help-block">{{ErrorDisplay.AlterEmailCtrl}}</span>
                                    </div>
                                </td>
                            </tr>
                        </table>

                        <h2>Mobile Number</h2>
                        <p>Select your preferred mobile number (Singapore mobile number)</p>

                        <table>
                            <tr>
                                <td valign="top">
                                    <div class="ns-radio radio">
                                        <input type="radio" name="optionsRadios-mobile"
                                            formControlName="optionsRadios-mobile" [value]=0 id="optionsRadios3"
                                            (click)="profileCopy.preferredMobile=0; refreshFormGroupCtrls()">
                                        <label for="optionsRadios3"> </label>
                                    </div>
                                </td>
                                <td class="radio-content">{{ userProfileService.userProfile.mobile }}</td>
                            </tr>
                        </table>

                        <table>
                            <tr>
                                <td valign="top">
                                    <div class="ns-radio radio">
                                        <input type="radio" name="optionsRadios-mobile"
                                            formControlName="optionsRadios-mobile" [value]=1 id="optionsRadios4"
                                            (click)="profileCopy.preferredMobile=1; refreshFormGroupCtrls()">
                                        <label for="optionsRadios4"> </label>
                                    </div>
                                </td>
                                <td class="radio-content">
                                    <div class="form-group inline-block"
                                        [ngClass]="{ 'has-error': (ErrorDisplay.AlterMobileCtrl)}">
                                        <input type="text" maxlength="8" [ngModel]="profileCopy.alternateMobile"
                                            (ngModelChange)="profileCopy.alternateMobile=$event" class="form-control"
                                            placeholder="Enter an alternate mobile number"
                                            formControlName="AlterMobileCtrl">
                                        <span class="help-block">{{ErrorDisplay.AlterMobileCtrl}}</span>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="deliveryMode === DeliveryMode.HOME_DELIVERY">
                    <div class="box-content">
                        <h2>Delivery Address</h2>
                        <table>
                            <tr>
                                <td class="radio-content single-col">
                                    <div class="form-group inline-block">
                                        <div [ngClass]="{ 'has-error': (ErrorDisplay.PreferBlkCtrl)}">
                                            <input style="margin-bottom: 0px;" type="text" maxlength="10"
                                                [ngModel]="profileCopy.alternateDeliveryAddr?.blkNo"
                                                (ngModelChange)="profileCopy.alternateDeliveryAddr.blkNo=$event"
                                                class="form-control halfwidth" placeholder="House/Block"
                                                formControlName="PreferBlkCtrl">
                                            <input
                                                style="width: auto; border-color: #ddd; box-shadow:none; margin-bottom: 0px;"
                                                type="text" maxlength="10"
                                                [ngModel]="profileCopy.alternateDeliveryAddr?.unitNo"
                                                (ngModelChange)="profileCopy.alternateDeliveryAddr.unitNo=$event"
                                                class="form-control halfwidth" placeholder="Unit No. (Optional)"
                                                formControlName="PreferUnitCtrl">
                                            <span class="help-block"
                                                style="margin-top: 10px;">{{ErrorDisplay.PreferBlkCtrl}}</span>
                                        </div>
                                        <div [ngClass]="{ 'has-error': (ErrorDisplay.PreferStreetCtrl)}">
                                            <input type="text" maxlength="120"
                                                [ngModel]="profileCopy.alternateDeliveryAddr?.streetName"
                                                (ngModelChange)="profileCopy.alternateDeliveryAddr.streetName=$event"
                                                class="form-control" placeholder="Address"
                                                formControlName="PreferStreetCtrl">
                                            <span class="help-block">{{ErrorDisplay.PreferStreetCtrl}}</span>
                                        </div>
                                        <div [ngClass]="{ 'has-error': (ErrorDisplay.PreferBuildingCtrl)}">
                                            <input type="text" maxlength="120"
                                                [ngModel]="profileCopy.alternateDeliveryAddr?.buildingName"
                                                (ngModelChange)="profileCopy.alternateDeliveryAddr.buildingName=$event"
                                                class="form-control" placeholder="Building Name (Optional)"
                                                formControlName="PreferBuildingCtrl">
                                            <span class="help-block">{{ErrorDisplay.PreferBuildingCtrl}}</span>
                                        </div>
                                        <div [ngClass]="{ 'has-error': (ErrorDisplay.PostalCodeCtrl)}">
                                            <input type="text" maxlength="6"
                                                [ngModel]="profileCopy.alternateDeliveryAddr?.postalCode"
                                                (ngModelChange)="profileCopy.alternateDeliveryAddr.postalCode=$event"
                                                class="form-control" placeholder="Postal Code" maxlength="6"
                                                formControlName="PostalCodeCtrl">
                                            <span class="help-block">{{ErrorDisplay.PostalCodeCtrl}}</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12 text-right" *ngIf="isDataUpdating">
        <i class="fa fa-refresh fa-2x spinner text-primary"></i>
    </div>
    <div class="col-md-12 text-right" *ngIf="!isDataUpdating">
        <button [disabled]="!profileFormGroup.valid && !profileFormGroup.disabled" type="submit" class="btn">Save
            Changes</button>
    </div>
</form>

<simple-notifications [options]="notificationOptions"></simple-notifications>

<!-- Reminder Modal -->
<button id="openReminderModalButton" [hidden]="true" data-bs-toggle="modal" data-bs-target="#reminder-pop-up"></button>
<div id="reminder-pop-up" class="modal fade" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">{{reminderTitle}}</h1>
                <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                {{reminderContent}}
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" data-bs-dismiss="modal">OK</button>
            </div>
        </div>
    </div>
</div>

<!-- Collection Point Modal -->
<div id="select-collection-point" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title">Select {{deliveryMode == DeliveryMode.ELOCKER ? 'eLocker' : 'Collection Point'}}
                </h1>
                <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body scroll">
                <div class="row" *ngIf="deliveryMode == DeliveryMode.SELF_COLLECTION">
                    <ng-container
                        *ngFor="let selection of storeConfigService.collectionPointSelections; let idx = index">
                        <div class="col-sm-3">
                            <div class="ns-radio radio">
                                <label class="no-radio-btn" for="location-{{selection.zone}}">
                                    <strong>{{selection.zone}}</strong>
                                </label>
                            </div>
                            <div class="ns-radio radio" *ngFor="let location of selection.locations">
                                <input type="radio" name="{{location.text}}"
                                    id="location-{{selection.zone}}-{{location.value}}" value="{{location.text}}"
                                    [checked]="selectedCP.locationId === location.value"
                                    (click)="selectedCP = findCollectionPoint(location.value)">
                                <label for="location-{{selection.zone}}-{{location.value}}">{{location.text}}</label>
                            </div>
                        </div>
                        <div class="clearfix" *ngIf="(idx+1)%4==0"></div>
                    </ng-container>
                </div>
                <div class="row" *ngIf="deliveryMode == DeliveryMode.ELOCKER">
                    <ng-container *ngFor="let selection of storeConfigService.eLockerSelections; let idx = index">
                        <div class="col-sm-3">
                            <div class="ns-radio radio">
                                <label class="no-radio-btn" for="location-{{selection.zone}}">
                                    <strong>{{selection.zone}}</strong>
                                </label>
                            </div>
                            <div class="ns-radio radio" *ngFor="let location of selection.locations">
                                <input type="radio" name="{{location.text}}"
                                    id="location-{{selection.zone}}-{{location.value}}" value="{{location.text}}"
                                    [checked]="selectedEL.locationId === location.value"
                                    (click)="selectedEL = findElocker(location.value)">
                                <label for="location-{{selection.zone}}-{{location.value}}">{{location.text}}</label>
                            </div>
                        </div>
                        <div class="clearfix" *ngIf="(idx+1)%4==0"></div>
                    </ng-container>
                </div>
                <div class="row" *ngIf="deliveryMode == DeliveryMode.WALK_IN_SELF_ORDER">
                    <ng-container
                        *ngFor="let selection of storeConfigService.walkInCollectionPointSelections; let idx = index">
                        <div class="col-sm-3">
                            <div class="ns-radio radio">
                                <label class="no-radio-btn" for="location-{{selection.zone}}">
                                    <strong>{{selection.zone}}</strong>
                                </label>
                            </div>
                            <div class="ns-radio radio" *ngFor="let location of selection.locations">
                                <input type="radio" name="{{location.text}}"
                                    id="location-{{selection.zone}}-{{location.value}}" value="{{location.text}}"
                                    [checked]="selectedWICP.locationId === location.value"
                                    (click)="selectedWICP = findWalkInCollectionPoint(location.value)">
                                <label for="location-{{selection.zone}}-{{location.value}}">{{location.text}}</label>
                            </div>
                        </div>
                        <div class="clearfix" *ngIf="(idx+1)%4==0"></div>
                    </ng-container>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn" data-bs-dismiss="modal">Continue</button>
            </div>
        </div>
    </div>
</div>