<div class="breadcrumb-section">
    <div class="container">
        <ul class="breadcrumb">
            <li class="breadcrumb-item">
                <a routerLink="/dashboard" aria-label="breadcrumb-item"></a>
            </li>
            <li class="breadcrumb-item active">
                <a id="breadcrumb-active-link" routerLink="/contact-us" aria-label="breadcrumb-item">
                    Contact Us
                </a>
            </li>
        </ul>
    </div>
</div>

<div class="container">
    <h1>Contact Us</h1>
    <h2>NS Call Centre</h2>
    <tr class="py-1">
        <a href="mailto:contact@ns.gov.sg" target="_blank">
            <td class="px-1"><span class="fa fa-envelope contact-us-icon pr-2"></span></td>
            <td class="px-2">contact@ns.gov.sg</td>
        </a>
    </tr>
</div>
