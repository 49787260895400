import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { ProductsService } from 'app/services/products.service';
import { Constants } from 'app/shared/global-constants/constants';
import { Observable, of, forkJoin, catchError } from 'rxjs';

import { CatalogueService } from './../../services/catalogue.service';
import { UserProfileService } from './../../services/user-profile.service';

@Injectable()
export class ViewProductsGuard implements Resolve<any> {

    constructor(
        private router: Router,
        private userProfileService: UserProfileService,
        private productsService: ProductsService,
        private catalogueService: CatalogueService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        const $obs =  forkJoin({
            categories: this.catalogueService.forceLoadCategories(),
            creditSummary: this.userProfileService.forceLoadCreditSummary(),
            productsNormal: this.productsService.loadProducts(Constants.ProductType.NORMAL),
        }).pipe(catchError(err => {
            this.router.navigate(['/general-error']);
            return of(err);
        }));

        return $obs;
    }

}

@Injectable()
export class ViewUniformProductsGuard implements Resolve<any> {

    constructor(
        private router: Router,
        private userProfileService: UserProfileService,
        private productsService: ProductsService,
        private catalogueService: CatalogueService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return forkJoin(
            this.productsService.loadProducts(Constants.ProductType.TAILOR),
        ).subscribe(res=> {
            return res;
        }, err => {
            this.router.navigate(['/general-error']);
            return of(err);
        });
    }

}

@Injectable()
export class ViewNameTagProductsGuard implements Resolve<any> {

    constructor(
        private router: Router,
        private userProfileService: UserProfileService,
        private productsService: ProductsService,
        private catalogueService: CatalogueService) {
    }

    resolve(route: ActivatedRouteSnapshot) {
        return forkJoin(
            this.productsService.loadProducts(Constants.ProductType.NAME_TAG),
        ).subscribe(res=> {
            return res;
        }, err => {
            this.router.navigate(['/general-error']);
            return of(err);
        });
    }

}
