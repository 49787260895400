import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StoreConfigService } from 'app/services/store-config.service';

import { Constants } from '../../../shared/global-constants/constants';
import { CartService } from './../../../services/cart.service';
import { CatalogueService } from './../../../services/catalogue.service';
import { ProductsService } from './../../../services/products.service';
import { ResourceService } from './../../../services/resource.service';
import { UserProfileService } from './../../../services/user-profile.service';
import { UserProductDTO } from './../../../shared/backend-api/emart/model/models';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  @Input() showCredit = true;
  @Input() showUnit = true;
  @Input() showCart = true;
  @Input() showCatalogue = false;
  currentUrl: string;
  private total: number;
  private selectedCategory: string;
  private unitLogo: any;

  constructor(
    public userProfileService: UserProfileService,
    public catalogueService: CatalogueService,
    public cartService: CartService,
    public productsService: ProductsService,
    public resourceService: ResourceService,
    public configSvc: StoreConfigService,
    private router: Router) {
  }

  ngOnInit() {
    this.unitLogo = this.configSvc.dummyImgPath;
    if (this.showCatalogue) {
      this.catalogueService.loadCategories(); // sidebar is part of your main component, consider it's loaded already.
    }
    this.cartService.loadCartDetail().subscribe(response => {
      if (response.status === 200) {
        this.cartService.shoppingCart = response.data;
      } else {
        // TODO: error to get products. display error message
      }
    });
    this.productsService.loadProducts(Constants.ProductType.NORMAL).subscribe();
    this.productsService.loadProducts(Constants.ProductType.TAILOR).subscribe();
    this.productsService.loadProducts(Constants.ProductType.NAME_TAG).subscribe();
    this.resourceService.loadImageWithId(this.userProfileService.userProfile.unitLogoResourceId)
      .then(img => {
        this.unitLogo = img;
      });
  }

  catalogueSelected(categoryName: string) {
    this.catalogueService.selectCategory(categoryName);
  }

  getProduct(id: number): UserProductDTO {
    if (this.productsService.cachedProducts !== undefined && this.productsService.cachedProducts.data !== undefined) {
      for (const product of this.productsService.cachedProducts.data) {
        if (product.productId === id) {
          return product;
        }
      }
    } else {
      return null;
    }
  }

  getTailorProduct(id: number): UserProductDTO {
    if (this.productsService.cachedUniforms !== undefined && this.productsService.cachedUniforms.data !== undefined) {
      for (const product of this.productsService.cachedUniforms.data) {
        if (product.productId === id) {
          return product;
        }
      }
    } else {
      return null;
    }
  }

  getTailorProductPrice(id: number, productTailorId: number): number {
    const tailors = this.getTailorProduct(id).tailors

    for (const tailor of tailors) {
      if (tailor.productTailorId === productTailorId) {
        return tailor.price;
      }
    }
  }

  getNameTagProduct(id: number): UserProductDTO {
    if (this.productsService.cachedNameTags !== undefined && this.productsService.cachedNameTags.data !== undefined) {
      for (const product of this.productsService.cachedNameTags.data) {
        if (product.productId === id) {
          return product;
        }
      }
    } else {
      return null;
    }
  }

  getTotalPrice() {
    this.total = 0;

    if (this.cartService.shoppingCart !== undefined) {
      this.cartService.loadCartDetail();
      if (this.cartService.shoppingCart.tailorItems != null) {
        for (const item of this.cartService.shoppingCart.tailorItems) {
          if (this.getTailorProduct(item.productId) === null) {
            break;
          }
          this.total += item.quantity * this.getTailorProductPrice(item.productId, item.productTailorId);
        }
      } else if (this.cartService.shoppingCart.nameTagItems != null) {
        for (const item of this.cartService.shoppingCart.nameTagItems) {
          if (this.getNameTagProduct(item.productId) === null) {
            break;
          }
          this.total += item.quantity * this.getNameTagProduct(item.productId).productPrice;
        }
      } else if (this.cartService.shoppingCart.items != null) {
        for (const item of this.cartService.shoppingCart.items) {
          if (this.getProduct(item.productId) === null) {
            break;
          }
          this.total += item.quantity * this.getProduct(item.productId).productPrice;
        }
      }
    }

    return this.total;
  }

  getBalance(): number {
    const balance = 0;
    return this.userProfileService.creditSummary.creditBalance - this.getTotalPrice();
  }

  gotoCart() {
    if (this.cartService.shoppingCart.tailorItems != null) {
      this.router.navigate(['/tailor-shopping-cart']);
    } else if (this.cartService.shoppingCart.nameTagItems != null) {
      this.router.navigate(['/name-tag-shopping-cart']);
    } else {
      this.router.navigate(['/shopping-cart']);
    }
  }

}
