import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';

declare const jQuery: any;

declare global{
  interface Window{
    wogaaCustom:any;
  }
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  
  public getScreenWidth;
  public singpassUrl = environment.singpassLoginUrl;
  public corppassUrl = environment.corppassLoginUrl;

  constructor(private router: Router, private cookieService: CookieService) { }

  ngOnInit(): void {
    // let wogaaCustom = window.wogaaCustom.startTransactionalService('emartmha-4707');
    const $ = jQuery;
    //to enable tooltip for supplier login
    $('[data-bs-toggle="tooltip"]').tooltip();

    this.getScreenWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    //get screen width for applying css
    this.getScreenWidth = window.innerWidth;
  }

  
}
