import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { OrderDTO, UserLocationDTO } from 'app/shared/backend-api/emart';

import { CartService } from './../../../services/cart.service';
import { ResourceService } from './../../../services/resource.service';
import { StoreConfigService } from './../../../services/store-config.service';
import { UserProfileService } from './../../../services/user-profile.service';
import { Constants } from './../../../shared/global-constants/constants';

declare global{
  interface Window{
    wogaaCustom:any;
  }
}

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css']
})
export class OrderConfirmationComponent implements OnInit {

  imagestore: any = {};
  orderDetail: OrderDTO = {};
  collectionPoint = <UserLocationDTO>{};

  // a flag to mark that the validation has passed and is time to show the order confirmation.
  // this flag will prevent the errors caused when angular render the templates before data is loaded
  showOrderConfirmation = false;

  private DeliveryMode = Constants.DeliveryMode;
  private OrderType = Constants.OrderType;

  constructor(
    private router: Router,
    private resourceService: ResourceService,
    private cartService: CartService,
    private userProfileService: UserProfileService,
    private storeConfigService: StoreConfigService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    if (this.cartService.hasCartBeingValidated && this.cartService.hasCartBeingConfirmed && this.cartService.orderDetail != null) {
      this.showOrderConfirmation = true;
      this.orderDetail = this.cartService.orderDetail;
      for (const item of this.orderDetail.orderItems) {
        this.loadImageContent(item.imageResourceId, item.orderItemId);
      }
      this.userProfileService.loadCreditSummary();

      if (this.orderDetail.deliveryModeCode === Constants.DeliveryMode.SELF_COLLECTION) {
        this.collectionPoint = this.findCollectionPoint(this.orderDetail.collectionPointId);
      } else if (this.orderDetail.deliveryModeCode === Constants.DeliveryMode.ELOCKER) {
        this.collectionPoint = this.findElocker(this.orderDetail.collectionPointId);
      } else if (this.orderDetail.deliveryModeCode === Constants.DeliveryMode.WALK_IN_SELF_ORDER) {
        this.collectionPoint = this.findWalkInCollectionPoint(this.orderDetail.collectionPointId);
      }

    } else {
      // dont navigate to error page when refresh order-confirmation page
      // instead navigate to dashboard
      this.router.navigate(['dashboard']);
      return;
    }

    // reset flags and variables after init
    this.cartService.hasCartBeingValidated = false;
    this.cartService.validatedCart = {};
    this.cartService.hasCartBeingConfirmed = false;
    this.cartService.orderDetail = {};
    this.cartService.orderDetailImageStore = {};

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

    if(window.wogaaCustom) {
      let wogaaCustom = window.wogaaCustom.completeTransactionalService('emartmha-4707');
    }
  }

  private findCollectionPoint(locationId) {
    for (const location of this.storeConfigService.collectionPoints) {
      if (location.locationId === locationId) {
        return location;
      }
    }
    return null;
  }

  private findElocker(locationId) {
    for (const location of this.storeConfigService.eLockers) {
      if (location.locationId === locationId) {
        return location;
      }
    }
    return null;
  }

  private findWalkInCollectionPoint(locationId) {
    for (const location of this.storeConfigService.walkInCollectionPoints) {
      if (location.locationId === locationId) {
        return location;
      }
    }
    return null;
  }

  private findTailor(locationId) {
    for (const location of this.storeConfigService.tailors) {
      if (location.locationId === locationId) {
        return location;
      }
    }
    return null;
  }

  private loadImageContent(withId: number, orderItemId: number) {
    if (withId) {
      this.resourceService.loadImageWithId(withId).then(img => {
        this.imagestore[orderItemId] = img;
      })
    } else {
      this.imagestore[orderItemId] = this.storeConfigService.dummyImgPath;
    }
  }

  private getUniqueTailors() {
    const tailors = [];
    for (const item of this.orderDetail.orderItems) {
      let found = false;
      for (const tailor of tailors) {
        if (tailor.locationId === item.tailorId) {
          found = true;
          break;
        }
      }
      if (!found) {
        tailors.push(this.findTailor(item.tailorId));
      }
    }
    return tailors;
  }

  private getTailorRowNo() {
    if (this.orderDetail.orderType !== this.OrderType.TAILOR) {
      return [];
    }

    const count = this.getUniqueTailors().length;
    let row = count / 2;
    if (this.orderDetail.deliveryModeCode !== this.DeliveryMode.COLLECT_AT_TAILOR) {
      if (count % 2 > 0) {
        row++;
      }
    }

    const numbers: number[] = [];
    for (let i = 0; i < row; i++) {
      numbers.push(i);
    }
    return numbers;
  }

  private getTailorRow(i: number) {
    const tailors = this.getUniqueTailors();
    if (this.orderDetail.deliveryModeCode !== this.DeliveryMode.COLLECT_AT_TAILOR) {
      tailors.splice(0, 1);
    }
    return tailors.slice(i * 2, (i + 1) * 2);
  }

  private getFirstTailor() {
    return this.getUniqueTailors()[0];
  }

  printOrder() {
    window.print();
  }

}
