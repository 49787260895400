import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { FooterComponent } from './footer/footer.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { BannerComponent } from './banner/banner.component';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';
import { FaqsComponent } from './faqs/faqs.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PublicHeaderComponent } from './public-header/public-header.component';
import { NgxMarqueeModule } from 'ngx-marquee';
import { LeftNavbarComponent } from './faqs/left-navbar/left-navbar.component';
import { QuestionAnswerComponent } from './faqs/question-answer/question-answer.component';
import { AccessErrorComponent } from './error/access-error/access-error.component';
import { GeneralErrorComponent } from './error/general-error/general-error.component';
import { RouterModule } from '@angular/router';
import { LiveMaintenanceComponent } from './live-maintenance/live-maintenance.component';



@NgModule({
  declarations: [
    LoginComponent,
    FooterComponent,
    ContactUsComponent,
    BannerComponent,
    PrivacyStatementComponent,
    FaqsComponent,
    TermsOfUseComponent,
    PublicHeaderComponent,
    LeftNavbarComponent,
    QuestionAnswerComponent,
    AccessErrorComponent,
    GeneralErrorComponent,
    LiveMaintenanceComponent
  ],
  imports: [
    CommonModule,
    NgxMarqueeModule,
    RouterModule
  ],
  exports: [FooterComponent, PublicHeaderComponent, BannerComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class PublicModuleModule { }
