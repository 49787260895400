import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NotificationsService } from 'assets/lib/angular2-notifications';
import { Constants } from 'app/shared/global-constants/constants';

import { CartService } from '../../../services/cart.service';
import { CurrentOrdersService } from '../../../services/current-orders.service';
import { ProductsService } from '../../../services/products.service';
import { ResourceService } from '../../../services/resource.service';
import { StoreConfigService } from '../../../services/store-config.service';
import { TransactionHistoryService } from '../../../services/transaction-history.service';
import { UserProfileService } from '../../../services/user-profile.service';
import { OrdersApi } from '../../../shared/backend-api/emart';
import { CancelOrderRequest, GetProfileResult, UpdateOrderRequest } from '../../../shared/backend-api/emart';
import { AddressDTO } from '../../../shared/backend-api/emart';
import { ServicemanApi } from '../../../shared/backend-api/emart';
import { CommonApi } from '../../../shared/backend-api/emart';
import { OrderDTO } from '../../../shared/backend-api/emart';
import { OrderItemDTO } from '../../../shared/backend-api/emart';
import { UserLocationDTO } from '../../../shared/backend-api/emart';
import { CartDeliveryDTO } from '../../../shared/backend-api/emart';
import { CartItemDTO } from '../../../shared/backend-api/emart';
import { CommonUtils } from '../../../shared/utilities/common-utils';
import { NameTagCartService } from './../../../services/name-tag-cart.service';
import { TailorCartService } from './../../../services/tailor-cart.service';
import { Router } from '@angular/router';
import { Modal } from 'bootstrap';

declare const jQuery: any;

@Component({
  selector: 'app-order-detail-modify',
  templateUrl: './order-detail-modify.component.html',
  styleUrls: ['./order-detail-modify.component.css']
})
export class OrderDetailModifyComponent implements OnInit {

  ITEM_CHECKED_KEY = 'item_detail_checked';
  private _orderToViewDetail: OrderDTO;
  profile: GetProfileResult;

  hasPurchasable = false;
  allItemsCheckedStatus = false;
  imageStore: any = {};

  DeliveryMode = Constants.DeliveryMode;
  OrderStatus = Constants.OrderStatus;
  OrderType = Constants.OrderType;

  public notificationOptions = {
    position: ['top', 'right'],
    lastOnBottom: false,
    timeOut: 2000
  };

  // for modify order
  canCancel = false;

  reminderTitle;
  reminderContent;

  updatedOrder: OrderDTO;
  tempUpdatedOrder: OrderDTO;
  updatedDelivery: CartDeliveryDTO;

  availableDeliveryModes = [];
  deliveryModes = [];
  allDeliveryModes = [];
  updatedDeliveryMode = Constants.DeliveryMode.SELF_COLLECTION;
  updatedCP: UserLocationDTO;
  updatedEL: UserLocationDTO;
  updatedWICP: UserLocationDTO;
  updatedDeliveryAddr: CartDeliveryDTO;

  orderItemToBeCancelled: OrderItemDTO;

  isCheckingRefundable = false;
  refundableAmont = 0;
  expiredAmount = 0;

  originalCP: number;

  public CheckOutFormGroup: UntypedFormGroup;
  public CheckOutFormGroupMessages = {
    EmailCtrl: { required: 'Empty email address', pattern: 'Invalid email address' },
    MobileCtrl: { required: 'Empty mobile number', pattern: 'Invalid mobile number' },
    BlkCtrl: { required: 'Empty house/block' },
    StreetCtrl: { required: 'Empty street name' },
    PostalCodeCtrl: { required: 'Empty postal code', minlength: 'Incomplete postal code', pattern: 'Invalid postal code' },
  }
  public ErrorDisplay;
  // ---

  constructor(
    public api: ServicemanApi,
    public commonApi: CommonApi,
    public resourceService: ResourceService,
    public productsService: ProductsService,
    public cartService: CartService,
    public tailorCartService: TailorCartService,
    public nameTagCartService: NameTagCartService,
    public userProfileService: UserProfileService,
    public storeConfigService: StoreConfigService,
    public ordersApi: OrdersApi,
    public notificationService: NotificationsService,
    public currentOrdersService: CurrentOrdersService,
    public transactionHistoryService: TransactionHistoryService,
    private router: Router,
  ) {
    this.createFormGroup();
  }

  @Input() set orderToViewDetail(value: OrderDTO) {
    if (value == null) {
      return;
    }
    this._orderToViewDetail = value;
    this.allItemsCheckedStatus = false;
    this.hasPurchasable = false;

    this.originalCP = value.collectionPointId;

    for (const item of value.orderItems) {
      item[this.ITEM_CHECKED_KEY] = false;
      this.loadImageContent(item.imageResourceId, item.orderItemId);

      if (item.purchasable) {
        this.hasPurchasable = true;
      }
    }
    for (const item of value.cancelledItems) {
      item[this.ITEM_CHECKED_KEY] = false;
      this.loadImageContent(item.imageResourceId, item.orderItemId);
    }

    this.canCancel = this._orderToViewDetail.deliveryModeCode !== this.DeliveryMode.WALK_IN_SELF_ORDER;

    this.updatedOrder = CommonUtils.cloneDeep(this._orderToViewDetail);
    this.updatedDeliveryMode = this.updatedOrder.deliveryModeCode;
    this.availableDeliveryModes = [];

    if (this.updatedOrder.orderType === this.OrderType.NAME_TAG) {
      this.deliveryModes = this.storeConfigService.nameTagDeliveryModes;
      this.allDeliveryModes = this.storeConfigService.allNameTagDeliveryModes;
    } else if (this.updatedOrder.orderType === this.OrderType.TAILOR) {
      this.deliveryModes = this.storeConfigService.tailorDeliveryModes;
      this.allDeliveryModes = this.storeConfigService.allTailorDeliveryModes;
    } else {
      this.deliveryModes = this.storeConfigService.deliveryModes;
      this.allDeliveryModes = this.storeConfigService.allDeliveryModes;
    }

    if (this.updatedDeliveryMode === this.DeliveryMode.WALK_IN_SELF_ORDER) {
      this.availableDeliveryModes.push(this.findDeliveryMode(this.DeliveryMode.WALK_IN_SELF_ORDER));
    } else {
      if (this.updatedDeliveryMode === this.DeliveryMode.SELF_COLLECTION ||
        this.findAvailableDeliveryMode(this.DeliveryMode.SELF_COLLECTION) != null) {
        this.availableDeliveryModes.push(this.findDeliveryMode(this.DeliveryMode.SELF_COLLECTION));
      }
      if (this.updatedDeliveryMode === this.DeliveryMode.HOME_DELIVERY ||
        this.findAvailableDeliveryMode(this.DeliveryMode.HOME_DELIVERY) != null) {
        this.availableDeliveryModes.push(this.findDeliveryMode(this.DeliveryMode.HOME_DELIVERY));
      }
      if (this.updatedDeliveryMode === this.DeliveryMode.ELOCKER ||
        this.findAvailableDeliveryMode(this.DeliveryMode.ELOCKER) != null) {
        this.availableDeliveryModes.push(this.findDeliveryMode(this.DeliveryMode.ELOCKER));
      }
      if (this.updatedDeliveryMode === this.DeliveryMode.COLLECT_AT_TAILOR ||
        this.findAvailableDeliveryMode(this.DeliveryMode.COLLECT_AT_TAILOR) != null) {
        this.availableDeliveryModes.push(this.findDeliveryMode(this.DeliveryMode.COLLECT_AT_TAILOR));
      }
    }

    if (this.updatedDeliveryMode == null) {
      this.updatedDeliveryMode = this.DeliveryMode.SELF_COLLECTION;
    }
    if (this.updatedDeliveryMode === this.DeliveryMode.SELF_COLLECTION) {
      this.updatedCP = this.findCollectionPoint(this.updatedOrder.collectionPointId);
    } else if (this.updatedDeliveryMode === this.DeliveryMode.ELOCKER) {
      this.updatedEL = this.findElocker(this.updatedOrder.collectionPointId);
    } else if (this.updatedDeliveryMode === this.DeliveryMode.WALK_IN_SELF_ORDER) {
      this.updatedWICP = this.findWalkInCollectionPoint(this.updatedOrder.collectionPointId);
    }
    if (this.updatedCP == null && this.storeConfigService.collectionPoints && this.storeConfigService.collectionPoints.length > 0) {
      this.updatedCP = this.storeConfigService.collectionPoints[0];
    }
    if (this.updatedEL == null && this.storeConfigService.eLockers && this.storeConfigService.eLockers.length > 0) {
      this.updatedEL = this.storeConfigService.eLockers[0];
    }
    if (this.updatedWICP == null && this.storeConfigService.walkInCollectionPoints
      && this.storeConfigService.walkInCollectionPoints.length > 0) {
      this.updatedWICP = this.storeConfigService.walkInCollectionPoints[0];
    }

    this.updatedDelivery = {};
    this.updatedDelivery.deliveryMode = this.updatedOrder.deliveryModeCode;
    this.updatedDelivery.contactNo = this.updatedOrder.contactNo;
    this.updatedDelivery.email = this.updatedOrder.email;
    this.updatedDelivery.homeDeliveryAddress = {};

    if (this.updatedDelivery.deliveryMode === this.DeliveryMode.HOME_DELIVERY) {
      const updatedOrdCPAddr = this.updatedOrder.collectionPointAddress || <AddressDTO>{};
      this.updatedDelivery.homeDeliveryAddress.blkNo = updatedOrdCPAddr.blkNo;
      this.updatedDelivery.homeDeliveryAddress.streetName = updatedOrdCPAddr.streetName;
      this.updatedDelivery.homeDeliveryAddress.unitNo = updatedOrdCPAddr.unitNo;
      this.updatedDelivery.homeDeliveryAddress.buildingName = updatedOrdCPAddr.buildingName;
      this.updatedDelivery.homeDeliveryAddress.postalCode = updatedOrdCPAddr.postalCode;
    } else {
      const updatedOrdHDAddr = this.cartService.shoppingCart.delivery.homeDeliveryAddress || <AddressDTO>{};
      this.updatedDelivery.homeDeliveryAddress.blkNo = updatedOrdHDAddr.blkNo;
      this.updatedDelivery.homeDeliveryAddress.streetName = updatedOrdHDAddr.streetName;
      this.updatedDelivery.homeDeliveryAddress.unitNo = updatedOrdHDAddr.unitNo;
      this.updatedDelivery.homeDeliveryAddress.buildingName = updatedOrdHDAddr.buildingName;
      this.updatedDelivery.homeDeliveryAddress.postalCode = updatedOrdHDAddr.postalCode;
    }

    this.resetFormValidation();
  }

  get orderToViewDetail(): OrderDTO {
    return this._orderToViewDetail;
  }

  private loadImageContent(withId: number, orderItemId: number) {
    if (withId) {
      this.resourceService.loadImageWithId(withId).then(img => {
        this.imageStore[orderItemId] = img;
      })
    } else {
      this.imageStore[orderItemId] = this.storeConfigService.dummyImgPath;
    }
  }

  findAvailableDeliveryMode(deliveryModeId) {
    if (!this.deliveryModes) {
      return null;
    }
    for (const mode of this.deliveryModes) {
      if (mode.value === deliveryModeId) {
        return mode;
      }
    }
    return null;
  }

  findDeliveryMode(deliveryModeId) {
    if (!this.allDeliveryModes) {
      return null;
    }
    for (const mode of this.allDeliveryModes) {
      if (mode.value === deliveryModeId) {
        return mode;
      }
    }
    return null;
  }

  findCollectionPoint(locationId) {
    if (!this.storeConfigService.collectionPoints) {
      return null;
    }
    for (const location of this.storeConfigService.collectionPoints) {
      if (location.locationId === locationId) {
        return location;
      }
    }
    return null;
  }

  findElocker(locationId) {
    if (!this.storeConfigService.eLockers) {
      return null;
    }
    for (const location of this.storeConfigService.eLockers) {
      if (location.locationId === locationId) {
        return location;
      }
    }
    return null;
  }

  findWalkInCollectionPoint(locationId) {
    if (!this.storeConfigService.walkInCollectionPoints) {
      return null;
    }
    for (const location of this.storeConfigService.walkInCollectionPoints) {
      if (location.locationId === locationId) {
        return location;
      }
    }
    return null;
  }

  ngOnInit() {
    this.api.getProfileUsingGET().subscribe(response => {
      if (response.status === 200) {
        this.profile = response.data;
      } else {
        // TODO: display error message or redirect to error page.
      }
    });
    this.cartService.loadCartDetail().subscribe(response => {
      if (response.status === 200) {
        this.cartService.shoppingCart = response.data;
      } else {
        // TODO: error to get products. display error message
      }
    });
    this.userProfileService.loadCreditSummary().subscribe(response => {
      if (response.status === 200) {
        this.userProfileService.creditSummary = response.data;
      } else {
        // TODO: error to get products. display error message
      }
    });
    this.productsService.loadProducts(Constants.ProductType.NORMAL).subscribe();
    this.productsService.loadProducts(Constants.ProductType.TAILOR).subscribe();
    this.productsService.loadProducts(Constants.ProductType.NAME_TAG).subscribe();
  }

  /**
   * helper method to update the summary checkbox on the table header
   * when a single item is been checked.
   * This method will select the summary checkbox when all sub items been checked.
   * If anyone of the sub items is unselected, this method will also uncheck it.
   */
  private updateAllCheckedStatus() {
    for (const item of this.orderToViewDetail.orderItems) {
      if (!item[this.ITEM_CHECKED_KEY] && item.purchasable) {
        this.allItemsCheckedStatus = false;
        return;
      }
    }
    this.allItemsCheckedStatus = true;
  }

  /**
   * UI listener for single item checkbox
   * @param item
   */
  singleItemSelected(item: OrderItemDTO) {
    item[this.ITEM_CHECKED_KEY] = !item[this.ITEM_CHECKED_KEY];
    this.updateAllCheckedStatus();
  }

  /**
   * UI listen for the summary checkbox
   */
  allItemsSelected() {
    this.allItemsCheckedStatus = !this.allItemsCheckedStatus;
    for (const item of this.orderToViewDetail.orderItems) {
      if (item.purchasable) {
        item[this.ITEM_CHECKED_KEY] = this.allItemsCheckedStatus;
      }
    }
  }

  addToCart() {
    const items: Array<CartItemDTO> = [];
    const tailorItems: Array<CartItemDTO> = [];
    const nameTagItems: Array<CartItemDTO> = [];
    let type: number = null;

    for (const orderItem of this.orderToViewDetail.orderItems) {
      if (orderItem['item_detail_checked'] !== undefined && orderItem['item_detail_checked']) {
        const tempType = orderItem.tailorId !== null ? Constants.ProductType.TAILOR : orderItem.nameTagText != null ?
          Constants.ProductType.NAME_TAG : Constants.ProductType.NORMAL;
        if (type === null) {
          type = tempType;
        } else if (type !== tempType) {
          this.notificationService.warn(
            'Notification',
            'Catalogue items, tailored uniforms and name tags cannot be in the same shopping cart'
          );
          return;
        }

        if (type === Constants.ProductType.NORMAL) {
          const item: CartItemDTO = {};
          item.productId = orderItem.productId;
          item.skuId = orderItem.skuId;
          item.quantity = orderItem.quantity;
          items.push(item);
        } else if (type === Constants.ProductType.TAILOR) {
          const item: any = {};
          item.productId = orderItem.productId;
          item.quantity = orderItem.quantity;
          item.tailorId = orderItem.tailorId;
          tailorItems.push(item);
        } else if (type === Constants.ProductType.NAME_TAG) {
          const item: any = {};
          item.productId = orderItem.productId;
          item.skuId = orderItem.skuId;
          item.quantity = orderItem.quantity;
          nameTagItems.push(item);
        }
      }
    }

    if (type === Constants.ProductType.NORMAL) {
      this.cartService.addItemsToCart(items);
    } else if (type === Constants.ProductType.TAILOR) {
      this.tailorCartService.addOrderItemsToCart(tailorItems);
    } else if (type === Constants.ProductType.NAME_TAG) {
      this.nameTagCartService.addOrderItemsToCart(nameTagItems);
    }
  }

  hasSelected() {
    for (const orderItem of this.orderToViewDetail.orderItems) {
      if (orderItem['item_detail_checked'] !== undefined && orderItem['item_detail_checked']) {
        return true;
      }
    }
    return false;
  }

  createFormGroup(): void {
    this.CheckOutFormGroup = new UntypedFormGroup({
      EmailCtrl: new UntypedFormControl('', [Validators.required, Validators.pattern(Constants.EmailRegex)]),
      MobileCtrl: new UntypedFormControl('', [Validators.required, Validators.pattern(Constants.PhoneRegex)]),
      BlkCtrl: new UntypedFormControl('', [Validators.required]),
      UnitCtrl: new UntypedFormControl('', []),
      StreetCtrl: new UntypedFormControl('', [Validators.required]),
      BuildingCtrl: new UntypedFormControl('', []),
      PostalCodeCtrl: new UntypedFormControl('', [Validators.required, Validators.pattern(Constants.PostalCodeRegex)])
    });

    this.resetFormValidation();
    this.scanFormGroupForError();
    this.CheckOutFormGroup.valueChanges.subscribe(data => this.scanFormGroupForError()); // validate onValueChanged
  }

  scanFormGroupForError() {
    this.resetErrorDisplay(); // Flush old error
    const FG = this.CheckOutFormGroup;
    if (!FG) { return; }

    // Match new errors and push to error message array
    const FGMsgs = this.CheckOutFormGroupMessages;
    Object.keys(FG.controls).forEach((ctrlname) => {
      const currentCtrl = FG.get(ctrlname);
      if (currentCtrl && currentCtrl.dirty && !currentCtrl.valid && currentCtrl.errors) {
        const errMsgObj = FGMsgs[ctrlname];
        Object.keys(currentCtrl.errors).forEach(element => {
          const errMsg = errMsgObj[element];
          if (errMsg) { this.ErrorDisplay[ctrlname] = errMsg; }
        }
        );
      }
    });
  }

  private resetErrorDisplay() {
    this.ErrorDisplay = {};
  }

  resetFormValidation() {
    if (this.updatedDeliveryMode === this.DeliveryMode.HOME_DELIVERY) {
      this.CheckOutFormGroup.get('BlkCtrl').enable();
      this.CheckOutFormGroup.get('UnitCtrl').enable();
      this.CheckOutFormGroup.get('StreetCtrl').enable();
      this.CheckOutFormGroup.get('BuildingCtrl').enable();
      this.CheckOutFormGroup.get('PostalCodeCtrl').enable();
    } else {
      this.CheckOutFormGroup.get('BlkCtrl').disable();
      this.CheckOutFormGroup.get('UnitCtrl').disable();
      this.CheckOutFormGroup.get('StreetCtrl').disable();
      this.CheckOutFormGroup.get('BuildingCtrl').disable();
      this.CheckOutFormGroup.get('PostalCodeCtrl').disable();
    }
  }

  onDeliveryModeChanged(event) {
    this.updatedDeliveryMode = Number(event.target.value);

    this.resetFormValidation();

    if (this.updatedDeliveryMode === this.DeliveryMode.HOME_DELIVERY &&
      this._orderToViewDetail.deliveryModeCode !== this.DeliveryMode.HOME_DELIVERY) {
      this.reminderTitle = 'Home Delivery';
      if (this.getUpdatedDeliveryFee() <= this.userProfileService.creditSummary.creditBalance) {
        this.reminderContent = this.cartService.deliveryReminderMsg.replace('{placeholder}', 'S$' + this.cartService.deliveryFee);
      } else {
        this.reminderContent = this.cartService.deliveryInsufficientMsg.replace('{placeholder}', 'S$' + this.cartService.deliveryFee);
        this.updatedDeliveryMode = this.availableDeliveryModes[0].value;
        event.target.value = this.availableDeliveryModes[0].value;
      }
    } else {
      this.reminderContent = null;
    }

    if (this.reminderTitle && this.reminderContent) {
      jQuery("#reminder-pop-up").modal('show');
    }
  }

  saveDeliveryInfo() {
    const request: UpdateOrderRequest = {};
    request.orderId = this.updatedOrder.orderId;
    request.delivery = {};
    request.delivery.deliveryMode = this.updatedDeliveryMode;
    if (this.updatedDeliveryMode === this.DeliveryMode.SELF_COLLECTION) {
      request.delivery.locationId = this.updatedCP.locationId;
    } else if (this.updatedDeliveryMode === this.DeliveryMode.ELOCKER) {
      if (!this.updatedEL.isElockerAvailable && this.originalCP !== this.updatedEL.locationId) {
        this.notificationService.error(
          'Notification',
          'Selected eLocker has no vacancy'
        )
        return;
      }
      request.delivery.locationId = this.updatedEL.locationId;
    } else if (this.updatedDeliveryMode === this.DeliveryMode.WALK_IN_SELF_ORDER) {
      request.delivery.locationId = this.updatedWICP.locationId;
    }
    request.delivery.homeDeliveryAddress = this.updatedDelivery.homeDeliveryAddress;
    request.delivery.contactNo = this.updatedDelivery.contactNo;
    request.delivery.email = this.updatedDelivery.email;

    this.ordersApi.updateOrderUsingPOST(request).subscribe(
      data => {
        if (data.status === 200) {
          this.notificationService.success(
            'Modify Order',
            'Collection mode/point has been modified'
          )
          this._orderToViewDetail = data.data;
          this.updatedOrder = data.data;
          this.currentOrdersService.updateOrder(data.data);
          this.transactionHistoryService.updateOrder(data.data);
          
          const element = document.getElementById('item-detail-pop-up') as HTMLElement;
          const myModal = new Modal(element);
          myModal.show();
        } else {
          this.notificationService.error(
            'Notification',
            'Order modification failed, please try again later'
          )
        }
      },
      error => {
        this.notificationService.error(
          'Notification',
          'Order modification failed, please try again later'
        )
      }
    );
  }

  cancelEntireOrder() {
    const request: CancelOrderRequest = {};
    request.orderId = this.updatedOrder.orderId;
    request.orderItemIds = [];
    for (let i = 0; i < this.updatedOrder.orderItems.length; i++) {
      request.orderItemIds.push(this.updatedOrder.orderItems[i].orderItemId);
    }

    this.ordersApi.cancelOrderUsingPOST(request).subscribe(
      data => {
        if (data.status === 200) {
          this.productsService.forceLoadProductCaps().subscribe(() => {
            this.notificationService.success(
              'Modify Order',
              'Order has been cancelled'
            )
            this._orderToViewDetail = data.data;
            this.updatedOrder = data.data;
            this.currentOrdersService.removeOrder(data.data);
            this.transactionHistoryService.updateOrder(data.data);
          });

        } else {
          this.notificationService.error(
            'Notification',
            'Order cancellation failed, please refresh page or try again later'
          )
        }
      },
      error => {
        this.notificationService.error(
          'Notification',
          'Order cancellation failed, please refresh page or try again later'
        )
      }
    );
  }

  cancelOrderItem() {
    const request: CancelOrderRequest = {};
    request.orderId = this.updatedOrder.orderId;
    request.orderItemIds = [this.orderItemToBeCancelled.orderItemId];

    this.ordersApi.cancelOrderUsingPOST(request).subscribe(
      data => {
        if (data.status === 200) {
          this.productsService.forceLoadProductCaps().subscribe(() => {
            this.notificationService.success(
              'Modify Order',
              'Item has been cancelled'
            )
            this._orderToViewDetail = data.data;
            this.updatedOrder = data.data;
            if (data.data.statusCode === this.OrderStatus.CANCELLED) {
              this.currentOrdersService.removeOrder(data.data);
            } else {
              this.currentOrdersService.updateOrder(data.data);
            }
            this.transactionHistoryService.updateOrder(data.data);
          });

        } else {
          this.notificationService.error(
            'Notification',
            'Item cancellation failed, please try again later'
          )
        }
      },
      error => {
        this.notificationService.error(
          'Notification',
          'Item cancellation failed, please try again later'
        )
      }
    );
  }

  setOrderItemToBeCancelled(orderItem: OrderItemDTO) {
    this.orderItemToBeCancelled = orderItem;

    let toBeRefunded = this.orderItemToBeCancelled.totalPrice;
    if (this.updatedOrder.orderItems.length === 1) {
      toBeRefunded += this.updatedOrder.deliveryFee;
    }
    console.log("Going to refund:",toBeRefunded)
    this.checkRefundableAmount(toBeRefunded);

    this.tempUpdatedOrder = CommonUtils.cloneDeep(this.updatedOrder);
    for (let i = 0; i < this.tempUpdatedOrder.orderItems.length; i++) {
      if (this.tempUpdatedOrder.orderItems[i].orderItemId === orderItem.orderItemId) {
        this.tempUpdatedOrder.orderItems.splice(i, 1);
        this.tempUpdatedOrder.orderAmount = this.tempUpdatedOrder.orderAmount - orderItem.totalPrice;
      }
    }
  }

  checkRefundableAmount(toBeRefunded) {
    console.log("Checking amt to refund:",toBeRefunded)
    this.isCheckingRefundable = true;
    this.ordersApi.getRefundableAmountUsingGET(this.updatedOrder.orderId).subscribe(
      response => {
        const totalRefundable = response.data;
        this.refundableAmont = totalRefundable > toBeRefunded ? toBeRefunded : totalRefundable;
        this.expiredAmount = toBeRefunded - this.refundableAmont;
        console.log("Get refund using GET:",this.refundableAmont)
        this.isCheckingRefundable = false;
      });
  }

  getUpdatedDeliveryFee() {
    if (this.updatedDeliveryMode === Constants.DeliveryMode.HOME_DELIVERY) {
      return this.cartService.deliveryFee;
    } else {
      return 0;
    }
  }

  getUpdatedTotalAmount() {
    if (!this.updatedOrder) {
      return 0;
    }
    return this.updatedOrder.orderAmount + this.getUpdatedDeliveryFee();
  }

  getTempUpdatedTotalAmount() {
    if (!this.tempUpdatedOrder) {
      return 0;
    }
    return this.tempUpdatedOrder.orderAmount + this.getUpdatedDeliveryFee();
  }

  getExpiredCredits(cancelledAmt: number, cancelledItems: OrderItemDTO[]): number {
    let totalAmount = 0;

    if (cancelledItems.length > 0) {
      for (const item of cancelledItems) {
        totalAmount += item.totalPrice;
      }
    }

    if (totalAmount > cancelledAmt) {
      return totalAmount - cancelledAmt;
    }

    return 0;
  }

  printOrderDetail() {
    this.currentOrdersService.orderPrint.order = this.orderToViewDetail;
    this.currentOrdersService.orderPrint.imageStore = this.imageStore;
    this.router.navigate(['/order-detail-print']);
  }
}
