<!-- sidebar -->
<app-sidebar></app-sidebar>
<!-- boxes -->
<div *ngFor="let category of catalogueService.categoriesForDashboard; let i=index; trackBy: trackByFn" class="col-md-3 col-sm-4 col-xs-12">
    <a [routerLink]="['/view-products/'+category.categoryName]" routerLinkActive="active">
        <div class="homepage-box" [ngStyle]="{'background-image': 'url(' + catalogueService.categoryImages[category.image.resourceId] + ')'}">
            <div class="overlay">
                <div class="overlay-content">
                    <h2>{{ category.categoryName }}</h2>
                    <p>{{ category.categoryDescription }} </p>
                    <button type="button" class="btn">View All</button>
                    <span class="glyphicon glyphicon-menu-right" aria-hidden="true"></span>
                </div>
            </div>
        </div>
    </a>
</div>