/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class NameTagManagementApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {}

    /**
     * deleteNametags
     * Delete list of Nametags with the given product id.
     * @param request Should contain the list of product ids to be deleted.
     */
    public deleteNametagWithIdsUsingPOST(request: models.DeleteNameTagRequest, extraHttpRequestParams?: any): Observable<models.DeleteNameTagResponse> {
        return this.deleteNametagWithIdsUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getNameTagForAdmin
     * Get products
     * @param department Department of the categories &lt;br&gt;&lt;ul&gt;&lt;li&gt;0: SPF &amp; SCDF&lt;/li&gt;&lt;li&gt;1: SPF&lt;/li&gt; &lt;li&gt;2: SCDF&lt;/li&gt;&lt;/ul&gt;
     * @param page to set the index of pages to retrieve the products. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param skuId to set the product skuID to be retrieved. If it is set, the skuID must be matched. If other params is not null, they are all need to be matched the corresponding products as well.
     * @param name to set the product name to be retrieved. If it is set, the name must be matched fully or partially. If other params is not null, they are all need to be matched the corresponding products as well.
     * @param capType to set the product cap type for filtering.&lt;br&gt;&lt;ul&gt;&lt;li&gt;0: N.A&lt;/li&gt; &lt;li&gt;1: Hard&lt;/li&gt; &lt;li&gt;2: Soft&lt;/li&gt;&lt;/ul&gt;
     * @param status to set the product status for filtering.&lt;br&gt;&lt;ul&gt;&lt;li&gt;A: Active&lt;/li&gt; &lt;li&gt;I: Inactive&lt;/li&gt;&lt;/ul&gt;
     */
    public getNameTagForAdminUsingGET(department: number, page: number, size: number, skuId?: string, name?: string, capType?: number, status?: string, extraHttpRequestParams?: any): Observable<models.GetNameTagForAdminResponse> {
        return this.getNameTagForAdminUsingGETWithHttpInfo(department, page, size, skuId, name, capType, status, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * SaveNameTag
     * Add new or update name tag
     * @param request Request body to save name tag.
     */
    public saveNameTagUsingPOST(request: models.ProductDTO, extraHttpRequestParams?: any): Observable<models.SaveNameTagResponse> {
        return this.saveNameTagUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * deleteNametags
     * Delete list of Nametags with the given product id.
     * @param request Should contain the list of product ids to be deleted.
     */
    public deleteNametagWithIdsUsingPOSTWithHttpInfo(request: models.DeleteNameTagRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/nameTagManagement/nameTags/delete';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling deleteNametagWithIdsUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * getNameTagForAdmin
     * Get products
     * @param department Department of the categories &lt;br&gt;&lt;ul&gt;&lt;li&gt;0: SPF &amp; SCDF&lt;/li&gt;&lt;li&gt;1: SPF&lt;/li&gt; &lt;li&gt;2: SCDF&lt;/li&gt;&lt;/ul&gt;
     * @param page to set the index of pages to retrieve the products. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param skuId to set the product skuID to be retrieved. If it is set, the skuID must be matched. If other params is not null, they are all need to be matched the corresponding products as well.
     * @param name to set the product name to be retrieved. If it is set, the name must be matched fully or partially. If other params is not null, they are all need to be matched the corresponding products as well.
     * @param capType to set the product cap type for filtering.&lt;br&gt;&lt;ul&gt;&lt;li&gt;0: N.A&lt;/li&gt; &lt;li&gt;1: Hard&lt;/li&gt; &lt;li&gt;2: Soft&lt;/li&gt;&lt;/ul&gt;
     * @param status to set the product status for filtering.&lt;br&gt;&lt;ul&gt;&lt;li&gt;A: Active&lt;/li&gt; &lt;li&gt;I: Inactive&lt;/li&gt;&lt;/ul&gt;
     */
    public getNameTagForAdminUsingGETWithHttpInfo(department: number, page: number, size: number, skuId?: string, name?: string, capType?: number, status?: string, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/nameTagManagement/nameTags';

        let queryParameters = new HttpParams();

        // verify required parameter 'department' is not null or undefined
        if (department === null || department === undefined) {
            throw new Error('Required parameter department was null or undefined when calling getNameTagForAdminUsingGET.');
        }
        // verify required parameter 'page' is not null or undefined
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getNameTagForAdminUsingGET.');
        }
        // verify required parameter 'size' is not null or undefined
        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling getNameTagForAdminUsingGET.');
        }
        if (department !== undefined) {
            queryParameters = queryParameters.append('department', <any>department);
        }

        if (skuId !== undefined) {
            queryParameters = queryParameters.append('skuId', <any>skuId);
        }

        if (name !== undefined) {
            queryParameters = queryParameters.append('name', <any>name);
        }

        if (capType !== undefined) {
            queryParameters = queryParameters.append('capType', <any>capType);
        }

        if (status !== undefined) {
            queryParameters = queryParameters.append('status', <any>status);
        }

        if (page !== undefined) {
            queryParameters = queryParameters.append('page', <any>page);
        }

        if (size !== undefined) {
            queryParameters = queryParameters.append('size', <any>size);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
    }

    /**
     * SaveNameTag
     * Add new or update name tag
     * @param request Request body to save name tag.
     */
    public saveNameTagUsingPOSTWithHttpInfo(request: models.ProductDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/nameTagManagement/nameTags';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveNameTagUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

}
