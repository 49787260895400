<div id="announcement" class="show" *ngIf="announcementMessages.length > 0">
    <div class="alert-bar">
        <div class="container">
            <span class="float-end announcement-close">
                <a class="alert-bar-close js-alert-bar-close" data-id="{ID}"
                    (click)="closeAnnouncementBanner()">
                    <i class="fa fa-times"></i>
                    <span class="sr-only">Close alert bar</span>
                </a>
            </span>
            <table>
                <tr *ngFor="let announcement of announcementMessages; let i = index">
                    <td>
                        <h2 class="alert-bar-title">
                            {{announcement.announcementTitle}}
                        </h2>
                    </td>
                    <td>
                        <p class="alert-bar-text">
                            {{announcement.announcementContent}}
                        </p>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>