import { ResourceService } from './resource.service';
import { Observable } from 'rxjs';
import { OrderDTO } from './../shared/backend-api/emart';
import { Injectable } from '@angular/core';

@Injectable()
export class TransactionHistoryService {

  public orders: OrderDTO[] = null;

  updateOrder(updatedOrder: OrderDTO) {
    if (this.orders === null) {
      return;
    }

    for (let i = 0; i < this.orders.length; i++) {
      if (this.orders[i].orderId === updatedOrder.orderId) {
        this.orders[i] = updatedOrder;
        break;
      }
    }
  }

}
