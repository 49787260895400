import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { StoreConfigService } from 'app/services/store-config.service';
import { UserProductDTO, UserSkuDTO, GetProductsResponseError } from 'app/shared/backend-api/emart';
import * as naturalSort from 'javascript-natural-sort';

import { Constants } from '../../../shared/global-constants/constants';
import { CartService } from './../../../services/cart.service';
import { ProductsService } from './../../../services/products.service';
import { ResourceService } from './../../../services/resource.service';
import { SharedSearchService } from './../../../services/shared-search.service';
import { TailorCartService } from 'app/services/tailor-cart.service';
import { UserProfileService } from 'app/services/user-profile.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-view-tailored-uniforms',
  templateUrl: './view-tailored-uniforms.component.html',
  styleUrls: ['./view-tailored-uniforms.component.css'],
})
export class ViewTailoredUniformsComponent implements OnInit {

  listView = false;

  public sortType = 0;
  private searchString = '';

  products: UserProductDTO[];
  skus: UserSkuDTO[];
  originProducts: UserProductDTO[];

  imagestore: any = {};

  // -- Pagination
  pageIndex = 1;
  itemsPerPage = 12;
  totalPages = 1;
  frontPaginationDisabled = false;
  rearPaginationDisabled = false;
  loadProductsFailed = false;
  invalidRank = false;
  // ---

  public notificationOptions = {
    position: ['top', 'right'],
    lastOnBottom: false,
    timeOut: 2000
  };

  constructor(
    private activateRoute: ActivatedRoute,
    private resourceService: ResourceService,
    private productsService: ProductsService,
    private cartService: CartService,
    private tailorService: TailorCartService,
    private userProfileService: UserProfileService,
    private cookieService: CookieService,
    public configSvc: StoreConfigService,
    private searchService: SharedSearchService
  ) { }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnDestroy() {
    this.searchService.currentPage = '';
  }

  ngOnInit() {
    this.cartService.loadCartDetail().subscribe(response => {
      if (response.status === 200) {
        this.cartService.shoppingCart = response.data;
      } else {
        // TODO: error to get products. display error message
      }
    })

    this.searchService.currentPage = 'VIEW_UNIFORMS';
    this.searchService.listenFor(SharedSearchService.SEARCH_STRING).subscribe(
      searchFor => {
        this.searchString = searchFor;
        if (this.originProducts) {
          this.displayProducts();
        }
      }
    );

    const cookiePerPage = this.cookieService.get('uniforms_per_page');
    if (cookiePerPage) {
      this.itemsPerPage = Number(cookiePerPage);
    }
    const cookieListView = this.cookieService.get('uniforms_list_view');
    if (cookieListView) {
      this.listView = cookieListView === 'true';
    }
    const cookieSortType = this.cookieService.get('uniforms_sort_type');
    if (cookieSortType) {
      this.sortType = Number(cookieSortType);
    }

    this.loadProductsFailed = false;
    this.invalidRank = false;
    this.productsService.loadProducts(Constants.ProductType.TAILOR).subscribe(response => {
      this.productsService.loadProductCaps().subscribe(() => {
        if (response.status === 200) {
          this.originProducts = response.data;
          this.displayProducts();
        } else if (response.status === 204) {
          this.originProducts = [];
          this.displayProducts();
        } else {
          this.loadProductsFailed = true;
          if (response.errors[0].code === GetProductsResponseError.CodeEnum.RANK) {
            this.invalidRank = true;
          }
        }
      });
    });

  }

  getProduct(id: number): UserProductDTO {
    for (const product of this.productsService.cachedUniforms.data) {
      if (product.productId === id) {
        return product;
      }
    }
  }

  displayProducts() {
    this.pageIndex = 1;
    // copy the origin product list
    this.products = this.originProducts.map(p => Object.assign({}, p));
    this.searchProducts();
  }

  searchProducts() {
    if (this.searchString) {
      const sstr = this.searchString.trim().toUpperCase();
      this.products = this.products.filter(p => {
        return p.productName.toUpperCase().indexOf(sstr) >= 0;
      });
    }
    this.sortProducts();
  }

  selectSortProductType($event) {
    this.sortType = Number($event.target.value);
    this.cookieService.set('uniforms_sort_type', String(this.sortType));
    this.sortProducts();
  }

  sortProducts() {
    this.pageIndex = 1;
    /*
     *  Sort the index first using naturalSort
     */
    const sortedIndex = new Array;
    naturalSort.insensitive = true;
    for (const product of this.products) {
      sortedIndex.push(product.productName);
    }
    sortedIndex.sort(naturalSort);

    switch (this.sortType) {
      case 0:
        this.products.sort((a, b) => {
          return sortedIndex.indexOf(a.productName) <= sortedIndex.indexOf(b.productName) ? -1 : 1;
        })
        break;

      case 1:
        this.products.sort((a, b) => {
          return sortedIndex.indexOf(a.productName) <= sortedIndex.indexOf(b.productName) ? 1 : -1;
        });
        break;

      case 2:
        this.products.sort((a, b) => {
          return a.tailors[0].price <= b.tailors[0].price ? -1 : 1;
        });
        break;

      case 3:
        this.products.sort((a, b) => {
          return a.tailors[0].price <= b.tailors[0].price ? 1 : -1;
        });
        break;

      default:

    }
    this.updatePagination();
  }

  updatePagination() {
    this.totalPages = Math.ceil(this.products.length / this.itemsPerPage);
    if (this.hasNoMorePages()) {
      this.rearPaginationDisabled = true;
    } else {
      this.rearPaginationDisabled = false;
    }

    if (this.pageIndex > this.totalPages) {
      this.pageIndex = this.totalPages;
    }

    if (this.pageIndex > 1) {
      this.frontPaginationDisabled = false;
    } else {
      this.frontPaginationDisabled = true;
    }

    this.fetchImagesInThePage();
  }

  private hasNoMorePages() {
    return this.pageIndex * this.itemsPerPage >= this.products.length;
  }

  getProductsToDisplay(): UserProductDTO[] {
    if (this.products && this.products.length > 0) {
      const firstItemIndex = (this.pageIndex - 1) * this.itemsPerPage;
      const endItemIndex = this.pageIndex * this.itemsPerPage;
      return this.products.slice(firstItemIndex, endItemIndex);
    } else {
      return [];
    }
  }

  private loadProductImage(product: UserProductDTO) {
    if (this.imagestore[product.productId]) { return; }// cached.
    if (product.images && product.images.length > 0) {
      this.resourceService.loadImageWithId(product.images[0].resourceId).then(img => {
        this.imagestore[product.productId] = img;
      });
    } else {
      this.imagestore[product.productId] = this.configSvc.dummyImgPath;
    }
  }

  updateViewMode(listView: boolean) {
    this.listView = listView;
    this.cookieService.set('uniforms_list_view', String(listView));
  }

  fetchImagesInThePage() {
    for (const product of this.getProductsToDisplay()) {
      this.loadProductImage(product);
    }
  }

  updateItemsPerPage($event) {
    this.itemsPerPage = $event.target.value;
    if (Number(this.itemsPerPage) === 6 || Number(this.itemsPerPage) === 12) {
      this.cookieService.set('uniforms_per_page', String(this.itemsPerPage));
    }
    this.updatePagination();
  }

  jumpToPage(page: number, event?: any) {
    if (event && event.key !== 'Enter') {
      return;
    }

    if (page > 0 && page <= this.totalPages) {
      this.pageIndex = page;
      this.updatePagination();
    }
  }

  filterUniformPrice(tailors): any[] {
    return Array.from(new Set(tailors.map((tailor) => tailor.price)));
  }
}
