/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class AdhocCreditAllocationApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient,) {
        
    }

    /**
     * cancelRequest
     * Cancel ad-hoc credit allocation request
     * @param request request
     */
    public cancelRequestUsingPOST(request: models.CancelRequestRequest, extraHttpRequestParams?: any): Observable<models.CancelRequestResponse> {
        return this.cancelRequestUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getApprovers
     * Get ad-hoc credit allocation approvers
     */
    public getApproversUsingGET(extraHttpRequestParams?: any): Observable<models.GetApproversResponse> {
        return this.getApproversUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getDocument
     * Get document
     * @param id Document resource ID
     */
    public getDocumentUsingGET(id: number, extraHttpRequestParams?: any): Observable<models.GetDocumentResponse> {
        return this.getDocumentUsingGETWithHttpInfo(id, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getRequestDetails
     * Get ad-hoc credit allocation request details
     * @param id Request ID
     */
    public getRequestDetailsUsingGET(id: number, extraHttpRequestParams?: any): Observable<models.GetRequestDetailsResponse> {
        return this.getRequestDetailsUsingGETWithHttpInfo(id, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getRequestStatusSelections
     * Get ad-hoc credit allocation request status selections
     */
    public getRequestStatusSelectionsUsingGET(extraHttpRequestParams?: any): Observable<models.GetRequestStatusSelectionsResponse> {
        return this.getRequestStatusSelectionsUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getRequests
     * Get ad-hoc credit allocation requests
     * @param type to indicate the requests retrieval is for allocator or approver
     * @param page to set the index of pages to retrieve the order. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param from to indicate the submission start date (yyyy-MM-dd) for the requests to be retrieved. If it is null, no limitation of start date will be imposed.
     * @param to to indicate the submission end date (yyyy-MM-dd) for the requests to be retrieved. If it is null, no limitation of end date will be imposed.
     * @param status to indicate the status for the requests to be retrieved. If it is null, all available status will be returned. status map: &lt;br&gt;&lt;ul&gt;&lt;li&gt;1: DRAFT&lt;/li&gt; &lt;li&gt;2: PENDING&lt;/li&gt; &lt;li&gt;3: FAILED&lt;/li&gt; &lt;li&gt;4: APPROVED&lt;/li&gt; &lt;li&gt;5: REJECTED&lt;/li&gt; &lt;li&gt;6: ALLOCATED&lt;/li&gt;&lt;/ul&gt;
     */
    public getRequestsUsingGET(type: string, page: number, size: number, from?: string, to?: string, status?: number, extraHttpRequestParams?: any): Observable<models.GetRequestsResponse> {
        return this.getRequestsUsingGETWithHttpInfo(type, page, size, from, to, status, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * submitRequest
     * Submit ad-hoc credit allocation request
     * @param request request
     */
    public submitRequestUsingPOST(request: models.CreateCreditAdhocRequestDTO, extraHttpRequestParams?: any): Observable<models.SubmitRequestResponse> {
        return this.submitRequestUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * updateRequestStatus
     * Approve/reject ad-hoc credit allocation request
     * @param request request
     */
    public updateRequestStatusUsingPOST(request: models.UpdateRequestStatusRequest, extraHttpRequestParams?: any): Observable<models.UpdateRequestStatusResponse> {
        return this.updateRequestStatusUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * validateUsers
     * Validate servicemen
     * @param nrics nrics
     */
    public validateUsersUsingPOST(nrics: Array<string>, extraHttpRequestParams?: any): Observable<models.ValidateUsersResponse> {
        return this.validateUsersUsingPOSTWithHttpInfo(nrics, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * cancelRequest
     * Cancel ad-hoc credit allocation request
     * @param request request
     */
    public cancelRequestUsingPOSTWithHttpInfo(request: models.CancelRequestRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/adhocCredits/cancel';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling cancelRequestUsingPOST.');
        }

        return this.httpClient.post(path, request, {withCredentials: true});
    }

    /**
     * getApprovers
     * Get ad-hoc credit allocation approvers
     */
    public getApproversUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/adhocCredits/approvers';

        return this.httpClient.get(path, {withCredentials: true});
    }

    /**
     * getDocument
     * Get document
     * @param id Document resource ID
     */
    public getDocumentUsingGETWithHttpInfo(id: number, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/adhocCredits/documents/${id}'
            .replace('${' + 'id' + '}', String(id));

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getDocumentUsingGET.');
        }

        return this.httpClient.get(path, {withCredentials: true});
    }

    /**
     * getRequestDetails
     * Get ad-hoc credit allocation request details
     * @param id Request ID
     */
    public getRequestDetailsUsingGETWithHttpInfo(id: number, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/adhocCredits/${id}'
            .replace('${' + 'id' + '}', String(id));

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getRequestDetailsUsingGET.');
        }

        return this.httpClient.get(path, {withCredentials: true});
    }

    /**
     * getRequestStatusSelections
     * Get ad-hoc credit allocation request status selections
     */
    public getRequestStatusSelectionsUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/adhocCredits/requestStatusSelections';

        return this.httpClient.get(path, {withCredentials: true});
    }

    /**
     * getRequests
     * Get ad-hoc credit allocation requests
     * @param type to indicate the requests retrieval is for allocator or approver
     * @param page to set the index of pages to retrieve the order. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param from to indicate the submission start date (yyyy-MM-dd) for the requests to be retrieved. If it is null, no limitation of start date will be imposed.
     * @param to to indicate the submission end date (yyyy-MM-dd) for the requests to be retrieved. If it is null, no limitation of end date will be imposed.
     * @param status to indicate the status for the requests to be retrieved. If it is null, all available status will be returned. status map: &lt;br&gt;&lt;ul&gt;&lt;li&gt;1: DRAFT&lt;/li&gt; &lt;li&gt;2: PENDING&lt;/li&gt; &lt;li&gt;3: FAILED&lt;/li&gt; &lt;li&gt;4: APPROVED&lt;/li&gt; &lt;li&gt;5: REJECTED&lt;/li&gt; &lt;li&gt;6: ALLOCATED&lt;/li&gt;&lt;/ul&gt;
     */
    public getRequestsUsingGETWithHttpInfo(type: string, page: number, size: number, from?: string, to?: string, status?: number, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/adhocCredits';

        let queryParameters = new HttpParams();

        // verify required parameter 'type' is not null or undefined
        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling getRequestsUsingGET.');
        }
        // verify required parameter 'page' is not null or undefined
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getRequestsUsingGET.');
        }
        // verify required parameter 'size' is not null or undefined
        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling getRequestsUsingGET.');
        }
        if (type !== undefined) {
            queryParameters = queryParameters.append('type', <any>type);
        }

        if (from !== undefined) {
            queryParameters = queryParameters.append('from', <any>from);
        }

        if (to !== undefined) {
            queryParameters = queryParameters.append('to', <any>to);
        }

        if (status !== undefined) {
            queryParameters = queryParameters.append('status', <any>status);
        }

        if (page !== undefined) {
            queryParameters = queryParameters.append('page', <any>page);
        }

        if (size !== undefined) {
            queryParameters = queryParameters.append('size', <any>size);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true});
    }

    /**
     * submitRequest
     * Submit ad-hoc credit allocation request
     * @param request request
     */
    public submitRequestUsingPOSTWithHttpInfo(request: models.CreateCreditAdhocRequestDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/adhocCredits';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling submitRequestUsingPOST.');
        }

        return this.httpClient.post(path, request, {withCredentials: true});
    }

    /**
     * updateRequestStatus
     * Approve/reject ad-hoc credit allocation request
     * @param request request
     */
    public updateRequestStatusUsingPOSTWithHttpInfo(request: models.UpdateRequestStatusRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/adhocCredits/updateStatus';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling updateRequestStatusUsingPOST.');
        }

        return this.httpClient.post(path, request, {withCredentials: true});
    }

    /**
     * validateUsers
     * Validate servicemen
     * @param nrics nrics
     */
    public validateUsersUsingPOSTWithHttpInfo(nrics: Array<string>, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/adhocCredits/validateServicemen';

        // verify required parameter 'nrics' is not null or undefined
        if (nrics === null || nrics === undefined) {
            throw new Error('Required parameter nrics was null or undefined when calling validateUsersUsingPOST.');
        }

        return this.httpClient.post(path, nrics, {withCredentials: true});
    }

}
