<div id="login-page" class="container public-page">
    <div>
        <!-- content -->
        <div class="d-flex flex-lg-row flex-column justify-content-between align-items-center">
            <div class="mt-5">
                <div class="col-md-8 col-sm-12 col-xs-12" [ngClass]="{ 'text-center': getScreenWidth < 769 }">
                    <h1>Welcome to Home&nbsp;Team eMART</h1>
                </div>
            </div>
            <div class="login-panel shadow col-md-4 col-sm-7 col-xs-12 collapse-box mt-5 bg-white">
                <div class="col-12 text-center mt-3">
                    <a href="{{singpassUrl}}">
                    <!-- <a (click)="loginStore()"> -->
                        <button id="login-btn" type="button" class="btn mx-3 mt-2">Login with Singpass</button>
                    </a>
                </div>
                <div class="col-12 mx-3 mt-3 mb-4"> 
                    <a href="{{corppassUrl}}" title class="hatch">
                    <!-- <a href="javascript:void(0)" (click)="loginLogistic()" title class="hatch"> -->
                        <span class="fa fa-info-circle" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Login with your Corppass to perform Government-To-Business (G2B)transactions with eMART."></span> Supplier login
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>