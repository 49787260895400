<!-- sidebar -->
<app-sidebar></app-sidebar>

<simple-notifications [options]="notificationOptions"></simple-notifications>

<!-- content -->
<div class="col-md-9 col-sm-8 col-xs-12">
  <div class="collapse-box">
    <div class="collapse in">
      <div class="box-content">

        <div class="text-center" *ngIf="!catalogueService.productDetail">
          <i class="fa fa-refresh fa-2x spinner text-primary"></i>
        </div>

        <div class="catalogue-head" *ngIf="catalogueService.productDetail">
          <ol class="breadcrumb">
            <li *ngIf="isNormalProduct()">
              <a [routerLink]="['/view-products/All']">Catalogue</a>
            </li>
            <li *ngIf="isTailorProduct()">
              <a [routerLink]="['/view-uniforms']">Tailored Uniform</a>
            </li>
            <li *ngIf="isNameTagProduct()">
              <a [routerLink]="['/view-name-tags']">Name Tag</a>
            </li>
            <li *ngIf="isNormalProduct()">
              <a [routerLink]="['/view-products/', catalogueService.productDetail.categories[0]?.categoryName]">{{catalogueService.productDetail.categories[0]?.categoryName}}</a>
            </li>
            <li class="active">{{catalogueService.productDetail.productName}}</li>
          </ol>
        </div>
        <div class="row" *ngIf="catalogueService.productDetail">
          <div class="col-md-4 col-sm-6 col-xs-6 col-xxs-12">
            <div id="carousel-custom" class="carousel slide" data-bs-interval="false">
              <div class="carousel-outer">
                <!-- Wrapper for slides -->
                <div class="carousel-inner">
                  <div class="carousel-item" *ngFor="let image of catalogueService.getImagesToDisplay(); let index = index" [class.active]="index === 0">
                    <img [src]="catalogueService.productImages[image.resourceId] || configSvc.dummyImgPath">
                  </div>
                </div>

                <!-- Controls -->
                <a class="left carousel-control" data-bs-target="#carousel-custom" role="button" data-bs-slide="prev">
                  <span class="glyphicon glyphicon-chevron-left"></span>
                </a>
                <a class="right carousel-control" data-bs-target="#carousel-custom" role="button" data-bs-slide="next">
                  <span class="glyphicon glyphicon-chevron-right"></span>
                </a>
              </div>

              <!-- Indicators -->
              <ol class="carousel-indicators mCustomScrollbar">
                <li data-bs-target="#carousel-custom" *ngFor="let image of catalogueService.getImagesToDisplay(); let index = index" [attr.data-bs-slide-to]="index"
                  [class.active]="index === 0">
                  <img [src]="catalogueService.productImages[image.resourceId] || configSvc.dummyImgPath">
                </li>
              </ol>
            </div>
          </div>
          <div class="col-md-8 col-sm-6 col-xs-6 col-xxs-12">
            <div class="product-details">
              <ng-container *ngIf="catalogueService.selectedSkuStatus(selectedSize); then Active; else Inactive"></ng-container>
              <ng-template #Inactive>
                <h2 style="color:red;">(Temporary out-of-stock)</h2>
                <h2>{{catalogueService.productDetail.productName}}</h2>
              </ng-template>
              <ng-template #Active>
               <h2>{{catalogueService.productDetail.productName}}</h2>
              </ng-template>
              <p>{{catalogueService.productDetail.description}}</p>
              <h5 *ngIf="isNormalProduct() || isNameTagProduct()">{{catalogueService.productDetail.productPrice | priceFormat}}</h5>
            </div>
            <div class="product-options">
              <div class="size" *ngIf="isNormalProduct()">
                Size
                <div>
                  <select *ngIf="catalogueService.productDetail" [(ngModel)]="selectedSize" name="size" class="form-control" [disabled]="catalogueService.productDetail.skus.length == 1">
                    <option value="-1" *ngIf="catalogueService.productDetail.skus.length > 1">-Select-</option>
                    <option value="{{sku.skuId}}" *ngFor="let sku of catalogueService.productDetail.skus" [selected]="sku.preferred">{{sku.skuName == null ? 'N.A' : sku.skuName}}</option>
                  </select>
                </div>
              </div>
              <div class="size" *ngIf="isTailorProduct()">
                Price
                <div>
                  <select *ngIf="catalogueService.productDetail" [(ngModel)]="selectedTailor" name="size" class="form-control" [disabled]="filterUniformPrice(catalogueService.productDetail.tailors).length == 1">
                    <option value="-1" *ngIf="filterUniformPrice(catalogueService.productDetail.tailors).length > 1">-Select-</option>
                    <option value="{{tailorPrice}}" *ngFor="let tailorPrice of filterUniformPrice(catalogueService.productDetail.tailors)">{{tailorPrice | priceFormat}}</option>
                  </select>
                </div>
              </div>
              <div class="qty">
                Quantity
                <select [(ngModel)]="selectedQty" name="qty" class="form-control">
                  <option *ngFor="let item of PRODUCT_MAX_QUANTITY; let index = index" value="{{index+1}}" [class.selected]="index === 0">{{index+1}}
                  </option>
                </select>
              </div>
            </div>
            <ng-container *ngIf="catalogueService.selectedSkuStatus(selectedSize); then active; else inactive"></ng-container>
            <ng-template #inactive>
              <button *ngIf="isNormalProduct()" type="button" class="btn btn-addtocart" (click)="waitlistService.addDetailedItemToWaitlist(selectedSize,selectedQty)">
                <span class="glyphicon glyphicon-plus" aria-hidden="true"></span>Add to Waitlist</button>
            </ng-template>
            <ng-template #active>
            <button *ngIf="isNormalProduct()" type="button" class="btn btn-addtocart" (click)="cartService.addItemToCart(selectedSize, selectedQty)">
              <span class="glyphicon glyphicon-plus" aria-hidden="true"></span>Add to Cart</button>
            </ng-template>
              <button *ngIf="isTailorProduct()" type="button" class="btn btn-addtocart" (click)="tailorCartService.addItemToCart(selectedTailor, selectedQty, catalogueService.productDetail.productId)">
                <span class="glyphicon glyphicon-plus" aria-hidden="true"></span>Add to Cart</button>
              <button *ngIf="isNameTagProduct()" type="button" class="btn btn-addtocart" (click)="nameTagCartService.addItemToCart(catalogueService.productDetail.productId, selectedQty)">
                <span class="glyphicon glyphicon-plus" aria-hidden="true"></span>Add to Cart</button>
            <button type="button" class="btn btn-feedback" (click)="sendFeedback(catalogueService.productDetail.productId)">
              <span class="glyphicon glyphicon-envelope" aria-hidden="true"></span>Send Feedback</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- size guide -->
<div *ngIf="catalogueService.productDetail && catalogueService.productDetail.skus.length > 1 && catalogueService.measureMentData"
  class="col-md-9 col-sm-8 col-xs-12 col-lg-offset-3 col-md-offset-3 col-sm-offset-4" id="sizeguide">
  <div class="collapse-box">
    <div class="collapse-title">
      <h1>Size Guide</h1>
      <div class="btn-toggle-collapse-wrapper">
        <a class="btn-toggle-collapse" data-bs-target="#tab1" data-bs-toggle="collapse"></a>
      </div>
    </div>
    <div id="tab1" class="collapse show">
      <div class="box-content">
        <p>Use the chart and video/picture guide to determine your size.</p>
        <!-- Size Chart start-->
        <div class="size-chart">
          <div class="size-chart-title">
            <div class="item">
              <div style="text-overflow: ellipsis; overflow: hidden;">Size{{catalogueService.measurementUnit ? '('+catalogueService.measurementUnit+')':''}}
              </div>
              <div *ngFor="let measurementType of catalogueService.measureMentData.types;">
                {{measurementType}}
                <br/>
                <app-view-visual-size-guide [guideType]="measurementType"></app-view-visual-size-guide>
              </div>
            </div>
          </div>

          <div class="carousel slide size-chart-content" id="theCarousel" data-bs-interval="false" [class.twosize]="isTwoSize">
            <div class="carousel-inner">
              <div class="carousel-item" [class.active]="i == 0" *ngFor="let measurementDetail of catalogueService.measureMentData.details; let i = index;">
                <div class="item-wrap">
                  <div>{{measurementDetail.skuName}}</div>
                  <div *ngFor="let size of measurementDetail.sizes">{{size}}</div>
                </div>
              </div>
            </div>
            <a class="left carousel-control moveleft" data-bs-target="#theCarousel" data-bs-slide="prev">
              <i class="glyphicon glyphicon-chevron-left"></i>
            </a>
            <a class="right carousel-control" data-bs-target="#theCarousel" data-bs-slide="next">
              <i class="glyphicon glyphicon-chevron-right"></i>
            </a>
          </div>
        </div>
        <!-- Size Chart end-->
      </div>

    </div>
  </div>
</div>

<!-- related products -->
<div class="col-md-9 col-sm-8 col-xs-12 col-md-offset-3 col-sm-offset-4" *ngIf="catalogueService.getRelatedProducts().length > 0">
  <div class="collapse-box">
    <div class="collapse-title">
      <h1>Related Products</h1>
      <div class="btn-toggle-collapse-wrapper">
        <a class="btn-toggle-collapse" data-bs-target="#tab2" data-bs-toggle="collapse"></a>
      </div>
    </div>
    <div id="tab2" class="collapse show">
      <div class="box-content">
        <div class="col-md-3 col-sm-3 col-xs-6 recomended-item" *ngFor='let product of catalogueService.getRelatedProducts()'>
          <a [routerLink]="['../' +product['productId']]">
            <img *ngIf='product.image' [src]="catalogueService.productImages[product.image.resourceId] || configSvc.dummyImgPath" alt=""
              class="img-responsive">
          </a>
          <a [routerLink]="['../' +product['productId']]">
            <h4>{{product.productName}}</h4>
          </a>
          <h5>{{product.productPrice | priceFormat}}</h5>
        </div>
      </div>
    </div>
  </div>
</div>
