<!-- sidebar -->
<div id="sidebar">
    <div class="col-md-3 col-sm-4 col-xs-12">
        <div class="my-credits" *ngIf="showCredit && userProfileService.creditSummary?.creditBalance != undefined">My Credits: {{ userProfileService.creditSummary?.creditBalance | priceFormat}}</div>
        <div class="my-credits" *ngIf="showCredit && userProfileService.creditSummary?.creditBalance == undefined">My Credits: {{ 0 | priceFormat }}</div>
        <div class="my-unit" *ngIf="showUnit">
            <div class="header">My Unit</div>
            <div class="wrapper">
                <div class="unit-logo">
                    <img [src]="unitLogo || configSvc.dummyImgPath" alt="">
                </div>
                <div class="unit-name">{{ userProfileService.userProfile?.unit }}</div>
            </div>
        </div>

        <div class="my-cart catalogue" *ngIf="showCart">
            <div class="header">
                <div class="header-wrapper">
                    My Cart
                    <span class="glyphicon glyphicon-shopping-cart" aria-hidden="true"></span>
                    <span class="cart-qty">{{cartService.getCartItems()}}</span>
                </div>
            </div>

            <div class="wrapper">
                <table width="100%" class="cart" *ngIf="cartService.shoppingCart">
                    <tbody *ngIf="cartService.shoppingCart.items != null">
                        <tr *ngFor="let item of cartService.shoppingCart.items">
                            <td *ngIf='getProduct(item.productId)'>
                                <div class="long-text-wrapper">{{getProduct(item.productId).productName}}</div>
                            </td>
                            <td *ngIf='getProduct(item.productId)'>x{{item.quantity}}</td>
                            <td *ngIf='getProduct(item.productId)'>{{getProduct(item.productId).productPrice * item.quantity | priceFormat}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="cartService.shoppingCart.tailorItems != null">
                        <tr *ngFor="let item of cartService.shoppingCart.tailorItems">
                            <td *ngIf='getTailorProduct(item.productId)'>
                                <div class="long-text-wrapper">{{getTailorProduct(item.productId).productName}}</div>
                            </td>
                            <td *ngIf='getTailorProduct(item.productId)'>x{{item.quantity}}</td>
                            <td *ngIf='getTailorProduct(item.productId)'>{{getTailorProductPrice(item.productId, item.productTailorId) * item.quantity | priceFormat}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="cartService.shoppingCart.nameTagItems != null">
                        <tr *ngFor="let item of cartService.shoppingCart.nameTagItems">
                            <td *ngIf='getNameTagProduct(item.productId)'>
                                <div class="long-text-wrapper">{{getNameTagProduct(item.productId).productName}}</div>
                            </td>
                            <td *ngIf='getNameTagProduct(item.productId)'>x{{item.quantity}}</td>
                            <td *ngIf='getNameTagProduct(item.productId)'>{{getNameTagProduct(item.productId).productPrice * item.quantity | priceFormat}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="2">Total Spending</td>
                            <td>{{getTotalPrice() | priceFormat}}</td>
                        </tr>
                        <tr>
                            <td colspan="2">Balance After Checkout</td>
                            <td *ngIf="userProfileService.creditSummary?.creditBalance != undefined">{{ getBalance() | priceFormat}}</td>
                            <td *ngIf="userProfileService.creditSummary?.creditBalance == undefined">$0.00</td>
                        </tr>
                    </tfoot>
                </table>
                <a style="text-decoration: underline; cursor:pointer;" (click)="gotoCart()">
                    <button type="button" class="btn btn-gotocart">
                        <span class="glyphicon glyphicon-shopping-cart" aria-hidden="true"></span>Go to Cart</button>
                </a>
            </div>
        </div>

        <div class="catalogue hidden-xs" *ngIf="showCatalogue">
            <div class="header">Catalogue</div>
            <ul>
                <a [routerLink]="['/view-products/'+category.categoryName]" routerLinkActive="active" *ngFor="let category of catalogueService.categories">
                    <li>{{category.categoryName}}</li>
                </a>
            </ul>
        </div>
        <div class="catalogue-mobile hidden-lg hidden-md hidden-sm visible-xs" *ngIf="showCatalogue">
            <select #category name="catalogue" class="form-control" (change)="catalogueSelected(category.value)">
                <option value="All">View all</option>
                <option value="{{category.categoryName}}" *ngFor="let category of catalogueService.categories" [selected]="category.categoryName == catalogueService.selectedCategory">{{category.categoryName}}</option>
            </select>
        </div>

    </div>
</div>