import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import * as moment from 'moment';
import { UserProfileService } from './../../../services/user-profile.service';
import { CreditTransactionDTO } from './../../../shared/backend-api/emart/model/CreditTransactionDTO';
import { Router } from '@angular/router';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.css']
})

export class CreditsComponent implements OnInit {
  private router: Router;

  fromDateForm: UntypedFormGroup;
  toDateForm: UntypedFormGroup;
  errorMsg: string = null;
  today: Date = new Date();
  minDate: Date = new Date();
  maxDate: Date = new Date();

  // Pagination
  private currentPage = 1;
  private itemPerPage = 10;
  private pageCount = 1;
  private nextPaginationDisabled = false;
  private prevPaginationDisabled = false;
  // ---

  constructor(private formBuilder: UntypedFormBuilder, public userProfileService: UserProfileService) {
  }

  ngOnInit() {
    this.minDate.setFullYear(this.maxDate.getFullYear() - 10);
    this.maxDate.setFullYear(this.maxDate.getFullYear() + 10);
    this.fromDateForm = this.formBuilder.group({ date: [], startAt: [], endAt: [] });
    this.toDateForm = this.formBuilder.group({ date: [], startAt: [], endAt: [] });
    this.updatePagination();
  }

  getCreditHistory() {
    this.errorMsg = null;
    this.currentPage = 1;

    let fromDate: Date = new Date();
    let toDate: Date = new Date();
    fromDate = moment(this.fromDateForm.controls['date'].value, 'DD MMM YYYY').toDate();
    toDate = moment(this.toDateForm.controls['date'].value, 'DD MMM YYYY').toDate();

    if (!this.validateDates(fromDate, toDate)) {
      return null;
    }

    this.userProfileService.getCreditHistory(moment(fromDate).format('YYYY-MM-DD'), moment(toDate).format('YYYY-MM-DD')).subscribe(
      data => {
        this.currentPage = 1;
        this.updatePagination();
      },
      err => {
        this.router.navigate(['/general-error']);
      }
    )
  }

  validateDates(fromDate, toDate): boolean {
    if (isNaN(fromDate) || isNaN(toDate)) {
      this.errorMsg = 'Invalid date';
      return false;
    }
    if (toDate < fromDate) {
      this.errorMsg = 'Start date must not be greater than end date';
      return false;
    }
    if (toDate > new Date()) {
      this.errorMsg = 'End date must not be greater than today\'s date';
      return false;
    }
    return true;
  }

  // Pagination
  getItemsToDisplay(): any[] {
    if (this.userProfileService.creditHistorys && this.userProfileService.creditHistorys.length > 0) {
      const firstItemIndex = (this.currentPage - 1) * this.itemPerPage;
      const endItemIndex = this.currentPage * this.itemPerPage;
      return this.userProfileService.creditHistorys.slice(firstItemIndex, endItemIndex);
    } else {
      return [];
    }
  }

  updatePagination() {
    try {
      this.pageCount = Math.ceil(this.userProfileService.creditHistorys.length / this.itemPerPage);
      this.currentPage = this.currentPage > this.pageCount ? this.pageCount : this.currentPage;
      this.nextPaginationDisabled = this.currentPage * this.itemPerPage >= this.userProfileService.creditHistorys.length;
      this.prevPaginationDisabled = this.currentPage <= 1;
    } catch (e) {
      this.pageCount = 1;
      this.currentPage = 1;
      this.nextPaginationDisabled = true;
      this.prevPaginationDisabled = true;
    }
  }

  gotoPreviousPage() {
    this.gotoPage(Number(this.currentPage) - 1);
  }

  gotoNextPage() {
    this.gotoPage(Number(this.currentPage) + 1);
  }

  gotoFirstPage() {
    this.gotoPage(1);
  }

  gotoLastPage() {
    this.gotoPage(this.pageCount);
  }

  gotoPage(page: number, event?: any) {
    if (event && event.key !== 'Enter') {
      return;
    }

    if (page > 0 && page <= this.pageCount) {
      this.currentPage = page;
      this.updatePagination();
    }
  }
  // ---

}
