import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { environment } from 'environments/environment';

import { AuthService } from './auth.service';
import { IS_NGNSP_ENV, NGNSP_ERROR_REDIRECTURL } from './auth.tokens';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [AuthService,
    { provide: NGNSP_ERROR_REDIRECTURL, useValue: environment.ngnspErrorRedirectUrl },
    { provide: IS_NGNSP_ENV, useValue: environment.isNgnspEnv }
  ]
})
export class AuthModule { }
