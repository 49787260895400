import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NotificationsService } from 'assets/lib/angular2-notifications';
import {
  WaitlistApi,
  WaitlistDTO,
  WaitlistItemDTO,
  UserProductDTO,
} from 'app/shared/backend-api/emart';
import { WaitlistService } from './../../../services/waitlist.service';
import { ProductsService } from './../../../services/products.service';
import { ResourceService } from './../../../services/resource.service';
import { StoreConfigService } from './../../../services/store-config.service';
import { UserProfileService } from './../../../services/user-profile.service';
import { Constants } from './../../../shared/global-constants/constants';
import { CommonUtils } from './../../../shared/utilities/common-utils';

declare const jQuery: any;

@Component({
  selector: 'app-waitlist',
  templateUrl: './waitlist.component.html',
  styleUrls: ['./waitlist.component.css']
})
export class WaitlistComponent implements OnInit {

  private waitlistDetail: WaitlistDTO;
  public allProducts: Array<UserProductDTO>;
  private productMap = new Map<number, UserProductDTO>();
  private PRODUCT_MAX_QUANTITY = Array.from(Array(10).keys());
  private SuccessDisplay: string;
  private ErrorDisplay;
  private imagestore: any = {};
  private products: any = {};
  private skuNames: any = {};
  private product: UserProductDTO;
  private tnc1: boolean;



  public notificationOptions = {
    position: ['top', 'right'],
    lastOnBottom: false,
    timeOut: 2000
  };



  constructor(
    private router: Router,
    private notificationService: NotificationsService,
    private waitlistApi: WaitlistApi,
    private userProfileService: UserProfileService,
    private storeConfigService: StoreConfigService,
    private productsService: ProductsService,
    private resourceService: ResourceService,
    public waitlistService: WaitlistService) {
    this.waitlistDetail = CommonUtils.cloneDeep(this.waitlistService.waitlist);
    this.allProducts = CommonUtils.cloneDeep(this.productsService.cachedProducts.data);
  }

  ngOnInit() {

    this.loadProducts();    // comment it for unit test
    this.fetchImagesInThePage();    // comment it for unit test

  }

  loadProducts() {
    for (const product of this.allProducts) {
      this.productMap.set(product.productId, product);
    }
  }

  getProduct(id: number): UserProductDTO {
    for (const product of this.allProducts) {
      if (product.productId === id) {
        return product;
      }
    }
  }

  private loadProductImage(product: UserProductDTO) {
    if (this.imagestore[product.productId]) { // cached
      return;
    }
    if (product.images && product.images.length > 0) {
      this.resourceService.loadImageWithId(product.images[0].resourceId).then(img => {
        this.imagestore[product.productId] = img;
      });
    } else {
      this.imagestore[product.productId] = this.storeConfigService.dummyImgPath;
    }
  }

  checkQty(qty: number) {
    if (Number(qty) <= 10) {
      qty = 10;
    }
    return Array.from(Array(qty).keys());
  }

  fetchImagesInThePage() {
    for (const product of this.allProducts) {
      this.loadProductImage(product);
    }
  }


  updateQuantity(item: WaitlistItemDTO, $event) {
    this.waitlistService.updateWaitlistItem(item.skuId, item.skuId, $event.target.value);
    jQuery('#itemquantity_' + item.skuId).trigger('reset');
  }


}
