import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from 'app/auth/auth.service';

import { first, map, Observable, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild, OnDestroy {

  isAuthenticated: boolean = false;
  authenticationSub: Subscription;
  validationSub : Subscription;

  constructor(
    private router: Router,
    private authService: AuthService,
) {

}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    
      return this.authService.isAuthenticated.pipe(map((isAuthenticated) => {
        console.log('canActivate:' + isAuthenticated);

        if (isAuthenticated) {
          // authorised so return true
          console.log("login already");
          return true;
        } 
        else {
          // Authentication and authorization not completed
          this.validationSub = 
          this.authService.doValidation().subscribe(data => 
            { 
              // successful Authentication and authorization
              return true;
            }, 
            // unsuccessful Authentication and authorization
            // thus not logged in so redirect to login page with the return url
            error => 
            { 
              console.log("Please login first.");
              return false
            });
        }
    }), first());
  }
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
  ngOnDestroy(){
    if (this.validationSub){
      this.validationSub.unsubscribe();
    }
  }
}
