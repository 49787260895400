/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class RoleManagementApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {}

    /**
     * deleteRoles
     * Delete an existing role
     * @param roleIdList roleIdList
     */
    public deleteRolesUsingPOST(roleIdList: Array<number>, extraHttpRequestParams?: any): Observable<models.DeleteRolesResponse> {
        return this.deleteRolesUsingPOSTWithHttpInfo(roleIdList, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getAllRoles
     * Get all available roles that the user can assign to other user or can update in the system
     * @param type to indicate the request type for the role that need to be retrieved. If it is null, all roles assignable by user will be returned, i.e. default value to 0. Type map: &lt;br&gt;&lt;ul&gt;&lt;li&gt;0: for role assignment&lt;/li&gt; &lt;li&gt;1: for role modification&lt;/li&gt;&lt;/ul&gt;
     */
    public getAllRolesUsingGET(type?: number, extraHttpRequestParams?: any): Observable<models.GetAllRolesResponse> {
        return this.getAllRolesUsingGETWithHttpInfo(type, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * savePermissions
     * Create or update permissions for an existing role
     * @param request request
     */
    public savePermissionsUsingPOST(request: models.RoleDTO, extraHttpRequestParams?: any): Observable<models.SavePermissionsResponse> {
        return this.savePermissionsUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * saveRoleAssignment
     * Save Role Assignment Changes
     * @param request request
     */
    public saveRoleAssignmentUsingPOST(request: Array<models.UserRoleDTO>, extraHttpRequestParams?: any): Observable<models.SaveRoleAssignmentResponse> {
        return this.saveRoleAssignmentUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * saveRole
     * Create new role or update existing role
     * @param request request
     */
    public saveRoleUsingPOST(request: models.RoleDTO, extraHttpRequestParams?: any): Observable<models.SaveRoleResponse> {
        return this.saveRoleUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getProfileRoleForVendor
     * Get manager profile and roles by nric (only Corppass manager, no Intranet manager or servicemen).
     * @param request request
     */
    public validateManageableCorppassUsersUsingPOST(request: models.ValidateManageableUsersRequest, extraHttpRequestParams?: any): Observable<models.ValidateManageableUsersResponse> {
        return this.validateManageableCorppassUsersUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getProfileRole
     * Get manager profile and roles by nric (if not manager, return serviceman profile only).
     * @param request request
     */
    public validateManageableUsersUsingPOST(request: models.ValidateManageableUsersRequest, extraHttpRequestParams?: any): Observable<models.ValidateManageableUsersResponse> {
        return this.validateManageableUsersUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * deleteRoles
     * Delete an existing role
     * @param roleIdList roleIdList
     */
    public deleteRolesUsingPOSTWithHttpInfo(roleIdList: Array<number>, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/roleManagement/roles/delete';

        // verify required parameter 'roleIdList' is not null or undefined
        if (roleIdList === null || roleIdList === undefined) {
            throw new Error('Required parameter roleIdList was null or undefined when calling deleteRolesUsingPOST.');
        }

        return this.httpClient.post(path, roleIdList, { withCredentials: true });
    }

    /**
     * getAllRoles
     * Get all available roles that the user can assign to other user or can update in the system
     * @param type to indicate the request type for the role that need to be retrieved. If it is null, all roles assignable by user will be returned, i.e. default value to 0. Type map: &lt;br&gt;&lt;ul&gt;&lt;li&gt;0: for role assignment&lt;/li&gt; &lt;li&gt;1: for role modification&lt;/li&gt;&lt;/ul&gt;
     */
    public getAllRolesUsingGETWithHttpInfo(type?: number, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/roleManagement/roles';

        let queryParameters = new HttpParams();

        if (type !== undefined) {
            queryParameters = queryParameters.append('type', <any>type);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
    }

    /**
     * savePermissions
     * Create or update permissions for an existing role
     * @param request request
     */
    public savePermissionsUsingPOSTWithHttpInfo(request: models.RoleDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/roleManagement/assignPermissions';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling savePermissionsUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * saveRoleAssignment
     * Save Role Assignment Changes
     * @param request request
     */
    public saveRoleAssignmentUsingPOSTWithHttpInfo(request: Array<models.UserRoleDTO>, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/roleManagement/assignRoles';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveRoleAssignmentUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * saveRole
     * Create new role or update existing role
     * @param request request
     */
    public saveRoleUsingPOSTWithHttpInfo(request: models.RoleDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/roleManagement/roles';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveRoleUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * getProfileRoleForVendor
     * Get manager profile and roles by nric (only Corppass manager, no Intranet manager or servicemen).
     * @param request request
     */
    public validateManageableCorppassUsersUsingPOSTWithHttpInfo(request: models.ValidateManageableUsersRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/roleManagement/validateManageableForVendor';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling validateManageableCorppassUsersUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * getProfileRole
     * Get manager profile and roles by nric (if not manager, return serviceman profile only).
     * @param request request
     */
    public validateManageableUsersUsingPOSTWithHttpInfo(request: models.ValidateManageableUsersRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/roleManagement/validateManageable';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling validateManageableUsersUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

}
