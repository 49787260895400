import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, pluck, share, distinctUntilChanged } from 'rxjs';

@Injectable()
export class SharedSearchService {
  public static SEARCH_STRING = 'SEARCH_STRING';

  /**
   * a flag to denote that current page listens for search
   * so that we dont need to redirect to anywhere
   */
  public currentPage = '';

  private store = new BehaviorSubject<any>({});

  listenFor(prop: string): Observable<any> {
    return this.store.pipe(pluck(prop)).pipe(share()).pipe(distinctUntilChanged());
  }

  update(prop: string, value: any) {
    const state = this.store.getValue();
    this.store.next(Object.assign({}, state, { [prop]: value }));
  }

}
