export const Constants = {

    // tslint:disable-next-line:max-line-length
    EmailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PhoneRegex: /^\d{8}$/,
    PostalCodeRegex: /^\d{6}$/,

    DeliveryMode : {
        SELF_COLLECTION: 1,
        HOME_DELIVERY: 2,
        ELOCKER: 3,
        WALK_IN_SELF_ORDER: 4,
        WALK_IN: 5,
        COLLECT_AT_TAILOR: 6
    },

    OrderStatus : {
        WALK_IN_COLLECTED: 0,
        ADVANCE_CHECKED_OUT: 1,
        SENT_TO_IMS: 2,
        PACKED: 3,
        ARRIVED_AT_COLLECTION_POINT: 4,
        PENDING_EXCHANGE: 5,
        EXCHANGED_IMMEDIATE: 6,
        EXCHANGED_NEW_PREORDER: 7,
        EXCHANGED_OTHER_STORE: 8,
        COLLECTED: 9,
        FORFEITED: 10,
        UNPACKED: 11,
        PACKED_SENT_FOR_DELIVERY: 12,
        EXCHANGED: 13,
        CANCELLED: 14,
        STARTED_PACKING: 15,
        PACKED_FOR_ELOCKER: 16,
        DELIVERED: 17,
        ELOCKER_COLLECTED: 18,
        WALK_IN_ADVANCE_CHECKED_OUT: 19,
        WALK_IN_CHECKED_OUT: 20,
        TAILOR_CHECKED_OUT: 21,
        TAILOR_COLLECTED: 22,
        TAILOR_FORFEITED: 23,
        TAILOR_SELF_COLLECT_CHECKED_OUT: 24,
        NAME_TAG_CHECKED_OUT: 25,
        NAME_TAG_PROCESSING: 26,
        NAME_TAG_FORFEITED: 27,
    },

    ProductCapType : {
        HARD: 1,
        SOFT: 2
    },

    ProductType : {
        NORMAL: 1,
        NAME_TAG: 2,
        TAILOR: 3,
    },

    OrderType : {
        ADVANCED_ORDER: 1,
        WALK_IN: 2,
        TAILOR: 3,
        NAME_TAG: 4
    },

    MeasurementTypeDescription : {
        HEAD_CIRCUM: 'Head Circumference',
        SHOULDER_WIDTH: 'Shoulder Width',
        CHEST: 'Chest',
        WAIST: 'Waist',
        PANTS_LENGTH: 'Pants Length',
        FOOT_LENGTH: 'Foot Length',
        HAND_WIDTH: 'Hand Width'
    },

    DateFormat : {
        REQUEST: 'YYYY-MM-DD',
        RESPONSE: 'DD/MM/YYYY',
        DATEPICKER: 'DD MMM YYYY'
    },

    SessionDurationType : {
        EXTEND: 1,
        EXPIRED: 2
    },

    FAQConstants : {
        list:
            [
                {
                    name: 'Collection Queries',
                    content:
                        [
                            {
                                id: "collectionQuery1",
                                question: 'Where can I collect my items?',
                                answer: 'When confirming your order, you will need to choose your preferred ' +
                                    'collection point from a list of specified locations. The opening hours for ' +
                                    'each collection point are indicated on Home Team eMART. Additionally, you may ' +
                                    'opt for home delivery service for merchandises purchased through eMART online store.' +
                                    '<br><br>' +
                                    'For SPF officers, you may choose to collect your order from your parent unit ' +
                                    'e.g. Land Division HQs, PCG Bases, NPCs, Staff Departments and Specialist Units. ' +
                                    'Alternately, officers can choose to collect your orders from Police Logistics Base @Hemmant Road.' +
                                    '<br><br>' +
                                    'All SCDF officers can choose to collect their orders from HQ SCDF, CDA or any of the 4 Division HQs.' +
                                    '<br><br>' +
                                    'SCDF officers may use the walk-in stores available at the Division HQs to effect an immediate purchase.' +
                                    '<br><br>' +
                                    'All SPS officers can either opt for walk-in at SPS PHQ or home delivery service.' +
                                    '<br><br>' +
                                    'For ICA officers, you may opt for home delivery or self-collection at your parent unit ' +
                                    'e.g. respective Domains’ collection points or ICA HQ. ',
                                state: true
                            },
                            {
                                id: "collectionQuery2",
                                question: 'How will I know when my items are ready for collection?',
                                answer: 'An email and SMS will be sent to you when your items are ready for collection. ' +
                                    'Upon receiving the ready for collection email and SMS, you are to collect your ' +
                                    'items within 30 calendar days; uncollected order and credits will be forfeited.',
                                state: true
                            },
                            {
                                id: "collectionQuery3",
                                question: 'What is the purpose of the one-time code?',
                                answer: 'A One-Time code is generated and provided in the email and SMS when the order is ' +
                                    'ready for collection. The One-Time code is for verification upon collection of items.',
                                state: true
                            },
                            {
                              id: "collectionQuery4",
                              question: 'Can someone collect items on my behalf?',
                              answer: 'Yes, you may nominate another individual to collect your items at the eMART ' +
                                'collection point for you and he/she must have the One-Time code and your NRIC number ' +
                                'to collect the items on your behalf.',
                              state: true,
                            },
                            {
                              id: "collectionQuery5",
                              question: 'Where can NSMEN dispose used uniform?',
                              answer: 'Disposal bins for unwanted SCDF/SPF issued uniform/attire/head gear/footwear' +
                                'accoutrements are available at the HTNS Clubhouses (i.e. Bukit Batok, Khatib & Bedok). ' +
                                'HT NSmen can dispose these items when they visit the HTNS clubhouses.',
                              state: true,
                            }
                        ]
                },
                {
                    name: 'Credit Queries',
                    content:
                        [
                            {
                                id: "CreditQueries1",
                                question: 'How much credit do I get each cycle?',
                                answer: "Credits are pegged to your service status and vocation. When using Home Team eMART, you may check " +
                                    "your account's credit balance through the portal. Please refer to the following table for your credit entitlements." + "<br><br>" +
                                    '<div class=""responsive-overlay"" dir=""ltr"">' +
                                    '<div class="responsive-overlay"><table class="table table-bordered vert-middle">' +
                                    '<thead>' +
                                    '<tr class="bg-primary">' +
                                    '<th>Workforce</th>' +
                                    '<th>Credit&nbsp;Entitlement</th>' +
                                    '<th>Cycle Length</th>' +
                                    '</tr>' +
                                    '</thead>' +
                                    '<tbody class="bg-white">' +
                                    '<tr>' +
                                    '<td>Regulars &nbsp;&amp; NSF (Operational)</td>' +
                                    '<td>$260</td>' +
                                    '<td>1 year</td>' +
                                    '</tr>' +
                                    '<tr>' +
                                    '<td>Regulars &nbsp;&amp; NSF (Non-Operational)</td>' +
                                    '<td>$170</td>' +
                                    '<td>1 year</td>' +
                                    '</tr>' +
                                    '<tr>' +
                                    '<td>Active NSmen</td>' +
                                    '<td>$120</td>' +
                                    '<td>2 year</td>' +
                                    '</tr>' +
                                    '</tbody>' +
                                    '</table></div>' +
                                    '</div>',
                                state: true
                            },
                            {
                                id: "CreditQueries2",
                                question: 'How long are my credits valid for? ',
                                answer: 'Your credits are valid for two cycles. In other words, allocated credits can be used for two years ' +
                                    'for Regulars & NSFs, and four years for NSmen respectively. Credits that remain unused at the end of ' +
                                    'the validity period will be forfeited.',
                                state: true
                            },
                            {
                                id: "CreditQueries3",
                                question: 'When will my credit top-up dates be?',
                                answer: 'Please see the table below for your top-up dates.' + '<br><br>' +
                                    '<div class="responsive-overlay" dir="ltr">' +
                                    '<div class="responsive-overlay"><table class="table table-bordered vert-middle">' +
                                    '<thead>' +
                                    '<tr class="bg-primary">' +
                                    '<th>Workforce</th>' +
                                    '<th>Credit Top-up Dates</th>' +
                                    '</tr>' +
                                    '</thead>' +
                                    '<tbody class="bg-white">' +
                                    '<tr>' +
                                    '<td>Regulars</td>' +
                                    '<td>Credit top-ups take place on 03 November every year. For Regulars joined <u>after</u> 03 ' +
                                    'November 2014 will receive top-ups on their join date.</td>' +
                                    '</tr>' +
                                    '<tr>' +
                                    '<td>NSFs</td>' +
                                    '<td>' +
                                    '<p>NSFs enlisted <u>before</u> 03 November 2014 will receive top-ups on the launch date of Home ' +
                                    'Team eMART for their respective divisions.</p>' +
                                    '<p>NSFs enlisted <u>after</u> 03 November 2014 will receive top-ups on their enlistment date.</p>' +
                                    '</td>' +
                                    '</tr>' +
                                    '<tr>' +
                                    '<td>Active NSmen</td>' +
                                    '<td>' +
                                    '<p>Active NSmen whose first ICT reporting date falls before launch date of Home Team eMART for their ' +
                                    'Units will receive top-ups once every 2 years on the Home Team eMART launch date for their units.</p>' +
                                    '<p>Active NSmen who will report for their first ICT after launch date of Home Team eMART for their Units ' +
                                    'will receive top-ups once every 2 years on their posting date.</p>' +
                                    '</td>' +
                                    '</tr>' +
                                    '</tbody>' +
                                    '</table></div>' +
                                    '</div>',
                                state: true
                            },
                            {
                                id: "CreditQueries4",
                                question: 'Why is my credit top-up less than my credit entitlement? ',
                                answer: 'Your credits will be pro-rated if your balance of service (e.g. ORD/Resignation/Dismissal/Retirement) or next top-up ' +
                                    'is less than one cycle from the current top-up date.',
                                state: true
                            },
                            {
                                id: "CreditQueries5",
                                question: 'What happens to my credits when I leave service? ',
                                answer: 'Credits remaining at the end of service (e.g. ORD/Resignation/Dismissal/Retirement) will expire and invalidated.',
                                state: true
                            },
                            {
                                id: "CreditQueries6",
                                question: 'I am an NSF, what happens to my credits after my Operationally Ready Date (ORD)?',
                                answer: 'Your credits will be invalidated after your ORD. Subsequently, you will be allocated a new set of credits as per ' +
                                    'the NSmen workforce credit allocation policy.',
                                state: true
                            },
                            {
                                id: "CreditQueries7",
                                question: 'What happens if my vocation changes?',
                                answer: 'When a non-operational officer is posted to an operational vocation, he will receive an ad-hoc credit top-up to ' +
                                    'reflect his heightened need for credits. Future credit allocations will be made according to the credit policies ' +
                                    'pegged to his new vocation.' + '<br><br>' + 'However, no ad-hoc credit deduction will be performed for operational ' +
                                    'officers who are posted to a non-operational vocation. Only future credit allocations (i.e. during the next credit ' +
                                    'allocation cycle) will be adjusted accordingly.',
                                state: true
                            },
                            {
                                id: "CreditQueries8",
                                question: 'Why do credit entitlements differ at various vocations? ',
                                answer: 'Credits are allocated for you to replace personal equipping. The credit  entitlements have been scaled to reflect ' +
                                    'the different levels of demand at different vocations.',
                                state: true
                            },
                            {
                                id: "CreditQueries9",
                                question: 'Under what circumstances would I not be entitled to credits, or have my credits invalidated?',
                                answer: 'You will not be entitled to credits, and any unused credits will be withdrawn, if you are:' + '<br><br>' +
                                    '<ul dir="ltr">' +
                                    '<li style="margin-bottom: 0.0001pt;"><span style="font-size:12.0pt;">Posted to an inactive/holding unit</span></li>' +
                                    '<li style="margin-bottom: 0.0001pt;"><span style="font-size:12.0pt;">Completed Operationally Ready National Service ' +
                                    '(ORNS) Cycle, or Reached the Statutory Age, whichever is earlier</span></li>' +
                                    '<li style="margin-bottom: 0.0001pt;"><span style="font-size:12.0pt;">Retired or Discharged from Service</span></li>' +
                                    '<li style="margin-bottom: 0.0001pt;"><span style="font-size:12.0pt;">Regular Officer changing from Uniformed to ' +
                                    'non-Uniformed service</span></li>' +
                                    '</ul>',
                                state: true
                            },
                            {
                                id: "CreditQueries10",
                                question: 'If my service scheme changes from Regular to NSmen/NSF, or vice versa, will I be able to recover any unused credits?',
                                answer: 'No, any remaining credits will expire and invalidated. However, you will be allocated a new set of credits based on ' +
                                    'your new service scheme.',
                                state: true
                            },
                            {
                                id: "CreditQueries11",
                                question: 'I hold a secondary appointment (e.g. SWTT officer) which requires me to indent additional combat uniform. will I be ' +
                                    'given additional credits?',
                                answer: 'No additional credits will be given. Officers shall manage their credits prudently to ensure necessary equipping.',
                                state: true
                            },
                            {
                                id: "CreditQueries12",
                                question: 'Can I transfer credits to other officers or exchange credits for cash/other benefits?',
                                answer: 'No.',
                                state: true
                            },
                            {
                                id: "CreditQueries13",
                                question: 'Can I use my own cash to pay or top up for items on Shop at eMART (Home Team eMART)?',
                                answer: 'No.' + '<br><br>',
                                state: true
                            },
                            {
                                id: "CreditQueries14",
                                question: 'Can I request for an ad-hoc or early allocation of credits?',
                                answer: 'No, credit top-ups will only be done based on the credit allocation cycle assigned to you. ' +
                                    'Therefore, you are advised to spend your credit wisely.',
                                state: true
                            },
                            {
                                id: "CreditQueries15",
                                question: 'What are some examples of credit misuse?',
                                answer: 'Credits are allocated for your personal equipping only. Hence, using credits for any other purposes ' +
                                    'would be deemed as a misuse of credit. Examples include reselling items from Home Team eMART, buying ' +
                                    'excessive and unnecessary quantity of items. Such actions may prompt a disciplinary investigation.',
                                state: true
                            },
                            {
                                id: "CreditQueries16",
                                question: 'Is there a limit on the quantity of each item that I can purchase each cycle?',
                                answer: 'Yes, a purchase quantity limit is imposed on some products and is reset every calendar year. This is ' +
                                    'to prevent abuse and to ensure a sufficient supply of items across the force.' + '<br><br>' +
                                    'For some other products, if an officer ordered an unusually large quantity of a particular item he will ' +
                                    'be prompted to reconsider making the purchase. Should he proceed with confirming the order, an email ' +
                                    'notification will be forwarded to the administrator and an investigative proceeding may be initiated.  ',
                                state: true
                            },
                            {
                                id: "CreditQueries17",
                                question: 'How do I know when my credits are expiring?',
                                answer: 'Yes, you will receive an email notification 3 months before the credit expiry date.',
                                state: true
                            },





                        ]
                },

                {
                    name: 'Exchange Policy',
                    content:
                        [
                            {
                                id: "ExchangePolicy1",
                                question: 'My items are not satisfactory can I make an exchange?',
                                answer: 'If the items you receive are faulty or of wrong size, you may make a one-time exchange within 7 ' +
                                    'calendar days from the date of order collection. Otherwise, all orders will be considered as final ' +
                                    'and no further exchanges will be permitted.' + '<br><br>' + 'Goods for exchange must not be used or worn. ' +
                                    'It must be in the original packaging with all the labels and tags intact, if any.' + '<br><br>' +
                                    'You will need to return goods to the initial eMART collection point to initiate the exchange process.' + '<br><br>' +
                                    '<p dir="ltr" style="margin: 0cm 0cm 0pt;"><span style="color: rgb(0, 0, 0);">' +
                                    '<span style="font-family: Arial,Helvetica,sans-serif;"><b><font size="3">Please note that lead time is ' +
                                    'required to process your exchange request, should there be no available stock for exchange at the designated ' +
                                    'eMART collection point. </font></b></span></span></p>' + '<br>' + 'Officers are encouraged to try on and ' +
                                    'check their items at the collection point.',
                                state: true
                            },
                        ]
                },
                {
                    name: 'Portal Queries',
                    content:
                        [
                            {
                                id: "PortalQueries1",
                                question: 'How can I login to Home Team eMART?',
                                answer: 'Home Team eMART can be accessed via SingPass login.',
                                state: true
                            },
                            {
                                id: "PortalQueries2",
                                question: 'What should I do if I have forgotten my login details?',
                                answer: 'As the login is via Singpass, you should contact Singpass or as per Singpass process to reset your login details.',
                                state: true
                            },
                            {
                                id: "PortalQueries3",
                                question: 'Can I track the status of my order?',
                                answer: 'Yes, you can do so by logging into the Home Team eMART portal.',
                                state: true
                            },
                            {
                                id: "PortalQueries4",
                                question: 'Can I check my transaction history? ',
                                answer: 'Yes, you can do so by logging into the Home Team eMART portal.',
                                state: true
                            },
                            {
                                id: "PortalQueries5",
                                question: 'What should I do if I encounter any problems? For instance, my credit allocation is wrong; I have a special sizing ' +
                                    'requirement; my account has been hacked, etc?',
                                answer: 'You are encouraged to go through the FAQs on Home Team eMART to resolve initial issues.' + '<br>' +
                                    'For further assistance, you may contact us via email: contact@ns.gov.sg' + '<br>' +
                                    'You may also use the Home Team eMART feedback channel.' +
                                    '<br><br>' +
                                    'For SPS officers, please contact SPS_STL_EMART@pris.gov.sg' +
                                    '<br><br>' +
                                    'For ICA officers, please contact eMART_Feedback@ica.gov.sg',
                                state: true
                            },
                        ]
                },
                {
                    name: 'Walk-in purchases',
                    content:
                        [
                            {
                                id: "Walk-InPurchases1",
                                question: 'Why must Warrant Card or HTNS 11B be produced for purchases using credit?',
                                answer: 'This is to enable store staff to verify the identity of SPF / SCDF personnel. ' +
                                    'Other forms of identification such as NRIC and Driver’s License do not contain information ' +
                                    'regarding your status as a SPF / SCDF personnel.',
                                state: true
                            },
                        ]
                },
                {
                    name: 'Browsing Experience',
                    content:
                        [
                            {
                                id: "BrowsingExperience1",
                                question: 'What are the recommended browsers for the site?',
                                answer: 'We have optimized the site to look best on the following browsers with 1280 x 800 pixels screen resolution:<br>' +
                                    '<ul dir="ltr">' +
                                    '<li>Google Chrome v51&nbsp;and above</li>' +
                                    '<li>Safari v9 and above</li>' +
                                    '<li>Edge v13 and above</li>' +
                                    '</ul>',
                                state: true
                            },
                            {
                                id: "BrowsingExperience2",
                                question: 'What should I do if I am using one of the recommended browsers but the site is not displaying correctly?',
                                answer: 'Please clear your browser cache. This simple step can often resolve display issues. If you are accessing the site via Proxy ' +
                                    'or Firewall, contact your IT department regarding available proxy ports.<br><br>' +
                                    'If the problem persists, please report your issue and provide the following details:<br>' +
                                    '<ul dir="ltr">' +
                                    '<li>Browser information</li>' +
                                    '<li>Details of error messages</li>' +
                                    '<li>Screenshots of the issue</li>' +
                                    '</ul>',
                                state: true
                            },
                            {
                                id: "BrowsingExperience3",
                                question: 'What should I do when the webpage loads slowly or hangs?',
                                answer: 'The browser may be doing too much work, causing the webpage to load slowly or hang. Please close all browsers, open a new ' +
                                    'browser and try loading the webpage again. <br><br>If the problem persists, the issue is likely because your Internet Service ' +
                                    'Provider, Local Area Network or our system is facing intermittent slow response.',
                                state: true
                            },
                        ]
                },
                {
                    name: 'Singpass',
                    content:
                        [
                            {
                                id: "Singpass1",
                                question: 'How do I register for my Singpass?',
                                answer: '<div class="panel-body">' +
                                    '<p dir="ltr">For local users:</p>' +
                                    '<p dir="ltr">(a) Access <a href="https://www.singpass.gov.sg/home/ui/register/instructions" target="_blank" title="" rel="noopener noreferrer">' +
                                    'Register&nbsp;for&nbsp;Singpass</a> function on the Singpass website. Please note that you will receive your pin mailer password via mail (to ' +
                                    'your registered address) within four working days.</p>' +
                                    '<p dir="ltr">(b) If you require further assistance, you may visit the nearest <a href="https://www.singpass.gov.sg/home/ui/counter-locations" ' +
                                    'target="_blank" title="" rel="noopener noreferrer">Singpass&nbsp;Counter</a> with the necessary documents for verification and request to ' +
                                    'register a new Singpass account.</p>' +
                                    '<p dir="ltr">For overseas users:</p>' +
                                    '<p dir="ltr">If you are currently overseas, you can access Register for Singpass function on the Singpass website. You will receive your pin ' +
                                    'mailer password via mail (to your registered address). Please note that there will be a longer delivery time to overseas mails.</p>' +
                                    '<p dir="ltr">If you need any clarification or have queries about your Singpass account, you can also contact the Singpass Helpdesk at ' +
                                    '+65 6335 3533 or mail to <a href="mailto:support@singpass.gov.sg" target="_blank" title="" rel="noopener noreferrer">support@singpass.gov.sg</a></p>' +
                                    '</div>',
                                state: true
                            },
                            {
                                id: "Singpass2",
                                question: 'I have forgotten my password. What do I do?',
                                answer: '<div class="panel-body">' +
                                    '<p dir="ltr">You will need to reset your Singpass password via one of the following options:</p>' +
                                    '<p dir="ltr">(a) Access the <a href="https://www.singpass.gov.sg/home/ui/online-reset-password/user-detail" target="_blank" title="" ' +
                                    'rel="noopener noreferrer">Reset&nbsp;Password</a> function on the Singpass website. Please note that this is applicable only if you ' +
                                    'have registered your contact details and set up your security questions and answers.</p>' +
                                    '<p dir="ltr">(b) If you have forgotten your security answers or are unable to complete the above process, you can request for a pin ' +
                                    'mailer password online by accessing the&nbsp;function on the Singpass website. You will receive the pin mailer password via mail ' +
                                    '(to your registered address) within four working days for local addresses.</p>' +
                                    '<p dir="ltr">(c) If you require further assistance, you can visit the nearest <a href="https://www.singpass.gov.sg/home/ui/counter-locations" ' +
                                    'target="_blank" title="" rel="noopener noreferrer">Singpass&nbsp;Counter</a> with the necessary documents for verification and request to ' +
                                    'reset your Singpass password.</p>' +
                                    '<p dir="ltr">To ensure that you can reset your password online in the future, please update your contact details and security questions ' +
                                    'by accessing the Update Account Details function on the Singpass website upon your next login.</p>' +
                                    '</div>',
                                state: true
                            },
                            {
                                id: "Singpass3",
                                question: 'I have forgotten my Singpass ID. How do I retrieve it?',
                                answer: '<div class="panel-body">' +
                                    '<p dir="ltr">You can retrieve your Singpass ID via one of the following options:</p>' +
                                    '<p dir="ltr">(a) Access the <a href="https://www.singpass.gov.sg/home/ui/retrieve-account/retrieve-singpass-id" target="_blank" title="" ' +
                                    'rel="noopener noreferrer">Retrieve&nbsp;Singpass&nbsp;ID</a> function on the Singpass website and enter your personal information and ' +
                                    'registered contact details. After successful verification, your SingPass ID will be sent to your mobile phone or email address.</p>' +
                                    '<p dir="ltr">(b) If you require further assistance, you may visit the nearest <a href="https://www.singpass.gov.sg/home/ui/counter-locations" ' +
                                    'target="_blank" title="" rel="noopener noreferrer">Singpass&nbsp;Counter</a> with the necessary documents for verification and request to ' +
                                    'retrieve your Singpass ID.</p>' +
                                    '<p dir="ltr">If you need any clarification or have queries about your Singpass account, you can contact the Singpass Helpdesk at +65 6335 3533 ' +
                                    'or mail to <a href="mailto:support@singpass.gov.sg" target="_blank" title="" rel="noopener noreferrer">support@singpass.gov.sg</a></p>' +
                                    '</div>',
                                state: true
                            },
                            {
                                id: "Singpass4",
                                question: 'What if I need my Singpass urgently?',
                                answer: '<div class="panel-body">' +
                                    '<p dir="ltr">You can get your Singpass immediately if you apply at the <a href="https://www.singpass.gov.sg/home/ui/counter-locations" ' +
                                    'target="_blank" title="" rel="noopener noreferrer">Singpass&nbsp;Counter</a>. Alternatively, if you apply online, it will require 4 days ' +
                                    'for you to get your Singpass through pin mailer.</p>' +
                                    '</div>',
                                state: true
                            },
                            {
                                id: "Singpass5",
                                question: 'What is 2-Step Verification (2FA)?',
                                answer: '2-Step Verification, otherwise known as Two Factor Authentication (2FA), is a security process where a user provides two means of ' +
                                    'identification to log into an account. The first layer of identification is a user ID and password, while the second layer of ' +
                                    'identification is a unique One-Time Password (OTP) obtained via SMS or OneKey token.<br><br>' +
                                    '2-step Verification will apply to selected government e-Services, which require a higher level of assurance or involve sensitive ' +
                                    'information. This security enhancement helps to confirm your identity and better protect your Singpass account.',
                                state: true
                            },
                            {
                                id: "Singpass6",
                                question: 'What is a Singpass?',
                                answer: 'Singapore Personal Access (or Singpass), launched in March 2003, is a gateway to hundreds of e-services offered by more than 60 ' +
                                    'government agencies, enabling users to only have to remember one password when connecting and transacting with the Government.',
                                state: true
                            },
                        ]
                },
                {
                    name: 'Troubleshooting',
                    content:
                        [
                            {
                                id: "Troubleshooting1",
                                question: 'What should I do when I encounter an error page when trying to access certain pages?',
                                answer: 'Please report your issue and provide the following details:<br>' +
                                    '<ul dir="ltr">' +
                                    '<li>Date and Time of the access</li>' +
                                    '<li>Page that you were trying to access</li>' +
                                    '<li>Details of the error messages</li>' +
                                    '<li>Browser version</li>' +
                                    '<li>Internet Service Provider</li>' +
                                    '</ul>',
                                state: true
                            },
                            {
                                id: "Troubleshooting2",
                                question: 'How do I clear my browser cache and cookies?',
                                answer: '<div class="panel-body">' +
                                    '<p dir="ltr">For Google Chrome v51 and above</p>' +
                                    '<ul dir="ltr">' +
                                        '<li>Go to "Settings"</li>' +
                                        '<li>Click on “History”</li>' +
                                        '<li>Click on “Clear browsing data”</li>' +
                                        '<li>Ensure that “Cookies and other site and plugin data” and “Cached images and files” are checked</li>' +
                                        '<li>Go to "Clear browsing data"</li>' +
                                        '<li>Ensure that "Cached images and files" is checked</li>' +
                                        '<li>Click on "Clear browsing data"</li>' +
                                    '</ul>' +
                                    '<p dir="ltr">For Safari v9&nbsp;and above</p>' +
                                    '<ul dir="ltr">' +
                                        '<li>Go to “Preferences”</li>' +
                                        '<li>Click on “Privacy” tab</li>' +
                                        '<li>Click on “Remove All Website Data”</li>' +
                                        '<li>Click on “Remove Now”</li>' +
                                    '</ul>' +
                                    '<p dir="ltr">For Microsoft Edge v13 and above</p>' +
                                    '<ul dir="ltr">' +
                                        '<li>Click on 3-lined Hub button in the top right corner of your browser</li>' +
                                        '<li>Click on the clock-shaped History button</li>' +
                                        '<li>Right beside "History", click on "Clear all history"</li>' +
                                        '<li>Ensure that "Browsing history", "Cookies and saved website data" and "Cached data and files" are checked</li>' +
                                        '<li>Click on "Clear"</li>' +
                                    '</ul>' +
                                    '</div>',
                                state: true
                            },
                        ]
                }
            ]
    },

    HttpStatus: {
        UNAUTHORIZED: 401,
        FORBIDDEN: 403,
        BAD_REQUEST: 400,
        ENTITY_NOT_FOUND: 404,
        INTERNAL_SERVER_ERROR: 500
    },

    // For Broadcast Messages
    BROADCAST_MESSAGES: {
        LOGOUT: {
            type: 'logout',
            payload: 'Sessopm was logout successfully'
        },
        EXTEND:{
            type:'sessionExtend',
            payload:'Session was extended successfully'
        }
    },

    MAT_DATEPICKER_FORMATS: {
        MONTH_PICKER: {
            parse: {
                dateInput: ['MM/YYYY','YYYY/MM', 'MMM YYYY', 'YYYY MMM'],
            },
            display: {
                dateInput: 'MMM YYYY',
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY',
            },
        },
        DAY_PICKER: {
            parse: {
                dateInput: ['DD/MM/YYYY', 'MM/DD/YYYY', 'YYYY/MM/DD', 'DD MMM YYYY', 'MMM DD YYYY'],
            },
            display: {
                dateInput: 'DD MMM YYYY',
                monthYearLabel: 'MMM YYYY',
                dateA11yLabel: 'LL',
                monthYearA11yLabel: 'MMMM YYYY',
            },
        }
    },

    AuditLoginAction: {
        LOGIN: 'LOGIN',
        LOGOUT: 'LOGOUT',
        SESSION_TIMEOUT: 'SESSION_TIMEOUT'
    }
}
