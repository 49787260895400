import { Observable, of, forkJoin, catchError } from 'rxjs';
import { Router, ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserProfileService } from './../../services/user-profile.service';
import { StoreConfigService } from './../../services/store-config.service';
import { ProductsService } from './../../services/products.service';
import { WaitlistService } from './../../services/waitlist.service';
import { Constants } from './../../shared/global-constants/constants';

@Injectable()
export class WaitlistGuard implements Resolve<any> {

  constructor(
    private router: Router,
    private userProfileService: UserProfileService,
    private storeConfigService: StoreConfigService,
    private productService: ProductsService,
    private waitlistService: WaitlistService) {
  }

  resolve(route: ActivatedRouteSnapshot) {
    const $obs = forkJoin({
      waitListDetail: this.waitlistService.getWaitlistDetail(),
      products: this.productService.loadProducts(Constants.ProductType.NORMAL),
      productsCaps: this.productService.loadProductCaps()
    }).pipe(catchError(err => {
      this.router.navigate(['/general-error']);
      return of(err);
    }));
      
    return $obs;
  }

}
