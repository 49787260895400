import { Injectable } from '@angular/core';
import { CanActivate, RouterStateSnapshot, Router, Resolve } from '@angular/router';
import { map, Observable } from 'rxjs';
import { CurrentOrdersService } from '../../services/current-orders.service';

@Injectable()
export class OrderDetailPrintGuard implements Resolve<boolean> {

  constructor(
    private router: Router,
    public currentOrdersService: CurrentOrdersService) {
  }

  resolve() {
    if (this.currentOrdersService.orderPrint.order) {
      return true;
    } else {
      this.router.navigate([this.currentOrdersService.orderPrint.prevPath]);
      return false;
    }
  }

}
