import { CartService } from './cart.service';
import { Injectable } from '@angular/core';
import { NotificationsService } from 'assets/lib/angular2-notifications';
import { OrdersApi } from 'app/shared/backend-api/emart';
import { Observable } from 'rxjs';

import { CartApi } from './../shared/backend-api/emart/api/CartApi';
import { CommonApi } from './../shared/backend-api/emart/api/CommonApi';
import { CatalogueApi } from './../shared/backend-api/emart/api/CatalogueApi';
import {
  CartDTO, CartItemDTO, UserProductDTO, GetProductCapsResult
} from './../shared/backend-api/emart/model/models';
import { Constants } from './../shared/global-constants/constants';
import { CommonUtils } from './../shared/utilities/common-utils';
import { ProductsService } from './products.service';
import { UserProfileService } from './user-profile.service';
import naturalSort from 'javascript-natural-sort';

@Injectable()
export class NameTagCartService {

  private DeliveryMode = Constants.DeliveryMode;

  constructor(
    private orderApi: OrdersApi,
    private cartApi: CartApi,
    private commonApi: CommonApi,
    private catalogueApi: CatalogueApi,
    private cartService: CartService,
    private productsService: ProductsService,
    private userProfileService: UserProfileService,
    private notificationService: NotificationsService
  ) {
    this.productsService.loadProducts(Constants.ProductType.NAME_TAG);
  }

  addItemToCart(productId: number, qty: number) {
    qty = Number(qty);

    if (qty == null || qty <= 0) {
      this.notificationService.warn(
        'Shopping Cart',
        'Invalid quantity'
      )
      return;
    }

    const product = this.cartService.getNameTagProduct(productId);

    const nameTagItems: Array<CartItemDTO> = [];
    const item: CartItemDTO = {};
    item.productId = product.productId;
    item.skuId = product.skus[0].skuId;
    item.quantity = qty;

    nameTagItems.push(item);

    this.addItemsToCart(nameTagItems);
  }

  addOrderItemsToCart(items?: Array<any>) {
    const nameTagItems: Array<CartItemDTO> = [];

    for (const item of items) {
      const product = this.cartService.getNameTagProduct(item.productId);

      const itemN: CartItemDTO = {};
      itemN.productId = product.productId;
      itemN.skuId = product.skus[0].skuId;
      itemN.quantity = item.quantity;

      nameTagItems.push(itemN);
    }

    this.addItemsToCart(nameTagItems);
  }

  addItemsToCart(nameTagItems?: Array<CartItemDTO>) {
    this.cartService.getCartDetail().subscribe(data => {
      if (this.cartService.shoppingCart.items != null && this.cartService.shoppingCart.items.length > 0) {
        this.notificationService.warn(
          'Shopping Cart',
          'Name tags and catalogue items cannot be in the same shopping cart'
        )
        return;
      }
      if (this.cartService.shoppingCart.tailorItems != null && this.cartService.shoppingCart.tailorItems.length > 0) {
        this.notificationService.warn(
          'Shopping Cart',
          'Name tags and tailored uniforms cannot be in the same shopping cart'
        )
        return;
      }

      let tempCartItems = CommonUtils.cloneDeep(this.cartService.shoppingCart.nameTagItems);
      if (tempCartItems == null) {
        tempCartItems = [];
      }

      let existing: boolean;
      const itemsToBeAdded = nameTagItems;

      const modifiedGroupIds: Array<Number> = [];

      for (const item of itemsToBeAdded) {
        existing = false;

        for (const groupId of this.cartService.getNameTagProduct(item.productId).productGroupIds) {
          modifiedGroupIds.push(groupId);
        }

        for (const tempCartItem of tempCartItems) {
          if (Number(tempCartItem.productId) === Number(item.productId)) {
            tempCartItem.quantity = Number(tempCartItem.quantity) + Number(item.quantity);
            existing = true;
            break;
          }
        }

        if (!existing) {
          tempCartItems.push(item);
        }
      }

      this.cartService.checkCapAndCreditBalanceAndSaveCart(Constants.ProductType.NAME_TAG, tempCartItems, modifiedGroupIds);
    });
  }

  updateCartItemQty(productId: number, qty: number) {
    productId = Number(productId);
    qty = Number(qty);

    if (productId == null || productId <= 0) {
      this.notificationService.warn(
        'Shopping Cart',
        'Invalid name tag'
      )
      return;
    }
    if (qty == null || qty <= 0) {
      this.notificationService.warn(
        'Shopping Cart',
        'Invalid quantity'
      )
      return;
    }

    this.cartService.loadTempShoppingCart = true;
    this.cartService.getCartDetail().subscribe(() => {
      this.cartService.loadTempShoppingCart = false;
      const tempCartItems = this.cartService.tempShoppingCart.nameTagItems;

      const modifiedGroupIds: Array<Number> = [];

      for (const item of tempCartItems) {
        if (item.productId === productId) {
          item.productId = productId;
          item.quantity = qty;
          for (const groupId of this.cartService.getNameTagProduct(item.productId).productGroupIds) {
            modifiedGroupIds.push(groupId);
          }
          break;
        }
      }

      this.cartService.checkCapAndCreditBalanceAndSaveCart(Constants.ProductType.NAME_TAG, tempCartItems, modifiedGroupIds);
    });
  }

  removeItemFromCart(productId: number) {
    productId = Number(productId);

    if (productId == null || productId <= 0) {
      this.notificationService.warn(
        'Shopping Cart',
        'Invalid name tag'
      )
      return;
    }

    this.cartService.getCartDetail().subscribe(() => {
      const tempCartItems = CommonUtils.cloneDeep(this.cartService.shoppingCart.nameTagItems);

      for (let i = 0; i < tempCartItems.length; i++) {
        if (tempCartItems[i].productId === productId) {
          tempCartItems.splice(i, 1);
          break;
        }
      }

      this.cartService.checkCapAndCreditBalanceAndSaveCart(Constants.ProductType.NAME_TAG, tempCartItems, []);
    });
  }

}
