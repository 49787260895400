/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class TailoringManagementApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient) {}

    /**
     * deleteTailors
     * Delete tailors
     * @param locationIds locationIds
     */
    public deleteTailorUsingPOST(locationIds: Array<number>, extraHttpRequestParams?: any): Observable<models.DeleteTailorsResponse> {
        return this.deleteTailorUsingPOSTWithHttpInfo(locationIds, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * deleteUniforms
     * Delete list of uniforms with the given product id.
     * @param request Should contain the list of product ids to be deleted.
     */
    public deleteUniformsWithIdsUsingPOST(request: models.DeleteUniformRequest, extraHttpRequestParams?: any): Observable<models.DeleteUniformResponse> {
        return this.deleteUniformsWithIdsUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getTailorUniforms
     * Get Tailor&#39;s Uniforms
     * @param id to set the tailorID to be retrieved.
     * @param page to set the index of pages to retrieve the products. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     */
    public getTailorUniformsUsingGET(id: number, page: number, size: number, extraHttpRequestParams?: any): Observable<models.GetTailorUniformsResponse> {
        return this.getTailorUniformsUsingGETWithHttpInfo(id, page, size, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * GetTailorsForAdmin
     * Get tailors for Admin
     */
    public getTailorsForAdminUsingGET(extraHttpRequestParams?: any): Observable<models.GetTailorsForAdminResponse> {
        return this.getTailorsForAdminUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getUniforms
     * Get Uniforms
     * @param page to set the index of pages to retrieve the products. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param skuId to set the product skuID to be retrieved. If it is set, the skuID must be matched. If other params is not null, they are all need to be matched the corresponding products as well.
     * @param name to set the product name to be retrieved. If it is set, the name must be matched fully or partially. If other params is not null, they are all need to be matched the corresponding products as well.
     * @param capType to set the product cap type for filtering.&lt;br&gt;&lt;ul&gt;&lt;li&gt;0: N.A&lt;/li&gt; &lt;li&gt;1: Hard&lt;/li&gt; &lt;li&gt;2: Soft&lt;/li&gt;&lt;/ul&gt;
     * @param status to set the product status for filtering.&lt;br&gt;&lt;ul&gt;&lt;li&gt;A: Active&lt;/li&gt; &lt;li&gt;I: Inactive&lt;/li&gt;&lt;/ul&gt;
     */
    public getUniformsUsingGET(page: number, size: number, skuId?: string, name?: string, capType?: number, status?: string, extraHttpRequestParams?: any): Observable<models.GetUniformsResponse> {
        return this.getUniformsUsingGETWithHttpInfo(page, size, skuId, name, capType, status, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * saveTailorUniforms
     * Save Tailor&#39;s Uniforms
     * @param id to set the tailorID to be retrieved.
     * @param tailorProducts tailorProducts
     */
    public saveTailorUniformsUsingPOST(id: number, tailorProducts: Array<models.TailorProductDTO>, extraHttpRequestParams?: any): Observable<models.SaveTailorUniformsResponse> {
        return this.saveTailorUniformsUsingPOSTWithHttpInfo(id, tailorProducts, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * saveTailor
     * Save new or update existed tailor
     * @param request Request body for save tailor.
     */
    public saveTailorUsingPOST(request: models.LocationDTO, extraHttpRequestParams?: any): Observable<models.SaveTailorResponse> {
        return this.saveTailorUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * SaveUniform
     * Add new or update uniform
     * @param request Request body to save uniform.
     */
    public saveUniformUsingPOST(request: models.ProductDTO, extraHttpRequestParams?: any): Observable<models.SaveUniformResponse> {
        return this.saveUniformUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * deleteTailors
     * Delete tailors
     * @param locationIds locationIds
     */
    public deleteTailorUsingPOSTWithHttpInfo(locationIds: Array<number>, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/tailoringManagement/tailors/delete';

        // verify required parameter 'locationIds' is not null or undefined
        if (locationIds === null || locationIds === undefined) {
            throw new Error('Required parameter locationIds was null or undefined when calling deleteTailorUsingPOST.');
        }

        return this.httpClient.post(path, locationIds, { withCredentials: true });
    }

    /**
     * deleteUniforms
     * Delete list of uniforms with the given product id.
     * @param request Should contain the list of product ids to be deleted.
     */
    public deleteUniformsWithIdsUsingPOSTWithHttpInfo(request: models.DeleteUniformRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/tailoringManagement/uniforms/delete';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling deleteUniformsWithIdsUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * getTailorUniforms
     * Get Tailor&#39;s Uniforms
     * @param id to set the tailorID to be retrieved.
     * @param page to set the index of pages to retrieve the products. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     */
    public getTailorUniformsUsingGETWithHttpInfo(id: number, page: number, size: number, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/tailoringManagement/tailors/${id}/uniforms'
            .replace('${' + 'id' + '}', String(id));

        let queryParameters = new HttpParams();

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getTailorUniformsUsingGET.');
        }
        // verify required parameter 'page' is not null or undefined
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getTailorUniformsUsingGET.');
        }
        // verify required parameter 'size' is not null or undefined
        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling getTailorUniformsUsingGET.');
        }
        if (page !== undefined) {
            queryParameters = queryParameters.append('page', <any>page);
        }

        if (size !== undefined) {
            queryParameters = queryParameters.append('size', <any>size);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
    }

    /**
     * GetTailorsForAdmin
     * Get tailors for Admin
     */
    public getTailorsForAdminUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/tailoringManagement/tailors';

        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getUniforms
     * Get Uniforms
     * @param page to set the index of pages to retrieve the products. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param skuId to set the product skuID to be retrieved. If it is set, the skuID must be matched. If other params is not null, they are all need to be matched the corresponding products as well.
     * @param name to set the product name to be retrieved. If it is set, the name must be matched fully or partially. If other params is not null, they are all need to be matched the corresponding products as well.
     * @param capType to set the product cap type for filtering.&lt;br&gt;&lt;ul&gt;&lt;li&gt;0: N.A&lt;/li&gt; &lt;li&gt;1: Hard&lt;/li&gt; &lt;li&gt;2: Soft&lt;/li&gt;&lt;/ul&gt;
     * @param status to set the product status for filtering.&lt;br&gt;&lt;ul&gt;&lt;li&gt;A: Active&lt;/li&gt; &lt;li&gt;I: Inactive&lt;/li&gt;&lt;/ul&gt;
     */
    public getUniformsUsingGETWithHttpInfo(page: number, size: number, skuId?: string, name?: string, capType?: number, status?: string, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/tailoringManagement/uniforms';

        let queryParameters = new HttpParams();

        // verify required parameter 'page' is not null or undefined
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getUniformsUsingGET.');
        }
        // verify required parameter 'size' is not null or undefined
        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling getUniformsUsingGET.');
        }
        if (skuId !== undefined) {
            queryParameters = queryParameters.append('skuId', <any>skuId);
        }

        if (name !== undefined) {
            queryParameters = queryParameters.append('name', <any>name);
        }

        if (capType !== undefined) {
            queryParameters = queryParameters.append('capType', <any>capType);
        }

        if (status !== undefined) {
            queryParameters = queryParameters.append('status', <any>status);
        }

        if (page !== undefined) {
            queryParameters = queryParameters.append('page', <any>page);
        }

        if (size !== undefined) {
            queryParameters = queryParameters.append('size', <any>size);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
    }

    /**
     * saveTailorUniforms
     * Save Tailor&#39;s Uniforms
     * @param id to set the tailorID to be retrieved.
     * @param tailorProducts tailorProducts
     */
    public saveTailorUniformsUsingPOSTWithHttpInfo(id: number, tailorProducts: Array<models.TailorProductDTO>, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/tailoringManagement/tailors/${id}/uniforms'
            .replace('${' + 'id' + '}', String(id));

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling saveTailorUniformsUsingPOST.');
        }
        // verify required parameter 'tailorProducts' is not null or undefined
        if (tailorProducts === null || tailorProducts === undefined) {
            throw new Error('Required parameter tailorProducts was null or undefined when calling saveTailorUniformsUsingPOST.');
        }

        return this.httpClient.post(path, tailorProducts, { withCredentials: true });
    }

    /**
     * saveTailor
     * Save new or update existed tailor
     * @param request Request body for save tailor.
     */
    public saveTailorUsingPOSTWithHttpInfo(request: models.LocationDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/tailoringManagement/tailors';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveTailorUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

    /**
     * SaveUniform
     * Add new or update uniform
     * @param request Request body to save uniform.
     */
    public saveUniformUsingPOSTWithHttpInfo(request: models.ProductDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/tailoringManagement/uniforms';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling saveUniformUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

}
