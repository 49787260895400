/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class FeedbackManagementApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient,) {
        
    }

    /**
     * getFeedbackDetail
     * Get feedback detail
     * @param id Feedback id
     */
    public getFeedbackDetailUsingGET(id: number, extraHttpRequestParams?: any): Observable<models.GetFeedbacksResponse> {
        return this.getFeedbackDetailUsingGETWithHttpInfo(id, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getFeedbacks
     * Get feedbacks
     * @param page to set the index of pages to retrieve the order. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param subject to indicate feedback subject to be retrieved. If it is null, all available subjects will be returned. subject map: &lt;br&gt;&lt;ul&gt;&lt;li&gt;1: CREDITS_RELATED&lt;/li&gt; &lt;li&gt;2: ORDER_RELATED&lt;/li&gt; &lt;li&gt;3: ACCOUNT_RELATED&lt;&lt;/li&gt; &lt;li&gt;4: PRODUCT_FEEDBACK/li&gt; &lt;li&gt;5: SUGGESTIONS&lt;/li&gt; &lt;li&gt;6: TAILORING_AND_NAMETAG&lt;/li&gt; &lt;li&gt;7: GENERAL_INQUIRIES&lt;/li&gt; &lt;/ul&gt;
     * @param name to find by serviceman name who submit the feedback
     */
    public getFeedbacksUsingGET(page: number, size: number, subject?: number, name?: string, extraHttpRequestParams?: any): Observable<models.GetFeedbacksResponse> {
        return this.getFeedbacksUsingGETWithHttpInfo(page, size, subject, name, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * updateFeedbackStatus
     * Update feedback status
     * @param request request
     */
    public updateFeedbackStatusUsingPOST(request: models.UpdateFeedbackStatusRequest, extraHttpRequestParams?: any): Observable<models.UpdateFeedbackStatusResponse> {
        return this.updateFeedbackStatusUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * getFeedbackDetail
     * Get feedback detail
     * @param id Feedback id
     */
    public getFeedbackDetailUsingGETWithHttpInfo(id: number, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/feedbackManagement/feedbacks/${id}'
            .replace('${' + 'id' + '}', String(id));

        // verify required parameter 'id' is not null or undefined
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getFeedbackDetailUsingGET.');
        }
        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getFeedbacks
     * Get feedbacks
     * @param page to set the index of pages to retrieve the order. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param subject to indicate feedback subject to be retrieved. If it is null, all available subjects will be returned. subject map: &lt;br&gt;&lt;ul&gt;&lt;li&gt;1: CREDITS_RELATED&lt;/li&gt; &lt;li&gt;2: ORDER_RELATED&lt;/li&gt; &lt;li&gt;3: ACCOUNT_RELATED&lt;&lt;/li&gt; &lt;li&gt;4: PRODUCT_FEEDBACK/li&gt; &lt;li&gt;5: SUGGESTIONS&lt;/li&gt; &lt;li&gt;6: TAILORING_AND_NAMETAG&lt;/li&gt; &lt;li&gt;7: GENERAL_INQUIRIES&lt;/li&gt; &lt;/ul&gt;
     * @param name to find by serviceman name who submit the feedback
     */
    public getFeedbacksUsingGETWithHttpInfo(page: number, size: number, subject?: number, name?: string, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/feedbackManagement/feedbacks';

        let queryParameters = new HttpParams();

        // verify required parameter 'page' is not null or undefined
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getFeedbacksUsingGET.');
        }
        // verify required parameter 'size' is not null or undefined
        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling getFeedbacksUsingGET.');
        }
        if (subject !== undefined) {
            queryParameters = queryParameters.append('subject', <any>subject);
        }

        if (name !== undefined) {
            queryParameters = queryParameters.append('name', <any>name);
        }

        if (page !== undefined) {
            queryParameters = queryParameters.append('page', <any>page);
        }

        if (size !== undefined) {
            queryParameters = queryParameters.append('size', <any>size);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
    }

    /**
     * updateFeedbackStatus
     * Update feedback status
     * @param request request
     */
    public updateFeedbackStatusUsingPOSTWithHttpInfo(request: models.UpdateFeedbackStatusRequest, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/feedbackManagement/updateStatus';
        
        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling updateFeedbackStatusUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

}
