<!-- Transaction History -->
<div class="col-md-12 hidden-print">
    <div class="collapse-box">
        <div class="collapse-title">
            <h1>Transaction History</h1>
            <div class="btn-toggle-collapse-wrapper">
                <a class="btn-toggle-collapse" data-bs-target="#history1" data-bs-toggle="collapse"></a>
            </div>
        </div>
        <div id="history1" class="collapse show">
            <div class="box-content">
                <div class="find-box">
                    <table>
                        <tr>
                            <td valign="top">
                                <div class="ns-radio radio">
                                    <input id="searchOption1" type="radio" name="optionsRadios" id="optionsRadios1" (click)="searchOption='1'; searchOrder();"
                                        [checked]="searchOption === '1'">
                                    <label for="optionsRadios1"> </label>
                                </div>
                            </td>
                            <td valign="top" class="title">Time Range</td>
                            <td class="radio-content">
                                <select [(ngModel)]="timeRangeOption" [disabled]="searchOption === '2'" (change)="searchOrder();" name="collection-point-list"
                                    class="collection-point-list form-control">
                                    <option value="1">Past One Month</option>
                                    <option value="2">Past Three Months</option>
                                    <option value="3">Past Six Months</option>
                                    <option value="4">Past One Year</option>
                                </select>
                            </td>
                        </tr>
                    </table>
                    <table>
                        <tr>
                            <td valign="top">
                                <div class="ns-radio radio">
                                    <input id="searchOption2" type="radio" name="optionsRadios" id="optionsRadios2" (click)="searchOption='2'; enableMatDatePicker();" [checked]="searchOption === '2'">
                                    <label for="optionsRadios2"> </label>
                                </div>
                            </td>
                            <td valign="top" class="title">Transaction from</td>
                            <td class="radio-content">
                                <mat-form-field appearance="none">
                                    <input matInput [matDatepicker]="fromDate" id="fromDate" type="text" [formControl]="fromDateForm.controls.date" 
                                        [min]="minDate" [max]="maxDate" (click)="fromDate.open()"
                                        placeholder="Please Select" class="form-control clickable date-input"/>
                                    <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                                    <mat-datepicker #fromDate></mat-datepicker>
                                </mat-form-field>
                            </td> to
                            <td class="radio-content">
                                <mat-form-field appearance="none">
                                    <input matInput [matDatepicker]="toDate" id="toDate" type="text" [formControl]="toDateForm.controls.date" 
                                        [min]="minDate" [max]="maxDate" (click)="toDate.open()"
                                        placeholder="Please Select" class="form-control clickable date-input"/>
                                    <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                                    <mat-datepicker #toDate></mat-datepicker>
                                </mat-form-field>
                            </td>
                            <td class="radio-content">
                                <button id="btnFind" [attr.disabled]="searchOption === '1' ? '' : null" type="button" class="btn" (click)="searchOrder();">Find</button>
                            </td>
                        </tr>
                    </table>
                </div>
                <div id="error" *ngIf="errorMsg != null" class="alert alert-danger box-content">{{errorMsg}}</div>
                <div id="data" *ngIf="transactionHistoryService.orders != null && transactionHistoryService.orders.length > 0">
                    <table class="table orders">
                        <thead>
                            <tr>
                                <th>Order No.</th>
                                <th>Order Date</th>
                                <th>Collection Point</th>
                                <th>No. of Items</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr data-bs-toggle="modal" data-bs-target="#item-detail-pop-up" (click)="viewOrderDetail(order);" *ngFor="let order of getItemsToDisplay()">
                                <td class="number">
                                    <a href="javascript:void(0);" data-bs-target="#item-detail-pop-up">{{order.orderRefNo}}</a>
                                </td>
                                <td class="date">{{order.orderDate}}</td>
                                <td class="point">{{order.collectionPointName}}</td>
                                <td class="qty">{{order.totalQuantity}}</td>
                                <td class="status">{{order.status}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="pagination">
                        <button type="button" class="btn btn-square" [disabled]="prevPaginationDisabled" (click)="gotoFirstPage()">
                            <span class="glyphicon glyphicon-step-backward" aria-hidden="true"></span>
                        </button>
                        <button type="button" class="btn btn-square" [disabled]="prevPaginationDisabled" (click)="gotoPreviousPage()">
                            <span class="glyphicon glyphicon-triangle-left" aria-hidden="true"></span>
                        </button>
                        <div class="page">
                            <input #page type="text" class="form-control" [value]="currentPage" (keyup)="gotoPage(page.value, $event)"> / {{pageCount}}
                        </div>
                        <button type="button" class="btn btn-square" [disabled]="nextPaginationDisabled" (click)="gotoNextPage()">
                            <span class="glyphicon glyphicon-triangle-right" aria-hidden="true"></span>
                        </button>
                        <button type="button" class="btn btn-square" [disabled]="nextPaginationDisabled" (click)="gotoLastPage()">
                            <span class="glyphicon glyphicon-step-forward" aria-hidden="true"></span>
                        </button>
                    </div>
                </div>
                <div id="nodata" class="alert alert-info text-center" role="alert" *ngIf="transactionHistoryService.orders != null && transactionHistoryService.orders.length == 0">
                    <i class="fa fa-info-circle fa-2x"></i>
                    <p>No transaction is found</p>
                </div>
                <div class="text-center" *ngIf="transactionHistoryService.orders == null">
                    <i class="fa fa-refresh fa-2x spinner text-primary"></i>
                </div>
            </div>
        </div>
    </div>
</div>

<app-order-detail-modify [orderToViewDetail]="orderToViewDetail"></app-order-detail-modify>