import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { Subscription } from 'rxjs';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-footer-new',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  public currentUrl;
  public lastUpdatedDate: Date;

  isAuthenticated = false;
  private authenticationSub: Subscription;

  constructor(private authService: AuthService) { }

  ngOnInit() {
    this.checkLogin();

    this.getLastUpdatedDate(environment.timeStamp);
  }

  ngOnDestroy() {
    if(this.authenticationSub) {
      this.authenticationSub.unsubscribe();
    }
  }

  // get login status
  checkLogin() {
    this.authenticationSub = this.authService.isAuthenticated.subscribe(isAuthenticated => {
      this.isAuthenticated = isAuthenticated;
    });
  }

  getLastUpdatedDate(date: string) {
    this.lastUpdatedDate = new Date(date);
  }
}
