<div id="printModal" class="modal-content" *ngIf="orderToViewDetail">
  <div class="modal-header">
    <h1 class="modal-title">Order No: {{orderToViewDetail.orderRefNo}}</h1>
  </div>
  <div class="modal-body">
    <div class="order-info">
      <table class="details">
        <tr>
          <td class="left">Name</td>
          <td class="right">{{orderToViewDetail.name}}</td>
        </tr>
        <tr>
          <td class="left">NRIC</td>
          <td class="right">{{orderToViewDetail.nric}}</td>
        </tr>
      </table>
      <table class="details">
        <tr>
          <td class="left">No. of Items</td>
          <td class="right">{{orderToViewDetail.totalQuantity}}</td>
        </tr>
        <tr>
          <td class="left">Order Date</td>
          <td class="right">{{orderToViewDetail.orderDate}}</td>
        </tr>
      </table>
      <table class="details">
        <tr>
          <td class="left">{{orderToViewDetail.deliveryModeCode === DeliveryMode.HOME_DELIVERY || orderToViewDetail.deliveryModeCode ===
            DeliveryMode.COLLECT_AT_TAILOR ? 'Collection Mode' : 'Collection Point'}}</td>
          <td class="right">{{orderToViewDetail.collectionPointName}}</td>
        </tr>
        <tr *ngIf="orderToViewDetail.deliveryModeCode === DeliveryMode.HOME_DELIVERY">
          <td class="left">Address</td>
          <td class="right">
            <div>{{orderToViewDetail.collectionPointAddress.blkNo}} {{orderToViewDetail.collectionPointAddress.streetName}}</div>
            <div>{{orderToViewDetail.collectionPointAddress.unitNo}}</div>
            <div>{{orderToViewDetail.collectionPointAddress.buildingName}}</div>
            <div>Singapore {{orderToViewDetail.collectionPointAddress.postalCode}}</div>
          </td>
        </tr>
      </table>
    </div>
    <table class="table purchases" *ngIf="orderToViewDetail.orderItems.length > 0" id="availableItems">
      <thead>
        <tr>
          <th class="check">
            <div class="checkbox-square checkbox" *ngIf="hasPurchasable">
              <input type="checkbox" id="select-item-detail-all">
              <label class="cbox" for="select-item-detail-all"></label>
            </div>
          </th>
          <th>Item(s)</th>
          <th *ngIf="orderToViewDetail.orderType == OrderType.ADVANCED_ORDER || orderToViewDetail.orderType == OrderType.WALK_IN">Size</th>
          <th *ngIf="orderToViewDetail.orderType == OrderType.TAILOR">Tailor</th>
          <th *ngIf="orderToViewDetail.orderType == OrderType.NAME_TAG">Name</th>
          <th class="qty">Qty</th>
          <th class="price">Unit Price</th>
          <th class="price">Subtotal</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of orderToViewDetail.orderItems">
          <td class="checkbox-width">
            <div class="checkbox-square checkbox" *ngIf="item.purchasable">
              <input type="checkbox" id="select-item-detail-{{item.orderItemId}}">
              <label class="cbox" for="select-item-detail-{{item.orderItemId}}"></label>
            </div>
          </td>
          <td class="item">
            <div class="item-image">
              <img [src]="imageStore[item.orderItemId] || storeConfigService.dummyImgPath" alt="">
            </div>
            <div class="item-name">{{item.productName}}</div>
          </td>
          <td class="block size" *ngIf="orderToViewDetail.orderType == OrderType.ADVANCED_ORDER || orderToViewDetail.orderType == OrderType.WALK_IN">{{ item.skuName ? item.skuName : 'N.A' }}</td>
          <td class="block tailor" *ngIf="orderToViewDetail.orderType == OrderType.TAILOR">{{ item.tailorName }}</td>
          <td class="block text" *ngIf="orderToViewDetail.orderType == OrderType.NAME_TAG">{{ item.nameTagText }}</td>
          <td class="block qty">{{item.quantity}}</td>
          <td class="block price">{{item.unitPrice | priceFormat}}</td>
          <td class="block price total">{{item.totalPrice | priceFormat}}</td>
        </tr>
      </tbody>
      <tfoot class="cart-footer" style="display: table-row-group;">
        <tr *ngIf="orderToViewDetail.deliveryFee > 0">
          <td colspan="5" align="right" style="text-align: right;">Delivery Fee</td>
          <td style="text-align: right;">{{orderToViewDetail.deliveryFee | priceFormat}}</td>
        </tr>
        <tr class="{{orderToViewDetail.deliveryFee > 0 ? 'balance-after' : ''}}">
          <td colspan="5" align="right" style="text-align: right;">Total Price</td>
          <td style="text-align: right;">{{orderToViewDetail.totalAmount | priceFormat}}</td>
        </tr>
      </tfoot>
    </table>
    <h2 *ngIf="orderToViewDetail.cancelledItems.length > 0">Item(s) Cancelled</h2>
    <table *ngIf="orderToViewDetail.cancelledItems.length > 0" class="table purchases" id="cancelledItems">
      <thead>
        <tr>
          <th class="check"></th>
          <th>Item(s)</th>
          <th *ngIf="orderToViewDetail.orderType == OrderType.ADVANCED_ORDER || orderToViewDetail.orderType == OrderType.WALK_IN">Size</th>
          <th *ngIf="orderToViewDetail.orderType == OrderType.TAILOR">Tailor</th>
          <th *ngIf="orderToViewDetail.orderType == OrderType.NAME_TAG">Name</th>
          <th class="qty">Qty</th>
          <th class="price">Unit Price</th>
          <th class="price">Subtotal</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of orderToViewDetail.cancelledItems">
          <td class="checkbox-width"></td>
          <td class="item">
            <div class="item-image">
              <img [src]="imageStore[item.orderItemId]" alt="">
            </div>
            <div class="item-name">{{item.productName}}</div>
          </td>
          <td class="block size" *ngIf="orderToViewDetail.orderType == OrderType.ADVANCED_ORDER || orderToViewDetail.orderType == OrderType.WALK_IN">{{ item.skuName ? item.skuName : 'N.A' }}</td>
          <td class="block tailor" *ngIf="orderToViewDetail.orderType == OrderType.TAILOR">{{ item.tailorName }}</td>
          <td class="block text" *ngIf="orderToViewDetail.orderType == OrderType.NAME_TAG">{{ item.nameTagText }}</td>
          <td class="block qty">{{item.quantity}}</td>
          <td class="block price">{{item.unitPrice | priceFormat}}</td>
          <td class="block price total">{{item.totalPrice | priceFormat}}</td>
        </tr>
      </tbody>
      <tfoot class="cart-footer" style="display: table-row-group;">
        <tr>
          <td colspan="5" align="right" style="text-align: right;">Refunded Credits</td>
          <td style="text-align: right;">{{orderToViewDetail.cancelledAmount | priceFormat}}</td>
        </tr>
        <tr>
          <td colspan="5" align="right" style="text-align: right;">Expired Credits</td>
          <td style="text-align: right;">{{getExpiredCredits(orderToViewDetail.cancelledAmount, orderToViewDetail.cancelledItems) | priceFormat}}</td>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
