import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { catchError, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  maintenanceFileURL = environment.maintenanceURL;

  constructor(
    private httpClient: HttpClient
  ) { }

  getMaintenanceFromS3(): Observable<any> {
    return this.httpClient.get(this.maintenanceFileURL).pipe(tap(
      data => {
        console.log(data);
      }
    ), catchError(
      err => {
        return err;
      }
    ));
  }
}
