<a href="" (click)="initGuideAssetAndText()" data-bs-toggle="modal" [attr.data-bs-target]="'#guide-pop-up-' + makeGuideId()">view guide</a>

<!-- Modal -->
<div id="guide-pop-up-{{makeGuideId()}}" class="modal fade guide" role="dialog">
  <div class="modal-dialog modal-md">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title">{{guideType}} Measurement</h1>
        <button type="button" class="close" data-bs-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">

        <ng-container *ngIf="guideAssetUrl">
          <ng-container *ngIf="isImageGuide(); else elseBlock">
            <div class="guide-visual">
              <img class="guide-visual" [src]="guideAssetUrl" alt="{{guideType}} Measurement">
            </div>
          </ng-container>
          <ng-template #elseBlock>
            <div class="guide-visual">
              <video width="300" height="170" onclick="this.paused ? this.play() : this.pause();" controls>
                <source [src]="guideAssetUrl" type='video/mp4' />
              </video>
            </div>
          </ng-template>
          <p>{{guideHelpText}}</p>
        </ng-container>

      </div>
    </div>

  </div>
</div>