import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NotificationsService } from 'assets/lib/angular2-notifications';
import { finalize } from "rxjs";

import { CatalogueService } from '../../../services/catalogue.service';
import { UserProfileService } from '../../../services/user-profile.service';
import { FeedbackService } from './../../../services/feedback.service';
import { CreateFeedbackDTO } from './../../../shared/backend-api/emart/model/CreateFeedbackDTO';
import { Constants } from './../../../shared/global-constants/constants';
import { Modal } from 'bootstrap';

declare const jQuery: any

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {

  private details: string;

  /* Default subject is "General Inquiries". This value has to be changed if the values for
   * the subject selections in the backend is changed
  */
  private selectedSubject = 1;

  public feedbackFormGroup: UntypedFormGroup

  private productId: number;
  private productName: string;

  private isProductFeedback = false;

  public notificationOptions = {
    position: ['top', 'right'],
    lastOnBottom: false,
    timeOut: 2000
  };

  public isSubmitting = false;
  public isRedirecting = false;

  private feedbackFormGroupMessages = {
    MobileNumberCtrl: { required: 'Empty mobile number', pattern: 'Invalid mobile number' },
    EmailCtrl: { required: 'Empty email address', pattern: 'Invalid email address' },
    DetailCtrl: { required: 'Empty details' }
  };

  public ErrorDisplay;

  public feedbackSubmissionTitle = 'Success';
  public feedbackSubmissionContent = `Feedback submission is successful. Do you wish to submit another feedback?`;

  constructor(public userProfileServce: UserProfileService,
    public feedbackService: FeedbackService,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationsService,
    public catalogueService: CatalogueService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.productId = params['productId'];

      // product details should be loaded already since only entry to product feedback is from product details page
      try {
        if (this.productId) {
          this.isProductFeedback = true;
          this.selectedSubject = 5;
          this.productName = this.catalogueService.productDetail.productName;
        }
      } catch (err) {
        this.router.navigateByUrl('/dashboard');
      }
    });

    this.userProfileServce.loadUserProfile().subscribe(
      response => {
        this.createFormGroup();
      }
    );
    this.feedbackService.getSubjectsSelection().subscribe();
  }

  createFormGroup() {
    const userProfile = this.userProfileServce.userProfile;

    this.feedbackFormGroup = new UntypedFormGroup({
      MobileNumberCtrl: new UntypedFormControl(userProfile.preferredMobile === 1 ?
        userProfile.alternateMobile : userProfile.mobile, [Validators.required, Validators.pattern(Constants.PhoneRegex)]),
      EmailCtrl: new UntypedFormControl(userProfile.preferredEmail === 1 ?
        userProfile.alternateEmail : userProfile.email, [Validators.required, Validators.pattern(Constants.EmailRegex)]),
      DetailCtrl: new UntypedFormControl('', [Validators.required])
    })
    this.feedbackFormGroup.valueChanges.subscribe(data => this.scanFormGroupForError()); // validate onValueChanged
  }

  scanFormGroupForError() {
    this.resetErrorDisplay(); // Flush old error
    const FG = this.feedbackFormGroup;
    if (!FG) { return; }

    // Match new errors and push to error message array
    const FGMsgs = this.feedbackFormGroupMessages;
    Object.keys(FG.controls).forEach((ctrlname) => {
      const currentCtrl = FG.get(ctrlname);
      if (currentCtrl && currentCtrl.dirty && !currentCtrl.valid && currentCtrl.errors) {
        const errMsgObj = FGMsgs[ctrlname];
        Object.keys(currentCtrl.errors).forEach(element => {
          const errMsg = errMsgObj[element];
          if (errMsg) { this.ErrorDisplay[ctrlname] = errMsg; }
        }
        );
      }
    });
  }

  private resetErrorDisplay() {
    this.ErrorDisplay = {};
  }

  // Use event.target.selectedIndex instead of event.target.value as the latter will return the text value
  onSubjectChange(event: any): void {
    this.selectedSubject = Number(event.target.selectedIndex) + 1;
  }

  doSubmit() {
    const postBody: CreateFeedbackDTO = {};
    postBody.mobile = this.feedbackFormGroup.controls['MobileNumberCtrl'].value;
    postBody.email = this.feedbackFormGroup.controls['EmailCtrl'].value;
    postBody.subject = this.selectedSubject;
    postBody.details = this.feedbackFormGroup.controls['DetailCtrl'].value;
    postBody.productId = this.productId;

    this.isSubmitting = true;
    this.feedbackService.submitFeedback(postBody)
      .pipe(finalize(() => this.isSubmitting = false))
      .subscribe(
      response => {
        if (this.isProductFeedback) {
          this.notificationService.success(
            'Feedback',
            'Submission successful'
          );
          this.isRedirecting = true;
          setTimeout(() => {
            this.location.back();
          }, 2000);
        } else {         
          const element = document.getElementById('feedback-submission-pop-up') as HTMLElement;
          const myModal = new Modal(element);
          myModal.show();
        }
      },
      err => {
        this.notificationService.warn(
          'Feedback',
          'Submission failed'
        );
      }
      )
  }

  resetFeedbackForm(): void {
    this.feedbackFormGroup.controls['DetailCtrl'].reset();
    this.selectedSubject = 1;
    this.resetErrorDisplay();
  }

  redirectToDashBoard(): void {
    this.router.navigate(['/dashboard']);
  }

}
