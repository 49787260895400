/**
 * MHA eMART eService API
 * An application program interface that allows users or systems to interact with eMART API services
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import { Inject, Injectable, Optional } from '@angular/core';
import { map, Observable } from 'rxjs';
import * as models from '../model/models';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from 'environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

/* tslint:disable:no-unused-variable member-ordering */


@Injectable()
export class NameTagApi {
    protected basePath = environment.apiBaseUrl;
    public defaultHeaders: Headers = new Headers();
    public configuration: Configuration = new Configuration();

    constructor(protected httpClient: HttpClient,) {}

    /**
     * getOrderStatusSelectionsForNameTag
     * Get order status selections for name tag
     */
    public getOrderStatusSelectionsForNameTagUsingGET(extraHttpRequestParams?: any): Observable<models.GetOrderStatusSelectionsForNameTagResponse> {
        return this.getOrderStatusSelectionsForNameTagUsingGETWithHttpInfo(extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * getOrdersForNameTag
     * Get orders for name tag
     * @param page to set the index of pages to retrieve the order. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param cp to set the list of collection points interested. If it is null, all collection points will be returned.
     * @param deliveryMode to indicate the delivery mode for the orders to be retrieved. If it is null, all delivery mode will be returned.
     * @param from to indicate the start date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of start date will be imposed.
     * @param to to indicate the end date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of end date will be imposed.
     * @param status to indicate the status for the orders to be retrieved. If it is null, all available status will be returned. status map: &lt;br&gt;&lt;ul&gt;&lt;li&gt;25: NAME_TAG_CHECKED_OUT&lt;/li&gt; &lt;li&gt;26: NAME_TAG_PROCESSING&lt;/li&gt; &lt;li&gt;27: NAME_TAG_FORFEITED&lt;/li&gt;&lt;/ul&gt;
     */
    public getOrdersForNameTagUsingGET(page: number, size: number, cp?: Array<number>, deliveryMode?: number, from?: string, to?: string, status?: number, extraHttpRequestParams?: any): Observable<models.GetOrdersForNameTagResponse> {
        return this.getOrdersForNameTagUsingGETWithHttpInfo(page, size, cp, deliveryMode, from, to, status, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }

    /**
     * updateOrderStatusForNameTag
     * Update orders statuses for name tag
     * @param request request
     */
    public updateOrderStatusForNameTagUsingPOST(request: models.UpdateOrderStatusRequestDTO, extraHttpRequestParams?: any): Observable<models.UpdateOrderStatusForNameTagResponse> {
        return this.updateOrderStatusForNameTagUsingPOSTWithHttpInfo(request, extraHttpRequestParams)
            .pipe(map((response: Response) => {
                return response;
            }));
    }


    /**
     * getOrderStatusSelectionsForNameTag
     * Get order status selections for name tag
     */
    public getOrderStatusSelectionsForNameTagUsingGETWithHttpInfo(extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/nameTag/orderStatusSelections';
        
        return this.httpClient.get(path, { withCredentials: true });
    }

    /**
     * getOrdersForNameTag
     * Get orders for name tag
     * @param page to set the index of pages to retrieve the order. page should be &gt;&#x3D; 1.
     * @param size to set the number of items to be retrieved. size should be &gt;&#x3D; 1
     * @param cp to set the list of collection points interested. If it is null, all collection points will be returned.
     * @param deliveryMode to indicate the delivery mode for the orders to be retrieved. If it is null, all delivery mode will be returned.
     * @param from to indicate the start date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of start date will be imposed.
     * @param to to indicate the end date (yyyy-MM-dd) for the orders to be retrieved. If it is null, no limitation of end date will be imposed.
     * @param status to indicate the status for the orders to be retrieved. If it is null, all available status will be returned. status map: &lt;br&gt;&lt;ul&gt;&lt;li&gt;25: NAME_TAG_CHECKED_OUT&lt;/li&gt; &lt;li&gt;26: NAME_TAG_PROCESSING&lt;/li&gt; &lt;li&gt;27: NAME_TAG_FORFEITED&lt;/li&gt;&lt;/ul&gt;
     */
    public getOrdersForNameTagUsingGETWithHttpInfo(page: number, size: number, cp?: Array<number>, deliveryMode?: number, from?: string, to?: string, status?: number, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/nameTag/orders';

        let queryParameters = new HttpParams();

        // verify required parameter 'page' is not null or undefined
        if (page === null || page === undefined) {
            throw new Error('Required parameter page was null or undefined when calling getOrdersForNameTagUsingGET.');
        }
        // verify required parameter 'size' is not null or undefined
        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling getOrdersForNameTagUsingGET.');
        }
        if (cp) {
            cp.forEach((element) => {
                queryParameters = queryParameters.append('cp', <any>element);
            })
        }

        if (deliveryMode !== undefined) {
            queryParameters = queryParameters.append('deliveryMode', <any>deliveryMode);
        }

        if (from !== undefined) {
            queryParameters = queryParameters.append('from', <any>from);
        }

        if (to !== undefined) {
            queryParameters = queryParameters.append('to', <any>to);
        }

        if (status !== undefined) {
            queryParameters = queryParameters.append('status', <any>status);
        }

        if (page !== undefined) {
            queryParameters = queryParameters.append('page', <any>page);
        }

        if (size !== undefined) {
            queryParameters = queryParameters.append('size', <any>size);
        }

        return this.httpClient.get(path, {params: queryParameters, withCredentials: true });
    }

    /**
     * updateOrderStatusForNameTag
     * Update orders statuses for name tag
     * @param request request
     */
    public updateOrderStatusForNameTagUsingPOSTWithHttpInfo(request: models.UpdateOrderStatusRequestDTO, extraHttpRequestParams?: any): Observable<any> {
        const path = this.basePath + '/nameTag/orders/updateStatus';

        // verify required parameter 'request' is not null or undefined
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling updateOrderStatusForNameTagUsingPOST.');
        }

        return this.httpClient.post(path, request, { withCredentials: true });
    }

}
