<!--footer-->
<div class="footermargin hidden-print d-flex justify-content-center mt-auto"></div>
<div class="container-fluid footer-wrapper hidden-print">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <h2 id="copyright">Ministry of Home Affairs - Home&nbsp;Team eMART</h2>
            </div>
            
            <div class="col-md-12 col-sm-12">
                <ul class="nav navbar-nav flex-container">
                    <li class="me-4"><a href="https://tech.gov.sg/report_vulnerability" target='_blank'>Report Vulnerability</a></li>
                    <li class="me-4"><a href="#/mha-emart/privacy-statement" target='_blank'>Privacy Statement</a></li>
                    <li class="me-4"><a href="#/mha-emart/terms-of-use" target='_blank'>Terms of Use</a></li>
                    <li class="me-4"><a href="#/mha-emart/contact-us" target='_blank'>Contact Us</a></li>
                    <li class="me-4"><a href="#/mha-emart/faq" target='_blank'>FAQs</a></li>
                    <li class="me-4" *ngIf="isAuthenticated"><a href="#/mha-emart/feedback">Feedback</a></li>
                    <li class="me-4" *ngIf="isAuthenticated"><a href="#/mha-emart/collection-point-list">Collection Points</a></li>
                    <li class="me-4" *ngIf="isAuthenticated"><a href="#/mha-emart/tailor-list">Tailor List</a></li>
                </ul>
            </div>
            <div class="col-md-12 col-sm-12">
                <div class="col-12 float-end">
                    <p class="text-start text-lg-end">
                        &copy; {{lastUpdatedDate | date: 'yyyy'}}, Ministry of Home Affairs
                        <br>
                        Last Updated {{lastUpdatedDate | date: 'dd MMMM yyyy'}}
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>