import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, catchError, throwError, tap } from 'rxjs';

import { CatalogueApi, UserSkuMeasurementDTO } from './../shared/backend-api/emart';
import { UserCategoryDTO } from './../shared/backend-api/emart/model/UserCategoryDTO';
import { UserProductDetailsDTO } from './../shared/backend-api/emart/model/UserProductDetailsDTO';
import { ResourceService } from './resource.service';

export interface MeasurementModel {
  types: string[],
  details: MeasurementSize[],
}

interface MeasurementSize {
  skuName: string,
  sizes: string[]
}

// Singleton data storage for eMart's catalogue.
// Read and write all catalogue, products, sku, etc related ojects here.
@Injectable()
export class CatalogueService {

  public selectedCategory: string = null;
  public categories: UserCategoryDTO[];
  public categoriesForDashboard: UserCategoryDTO[];
  public productDetail: UserProductDetailsDTO;
  public relatedProducts: UserProductDetailsDTO[];
  public productImages = {};
  public categoryImages = {};
  public measureMentData: MeasurementModel;
  public measurementUnit: string = null;

  constructor(private catalogueApi: CatalogueApi,
    private resourceService: ResourceService,
    private router: Router) { }

  /**
   * Subscribe this Observable or pass it to resolve guards.
   * @returns {Observable<any>}
   * @memberof CatalogueService
   */
  loadCategories(): Observable<any> {
    if (this.router.url.indexOf('All') > -1) {
      this.selectedCategory = 'All';
    }

    if (this.categories) {
      return of('No need to reload categories.');
    } else {
      return this.forceLoadCategories();
    }
  }

  /**
   * Force reload categories.
   * Subscribe this Observable or pass it to resolve guards.
   * @returns {Observable<any>}
   * @memberof CatalogueService
   */
  forceLoadCategories(): Observable<any> {

    return this.catalogueApi.getCategoriesUsingGET()/*.retry(2)*/.pipe(tap(
      payload => {
        console.log('category payload: ' + payload);
       //when status == 200 & 204, execute happy flow
        if (payload.status == 200 || payload.status == 204){ 
          this.categories = payload.data as UserCategoryDTO[];
          if (this.categories.length <= 6) {
            this.categoriesForDashboard = this.categories;
          } else {
            this.categoriesForDashboard = this.categories.slice(0, 6);
          }
          this.fetchImagesForCategory();

          // other than 200, 204, 401, 403, raise error
        }else if (payload.status != 401 && payload.status != 403){
          throw throwError(payload);
        }
      }
    ), catchError(
      err => {
        console.log('category error:' +err);
        return err;
      }
      ));
  }

  /**
 * Subscribe this Observable or pass it to resolve guards.
 * @returns {Observable<any>}
 * @memberof ProductDetailService
 */
  loadProductDetail(productId: number): Observable<any> {
    if (this.productDetail) {
      return of('No need to reload product.');
    } else {
      this.forceLoadProductDetail(productId);
    }
  }

  /**
* Force reload categories.
* Subscribe this Observable or pass it to resolve guards.
* @returns {Observable<any>}
* @memberof ProductDetailService
*/
  forceLoadProductDetail(productId: number): Observable<any> {
    return this.catalogueApi.getProductDetailUsingGET(productId).pipe(tap(
      response => {
        //when status == 200 & 204, execute happy flow
        if (response.status == 200 || response.status == 204){ 
          this.productDetail = response.data as UserProductDetailsDTO;
          this.buildMeasurementData();
          this.fetchImagesForProduct();
          this.fetchImageForRelatedProducts();

        // other than 200, 204, 401, 403, raise error
        }else if (response.status != 401 && response.status != 403){
          throw throwError(response);
        }
      }
    ), catchError(
      err => {
        return err;
      }
      ));
  }

  // Product can contain multiple images
  fetchImagesForProduct(): void {
    const productImages = this.productDetail.images;
    if (productImages && productImages.length > 0) {
      for (const productImage of productImages) {
        if (this.productImages[productImage.resourceId]) {
          continue;
        }

        this.resourceService.loadImageWithId(productImage.resourceId)
          .then(img => {
            this.productImages[productImage.resourceId] = img;
          });
      }
    }
  }

  fetchImagesForCategory(): void {
    console.log('fetchImagesForCategory');
    for (const category of this.categories) {
      this.resourceService.loadImageWithId(category.image.resourceId)
        .then(img => {
          this.categoryImages[category.image.resourceId] = img;
        });
    }
    console.log('this.categoryImages');
    console.log(this.categoryImages);
  }

  // Related products will only have one image
  fetchImageForRelatedProducts(): void {
    const relatedProducts = this.productDetail.relatedProducts;

    for (const relatedProduct of relatedProducts) {
      const relatedProductImage = relatedProduct.image;
      if (relatedProductImage) {
        if (this.productImages[relatedProductImage.resourceId]) {
          continue;
        } else {
          this.resourceService.loadImageWithId(relatedProductImage.resourceId)
            .then(img => {
              this.productImages[relatedProductImage.resourceId] = img;
            })
        }
      }
    }
  }

  /* To display the product images after the productImages have been populated
   *
   */

  getImagesToDisplay(): any[] {
    if (this.productDetail) {
      const productImages = this.productDetail.images;
      if (productImages && productImages.length > 0) {
        return productImages;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  getRelatedProducts(): any[] {
    if (this.productDetail) {
      const relatedProducts = this.productDetail.relatedProducts;
      if (relatedProducts && relatedProducts.length > 0) {
        return relatedProducts;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  private createMeasurementDisplayString(size: UserSkuMeasurementDTO) {
    if (size.fromValue !== null && size.toValue !== null) {
      return size.fromValue + ' - ' + size.toValue;
    } else if (size.fromValue !== null) {
      return size.fromValue;
    } else if (size.toValue !== null) {
      return size.toValue;
    } else {
      return '-';
    }
  }

  buildMeasurementData() {
    if (!this.productDetail.sizeGuides || this.productDetail.sizeGuides.length === 0) {
      this.measureMentData = undefined;
      this.measurementUnit = null;
      return;
    }
    const types: string[] = [];
    const sizeMap: any = {};
    const skuList: string[] = [];
    this.measurementUnit = this.productDetail.sizeGuides[0].measurements[0] ?
                              this.productDetail.sizeGuides[0].measurements[0].measurementUnit : null;
    for (const measureType of this.productDetail.sizeGuides) {
      types.push(measureType.measurementType);
      for (const size of measureType.measurements) {
        if (!sizeMap[size.skuName]) {
          sizeMap[size.skuName] = [this.createMeasurementDisplayString(size)];
          skuList.push(size.skuName);
        } else {
          sizeMap[size.skuName].push(this.createMeasurementDisplayString(size));
        }
      }
    }
    const sizes: MeasurementSize[] = [];
    for (const sku of skuList) {
      const oneSkuSizes = sizeMap[sku];
      const oneSize: MeasurementSize = {
        skuName: sku,
        sizes: oneSkuSizes
      }
      sizes.push(oneSize);
    }
    const result: MeasurementModel = {
      types: types,
      details: sizes
    }
    this.measureMentData = result;
  }

  selectCategory(categoryName: string) {
    this.selectedCategory = categoryName;
    this.router.navigateByUrl('/view-products/' + encodeURIComponent(this.selectedCategory));
  }

  selectedSkuStatus(skuId: number):boolean{
    skuId = Number(skuId);
    if(skuId === -1){
        return true;
    }
    for(const sku of this.productDetail.skus){
      if(sku.skuId === skuId){
        if(sku.status === true){
          return true;
        }
        else if(sku.status === false){
          return false;
        }
      }
    }
  }
}
